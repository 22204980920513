import React, { Component } from "react"
import BzrAxios from '../../utils/BzrAxios'
import { Nav, NavDropdown, Form, FormControl, Button } from "react-bootstrap"
import { NavLink, Link } from 'react-router-dom'
import { AppStateContext, LangContext, PolicyContext } from '../../utils/Contexts'
import { getCookie, setCookie } from '../../utils/Cookies'
import t from '../../utils/I18n/I18n'
import { ParentModal } from '../../layouts/Modals/Modals'


interface Props {
    appStates: any
}
interface State {
    showBUChange: boolean
    bus: any
    activeBU: any
}

class AdminNavbarLinks extends Component<Props,State> {
    static contextType = AppStateContext
    activeBU = null

    constructor(props: Props) {
        super(props)
        this.state = {
            showBUChange: false,
            bus: [],
            activeBU: {}
        }
        this.activateCompany = this.activateCompany.bind(this)
    }
    handleBUChange(busIds:any) {
        console.log(busIds)
        this.setState({showBUChange: true})
        if(this.state.bus.length === 0) {

            BzrAxios.orgs({url: `/BusinessUnits?ids=${busIds.join('+')}`}).then((response:any) => {
                console.log(response.data.companies)
                this.setState({bus: response.data.companies, activeBU: this.props.appStates.activeBU})
            }).catch((err) => {
                alert(t('error fetching companies'))
                console.log(err)
            })
        }
    }
    activateCompany(event:any) {
        const ck:any = JSON.parse(getCookie('active-bu'))
        console.log(ck, event.target.value)
        setCookie('active-bu', JSON.stringify({version: ck.version, value: {bid: event.target.value, oid: this.props.appStates.org.id, uid: this.props.appStates.user.id}}), 30)
        window.location.reload()
    }
    render() {
        return (
            <div className="w-100 admin-navbar">
                <Nav>
                <Nav.Item>
                    <Link to="#" className="nav-link"> 
                    <i className="pe-7s-display1" style={{fontSize: "25px"}}/>
                    <p className="d-lg-none">{t('dashboard')}</p> 
                    </Link>
                </Nav.Item>
                {/* <NavDropdown title={notification} id="notification-dropdown">
                    <NavDropdown.Item eventKey="2.2">Notification 1</NavDropdown.Item>
                    <NavDropdown.Item eventKey="2.3">Notification 2</NavDropdown.Item>
                </NavDropdown>
                <Nav.Item>
                    <Link to="#" className="nav-link">
                        <i className="fa fa-search" />
                        <p className="d-lg-none">Search</p>
                    </Link>
                </Nav.Item> */}
                </Nav>
                <PolicyContext.Consumer>
                    {(policies:any) => (
                    <>
                        <Nav className="float-right" id="user-nav-dropdown">
                            <Nav.Item id="active-bu" onClick={() => this.handleBUChange(Object.keys(policies))} title={t('switch company')}>{ this.props.appStates.activeBU ?  this.props.appStates.activeBU.legalName : 'company not set' }</Nav.Item>
                            <NavDropdown
                                className="user-dropdown"
                                title={this.context.user.username ?? ''}
                                id="user-dropdown-right"
                                onClick={(event:any) => {
                                    const a:any = event.currentTarget.children[0]
                                    if(a.getAttribute('aria-expanded')) {
                                        const ddi:any = event.currentTarget.getElementsByTagName('a')
                                        Array.from(ddi).forEach((a:any) => a.classList.remove('active'))
                                        a.click()
                                    }
                                }}
                            >
                                <div >
                                    <div style={{height: "200px"}}>
                                        <NavLink to="/admin/user/profile" className="dropdown-item">{t('profile')}</NavLink>
                                        <NavLink to="/admin/user/preference" className="dropdown-item">{t('preferences')}</NavLink>
                                        <LangContext.Consumer>
                                            {({lang, setLang}) => (
                                            <Form className="admin-language-select">
                                                <Form.Group controlId="language">
                                                    <Form.Control 
                                                        className="form-control custom-select"
                                                        as="select" size="sm"
                                                        value={lang}
                                                        onClick={(event:any) => event.stopPropagation()}
                                                        onChange={(event:any) => {
                                                            setLang(event.target.value)
                                                            window.location.reload()
                                                        }}
                                                        >
                                                        <option value='en'>en</option>
                                                        <option value='es'>es</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form>)}
                                        </LangContext.Consumer>
                                    </div>
                                    <NavDropdown.Divider />
                                    <NavLink to="/admin/signout" className="dropdown-item"><i className="pe-7s-power" /> {t('sign out')}</NavLink>
                                </div>
                            </NavDropdown>
                            <Nav.Item className="ml-3">
                                <Link to="/admin/menu" className="nav-link"> 
                                    <i className="ti-layout-grid3-alt" style={{fontSize: "20px"}} title="menu"/>
                                    <p className="d-lg-none">{t('menu')}</p> 
                                </Link>
                            </Nav.Item>                        
                        </Nav>
                        <ParentModal 
                            title={t('change operation to:')}
                            show={this.state.showBUChange}  
                            onHide={() => this.setState({showBUChange: false})}
                            >
                                <FormControl as="select" custom onChange={this.activateCompany} value={this.state.activeBU.id}>
                                    {this.state.bus.map((bu:any, key:any) => (<option key={key} value={bu.id} > {bu.legalName}</option>))}
                                </FormControl>
                                <br /><br />
                                <Button className="mx-auto" variant="danger" onClick={() => this.setState({showBUChange: false})}>{t('close')}</Button>
                        </ParentModal>
                    </>
                    )}
                </PolicyContext.Consumer>
            </div>
        )
  }
}

export default AdminNavbarLinks;
