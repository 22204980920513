import React from 'react'
import { FormControl, Table, NavDropdown, Button, Row, Col} from 'react-bootstrap'
import t from '../../utils/I18n/I18n'

interface Props {
    id: any
    items:any
    liftUpOption:any
    pkey:string
    label?:string
    filters?:string[]
    whenEmpty?:string
    placeholder?:string
    nextFocus?:string
    disabled?:boolean
    autoComplete?:string
    handleClear?:any
}

interface States {
    initValues: any[]
    listItems: any[]
    prevKeyEvent: any
    value: string
    selects: any[]
    show:boolean
    isActive:boolean
}

class FilterSearch extends React.Component<Props, States> {
    constructor(props: Props){
        super(props)
        this.state = {
            initValues: [],
            listItems: [],
            prevKeyEvent: null,
            value: '',
            selects: [],
            show:false,
            isActive:false
        }
        this.updateList = this.updateList.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleSelectAll = this.handleSelectAll.bind(this)
        this.searchItem = this.searchItem.bind(this)
        this.handleOnKeyUp = this.handleOnKeyUp.bind(this)
        this.clickListener = this.clickListener.bind(this)
    }

    componentDidUpdate() {
        //if(this.props.items && this.props.items.values.length > 0 && JSON.stringify(this.props.items.values) !== JSON.stringify(this.state.listItems)) {
        if(this.props.items && this.props.items.values.length > 0 && JSON.stringify(this.props.items.values) !== JSON.stringify(this.state.initValues)) {
            const selects:any = {}
            this.props.items.values.forEach((i:any) => selects[i] = {selected: false})
            this.setState({listItems: this.props.items.values, initValues: this.props.items.values, selects})
        }
        window.addEventListener('click', this.clickListener);
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.clickListener)
    }
    clickListener(event:any) {
        const cb:any = document.getElementById(`filter-dropdown-${this.props.id}`)
        if (!cb.contains(event.target) && this.state.show) this.setState({show: false})
    }

    updateList() {
        if(this.props.items) {
            let listItems = this.state.listItems.map((item:any, i:number) => 
                <tr key={i} >
                    <td style={{width: '20px'}}>
                        <input type="checkbox" name={`cb-${this.props.id}-${i}`} value={`${item}`} checked={this.state.selects[item].selected} onChange={this.handleSelect}  />
                    </td>
                    <td>{item} {this.props.filters?.map((filter) => item[filter]).join(' - ')}</td>
                </tr>
            )
            return listItems
        } else return null
    }

    handleSelect(event:any) {
        let selects = this.state.selects
        if(event.target.checked && !selects[event.target.value].selected) {
            selects[event.target.value].selected = true
        } else if(!event.target.checked && selects[event.target.value].selected) {
            selects[event.target.value].selected = false
        }
        this.setState({selects})
    }

    handleSelectAll(event:any) {
        let item:any
        const selects:any = this.state.selects
        if(event.target.checked) {
            for(item in selects) {
                if(this.state.listItems.includes(item))
                    selects[item].selected = true
            }
        } else {
            for(item in this.state.selects) {
                selects[item].selected = false
            }
        }
        this.setState({selects})
    }

    handleOnKeyUp(event: any) {
        const k = event.keyCode
        if(k === 40) {
            const items:any = document.getElementsByClassName('cblist-dd-' + this.props.id)
            if(items.length > 0) items[0].focus()
        } else if(k === 13 && this.state.prevKeyEvent === k && this.props.nextFocus) {
            if(!this.props.nextFocus) return
            const input:any = document.getElementById(this.props.nextFocus)
            input.select()
            this.setState({prevKeyEvent: null})
            return
        } else if(k === 39 && this.props.nextFocus) {
            const input:any = document.getElementById(this.props.nextFocus)
            input.select()
        }
        this.setState({prevKeyEvent: k})
    }

    searchItem(event: any) {
        event.stopPropagation()
        if(!this.props.items) return
        // Declare variables 
        const ops:any[] = []
        const match = event.target.value.toUpperCase()
        const all:any = document.getElementById(`cb-all-${this.props.id}`)
        this.props.items.values.forEach((item:any) => {
            if (item.toUpperCase().indexOf(match) > -1) {
                if(all.checked) {
                    const selects:any = this.state.selects
                    selects[item].selected = true
                }
                ops.push(item)
            } else {
                const selects:any = this.state.selects
                selects[item].selected = false
            }
        })
        this.setState({listItems: ops, value: event.target.value})
    }

    render() {
        return (
            <div>{this.props.label}
            <NavDropdown 
                show={this.state.show}
                title={<i className="fas fa-filter" style={{color: this.state.isActive ? "red" : "inherit"}} />} 
                id={`filter-dropdown-${this.props.id}`}
                rootCloseEvent="click"
                onClick={()=>{
                    if(document.getElementById(`filter-dropdown-${this.props.id}`)!.getAttribute('aria-expanded') === 'true') {
                        const selects = Object.values(this.state.selects).findIndex((i:any) => i.selected === true) > -1 ? this.state.selects : null
                        this.props.liftUpOption({[this.props.pkey]: {values: selects, dataType: this.props.items.dataType}})
                        this.setState({show: false, isActive: !!selects})
                    } else {
                        this.setState({show: true})
                    }
                }}
                >
                <div className="filter-list" onClick={(event:any) => event.stopPropagation()}>
                    <Table striped hover size="sm">
                        <thead>
                            <tr>
                                <th colSpan={2}>
                                    <Row className="pb-1 pr-3">
                                        <Col md={8}>
                                            <FormControl
                                            id={`filter-input-${this.props.id}`}
                                            type="text"
                                            placeholder={this.props.placeholder}
                                            aria-label={this.props.placeholder}
                                            aria-describedby="cblist-addon"
                                            onKeyUp={this.handleOnKeyUp}
                                            onChange={this.searchItem}
                                            value={this.state.value}
                                            style={{width: "250px !important"}}
                                            disabled={this.props.disabled ?? false}
                                            autoComplete={this.props.autoComplete ?? 'off'}
                                            autoFocus
                                            />
                                        </Col>
                                        <Col md={4} className="text-right">
                                            <Button id="clear-btn" variant="outline-danger" onClick={() => {
                                                let item:any, selects:any = this.state.selects
                                                for(item in this.state.selects) { selects[item].selected = false }
                                                this.setState({selects})
                                                selects =  null
                                                this.props.liftUpOption({[this.props.pkey]: {values: selects, dataType: this.props.items.dataType}})
                                                this.setState({show: false, isActive: false})
                                                if(this.props.handleClear) setTimeout(() => this.props.handleClear(),100)
                                            }}>{t('clear')}</Button>
                                        </Col>
                                    </Row>
                                    <div className="pr-3">
                                        <div>
                                            <input type="checkbox" id={`cb-all-${this.props.id}`} name={`cb-all-${this.props.id}`} onChange={this.handleSelectAll} />
                                            <label>{t('select all')}</label>
                                        </div>
                                            <Button id="apply-btn" variant="primary" className="ml-3" onClick={() => {
                                                const selects = Object.values(this.state.selects).findIndex((i:any) => i.selected === true) > -1 ? this.state.selects : null
                                                this.props.liftUpOption({[this.props.pkey]: {values: selects, dataType: this.props.items.dataType}})
                                                this.setState({show: false, isActive: !!selects})
                                                }}>
                                                {t('apply')}
                                            </Button>
                                        <span><i className="fas fa-sort-alpha-down" /></span>
                                        <span className="float-right"><i className="fas fa-sort-alpha-up" /></span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.updateList()}
                        </tbody>
                    </Table>
                </div>
            </NavDropdown>
            </div>
        )
    }
}

export default FilterSearch