// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const auth = {
    identityPoolId: 'us-east-2:53441b11-74f9-42fa-83b4-eb3d26b06703',
    region: 'us-east-2',
    userPoolId: 'us-east-2_TkQB8g9bL',
    userPoolWebClientId: '7v153mrhsqurockp8ll2c9sg4s',
    oauth: {}
}

const storage = {
    AWSS3: {
        bucket: 'bizor-s3-imgs', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-2' //OPTIONAL -  Amazon service region
    }
}

const awsconfig = {
    Auth: auth,
    Storage: storage
};

/* "aws_cognito_identity_pool_id": "us-east-2:53441b11-74f9-42fa-83b4-eb3d26b06703",
"aws_cognito_region": "us-east-2",
"aws_user_pools_id": "us-east-2_TkQB8g9bL",
"aws_user_pools_web_client_id": "7v153mrhsqurockp8ll2c9sg4s",
"oauth": {} */

export default awsconfig;

