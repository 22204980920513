import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import CBList from './CBList'
import { Container } from 'react-bootstrap'

interface Props {
    name:string,
    parties: any[],
    liftUpState: any,
    initState?: any,
    placeholder?: string,
    emptyPlaceholder?: string 
}

const PartyInput = (props: Props) => {
    const [party, setParty] = useState({identificationNumber: '', addressLine1: '', phoneCountryCode: '', areaCode: '', telephoneNumber: ''}),
        [partyValue, setPartyValue] = useState(''),
        [blockParty, setBlockParty] = useState(false),
        [touched, setTouched] = useState(false)

    useEffect(() => {
        if(!props.initState && partyValue !== '') resetForm()
        else if(props.initState && !touched) {
            setParty(props.initState)
            setPartyValue(props.initState.legalName)
            setTouched(true)
        }
    }, [props.initState, partyValue, touched])

    function resetForm() {
        setParty({identificationNumber: '', addressLine1: '', phoneCountryCode: '', areaCode: '', telephoneNumber: ''})
        setPartyValue('')
    }

    function handleInputChange(event: any) {
        const c:any = Object.assign({}, party)
        c[event.target.name] = event.target.value
        c['legalName'] = partyValue
        setParty(c)
        props.liftUpState({party: c})
    }

    function getParty(party: any) {
        if(party) {
            party = props.parties.find((p:any) => p.id === party.id)
            props.liftUpState({party})
            setParty(party)
            setBlockParty(true)
        } else if(blockParty) {
            props.liftUpState({party: {identificationNumber: '', addressLine1: '', phoneCountryCode: '', areaCode: '', telephoneNumber: ''}})
            setParty({identificationNumber: '', addressLine1: '', phoneCountryCode: '', areaCode: '', telephoneNumber: ''})
            setBlockParty(false)
        }
    }

    function getPartyValue(value: any) {
        const c:any = Object.assign({}, party)
        c['legalName'] = value
        if(!c.id) props.liftUpState({party: c})

        setPartyValue(value)
        if(blockParty) setBlockParty(false)
    }

    return(
    <Container>
        <h5 className="text-capitalize"><b>{props.name}</b></h5>
        <Form.Group as={Row}>
            <Form.Label column md="4">legal name</Form.Label>
            <Col md="8">
                <CBList 
                    id={props.name + "-cblist"}
                    pkey="legalName"
                    value={partyValue}
                    selected={party}
                    liftUpOption={getParty} 
                    liftUpValue={getPartyValue}
                    items={props.parties} 
                    placeholder={props.placeholder ?? "search..."}
                    whenEmpty={props.emptyPlaceholder ?? "empty..."}
                    />
            </Col>
        </Form.Group>
        <fieldset disabled={blockParty}>
            <Form.Group as={Row}>
                <Form.Label column md="4">legal id</Form.Label>
                <Col md="8">
                   <Form.Control type="text" name="identificationNumber" onChange={handleInputChange} value={party.identificationNumber ?? ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column md="4">short address</Form.Label>
                <Col md="8">
                   <Form.Control type="text" name="addressLine1" onChange={handleInputChange} value={party.addressLine1 ?? ''} />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Form.Label column md="4">phone number</Form.Label>
                <Col as={Col} md={2} className="text-center">
                    <Form.Control 
                        type="text" 
                        name="phoneCountryCode" 
                        onChange={handleInputChange} 
                        value={party.phoneCountryCode ?? ''} 
                        placeholder="+58" 
                        style={{minWidth: "50px", maxWidth: "70px", margin: "auto"}} />
                </Col>
                <Col as={Col} md={2} className="text-center">
                    <Form.Control 
                        type="text" 
                        name="areaCode"  
                        onChange={handleInputChange} 
                        value={party.areaCode ?? ''} 
                        placeholder="212" 
                        style={{minWidth: "50px", maxWidth: "70px", margin: "auto"}} />
                </Col>
                <Col as={Col} md={4} className="text-center">
                    <Form.Control 
                        type="text" 
                        name="telephoneNumber"  
                        onChange={handleInputChange}
                        value={party.telephoneNumber ?? ''} 
                        placeholder="555-5555" />
                </Col>
            </Form.Group>
        </fieldset>
    </Container>
    )
}

export default PartyInput