import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BzrAxios from '../../utils/BzrAxios'
import Axios from 'axios'
import BzrTopNChart from '../Charts/BzrTopNChart'
import Messages from './Messages'
import t, { translate } from '../../utils/I18n/I18n'

interface Props {
    appStates: any
}
interface State {
    data: any
}

class Dashboard extends React.Component<Props, State> {
    chartRef:any = null
    AxiosCancelToken:any

    constructor(props: Props) {
        super(props)
        this.state = {
            data: {
                show: false,
                topSales: {legends: [], labels: [], dataSets: []},
                topNProducts: []
            }
        }
        this.getTopSales = this.getTopSales.bind(this)
    }

    componentDidMount() {
        this.AxiosCancelToken = Axios.CancelToken.source()
        const d:any = new Date()
        this.getTopSales({from: new Date(d.getFullYear(), d.getMonth(), 1), to: d})

        // translate
        const view:any = document.getElementById('bzr-dashboard')
        translate(view)
    }
    componentWillUnmount() {
        this.AxiosCancelToken.cancel('Axios Dashboard Requests Canceled')
    }

    getTopSales(range:any) {
        let url = `/DailyTotals?productIdRef=value1&sumRef=value4&` + 
            `dateRef=updatedAt&from=${range.from.toISOString()}&to=${range.to.toISOString()}&n=5&order=desc`
        const data = this.state.data
        data.show = false
        this.setState({data})

        BzrAxios.records({url, cancelToken: this.AxiosCancelToken.token})
        .then((response) => {
            const topNProducts:any = response.data.topNProducts,
                topNProductsSum:any = response.data.topNProductsSum,
                dailyTotals:any = response.data.dailyTotals,
                labels = this.getDateArray(range.from, range.to),
                legends:string[] = topNProducts.map((p:any) => p.name),
                valueSet:any = {},
                dataSets:any = {}
            legends.forEach((k) => valueSet[k] = 0)
            labels.forEach((d) => {dataSets[d] = Object.assign({},valueSet)})
            dailyTotals.forEach((r:any) => {
                const p = topNProducts.find((p:any) => p.id === r.id)
                dataSets[r.date.slice(0,10)][p.name] = r.sum
            })
            const data = this.state.data
            data.show = true
            data.topSales = {legends, labels, dataSets}
            let ordered:any[] = []
            topNProductsSum.forEach((tp:any) => ordered.push(Object.assign({}, tp, response.data.topNProducts.find((p:any) => p.id === tp.id))))
            data.topNProducts = ordered
            this.setState({data})
        }).catch((err) => console.log(err))
    }
  
    getDateArray(from:any, to:any) {
        // Create random array of objects (with date)
        from = new Date(from.toISOString().slice(0,10))
        to = new Date(to.toISOString().slice(0,10))
        let dates = [],
            baseTime = from.getTime(),
            dayMs = 24 * 60 * 60 * 1000
        const period:any =  (to - from)/dayMs

        for(var i = 0; i <= period; i++) {
            dates.push(new Date(baseTime + i * dayMs).toISOString().slice(0,10))
        }
        return dates;
    }
    render() {
        return(
        <Container id="bzr-dashboard" className="my-4 mx-auto px-5 bzr-dashboard ">
            <Row>
                <Col md={12}>
                    <BzrTopNChart data={this.state.data} updateChart={this.getTopSales} /> 
                </Col>
                <Col md={12}>
                    <Row>
                        <Col md={{span: 4, order: 2}} className="ml-auto">
                            <Messages />
                        </Col>
                        <Col md={8}>
                            <h3>{t('hi')}, {this.props.appStates.user.firstName} {this.props.appStates.user.middleName ?? ''}</h3>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        )
    }
}

export default Dashboard