import React from 'react'
import { Route } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import ProfileForm from '../components/User/ProfileForm'
import AddressForm from '../components/User/AddressForm'
import SingleBox from '../layouts/Forms/SingleBox'
import PlainBox from '../layouts/Forms/PlainBox'
import PreferenceForm from '../components/User/PreferenceForm'
import OrgForm from '../components/Org/OrgForm'
import BusinessUnitForm from '../components/Org/BUForm'



export const enrollmentRoutes = (path:string) => [
    {
        path: "/users/personal-information",
        name: "Personal Information",
        icon: "pe-7s-graph",
        grid: [[{
            main: SingleBox,
            span: 12,
            type: "single-submit",
            component: ProfileForm, 
            params: { id: 'user-profile', title: 'Personal Information', endpoint: 'PUT /users/:id'}
        }]],
        layout: "/enrollment"
    },
    {
        path: "/users/primary-address",
        name: "Primary Address",
        icon: "pe-7s-graph",
        grid: [[{
            main: SingleBox,
            span: 12,
            type: "single-submit",
            component: AddressForm, 
            params: {id: 'user-address', title: 'Primary Address', endpoint: 'POST /users/:id/addresses'}
        }]],
        layout: "/enrollment"
    },
    {
        path: "/users/preferences",
        name: "Preferences",
        icon: "pe-7s-graph",
        grid: [[{
            main: SingleBox,
            span: 12,
            type: "single-submit",
            component: PreferenceForm, 
            params: {id: 'user-preference', title: 'Preferences', endpoint: 'POST /users/:id/preferences'}
        }]],
        layout: "/enrollment"
    },
    {
        path: "/users/org",
        name: "Organization",
        icon: "pe-7s-graph",
        grid: [
            [{
                main: PlainBox,
                span: 6,
                type: "single-submit",
                component: OrgForm, 
                params: {id: 'org', title: 'Organization', endpoint: 'POST /orgs'}
            },
            {
                main: PlainBox,
                span: 6,
                type: "single-submit",
                component: BusinessUnitForm, 
                params: {id: 'bu', title: 'Business Unit', endpoint: 'POST /orgs/:id/companies'}
            }]
        ],
        layout: "/enrollment"
    }
].find((p:any) => (p.layout + p.path) === path)

export const getRoutes = (routes:any, extras?:any) => {
    if(!routes[0]) return null
    return routes.map((route:any, index:any) => {
        if(route.dropdown) return getRoutes(route.dropdown.routes, extras) 
        else 
            return <Route
                key={"r" + index}
                path={route.layout + route.path}
                exact={route.exact}
                children={ (props:any) => {
                    let gridRow:any = []
                    route.grid.forEach((row:any, n:number) => 
                        gridRow.push(<Row key={n}> {row.map((col:any, m:number) => 
                            <Col md={col.span} key={m}>
                                <col.main 
                                    key={ (n*10 + 1) + m } 
                                    idn={ (n*10 + 1) + m }
                                    appStates={extras.appStates} 
                                    liftUpAppStates={extras.liftUpAppStates} 
                                    singleSubmit={col.type.match(/single-submit/gi) ? extras.singleSubmit : false}
                                    params={col.params}
                                    comps={[col]}
                                    {...props} />
                            </Col>)} </Row>
                        )
                    )
                    return <div>{ gridRow }</div>
                }}
            />
    })
}