import jsPDF from 'jspdf'
import 'jspdf-autotable'
import logoImage from '../../assets/img/reactlogo.png'

class Printer {
    static productToPDF(appStates:any, product:any, titles:any, products?:any[]) {
        const doc:any = new jsPDF('p', 'mm', 'letter')
        const company = this.getCompany(appStates)
        this.header(doc, company, product, titles)
        
        return doc.output('blob')
    }    

    static header(doc:any, company:any, product:any, titles:any) {
        let offset = 10 
        const incOffset = (o:number) => {
            return o + doc.getFontSize() * doc.getLineHeightFactor() * 34/100
        }
        // Company Logo
        const logoWidth = 70
        this.placeImage(doc, company.logo, 10, 5, logoWidth, 30, 2)


        // Doc Title (e.g. 'Invoice)
        doc.setTextColor(100)
        doc.setFontSize(18)
        doc.setFontStyle("bold")
        doc.text(titles.title.toUpperCase(), 140, 35)
    
        // Company Info
        doc.setTextColor(100)
        doc.setFontSize(7)
        doc.setFontStyle("bold")
        doc.text(company.legalName.toUpperCase(), 13 + logoWidth, offset)

        doc.setFontSize(6)
        doc.setFontStyle("normal")
        const companyLegalId = company.identificationNumber ? company.identificationNumber.toUpperCase() : ''
        offset = incOffset(offset)
        doc.text(companyLegalId, 13 + logoWidth, offset)
        const companyAddress = company.address ?? {addressLine1: '', addressLine2: '', cityName: '', postalCodeText: '', stateProvinceName: '', countryName: ''}
        offset = incOffset(offset)
        const address = [companyAddress.addressLine1, companyAddress.addressLine2].join(', ')
        offset += this.textBlock(doc, address.toUpperCase(), 13 + logoWidth, offset, 55)
        doc.text((companyAddress.cityName + ' ' + companyAddress.postalCodeText).toUpperCase(), 13 + logoWidth, offset)
        offset = incOffset(offset)
        doc.text([companyAddress.stateProvinceName, companyAddress.countryName].join(', ').toUpperCase(), 13 + logoWidth, offset)
        offset = incOffset(offset)
        doc.text(`Tel: ${company.phone.phoneCountryCode} (${company.phone.areaCode}) ${company.phone.telephoneNumber}`, 13 + logoWidth, offset)
    
        // Horizontal Ruler
        const headerBorder = 40
        doc.setDrawColor(100)
        doc.line(10, headerBorder, 200, headerBorder)

        // Product
        doc.setTextColor(52,114,255)
        doc.setFontSize(10)
        doc.setFontStyle("bold")
        doc.text(product.name, 95, headerBorder + 10)

        doc.setTextColor(100)
        doc.setFontSize(12)
        doc.setFontStyle("bold")
        doc.text(product.model ?? '', 94, headerBorder + 15)
        
       
        // Horizontal Ruler
        doc.setDrawColor(100)
        doc.line(15, headerBorder + 30, 200, headerBorder + 30)

        // Product Image
        if(product.imageURL) this.placeImage(doc, product.imageURL, 80, 130, 100, 100)
    }

    static placeImage(doc:any, url:string, right:number, top:number, width:number,  height:number, rel = 1) {
        const img:any = new Image() // HTML5 Constructor
        img.src = url
        //console.log(img)
        const imgProps:any = doc.getImageProperties(img)
        //console.log(imgProps)
        if(imgProps.width/imgProps.height > rel) {
            width = 70
            doc.addImage(img, imgProps.fileType, right, top, width, 2 + Math.floor(width * imgProps.height/imgProps.width))
        } else {
            width = Math.floor(height * imgProps.width/imgProps.height)
            doc.addImage(img, imgProps.fileType, right, top, width, height)
        }
    }

    static textBlock(doc:any, text:string, right:number, top:number, width:number, {fontSize, lineHeightFactor}:any = {}) {
        const splitText:string[] = doc.splitTextToSize(text, width),
        lineCount:number = splitText.length,
        textHeight:number = lineCount * (fontSize ?? doc.getFontSize()) * (lineHeightFactor ?? doc.getLineHeightFactor()) * 34/100
        doc.text(splitText, right, top)
        return textHeight
    }

    static getCompany(appStates:any) {
        const company = {
            legalName: appStates.activeBUId ? appStates.activeBU.legalName : '',
            identificationNumber: appStates.activeBUId ? appStates.activeBUId.identificationNumber : '',
            address: appStates.activeBUAddress ?? '',
            phone: appStates.activeBUPhone ?? '',
            logo: appStates.activeBULogo ?? logoImage
        }
        return company
    }

    static productsTable(doc:any, products:any) {
        doc.autoTable({
            head: [['Name', 'Email', 'Country']],
            body: [
                ['David', 'david@example.com', 'Sweden'],
                ['Castille', 'castille@example.com', 'Norway'],
                // ...
            ],
            })
    }

    static listItems(doc:any, table:any, count:number):any {
        let itemsOffset = 75, fontSize = 9, lineHeightFactor = 1.15, maxItemsHeight = 190
        const fn = (n:any) => Number(n).toFixed(2).toString()

        itemsOffset += 10
        doc.setFontSize(12)
        doc.setFontStyle("bold")
        doc.text('DETAILS', 17, itemsOffset)
        itemsOffset += 2
        doc.setDrawColor(100,200,255)
        doc.setLineWidth(0.5)
        doc.line(15, itemsOffset, 200, itemsOffset)
        itemsOffset += 10
        // List Items Header
        doc.setTextColor(100)
        doc.setFontSize(fontSize)
        doc.text(table.headers[0], 17, itemsOffset)                         // #
        doc.text(table.headers[1], 20, itemsOffset)                         // NAME
        doc.text(table.headers[2], 50, itemsOffset)                         // DESCRIPTION
        doc.text(table.headers[3], 130, itemsOffset)                        // QTY
        doc.text(table.headers[4], 160, itemsOffset, {align: 'right'})      // PRICE
        doc.text(table.headers[5], 175, itemsOffset, {align: 'right'})      // VAT
        doc.text(table.headers[6], 200, itemsOffset, {align: 'right'})      // AMOUNT
        itemsOffset += 10
        // ITEM X
        doc.setFontStyle("normal")
        const items:any = table.items
        for(let i = count; i < items.length; i++) {

            const name:string[] = doc.splitTextToSize(items[i].name, 30),
            desc:string[] = doc.splitTextToSize(items[i].description, 75),
            lineCount:number = desc.length > name.length ? desc.length : name.length,
            textHeight:number = lineCount * fontSize * lineHeightFactor * 34/100

            if(itemsOffset + textHeight > maxItemsHeight) {
                // Horizontal Ruler
                doc.setDrawColor(100)
                doc.setLineWidth(0.2)
                doc.line(15, itemsOffset, 200, itemsOffset)
                return {count: i, height: itemsOffset}
            }
            doc.text(items[i].n.toString(), 18, itemsOffset, {align: 'right'})
            doc.text(name, 20, itemsOffset)
            doc.text(desc, 50, itemsOffset)
            doc.text(fn(items[i].quantity), 137, itemsOffset, {align: 'right'})
            doc.text(fn(items[i].unitListPrice), 160, itemsOffset, {align: 'right'})
            // EXEMPT 
            doc.setFontSize(fontSize - 1)
            doc.text(items[i].isTaxExempt === 'true' ? '(e)' : fn(items[i].taxRate1 * 100) + '%', 177, itemsOffset, {align: 'right'})
            doc.setFontSize(fontSize)
            ////
            doc.text(fn(items[i].amount), 200, itemsOffset, {align: 'right'})
            itemsOffset += textHeight + 3
        }
        // Horizontal Ruler
        doc.setDrawColor(100)
        doc.setLineWidth(0.2)
        doc.line(15, itemsOffset, 200, itemsOffset)
        return {count: items.length, height: itemsOffset}
    }

    static totalize(doc:any, titles:any, order:any, offset:number) {
        const fn = (n:any) => Number(n).toFixed(2).toString()
        
        offset += 15
        doc.setFontSize(12)
        doc.setFontStyle("bold")
        doc.text('SUMARY', 17, offset)
        offset += 2
        // Horizontal Ruler
        doc.setDrawColor(100,200,255)
        doc.setLineWidth(0.5)
        doc.line(15, offset, 200, offset)
        // SUBTOTALS
        offset += 10
        doc.setTextColor(100)
        doc.setFontSize(10)
        doc.setFontStyle("bold")
        doc.text(titles.totals.subTotal, 90, offset)
        doc.text(fn(order.subTotal), 200, offset, {align: 'right'})

        // DISCOUNT
        if(order.discounts && order.discounts !== 0) {
            doc.setFontSize(10)
            doc.setFontStyle("normal")
            doc.text(titles.totals.discounts, 20, offset)
            doc.text(fn(order.discounts), 80, offset + 10, {align: 'right'})
        }

        // TAX
        offset += 5
        doc.setFontSize(8)
        doc.setFontStyle("normal")
        doc.text(titles.totals.taxable + ' ' + titles.totals.tax + ' (' + fn(order.taxable)+ ')', 90, offset)
        doc.text(fn(order.tax1), 200, offset, {align: 'right'})
        
        // EXEMPT
        if(order.exempt) {
            offset += 5
            doc.text(titles.totals.exempt + ' (' + fn(order.exempt) + ')', 90, offset)
            doc.text('0', 200, offset, {align: 'right'})
        }

        // CREDITS
        if(order.credits && order.credits !== 0) {
            offset += 10
            doc.setFontSize(10)
            doc.setFontStyle("bold")
            doc.text(titles.totals.credits, 90, offset)
            doc.text('(' + fn(order.credits) + ')', 201, offset, {align: 'right'})
        }

        // Horizontal Ruler
        offset += 5
        doc.setDrawColor(0,0,0)
        doc.setLineWidth(0.3)
        doc.line(90, offset, 200, offset)

        // TOTAL
        offset += 5
        doc.setFontSize(12)
        doc.setFontStyle("bold")
        doc.text(titles.totals.total, 89, offset)
        doc.text(fn(order.total), 201, offset, {align: 'right'})
    }
}

export default Printer