import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Card, Alert, Accordion } from 'react-bootstrap'
import { History, LocationState } from "history"
import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	liftUpAppStates: any,
	formType: any,
	params: any,
	idn: any,
	comps?: any
}

interface States {
	formError: any,
	disableFields: boolean,
	formState: any,
	eventKey: string,
	formCard: any
}

class AccordionBox extends React.Component<Props,States> {

	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null,
			disableFields: !this.props.singleSubmit,
			formState: {},
			eventKey: props.idn + "0",
			formCard: null
		}
		this.disableFields = this.disableFields.bind(this)
		this.getFormCard = this.getFormCard.bind(this)
	}

	getFormCard(formCard:any) { this.setState({formCard}) }

	disableFields(disableFields:boolean) { this.setState({disableFields}) }

	children() {
		if(!this.props.comps) return null
		let children:any[] = []
		this.props.comps.forEach(
			(comp:any, key:any) => children.push(
				<comp.component
					key={this.props.idn + key}
					idn={this.props.idn}
					singleSubmit={this.props.singleSubmit}
					appStates={this.props.appStates} 
					liftUpAppStates={this.props.liftUpAppStates}
					getFormCard={this.getFormCard}
					params = {comp.params}
					comps = {comp.children ?? null}
				/>
			)
		)
		return children
	}

	render() {
		return (
			<Accordion defaultActiveKey={this.props.idn + "1"}>
				<Card className="px-1 bzr-form mx-auto mt-4 mb-1 let-overflow">
					<Accordion.Toggle 
						as={Card.Header} 
						variant="link" 
						eventKey={this.state.eventKey}  
						onClick={() => {
							if(this.state.eventKey===this.props.idn + "0")this.setState({eventKey: this.props.idn + "1"})
							else if(this.state.eventKey===this.props.idn + "1")this.setState({eventKey: this.props.idn + "0"})
						}}
						className="bg-white"
						>
						<div className="d-flex justify-content-between mb-3">
							<div className="p-2"><h4>{t(this.props.params.title)}</h4></div>
							<div className="p-2 accordion-edit-label">  
								<span className="pe-7s-angle-down" style={{fontSize: "1.5rem"}} />
								edit
							</div>
						</div>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey={this.props.idn + "0"}>
						<Card.Body>
							{
								this.state.formError !== null ? 
								(<Alert variant={'danger'} onClose={() => this.setState({formError: null})} dismissible>
									<Alert.Heading>Oh snap! You got an error!</Alert.Heading>
									{this.state.formError.code}: {this.state.formError.message}
								</Alert>) : null
							} 
							<div className="mx-0">
								{this.children()}
							</div>
						</Card.Body>
					</Accordion.Collapse>
					<Accordion.Collapse eventKey={this.props.idn + "1"} >
						<Card.Footer>
							{this.state.formCard}
						</Card.Footer>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		)
	}
}
export default AccordionBox