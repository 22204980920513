import React from 'react'
import { History, LocationState } from "history"
import { withRouter, RouteComponentProps } from 'react-router-dom'
import BzrAxios from '../../utils/BzrAxios'
import { Formik } from 'formik'
import { Form, Container, Row, Col, Button, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import FormInputs from '../../layouts/Forms/FormInputs'
import t, { HelperMsg } from '../../utils/I18n/I18n'
import XLSX from 'xlsx'

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
interface FileProps {
    handleFile?:any
    cols?:any
    data?:any
}
class DragDropFile extends React.Component<FileProps> {
	constructor(props:any) {
		super(props);
		this.onDrop = this.onDrop.bind(this);
	};
	suppress(evt:any) { evt.stopPropagation(); evt.preventDefault(); };
	onDrop(evt:any) { evt.stopPropagation(); evt.preventDefault();
		const files = evt.dataTransfer.files;
		if(files && files[0]) this.props.handleFile(files[0]);
	};
	render() { 
        return (
            <div onDrop={this.onDrop} onDragEnter={this.suppress} onDragOver={this.suppress}>
                {this.props.children}
            </div>
        )
    }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component<FileProps> {
	constructor(props:any) {
		super(props)
		this.handleChange = this.handleChange.bind(this)
	}
	handleChange(e:any) {
		const files = e.target.files
		if(files && files[0]) this.props.handleFile(files[0])
	}
	render() { 
        return (
            <form className="form-inline">
                <div className="form-group">
                    <input type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
                </div>
            </form>
        )
    }
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
class OutTable extends React.Component<FileProps> {
	render() { 
        return (
            <Table bordered striped responsive>
                <thead>
                    <tr>{this.props.cols.map((c:any) => <th key={c.key}>{c.name}</th>)}</tr>
                </thead>
                <tbody>
                    {this.props.data.map((r:any,i:number) => <tr key={i}>
                        {this.props.cols.map((c:any) => <td key={c.key}>{ r[c.key] }</td>)}
                    </tr>)}
                </tbody>
            </Table>
        )
    }
}

/* generate an array of column objects */
const make_cols = (refstr:any) => {
    let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1
    console.log()
    for(var i = 0; i < C; ++i) o[i] = {name:XLSX.utils.encode_col(i), key:i}
	return o
};
/* list of supported file types */
const SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function(x) { return "." + x }).join(",")

//////////////

interface Props extends RouteComponentProps<LocationState> {
    history:History<LocationState>
    appStates:any
    products:any
    liftUpAppStates:any
    product?:any
}

interface State {
    product:any
    warehouses:any
    data: any[] /* Array of Arrays e.g. [["a","b"],[1,2]] */
    cols: any[]  /* Array of column objects e.g. { name: "C", K: 2 } */
}

class ClientBulkLoad extends React.Component<Props,State> {
    constructor(props:Props) {
        super(props)
        this.state = {
            product: null,
            warehouses: null,
            data: [],
            cols: []
        }
        this.handleFile = this.handleFile.bind(this)
        this.handleLoadInventory = this.handleLoadInventory.bind(this)
    }


    handleFile(file:File) {
		/* Boilerplate to set up FileReader */
		const reader = new FileReader()
		const rABS = !!reader.readAsBinaryString
		reader.onload = (e:any) => {
			/* Parse data */
			const bstr = e.target.result
			const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'})
			/* Get first worksheet */
			const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
			/* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {header:1})
            let cols = make_cols(ws['!ref'])

            console.log(cols,data)

            /// Re-Shape Clients Data
            const newData:any = data.map((d:any,i:number) => {
                if(i === 0) {
                    d[2] = 'addressLine1'
                    d[3] = 'cityName'
                    d[4] = 'stateProvinceName'
                    d[5] = 'phoneCountryCode'
                    d[6] = 'areaCode'
                    d[7] = 'telephoneNumber'
                    return d
                }
                
                const search:any = d[2].search(/distrito|miranda|caracas|vargas|bolivar|anzoategui|cojedes/i)
                const address:string = search > 0 ? d[2].slice(0, search) : d[2]
                let city:string = d[2].match(/caracas|san antonio de los altos|los teques|barcelona/i),
                    state:string = d[2].match(/distrito capital|miranda|vargas|bolivar|anzoategui|cojedes|zulia/i),
                    code:string = d[4] ? d[4].slice(0,3) : '',
                    phone:string = d[4] ? d[4].slice(3)  : ''

                city = city ? city[0] : ''
                state = state ? state[0] : ''
                return  [d[0], d[1], address, city, state, d[3], code, phone]
            })

            cols.push({name: "F", key: 5})
            cols.push({name: "G", key: 6})
            cols.push({name: "H", key: 7})
            /* Update state */
            this.setState({ data: newData, cols})
            
            ///  test 
            
/*             let c =  newData.shift()
            let sata:any = newData.map( (d:any) => {
                const o:any = {}
                o.objectId = '20'
                d.forEach((v:any, i:number) => o[c[i]] = v)
                return o
            }) */
		}
		if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file)
	}

    async handleLoadInventory(values:any) {
        
        let data = this.state.data
        console.log(data.length)
        data.forEach((d:any) => {
            d.push(values.priceBookId)
            d.push('default') 
        })

        const body:any = {data}
        
        try {
            const response:any = await BzrAxios.orgs({url: `/ClientLoads`, data: body})
            console.log(response.data)
        } catch(error) {
            console.log(error)
        }
    }

    render() {
        return(
            <Container>
                <Row>
                    <Col md={12}>
                            <Formik
                                onSubmit={this.handleLoadInventory}
                                initialValues={{
                                    costBookId: '',
                                    priceBookId: '',
                                    currency: '840',
                                    bzrInventoryBookId: '',
                                    orderNumber: ''
                                }}
                                enableReinitialize={true}
                                >
                                {
                                    ({
                                        handleSubmit,
                                        handleChange,
                                        isSubmitting,
                                        values
                                        }) => (
                                            <div>
                                                <h4>Load Inventory</h4>
                                                <Form noValidate onSubmit={handleSubmit} id={'load-inventory-form'} className="my-3">
                                                    <Row>
                                                        <Col md={5}>
                                                                <FormInputs
                                                                ncols={['12','12','12']}
                                                                className={['px-2','px-2','px-2']}
                                                                properties={[
                                                                    {
                                                                        label: 'Cost Book',
                                                                        horizontalLabel: "5",
                                                                        fieldType: 'select',
                                                                        name: 'costBookId',
                                                                        value: values.costBookId,
                                                                        options: (() => {
                                                                                const ops:any = this.props.appStates.bookNames ? Object.entries(this.props.appStates.bookNames.cost_hash).map((e:any) => [e[0],e[1].alias]) : []
                                                                                if(ops.length > 0) values.costBookId = ops[0][0]
                                                                                return ops
                                                                            })(),
                                                                        onChange: handleChange
                                                                    },
                                                                    {
                                                                        label: 'Price Book',
                                                                        horizontalLabel: "5",
                                                                        fieldType: 'select',
                                                                        name: 'priceBookId',
                                                                        value: values.priceBookId,
                                                                        options: this.props.appStates.bookNames ? Object.entries(this.props.appStates.bookNames.price_hash).map((e:any) => [e[0],e[1].alias]) : [],
                                                                        onChange: handleChange
                                                                    },
                                                                    {
                                                                        label: 'Currency',
                                                                        horizontalLabel: "5",
                                                                        fieldType: 'select',
                                                                        name: 'currency',
                                                                        value: values.currency,
                                                                        options: [
                                                                            ['840', 'USD'], 
                                                                            ['928', 'VES']
                                                                        ],
                                                                        onChange: handleChange,
                                                                        info: HelperMsg('We recomend using a strong currency to reference prices and the system will bill in your local curency')
                                                                    }
                                                                ]}
                                                            />
                                                        </Col>
                                                        <Col md={5}>
                                                            <FormInputs
                                                                ncols={['10', '10']}
                                                                className={['px-2', 'px-2']}
                                                                properties={[
                                                                    {
                                                                        label: 'inventories',
                                                                        horizontalLabel: '5',
                                                                        fieldType: 'select',
                                                                        name: 'bzrInventoryBookId',
                                                                        value: values.bzrInventoryBookId,
                                                                        options: (() => {
                                                                            const ops:any = this.props.appStates.bookNames && this.props.appStates.bookNames.inventory ? Object.entries(this.props.appStates.bookNames.inventory).map((b:any) => [b[1], b[0]]) : [['','']]
                                                                            if(ops.length > 0) values.bzrInventoryBookId = ops[0][0]
                                                                            return ops
                                                                        })(),
                                                                        onChange: handleChange
                                                                    },
                                                                    {
                                                                        label: 'order number',
                                                                        horizontalLabel: '5',
                                                                        fieldType: 'text',
                                                                        name: 'orderNumber',
                                                                        value: values.orderNumber,
                                                                        onChange: handleChange
                                                                    }
                                                                ]}
                                                            />
                                                            <div className="w-100 text-center mt-4">
                                                                {
                                                                isSubmitting ? <div><h5>{t('submitting')}...<FontAwesomeIcon icon={faSpinner} spin /></h5></div> :
                                                                <Button 
                                                                    type='submit' 
                                                                    id='load-inventory-submit' 
                                                                    >
                                                                        {t('submit')}
                                                                </Button>
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form> 
                                            </div>
                                        )
                                }
                            </Formik>
                        </Col>
                        <Col md={12}>
                        <DragDropFile handleFile={this.handleFile}>
                            <div className="row"><div className="col-xs-12">
                                <DataInput handleFile={this.handleFile} />
                            </div></div>
                            <br />
                            <h5>Data preview</h5>
                            <div className="row"><div className="col-xs-12 load-table">
                                <OutTable data={this.state.data} cols={this.state.cols} />
                            </div></div>
                        </DragDropFile>
                    </Col>
                 </Row>
            </Container>
        )
    }
}

export default withRouter(ClientBulkLoad)