import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert'
import { History, LocationState } from "history"
import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	liftUpAppStates: any,
	products: any,
	formType: any,
	params: any,
	idn: any,
	comps?: any
}

interface States {
	formError: any,
	formState: any
}

class PlainBox extends React.Component<Props,States> {

	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null,
			formState: {}
		}
	}

	children() {
		if(!this.props.comps) return null
		let children:any[] = []
		this.props.comps.forEach(
			(comp:any, key:any) => children.push(
				<comp.component
					key={this.props.idn + key}
					idn={this.props.idn + key}
					appStates={this.props.appStates} 
					products={this.props.products}
					liftUpAppStates={this.props.liftUpAppStates}
					params = {comp.params}
					comps = {comp.children ?? null}
					singleSubmit={this.props.singleSubmit}
				/>
				 )
		)
		return children
	}

	render() {
		return (
			<div className={this.props.params.className}>
				<div className="d-flex justify-content-between">
				{this.props.params.title ? <div className="p-2 ml-3"><h4>{t(this.props.params.title)}</h4></div> : null}
				</div>
				{
					this.state.formError !== null ? 
					(<Alert variant={'danger'} onClose={() => this.setState({formError: null})} dismissible>
						<Alert.Heading>Oh snap! You got an error!</Alert.Heading>
						{this.state.formError.code}: {this.state.formError.message}
					</Alert>) : null
				} 
				{this.children()}
			</div>
		)
	}
}
export default PlainBox