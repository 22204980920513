import React from 'react'
import PropTypes from 'prop-types'
import hoistStatics from 'hoist-non-react-statics'
import { newScript, series, noop } from './utils'

const loadedScript:any[] = []
const pendingScripts:any = {}
let failedScript:any[] = []

export function startLoadingScripts(scripts:any, onComplete = noop) {

    // sequence load
    const loadNewScript = (script:any) => {
        const src:any = typeof script === 'object' ? script.src : script
        if (loadedScript.indexOf(src) < 0) {
          return (taskComplete:any) => {
            const callbacks:any = pendingScripts[src] || []
            callbacks.push(taskComplete)
            pendingScripts[src] = callbacks
            if (callbacks.length === 1) {
              return newScript(script)((err:any) => {
                pendingScripts[src].forEach((cb:any) => cb(err, src))
                delete pendingScripts[src]
              })
            }
          }
        }
    }
    const tasks = scripts.map((src:any) => {
        if (Array.isArray(src)) {
            return src.map(loadNewScript)
        }
        else return loadNewScript(src)
    })

    series(...tasks)((err:any, src:any) => {
        if (err) {
            failedScript.push(src)
        }
        else {
            if (Array.isArray(src)) {
            src.forEach(addCache)
            }
            else addCache(src)
        }
        })((err:any) => {
        removeFailedScript()
        onComplete(err)
        })
}

const addCache = (entry:any) => {
  if (loadedScript.indexOf(entry) < 0) {
    loadedScript.push(entry)
  }
}

const removeFailedScript = () => {
    if (failedScript.length > 0) {
        failedScript.forEach((script) => {
        const node:any = document.querySelector(`script[src='${script}']`)
        if (node != null) {
            node.parentNode.removeChild(node)
        }
        })

        failedScript = []
    }
}

const scriptLoader = (...scripts:any) => (WrappedComponent:any) => {
    interface Props {
        onScriptLoaded:any
    }
    
    class ScriptLoader extends React.Component<Props> {
        static propTypes = {
            onScriptLoaded: PropTypes.func
        }
    
        static defaultProps = {
            onScriptLoaded: noop
        }
    
        _isMounted:boolean

        constructor (props:Props, context:any) {
            super(props, context)
    
            this.state = {
            isScriptLoaded: false,
            isScriptLoadSucceed: false
            }
    
            this._isMounted = false;
        }
    
        componentDidMount () {
            this._isMounted = true;
            startLoadingScripts(scripts, (scripts:any, err:any) => {
            if(this._isMounted) {
                this.setState({
                isScriptLoaded: true,
                isScriptLoadSucceed: !err
                }, () => {
                if (!err) {
                    this.props.onScriptLoaded()
                }
                })
            }
            })
        }
    
        componentWillUnmount () {
            this._isMounted = false;
        }
    
        getWrappedInstance () {
            return this.refs.wrappedInstance;
        }
    
        render () {
            const props = {
            ...this.props,
            ...this.state,
            ref: 'wrappedInstance'
            }
    
            return (
            <WrappedComponent {...props} />
            )
        }
    }
    return hoistStatics(ScriptLoader, WrappedComponent)
}

export default scriptLoader