import React from 'react'
import AWS from 'aws-sdk'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from 'history'
import BzrAxios from '../../utils/BzrAxios'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'
import CBList2 from '../Common/CBList2'
import BzrTable from '../Common/BzrTable'
import { getCookie, setCookie } from '../../utils/Cookies'
import { MessageModal } from '../../layouts/Modals/Modals'
import t, { translate } from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState> {
    history: History<LocationState>
    appStates: any
    liftUpAppStates: any
	facilities:any
}

interface State {
	parties:any
	client:any
	facility:any
	facilities:any
	fields:any
	showSelectFacility:boolean
	defaultHeaders:any
	cstates: any
	countries: any
}
class FacilityForm extends React.Component<Props,State> {
	objects:any = {
		clients: {name: 'clients', object: 20, cbTible: 'clients', cbName: 'legalName', facilityObject: 21, ref: 'clientId'},
		facilities: {name: 'facilities', object: 21, cbTible: 'facilities', cbName: 'facilityName'}
	}
	defaultHeaders:any[] = [
        {fieldName: 'facilityName', filter: true, show: true},
        {fieldName: 'facilityType', filter: true, show: false}, 
        {fieldName: 'addressLine1', filter: true, show: true},
        {fieldName: 'cityName', filter: true, show: false},
    ]
    constructor(props:Props) {
        super(props)
        this.state = {
			parties: null,
			client: null,
			facility: null,
			facilities: null,
			fields: [],
			showSelectFacility: true,
			defaultHeaders: [],
			cstates: null,
			countries: null,
		}
		this.handleSubmit = this.handleSubmit.bind(this)
    }
    async componentDidMount() {
		const el:any = document.getElementById('facility-form')
		translate(el)
		
		const match:any = this.props.match
		//if(!match.params.type) this.props.history.goBack()
		
		if(this.props.appStates[match.params.type]) {
            this.setState({parties: this.props.appStates[match.params.type]})
        } else { 
			try {
				const response:any = await BzrAxios.records({url: `/BzrClient?qorderby=legalName`})
				this.setState({parties: response.data.records})
				//this.props.liftUpAppStates({parties: response.data.records})
			} catch(err) {
				console.error(err)
				return
			}
		}

		const facilitiesCK:any = getCookie("facilities"),
        defaultHeaders:any = this.defaultHeaders
        if( facilitiesCK !== JSON.stringify({defaultHeaders}) ){
            this.setState({defaultHeaders})
            setCookie('facilities', JSON.stringify({defaultHeaders}), 30)
        } else{
            const { defaultHeaders } = JSON.parse(facilitiesCK)
            this.setState({defaultHeaders})
		}
		const s3 = new AWS.S3({apiVersion: '2006-03-01'})
		s3.getObject({ Bucket: 'bizor-s3-imgs', Key: `public/states.json` }, 
			(err:any, result:any) => {
				if(err) console.log(err)
				else {
					const cstates:any = JSON.parse(result.Body.toString())
					this.setState({cstates})
				}
			}
		)
		s3.getObject({ Bucket: 'bizor-s3-imgs', Key: `public/countries.json` }, 
			(err:any, result:any) => {
				if(err) console.log(err)
				else {
					const countries:any = JSON.parse(result.Body.toString())
					this.setState({countries})
				}
			}
		)
	}

	async getFacilities(id:any) {
		try {
			const response:any = await BzrAxios.records({url: `/BzrClientFacility?clientId=${id}`})
			this.setState({facilities: response.data.records, fields: response.data.fields})
			console.log(response.data.records)
		} catch(err) {
			console.error(err)
			return
		}
	}

	async handleSubmit(data:any, formikBag:any) {
		let url, method

		if(this.state.facility.id) {
			method = 'PUT'
			url = `/BzrClientFacility/${this.state.facility.id}`
		} else {
			method = 'POST'
			url = `/BzrClientFacility`
			data.facilityNumber = (new Date()).getTime().toString().slice(2,10)
        	data.clientId = this.state.client.id
		}

		try {
			const response:any = await BzrAxios.records({ method, url, data })
				

			if(method === 'PUT') {
				const facility = response.data.record
				this.setState({facility})
				let facilities:any[] = this.state.facilities.filter((f:any) => f.id !== facility.id)
				facilities.push(facility)
				this.setState({facilities})
			} else {
				const facility = response.data.records[0]
				let facilities:any[] = this.state.facilities
				facilities.push(facility)
				this.setState({facility, facilities})
			}
			formikBag.resetForm()
		} catch(err) {
			console.error(err)
			return
		}
	}

    render() {
		//const match:any = this.props.match
		const f:any = this.state.facility ?? {}
		return (
			<div id="facility-form">
			<Formik
				validationSchema={Yup.object({
					facilityName: Yup.string().required()
				})}
				onSubmit={this.handleSubmit}
				initialValues={{
					facilityName: f.facilityName ?? '',
					facilityType: f.facilityType ?? '', 
					addressLine1: f.addressLine1 ?? '',
					addressLine2: f.addressLine2 ?? '', 
					cityName: f.cityName ?? '', 
					postalCodeText: f.postalCodeText ?? '',
					stateProvinceName: f.stateProvinceName ?? 'Distrito Capital',
					countryName: f.countryName ?? 'venezuela',
					phoneCountryCode: f.phoneCountryCode ?? '+58',
					areaCode: f.areaCode ?? '',
					telephoneNumber: f.telephoneNumber ?? '',
					email: f.email ?? ''
				}}
				enableReinitialize={true}
			>
				{({
				handleSubmit,
				isSubmitting,
				handleChange,
				values,
				touched,
				isValid,
				errors,
				}) => (
				<Form noValidate onSubmit={handleSubmit}  id={'facility-form'}>
					<Row>
						<Col md={6}>
							<h4>{t('facilities')} {this.state.client && this.state.client.legalName}</h4><br />
							{this.state.facilities && 
							<BzrTable 
								id="facilities-list"
								items={this.state.facilities}
								liftUpHeaders={(defaultHeaders:any) => this.setState({defaultHeaders})}
								headers={this.state.defaultHeaders}
								fields={this.state.fields}
								extras={{activeBU: this.props.appStates.activeBU, activeBUId: this.props.appStates.activeBUId}}
								doubleClick={(facility:any) => this.setState({facility})}
								/>}
							<h6>*{t('double-click on facility to modify')}</h6>
						</Col>
						<Col md={6} className="pl-5">
						<h4>{this.state.facility && this.state.facility.id ? `${t('modifiying')}: ${this.state.facility.facilityName}`: t('creating new facility')}</h4>
						{this.state.facility && this.state.facility.id && <a href="#new" className="text-uppercase display-6" onClick={() => { this.setState({facility: {}}) }}>{`${t('create new facility')}`}</a>}
						<br /><br />
						<h5>basic info</h5>
							<FormInputs
								ncols={['7', '5']}
								className={['px-2', 'px-2']}
								properties={[
									{
										label: 'facility name',
										feedback: ' ',
										fieldType: 'text',
										name: 'facilityName',
										value: values.facilityName,
										onChange: handleChange,
										isValid: touched.facilityName && !errors.facilityName,
										isInvalid: !!errors.facilityName,
										error: errors.facilityName,
										autoFocus: true
									},
									{
										label: 'facility type',
										fieldType: 'select',
										name: 'facilityType',
										value: values.facilityType,
										options: [
											['retail', t('retail')], 
											['service', t('service')],
											['inventory', t('inventory')], 
											['logistic', t('logistic')], 
											['manufacture', t('manufacture')]
										],
										onChange: handleChange
									}
								]}
							/>
							<hr /><br />
							<h5>address & contact</h5>
							<FormInputs
								ncols={['8']}
								className={['ml-0']}
								properties={[
									{
										label: 'Address Line 1',
										fieldType: 'text',
										name: 'addressLine1',
										autoComplete: 'address-line1',
										value: values.addressLine1,
										onChange: handleChange,
										isValid: touched.addressLine1 && !errors.addressLine1
									}
								]}
							/>
							<FormInputs
								ncols={['8', '4']}
								className={['', '']}
								properties={[
									{
										label: 'Address Line 2',
										autoComplete: 'address-line1',
										fieldType: 'text',
										name: 'addressLine2',
										value: values.addressLine2,
										onChange: handleChange,
										isValid: touched.addressLine2 && !errors.addressLine2
									},
									{
										label: 'City',
										fieldType: 'text',
										name: 'cityName',
										autoComplete: 'address-level2',
										value: values.cityName,
										onChange: handleChange,
										isValid: touched.cityName && !errors.cityName
									}
								]}
							/>
							<FormInputs
								ncols={['4', '4', '4']}
								className={['', '', '']}
								properties={[
									{
										label: 'Postal Code',
										fieldType: 'text',
										name: 'postalCodeText',
										autoComplete: 'postal-code',
										value: values.postalCodeText,
										onChange: handleChange,
										isValid: touched.postalCodeText && !errors.postalCodeText
									},
									{
										label: 'State/Province',
										fieldType: 'select',
										name: 'stateProvinceName',
										value: values.stateProvinceName,
										options: [['0','select']].concat(Object.entries(this.state.cstates ? 
											this.state.cstates['ve'] : [])
												.map((e:any) => {
													e[1] = e[1].replace('Estado ', '') 
													e[0] = e[1]
													return e
												})),
										onChange: handleChange
									},
									{
										label: 'country',
										fieldType: 'select',
										name: 'countryName',
										value: values.countryName ?? 'venezuela',
										options: [
											['xx-XX', t('Select')],
											['venezuela', 'Venezuela']
										],
										onChange: handleChange
									}
								]}
							/>
							<hr />
							<Row className='mx-1'>
								<Col md={12}>
									<FormInputs
										ncols={['6', '2', '4']}
										className={['ml-0']}
										properties={[
											{
												label: 'Country Code',
												fieldType: 'select',
												name: 'phoneCountryCode',
												value: values.phoneCountryCode,
												options: this.state.countries ? [['0',t('select')]].concat(this.state.countries.map((c:any) => [c.dial_code, `${c.name} (${c.dial_code})`])) : [],
												onChange: handleChange
											},
											{
												label: 'Area Code',
												fieldType: 'text',
												name: 'areaCode',
												autoComplete: 'tel-area-code',
												value: values.areaCode,
												onChange: handleChange,
												isValid: touched.areaCode && !errors.areaCode
											},
											{
												label: 'Phone Number',
												fieldType: 'text',
												name: 'telephoneNumber',
												autoComplete: 'tel-local',
												value: values.telephoneNumber,
												onChange: handleChange,
												isValid: touched.telephoneNumber && !errors.telephoneNumber
											}
										]}
									/>
								</Col>
							</Row>
							<hr />
							<FormInputs
								ncols={['6']}
								className={['px-2']}
								properties={[
									{
										label: 'email',
										feedback: ' ',
										fieldType: 'text',
										name: 'email',
										value: values.email,
										onChange: handleChange,
										isValid: touched.email && !errors.email,
										isInvalid: !!errors.email,
										error: errors.email
									}
								]}
							/>
						</Col>
						<Col md='5' className='mx-auto my-4 text-center'>
							<Button id="back" type="button" className="m-2 mr-5" variant="danger"  onClick={() => this.props.history.push('/admin/clients/list')} >back</Button>
							<Button 
								type='submit' 
								id={'facility-form-submit-'} 
								style={ isSubmitting ? {display: 'none'}: {display: 'inline'} } >
									Submit
							</Button>
							{
								isSubmitting ? <div><h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
							}
						</Col>
					</Row>
				</Form>
				)}
				
			</Formik>
			<Modal
				show={this.state.showSelectFacility}
				centered
				dialogClassName="modal-cblist"
				onHide={() => {
					this.setState({showSelectFacility: false})
					//this.props.history.push('/admin/parties/list')
				}}
				>
				<Modal.Header closeButton>
					<Modal.Title>{t('pick client')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<br />
						<CBList2 
							id={"party-cblist-1"}
							label={t()}
                            pkey={this.objects['clients'].cbName}
                            liftUpOption={(client:any) => {
								if(!client || !client.id) return
								this.getFacilities(client.id);
								this.setState({client, facility: {}, showSelectFacility: false})
							}}
                            items={this.state.parties} 
                            placeholder={`${t('search')} ${t('client')}`}
                            whenEmpty={`${t('loading')}`}
                            />
						<br />
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => {
							this.setState({showSelectFacility: false})
							//this.props.history.push('/admin/parties/list')
							}}>
							{t('close')}
						</Button>
					</Modal.Footer>
			</Modal>
			<MessageModal
					show={false}
					message="loading"
				/>
			</div>
		)
    }
}

export default withRouter(FacilityForm)