import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history"
import { Container, Form, Col, Button, Accordion, Card, Alert, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ImageInput from '../Common/ImageInput'
import CBList2 from '../Common/CBList2'

import { Storage } from 'aws-amplify'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'
import PartyInput from '../Common/PartyInput'
import Printer from '../Printer/ProductToPDF'
//import { setMethod } from '../../utils/Endpoint'
import { PreviewModal, MessageModal } from '../../layouts/Modals/Modals'

import BzrAxios from '../../utils/BzrAxios'
import t, { HelperMsg } from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	products: any,
	liftUpAppStates: any,
	disableFields: boolean,
	disableFieldsHandler: any,
	params: any,
	idn: any
}

interface States {
	message: any,
	fixedFields: any,
	values: any,
	supplier: any,
	suppliers: any[],
	image: any,
	showPostview: any,
	productToEdit: any,
	accordionKey: any
	showSelectModal:boolean
	books:any
	locale:any
	productsLoaded: boolean
	isUnitsPerPkg: boolean
}

class NewProductForm extends React.Component<Props,States> {

	loaded = false

	fieldNames = [ 
		'name', 'type', 'description', 'unitOfMeasure', 
		'sku', 'barcode', 'model',
		'bookIdCost', 'entryValueCost', 'bookIdPrice', 'entryValuePrice', 'currency', 'isTaxExempt',
		'gpcLanguage', 'gpcPublication', 'gpcSegment', 'gpcFamily', 'gpcClass', 'gpcBrick', 'gpcAttribute', 'gpcValue',
		'supplierId', 'origin', 'countryOfOrigin',
		'fixedSchemaId'
	]

	constructor(props: Props) {
		super(props)
        this.state = {
			message: null,
			fixedFields: null,
			values: {},
			supplier: null,
			suppliers: [],
			image: {imageURL: null},
			showPostview: null,
			productToEdit: {},
			accordionKey: '0',
			showSelectModal: false,
			books: {costBookNames: [], priceBookNames: []},
			locale: null,
			productsLoaded: false,
			isUnitsPerPkg: false
		}
		this.handleSubmit = this.handleSubmit.bind(this)
		this.getImageUploadState = this.getImageUploadState.bind(this)
	}

	async componentDidMount() {
		// GET 'product.id' from url
		const match:any = this.props.match
		let productToEdit:any = {}, image:any = {imageURL: null}
		if(!match.params.id) this.setState({showSelectModal: true})
		else if(this.props.products) {
			productToEdit = this.props.products.find((p:any) => p.id === match.params.id)
			image = {imageURL: productToEdit.imageURL ?? null}

			const values:any = productToEdit

			values.costBookId = this.props.appStates.bookNames.cost.default
			values.entryValueCost = this.props.appStates.books[this.props.appStates.bookNames.cost.default][productToEdit.id].entryValue

			values.priceBookId = this.props.appStates.bookNames.price.default
			values.entryValuePrice = this.props.appStates.books[this.props.appStates.bookNames.price.default][productToEdit.id].entryValue
			values.currency = this.props.appStates.books[this.props.appStates.bookNames.price.default][productToEdit.id].currency

			if(productToEdit.unitOfMeasure === 'pkg' || productToEdit.unitOfMeasure === 'bulto') this.setState({isUnitsPerPkg: true})

			this.setState({ productToEdit, image, values })
		} else {
			this.props.history.push('/admin/products/list')
			return
		}

/* 		const productToEdit:any = this.props.appStates.productToEdit ?? {},
			image = {imageURL: productToEdit.imageURL ?? null}
			this.setState({productToEdit, image, values: productToEdit}) */

		// 2. Fixed Fields
		try {
			const fixedFields:any = (await BzrAxios.records({url: `/fields/6+7`})).data,
				suppliers = (await BzrAxios.records({url: `/BzrSupplier`})).data.records

			let supplier:any = null
			if(productToEdit) {
				const s = suppliers.find((s:any) => s.id === productToEdit.supplierId )
				//console.log(s)
				supplier = s
			}
			this.setState({suppliers, supplier, fixedFields, productToEdit, image, values: Object.assign({}, this.state.values, productToEdit)})
		} catch(err) {
			console.log(err)
			return
		}
	}

	getImageUploadState(imageState:any) {
		this.setState(imageState)
	}

	setFixedFields(formValues:any) {
		const values:any = {} 
		this.fieldNames.forEach((f) => values[f] = formValues[f])

		if(values.fixedSchemaId === '0') {
			this.setState({values})
		} else {
			this.state.fixedFields[values.fixedSchemaId].forEach(
				(f:any) => { values[f.fieldName] =  ''})
			this.setState({values})
		} 
	}

	compareValues() {
		// MUST BE IMPLEMENTED
	}

	async handleSubmit(values: any, formikBag: any) {
		
		const imageFile = this.state.image.imageFile
		if(imageFile) {
			try {
				const imageKey = [
					this.props.appStates.org.id,
					this.props.appStates.activeBU.id,
					'bzrProductImage',
					`${this.state.values.id}.${imageFile.type.replace('image/','')}`
				].join('/')
				await Storage.put(imageKey, imageFile, { contentType: imageFile.type })
				values['bzrProductImage'] = imageKey
			} catch (err) {
				alert(t('error uploading image - try again'))
				console.log(err)
				return
			}
		}
		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		/////////////////////////////////////////////////    PRICE LOGIC    ////////////////////////////////////////////////////

		const costEntry:any = {}
		const priceEntry:any = {}


		const bookEntries = { costEntry, priceEntry }
		//console.log(bookEntries)
		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		const data = { productData: values, supplierData: this.state.supplier, bookEntries }
		//console.log(data)
		try {
			const response:any = await BzrAxios.records({url: `/Products/${this.state.values.id}`, method: 'PUT', data})
			//console.log(response.data)
			let products:any[] = this.props.products
			const { product } = response.data   /// SUPPLIERS LOGIC MUST BE ADDED -> SEE BELOW
  
			if(this.state.image.imageURL) product['imageURL'] = this.state.image.imageURL

			const idx:number = products.findIndex((p:any) => p.id === product.id)
			if(idx > -1) products[idx] = product
			else console.error('this not must happen ever')

			this.props.liftUpAppStates({products})
			const productURL = URL.createObjectURL(Printer.productToPDF(this.props.appStates, product, {title: 'product card'}))
			this.setState({showPostview: productURL})
		} catch(err) {
			console.error(err)
		}
		this.setState({values: {}, supplier: null})
		formikBag.resetForm()
	}

	renderFields(values:any, handleChange:any, touched:any, errors:any, oid:string) {
		if(!this.state.fixedFields || !this.state.fixedFields[oid]) return null

		//handleChange = handleChange.bind(this)
		let cols:any = []
		let formInputs:any = []
		//const length = this.state.fixedFields[oid].length
		this.state.fixedFields[oid].forEach((field:any, i:any) => {
				formInputs.push(<FormInputs
					key={i}
					ncols={["12"]}
					className={[""]}
					properties={[
						{
							label: field.fieldName,
							horizontalLabel: "5",
							feedback: " ",
							fieldType: "text",
							name: field.fieldName,
							value: values[field.fieldName] ?? '',
							onChange: handleChange,
							isValid: touched[field.fieldName] && !errors[field.fieldName]
						}
					]}
				/>)
		})
		cols.push(<Col key="3.0" md={12}>{formInputs}</Col>)			
		return (<Form.Row>{cols}</Form.Row>)
	}

	messageBox() {
		if (this.state.message) {
		  	return (
				<Alert variant="success" onClose={() => this.setState({message: null})} dismissible>
					<Alert.Heading>Info</Alert.Heading>
					<p>
						{ this.state.message }
					</p>
				</Alert>
			)
		}
	}

	render() {
		const v:any = this.state.values ?? {}
		console.log(v.fixedSchemaId)
		return (
			<Container>
				<Formik
					validationSchema={
						Yup.object({
							name: Yup.string().max(100, 'string too large').required(),
							description: Yup.string().required(),
							sku: Yup.string().required(),
							oems: Yup.string().max(500, 'string too large').nullable(),
							unitsPerPkg: this.state.isUnitsPerPkg ? Yup.string().matches(/^[0-9]+$/, ' ').required(' ') : Yup.string().nullable()
						})
					}
					onSubmit={this.handleSubmit}
					initialValues={v}
					enableReinitialize={true}
				>
					{({
						handleSubmit,
						handleChange,
						setFieldValue,
						isSubmitting,
						values,
						touched,
						errors
					}) => (
					<Form noValidate onSubmit={handleSubmit} id={"business-unit-form-"  + this.props.idn}>
						<Accordion className="edit-product"  defaultActiveKey="0">
						<Form.Row>
							<Col md={9}>
								<fieldset disabled={this.props.disableFields}>
								<Form.Row className="mb-3">
									<Col md={7}>
										<FormInputs
											ncols={['12']}
											className={['px-2']}
											properties={[
												{
													label: "Name*",
													horizontalLabel: "4",
													feedback: " ",
													fieldType: "text",
													name: "name",
													value: values.name ?? '',
													onChange: handleChange,
													isValid: touched.name && !errors.name,
													isInvalid: !!errors.name,
													error: errors.name
												}
											]}
										/>
										<FormInputs
											ncols={["12"]}
											className={["px-2"]}
											properties={[
												{
													label: "Description",
													horizontalLabel: "4",
													feedback: " ",
													fieldType: "textarea",
													name: "description",
													className: "bzr-textarea",
													value: values.description ?? '',
													onChange: handleChange,
													isValid: touched.description && !errors.description,
													isInvalid: !!errors.description,
													error: errors.description
												}
											]}
										/>
										<FormInputs
											ncols={['12']}
											className={['px-2']}
											properties={[
												{
													label: "Code/SKU",
													horizontalLabel: "4",
													feedback: " ",
													fieldType: "text",
													name: "sku",
													value: values.sku ?? '',
													onChange: handleChange,
													isValid: touched.sku && !errors.sku,
													isInvalid: !!errors.sku,
													error: errors.sku
												}
											]}
										/>
									</Col>
									<Col md={5}>
										<ImageInput 
											image={this.state.image}
											liftUpState={(image:any) => this.setState({image})} 
											boxW={170}
											boxH={170} 
											scale={0.95}
											className="rounded-sm border mx-auto" />
									</Col>
									<Col md ={7}>
										<FormInputs
											ncols={['7', '5']}
											className={['px-2', 'px-2']}
											properties={[
												{
													label: 'Unit of Measure',
													horizontalLabel: "6",
													fieldType: 'select',
													name: 'unitOfMeasure',
													value: values.unitOfMeasure ?? 'u',
													options: [
														['u','Units'],
														['kg','Kilograms'],
														['gm','Grams'],
														['lt','Liters'],
														['mt','Meters'],
														['pkg','Package'],
														['bulto', 'Bulto']
													],
													onChange: (event:any) => {
														if(event.target.value === 'pkg' || event.target.value === 'bulto') this.setState({isUnitsPerPkg: true})
														else this.setState({isUnitsPerPkg: false})
														this.setState({values: Object.assign(values, {unitOfMeasure: event.target.value})})
													}
												},
												{
													label: "Units/Pkg",
													horizontalLabel: "8",
													feedback: " ",
													fieldType: "text",
													name: "unitsPerPkg",
													value: values.unitsPerPkg ?? '',
													onChange: handleChange,
													disabled: !this.state.isUnitsPerPkg,
													info: HelperMsg('For packages use a content ref. e.g.: 1Pkg = 10Unds, Fill de Units/Pkg fi'),
													isValid: touched.unitsPerPkg && !errors.unitsPerPkg,
													isInvalid: !!errors.unitsPerPkg,
													error: errors.unitsPerPkg
												}
											]}
										/>
									</Col>
								</Form.Row>
								{ this.messageBox() }
								<hr />
											
								<Accordion.Toggle 
									as={Card.Header} 
									eventKey="0" 
									onClick={(event:any) => {
										const key:string = this.state.accordionKey === '0' ? '10': '0'
										this.setState({accordionKey: key})
									}}
									children={
										<div className="d-flex flex-row">
											<div><h5>Codification</h5></div>
											<div className="flex-grow-1 pl-5">
												{ this.state.accordionKey !== '0' ?
												<div className="d-flex pt-1">
													<div className="px-3"><h6>type</h6>{values.type ?? 'not set'} </div>
													<div className="px-3"><h6>model</h6>{values.model ?? 'not set'} </div>
													<div className="px-3"><h6>group</h6>{values.group ?? 'not set'} </div>
													<div className="px-3"><h6>location</h6>{values.location ?? 'not set'} </div>
													<div className="px-3"><h6>barcode</h6>{values.barcode ?? 'not set'} </div>
													
												</div> : null}
											</div>
											<div className=""><h5><span className="pe-7s-angle-down caret" /></h5></div>
										</div>
										} 
									/>
								<Accordion.Collapse eventKey="0">
									<Form.Row>
									<Col md={8}>
										<FormInputs
											ncols={['6', '6', '6', '6']}
											className={['px-2', 'px-2', 'px-2', 'px-2']}
											properties={[
												{
													label: "Type",
													feedback: " ",
													fieldType: "text",
													name: "type",
													value: values.type ?? '',
													onChange: handleChange,
													isValid: touched.type && !errors.type
												},
												{
													label: "Model",
													feedback: " ",
													fieldType: "text",
													name: "model",
													value: values.model ?? '',
													onChange: handleChange,
													isValid: touched.model && !errors.model,
													isInvalid: !!errors.model,
													error: errors.model
												},
												{
													label: "Group",
													feedback: " ",
													fieldType: "text",
													name: "group",
													value: values.group ?? '',
													onChange: handleChange,
													isValid: touched.group && !errors.group
												},
												{
													label: "Location",
													feedback: " ",
													fieldType: "text",
													name: "location",
													value: values.location ?? '',
													onChange: handleChange,
													isValid: touched.location && !errors.location
												},
												{
													label: "Barcode",
													feedback: " ",
													fieldType: "text",
													name: "barcode",
													value: values.barcode ?? '',
													onChange: handleChange,
													isValid: touched.barcode && !errors.barcode,
													isInvalid: !!errors.barcode,
													error: errors.barcode
												}
											]}
										/>	
									</Col>
									<Col md={4}>
									<Col md={4}>
										<FormInputs
											ncols={['12']}
											className={['px-2']}
											properties={[
												{
													label: "oems*",
													feedback: " ",
													fieldType: "textarea",
													name: "oems",
													className: "bzr-textarea",
													value: values.oems ?? '',
													onChange: handleChange,
													isValid: touched.oems && !errors.oems,
													isInvalid: !!errors.oems,
													error: errors.oems,
													info: HelperMsg('e.g.: oem-00001|oem-00002 or oem-00001,oem-00002')
												}
											]}
										/>	
									</Col>
									</Col>
								</Form.Row>
								</Accordion.Collapse>
								<Accordion.Toggle 
									as={Card.Header} 
									eventKey="2" 
									onClick={(event:any) => {
										const key:string = this.state.accordionKey === '2' ? '10': '2'
										this.setState({accordionKey: key})
									}}
									children={
										<div className="d-flex flex-row">
											<div><h5>Clasification</h5></div>
											<div className="flex-grow-1 pl-5">
												{ this.state.accordionKey !== '2' ?
												<div className="d-flex pt-1">
													<div className="px-3"><h6>in implementation process</h6></div>
												</div> : null}
											</div>
											<div className=""><h5><span className="pe-7s-angle-down caret" /></h5></div>
										</div>
										} />
								<Accordion.Collapse eventKey="2">
									<Form.Row>
										GPC - BEING IMPLEMENTED
									</Form.Row>
								</Accordion.Collapse>
								<Accordion.Toggle 
									as={Card.Header} 
									eventKey="3" 
									onClick={(event:any) => {
										const key:string = this.state.accordionKey === '3' ? '10': '3'
										this.setState({accordionKey: key})
									}}
									children={
										<div className="d-flex flex-row">
											<div><h5>Source</h5></div>
											<div className="flex-grow-1 pl-5">
												{ this.state.accordionKey !== '3' ?
												<div className="d-flex pt-1">
													<div className="px-3"><h6>supplier</h6>{this.state.supplier ? this.state.supplier.legalName : 'not set'} </div>
												</div> : null}
											</div>
											<div className=""><h5><span className="pe-7s-angle-down caret" /></h5></div>
										</div>
										} 
									/>
								<Accordion.Collapse eventKey="3">
									<Form.Row>
										<Col md={6}>
											<PartyInput 
												name=''
												initState={this.state.supplier ?? {}}
												liftUpState={(state:any) => this.setState({supplier: state.party})} 
												parties={this.state.suppliers} 
												placeholder="Add/Searh Supplier"
												emptyPlaceholder="Add Supplier"
												/>
										</Col>
									</Form.Row>
								</Accordion.Collapse>
								
								<Form.Row>
									<Col md="5" className="mx-auto mt-3 text-center">							
										{
											isSubmitting ? <h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5> : 
												<Button 
													type="submit" 
													id={this.props.params.id + "-form-submit-"  + this.props.idn} 
													style={ this.props.singleSubmit || this.props.disableFields ? {display: "none"}: {display: "inline"} } >
														update
												</Button>
										}
									</Col>
								</Form.Row>
								</fieldset>
							</Col>
							<Col md={3} className="rounded-sm bg-light p-3">
								<FormInputs
									ncols={['6']}
									className={['']}
									properties={[
										{
											id: 'product',
											label: 'Product',
											fieldType: 'radio',
											name: 'fixedSchemaId',
											value: '6',
											onChange: () => this.setState({values: Object.assign(values, {fixedSchemaId: '6'})}),
											checked: values.fixedSchemaId === '6'
										}
									]}
								/>
								<hr />
								{ 
									this.state.fixedFields ?
									this.renderFields(values, handleChange, touched, errors, values.fixedSchemaId) :
									<h5>{t('loading')}<FontAwesomeIcon icon={faSpinner} spin /></h5>
								}
							</Col>
						</Form.Row>
						</Accordion>
						<MessageModal show={isSubmitting} message="updating product..." />
					</Form>
					)}
				</Formik>
				<PreviewModal 
					show={!!this.state.showPostview} 
					title="new product"
					url={this.state.showPostview ?? ''} 
					onHide={() => {
						this.setState({showPostview: null})
						this.props.history.push('/admin/products/list')
					}
				} />
				<Modal
					show={this.state.showSelectModal}
					centered
					dialogClassName="modal-cblist"
					onHide={() => {
						this.setState({showSelectModal: false})
						this.props.history.push('/admin/products/list')
					}}
					>
					<Modal.Header closeButton>
							<Modal.Title>select product to edit</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{ !this.props.products ? <div className="float-right verifier"><h6>{t('fetching products')}<i className="fas fa-spinner fa-spin" /></h6></div> : null }
                            <CBList2 
								id={"product-cblist-1"}
								pkey="name"
								value={this.state.productToEdit ? this.state.productToEdit.name : ''}
								liftUpOption={(productToEdit:any) => {
									if(productToEdit && productToEdit.id) {
										const image = {imageURL: productToEdit.imageURL ?? null}

										const values:any = {},
										books:any = this.props.appStates.books,
										bookNames:any = this.props.appStates.bookNames,
										costEntry:any = books[bookNames.cost.default][productToEdit.id] ?? {entryValue: 0},
										priceEntry:any = books[bookNames.price.default][productToEdit.id] ?? {entryValue: 0} 
						
										values.bookIdCost = bookNames.cost.default
										values.bookIdPrice = bookNames.price.default
										values.entryValueCost = costEntry.entryValue
										values.entryValuePrice = priceEntry.entryValue
										values.currency = costEntry.currency
										this.setState({
											productToEdit: Object.assign({}, productToEdit ?? {}), 
											image, 
											values: Object.assign({}, productToEdit, values),
											showSelectModal: false
										})
										this.props.history.replace(`/admin/products/edit/${productToEdit.id}`)
									} else {
										this.setState({ productToEdit: Object.assign({}, productToEdit ?? {}) })
									}
								}}
								items={this.props.products} 
								placeholder="Search product..."
								whenEmpty="No products..."
								disabled={!this.props.products ? true : false}
								/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => {
								this.setState({showSelectModal: false})
								this.props.history.push('/admin/products/list')
							}}>
								Close
							</Button>
						</Modal.Footer>
				</Modal>
			</Container>
		)
	}
}

export default withRouter(NewProductForm)