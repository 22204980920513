import React from 'react'

const ImageBox = (props:any) => {
    if(!props.url) return null
    const img:any = document.createElement('img')
    img.src=props.url
    img.alt="item-preview"
    const div:any = document.createElement('div')
    div.style.height = (props.size + 2) + 'px'
    div.style.width = (props.size + 2) + 'px'
    div.setAttribute("class", "shadow-sm")

    img.onload = () => {
        const imgRel = img.width/img.height
        if (imgRel >= 1) {
            img.width = props.size
            img.height = props.size/imgRel
            div.style.padding = 0
            div.style.paddingTop = (props.size - img.height)/2 + 'px'
        } else  {
            img.height = props.size
            img.width = props.size * imgRel
            div.style.padding = 0
            div.style.paddingLeft= (props.size - img.width)/2 + 'px'
        }
        div.appendChild(img)
    }
    const cont:any = document.getElementById(`img-${props.id}`)
    
    while(cont && cont.hasChildNodes()) {
        cont.removeChild(cont.firstChild)
    }
 
    return <div className="mx-2 my-0" id={`img-${props.id}`} ref={(nodeElement) => { nodeElement && nodeElement.appendChild(div) }}/>
}

export default ImageBox