import React from 'react'
import { Line, Bar } from 'react-chartjs-2'
import { Row, Col, ListGroup, ButtonGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap'
import t, { fn, months } from '../../utils/I18n/I18n'

interface Props {
    data: any
    liftUpRef?: any
    width?: any
    type?:any
    updateChart?:any
}
interface State {
    ref: any
    chartType: string
    range: any
}
class Chart extends React.Component<Props> {
    chartData = () => ({
        labels: this.props.data.labels,
        datasets: this.props.data.legends.map((l:any, i:number) => ({
            label: l,
            backgroundColor: this.color(i,0.2),
            borderColor: this.color(i),
            borderWidth: 1,
            hoverBackgroundColor: this.color(i,0.4),
            hoverBorderColor: this.color(i),
            data: Object.values(this.props.data.dataSets).map((d:any) => d[l])
        }))
    })

    color(i:number, o = 1) {
        const colorSet = [
            `rgba(255,99,132,${o})`,
            `rgba(132,99,255,${o})`,
            `rgba(132,255,132,${o})`,
            `rgba(0,99,132,${o})`,
            `rgba(132,0,132,${o})`
        ]
        return colorSet[i]
    }
    
    render() {
        let ct:any
        switch(this.props.type) {
            case 'line':
                ct = {main: Line}
                break
            case 'bar':
                ct = {main: Bar}
                break
        }
        return(
            <ct.main
                data={this.chartData()}
                width={this.props.width ?? 50}
                ref = {(ref:any) => this.props.liftUpRef(ref)}
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: 0
                            }
                        }]
                    },
                    animation: {
                        easing: "linear"
                    }
                }}
            />
        )
    }
}
class BzrTopNChart extends React.Component<Props,State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            ref: null,
            chartType: 'line',
            range: null
        }
        this.getChartRef = this.getChartRef.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }
    componentDidUpdate() {
        if(this.state.ref && this.state.ref.chartInstance) this.state.ref.chartInstance.update()
    }
    handleClick(event:any) {
        this.props.updateChart({[event.target.name]: event.target.value})
    }
    getChartRef(ref: any) {
        if(!this.state.ref)
            this.setState({ref: ref})
    }
    render() {
        return(
            <Row>
                <Col md={8}>
                    <div className="d-flex">
                        <div><h2 className="mt-3">Top 5 Sales</h2></div>
                        <div className="ml-auto mr-2 pt-5">
                            <ButtonGroup className="pl-3 bzr-chart-btns">
                                <Button variant="outline-secondary" onClick={() => this.setState({chartType: 'line'})}>
                                    <i className="ti-stats-up"/>
                                </Button>
                                <Button variant="outline-secondary" onClick={() => this.setState({chartType: 'bar'})}>
                                    <i className="ti-bar-chart"/>
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                    
                    <div style={{height: '300px', width: '100%', backgroundColor: 'white'}} 
                        className={`${ this.props.data.show ? '' : "placeholder-item placeholder-loading"}`}>
                            <Chart type={this.state.chartType} data={this.props.data.topSales} liftUpRef={this.getChartRef} />
                    </div>
                </Col>
                <Col md={4} className="pt-5">
                    <ListGroup className="bzr-list-1">
                        { this.props.data.topNProducts.map((p:any, k:number) => 
                            (<ListGroup.Item key={k}>{p.name.toUpperCase()} <span>{fn(p.sum)}</span></ListGroup.Item>)
                        )}
                        <ListGroup.Item style={{fontSize: "1rem"}}>Total <span>{fn(this.props.data.topNProducts.reduce((t:any, v:any) => t + v.sum, 0))}</span></ListGroup.Item>
                    </ListGroup>
                    <br /><br />
                    <ButtonGroup className="bzr-chart-btns">
                        <Button variant="outline-secondary" onClick={() => { 
                            const d:any = new Date(),
                                range = {
                                    from: new Date(
                                        d.getFullYear(), 
                                        d.getMonth(), 
                                        d.getDate() - (d.getDay() === 0 ? 6 : d.getDay() - 1 )), 
                                    to: new Date()
                                }
                            this.props.updateChart(range)
                            }}>current week</Button>
                        <Button variant="outline-secondary" onClick={() => { 
                            const d:any = new Date(),
                                range = {from: new Date(d.getFullYear(), d.getMonth(), 1), to: new Date()}
                            this.props.updateChart(range)   
                            }}>current month</Button>
                        <DropdownButton as={ButtonGroup} title={t('month')} id="month-dropdown" variant="outline-secondary">
                            {(() => {
                                let el:any[] = []
                                const d:any = new Date(),
                                    yy = d.getFullYear()
                                for(let m = d.getMonth() - 1; m > 0; m--) {
                                    el.push(
                                    <Dropdown.Item key={`${m}`}  eventKey={`${m}`} onClick={() => { 
                                        const range = { from: new Date(yy, m, 1), to: new Date(yy, m + 1, 0)}
                                        this.props.updateChart(range) 
                                    }}>
                                        {t(months[m])}
                                    </Dropdown.Item>)
                                }
                                return el
                            })()}
                        </DropdownButton>
                    </ButtonGroup>
                </Col>
            </Row>
        )
    }
}

export default BzrTopNChart