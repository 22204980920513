import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
import logo from '../../assets/img/logo-blue.png'
import { Link } from "react-router-dom"
import SignoutModal from '../../components/User/Signout'
import { AppStateContext, LangContext } from '../../utils/Contexts'
import t from '../../utils/I18n/I18n'
import { I18n } from 'aws-amplify';

interface Props {
    user: any
}

interface State {
    scrollClass: string,
    signout: boolean,
    language: string,
    show: boolean
}

class TopNavbar extends React.Component<Props, State>{

    static contextType = AppStateContext

    constructor(props: any) {
        super(props);
        this.state = {
            scrollClass: "",
            signout: false,
            language: 'en',
            show: false
		}
        this.handleChange = this.handleChange.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
	}
	componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}
    
	handleChange(event:any, lang:string, setLanguage:any) {
		const language:string = event.target.value
		this.setState({language})
		I18n.setLanguage(language)
	}
	
	handleScroll(event: any) {
        let scrollTop = window.scrollY;
        if(scrollTop > 100) {
            this.setState({scrollClass: "scrolled-nav"});
        } else {
            this.setState({scrollClass: ""});
        }
    }
    
    centerLinks() {
        
    }

    render() {
        return ( 
            <Navbar id="top-navbar"
                    className={"guest-navbar navbar-custom " + this.state.scrollClass}
                    collapseOnSelect 
                    expand="lg" 
                    sticky="top">
               
                <Navbar.Brand className="brz-brand" href="/">
                    <img
                        alt="Bizor Logo"
                        src={logo}
                        width="35"
                        height="35"
                        className="d-inline-block align-top"
                        style={{marginRight: "2px"}}
                    />
                    Bizor<span> {t('by')} CastellSys</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => this.setState({show: !this.state.show}) } />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="m-auto text-capitalize">
                        <Nav.Link href="/features">{t('features')}</Nav.Link>
                        <Nav.Link href="/pricing">{t('plans')} & {t('pricing')}</Nav.Link>
                        <Nav.Link href="/preview">{t('preview')}</Nav.Link>
                    </Nav>
                    <Nav>
                        <LangContext.Consumer>
                            {({lang, setLang}) => (
                            <Form className="language-select">
                                <Form.Group controlId="language">
                                    <Form.Control 
                                        className="form-control custom-select"
                                        as="select" size="sm"
                                        value={lang}
                                        onChange={(event:any) => setLang(event.target.value)}
                                        >
                                        <option value='en'>en</option>
                                        <option value='es'>es</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form>)}
                        </LangContext.Consumer>
                        {
                            this.props.user ? (
                                <NavDropdown 
                                    active={this.state.show}
                                    title={this.props.user.username ?? ''} 
                                    id="collasible-nav-dropdown">
                                    <Link to="/admin/dashboard" className="dropdown-item">{t('Dashboard')}</Link>
                                    <Link to="/admin/preferences" className="dropdown-item">{t('Preferences')}</Link>
                                    <Link to="/admin/account" className="dropdown-item">{t('Account')}</Link>
                                    <hr />
                                    <Nav.Link onClick={ () => this.setState({signout: true})} >sign out</Nav.Link>
                                </NavDropdown>
                            ) : (
                                <div className="mt-3">
                                    <Link to="/signup" className="right-top-links">{t('subscribe')}</Link>
                                    <Link to="/signin" className="right-top-links">{t('sign in')}</Link>
                                </div>
                            )
                        }
                        
                    </Nav>
                </Navbar.Collapse>
                { this.state.signout ? <SignoutModal /> : '' }
            </Navbar>
        )
    }
}

export default TopNavbar;