import React from 'react'
import { InputGroup, FormControl, Dropdown} from 'react-bootstrap'
import { isEmptyArray } from 'formik'
import t from '../../utils/I18n/I18n'

interface Props {
    id: any
    items: any[]
    liftUpOption: any
    pkey: string
    value?: any
    label?:string
    filters?: string[]
    whenEmpty?: string
    placeholder?: string
    nextFocus?: string
    disabled?: boolean
    autoComplete?: string
}

interface States {
    dropItems: any[]
    show: boolean
    prevKeyEvent: any
    value: string
    item: any
}

class CBList extends React.Component<Props, States> {
    constructor(props: Props){
        super(props)
        this.state = {
            dropItems: [],
            show: false,
            prevKeyEvent: null,
            item: null,
            value: ''
        }
        this.handleDropdown = this.handleDropdown.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.searchItem = this.searchItem.bind(this)
        this.handleOnKeyUp = this.handleOnKeyUp.bind(this)
    }

    componentDidUpdate() {
        if(this.state.value === '' && this.state.item) {
            this.setState({item: null})
            this.props.liftUpOption(null)
        } else if(this.props.value === null && this.state.value !== ''){
            this.setState({item: null, value: ''})
        } else if (this.props.value && this.state.value === '') {
            const item:any = this.props.items.find((item:any) => item[this.props.pkey] === this.props.value)
            this.setState({value: this.props.value, item})
        }
    }

    handleDropdown() {
        if(this.props.items && !this.state.show) {
            this.setState({
                dropItems: this.props.items.map((item:any, i:number) => 
                    <Dropdown.Item 
                    onSelect={this.handleSelect} 
                        key={i} 
                        eventKey={item.id}
                        className={'cblist-dd-' + this.props.id}
                        >
                        {item[this.props.pkey]} {this.props.filters ? '/' : ''} {this.props.filters?.map((filter) => item[filter]).join(' - ')}
                    </Dropdown.Item>
                )
            })
        } else if(!this.props.items && !this.state.show) {
            this.setState({
                dropItems: [
                <Dropdown.Item key="1">{ this.props.whenEmpty }</Dropdown.Item>
                ]
            })
        }
        this.setState({show: !this.state.show})
    }

    handleSelect(eventKey:any, event:any) {
        this.setState({show: false})
        const item:any = this.props.items.find((item:any) => item.id.toString() === eventKey)
        this.props.liftUpOption(item)
        this.setState({item, value: item[this.props.pkey]})
        const input:any = document.getElementById(this.props.id)
        input.focus()
    }

    handleOnKeyUp(event: any) {
        const k = event.keyCode
        //console.log('keyCode: ' + k)
        if(k === 40  && !this.state.show && !this.state.item ) {
            this.handleDropdown()
        }  else if(k === 40) {
            const items:any = document.getElementsByClassName('cblist-dd-' + this.props.id)
            if(items.length > 0) items[0].focus()
        } else if(k === 38 && this.state.show) {
            this.setState({show: false})
        } else if(k === 13 && this.state.prevKeyEvent === k && this.props.nextFocus) {
            if(!this.props.nextFocus) return
            const input:any = document.getElementById(this.props.nextFocus)
            input.select()
            this.setState({prevKeyEvent: null})
            return
        } else if(k === 39 && this.props.nextFocus) {
            const input:any = document.getElementById(this.props.nextFocus)
            input.select()
        }
        this.setState({prevKeyEvent: k})
    }

    searchItem(event: any) {
        if(!this.props.items) return
        // Declare variables 
        const ops:any[] = []
        const match = event.target.value.toUpperCase()
        if(match === '') {
            this.setState({show: false, item: null})
            this.props.liftUpOption(null)
        } else this.setState({show: true})

        this.props.items.forEach((item:any, key:any) => {
            if (item[this.props.pkey].toUpperCase().indexOf(match) > -1) {
                ops.push(
                    <Dropdown.Item 
                        onSelect={this.handleSelect} key={key} eventKey={item.id} className={'cblist-dd-' + this.props.id}>
                        {item[this.props.pkey]} {this.props.filters?.map((f) => item[f]).join(' - ')}
                    </Dropdown.Item>
                )
            } else {
                this.props.filters?.forEach((filter) => {
                    if (item[filter]?.toUpperCase().indexOf(match) > -1) {
                        ops.push(
                            <Dropdown.Item  onSelect={this.handleSelect} key={key} eventKey={item.id} className={'cblist-dd-' + this.props.id}>
                                {item[this.props.pkey]} {this.props.filters?.map((f) => item[f]).join(' - ')}
                            </Dropdown.Item>
                        )
                    }
                })
            } 
        })
        this.props.liftUpOption({[this.props.pkey]: event.target.value})
        if(isEmptyArray(ops)) {
            this.setState({show: false, item: null})
        } else {
            const selected:any = this.props.items.find((item:any) => item[this.props.pkey] === event.target.value)
            if(selected) this.setState({show: false, item: selected})
        }
        this.setState({dropItems: ops, value: event.target.value})
    }

    render() {
        return (
            <div className="bzr-cblist">
                { this.props.label ? <div className="bzr-label">{this.props.label}</div> : null}
                <InputGroup>
                    <FormControl
                        id={this.props.id}
                        placeholder={(this.props.items && !this.props.disabled) ? t(this.props.placeholder) : t('loading')}
                        aria-label={t(this.props.placeholder)}
                        aria-describedby="cblist-addon"
                        onKeyUp={this.handleOnKeyUp}
                        onChange={this.searchItem}
                        value={this.state.value}
                        style={{width: "250px !important"}}
                        disabled={this.props.disabled ?? false}
                        autoComplete={this.props.autoComplete ?? 'off'}
                        />
                    <InputGroup.Append>
                    <Dropdown id={'dropdown-menu-' + this.props.id} drop='down' show={this.state.show} >
                        <Dropdown.Toggle 
                            id={'dropdown-cblist-' + this.props.id} 
                            onClick={this.handleDropdown}
                            variant="info" 
                            disabled={this.props.disabled ?? false} 
                            >
                            <i className="fas fa-search" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu 
                            className="cblist-menu" 
                            flip={false} 
                            >
                          
                                {this.state.dropItems}
                            
                        </Dropdown.Menu>
                    </Dropdown>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        )
    }
}

export default CBList