import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Form, Row, Card, Button, Alert } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import { History, LocationState } from "history"
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import BzrAxios from '../../utils/BzrAxios'
import Axios from 'axios'

import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
    history: History<LocationState>
}

interface States {
	preSigned: boolean,
	email: string,
	submitted: boolean,
	alertMsg: any,
	provCreds: any
}

class Signup extends React.Component<Props,States> {
	
	constructor(props: Props) {
		super(props);

        this.state = {
            preSigned: false, 
			email: '',
			submitted: false,
			alertMsg: null,
			provCreds: null
		};
	}

	handleSingUp (values: any) {
		this.setState({alertMsg: null, provCreds: values})
		BzrAxios.users({url: '/Cupons', method: 'POST', data: {cupon: values.cupon}})
		.then((response:any) => {
			Auth.signUp({
				username: values.email,
				password: values.password,
				attributes: {
					email: values.email,          			// required
					"custom:username": values.username,		// required
					"custom:registered": 'false',		
					"custom:mfa": 'false',
					"custom:status": '700'		
				}
			})
			.then(data => {
				this.setState({
					preSigned: true, 
					email: values.email, 
					submitted: false,
					alertMsg: {
						variant: 'success', 
						heading: 'Success', 
						message: 'Check your email for a verification code & fill it bellow!'}
				})
				// First Approach
			})
			.catch((err:any) => {
				this.setState({
					alertMsg: {
						variant: 'danger', 
						heading: 'Error', 
						message: err.code + ' ' + err.message
					}, 
					submitted: false});
	/* 			switch (err.code) {
					case "UsernameExistsException":
						console.error(err.code);
					break;
					default:
						console.error(err);
					break;
				} */
			})
		})
		.catch((err) => {
			this.setState(
				{
					alertMsg: {
						variant: 'danger', 
						heading: 'Error', 
						message: 'Bad Cupon Request'
					}, 
					submitted: false
				}
			)
		})
	}

	handleConfirm (values: any) {
		console.log("handle confirm");
		Auth.confirmSignUp(
			values.email,
			values.code
		)
		.then(data => {
			console.log(data)
			Auth.signIn(this.state.provCreds.email, this.state.provCreds.password).then(
				(user: any) => {
					Axios.defaults.headers.common['Authorization'] = user.signInUserSession.idToken.jwtToken
					// 1. Create User in Bizor Realm
					BzrAxios.users({
						url: '/',
						method: 'POST',
						data:{
							username: user.attributes['custom:username'],
							email: user.attributes['email'],
							id: user.attributes['sub'],
							userType: 'root',
							userStatus: 900,
							emailVerifiedAt: (new Date()).toISOString(),  ////// Check,
							cupon: values.cupon
						}
					})
					.then((response: any) => {
						Auth.updateUserAttributes(user, {'custom:registered': 'true', 'custom:status': '900'})
						.then(() => {
							window.location.replace('/enrollment')
						})                            
					})
					.catch((error: any) => {
							// Error 409: User already exists in Bizor Realm
							// Notify -> Post Request /BzrDoctor 
							console.error(error.response.data)
							this.setState({alertMsg: {
								type: 'danger',
								heading: 'Error on Sign In',
								message: error.response.status + ': ' + error.response.data
							}
						})
					})
				}
			).catch(
				(err: any) => {
					this.setState({
					alertMsg: {
						type: 'danger',
						heading: 'Error on Sign In 2',
						message: err.code + ': ' + err.message
					}, 
					submitted: false
				})
					console.log('BAD SIGNIN REQUEST: ' + err.code);
				}
			)
		})
		.catch(err => {
			console.error(err)
		});
	}

	SignUpForm () {
		return (
			<Formik
				validationSchema={
					Yup.object().shape({
						username: Yup.string().required(),
						email: Yup.string().email().required(),
						password: Yup.string().required().matches(/(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,20}/gm),
						cPassword: Yup.string().oneOf([Yup.ref('password'), null]).required('Password confirm is required')
					})
				}
				onSubmit={ 
					(values) => { 
						this.handleSingUp(values); 
						this.setState({submitted: true}); 
					}
				}
				initialValues={{ username: '', email: '', password: '', cPassword: '', cupon: '' }}
			>
				{({
					handleSubmit, handleChange, handleBlur, values, touched, isValid, errors
				}) =>
				(
				<Form noValidate onSubmit={handleSubmit}>
					<fieldset disabled={this.state.submitted}>
					<Form.Group as={Row} controlId="validationFormikUsername">
						<Form.Label>{t('username')}</Form.Label>
						<Form.Control 	type="text" 
										name="username"
										value={values.username}
										onChange={handleChange}
										onBlur={handleBlur}
                						isInvalid={!!errors.username} 
										autoFocus/>
						<Form.Control.Feedback type="invalid">{t(errors.username)}</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Row} controlId="formGridEmail">
						<Form.Label>{t('email')}</Form.Label>
						<Form.Control 	type="email" 
										name="email"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
                						isInvalid={touched.email && !!errors.email} />
						<Form.Control.Feedback type="invalid">{t(errors.email)}</Form.Control.Feedback>						
					</Form.Group>
					<Form.Group as={Row} controlId="formGridPassword">
						<Form.Label>{t('password')}</Form.Label>
						<Form.Control 	type="password" 
										name="password"
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
                						isInvalid={touched.password && !!errors.password} />
						<Form.Control.Feedback type="invalid">{t(errors.password)}</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Row} controlId="formGridConfirmPassword">
						<Form.Label>{t('confirm')} {t('password')}</Form.Label>
						<Form.Control 	type="password" 
										name="cPassword"
										value={values.cPassword}
										onChange={handleChange} 
										onBlur={handleBlur}
										isInvalid={touched.cPassword && !!errors.cPassword} />
						<Form.Control.Feedback type="invalid">{t(errors.cPassword)}</Form.Control.Feedback>
					</Form.Group>
					<hr />
					<Form.Group as={Row} controlId="cupon">
						<Form.Label>{t('cupon')}</Form.Label>
						<Form.Control 	type="text" 
										name="cupon"
										value={values.cupon}
										onChange={handleChange} 
										onBlur={handleBlur}
										isInvalid={touched.cupon && !!errors.cupon} />
						<Form.Control.Feedback type="invalid">{t(errors.cupon)}</Form.Control.Feedback>
					</Form.Group>
					<br />
					<Form.Group as={Row}>
						<div>{ this.state.submitted ? (<div>Submitting <FontAwesomeIcon icon={faSpinner} spin /></div>) : ''}</div>
						<Button variant="primary" type="submit" className="ml-auto text-capitalize"> {t('submit')} </Button>
					</Form.Group>
					</fieldset>
				</Form>
				)}
			</Formik>
		);
	}

	ConfirmForm () {
		return (
			<Formik
			validationSchema={
				Yup.object().shape({
					code: Yup.string().required(),
					email: Yup.string().email().required(),
					key: Yup.string().required()
				})
			}
			enableReinitialize={true}
			onSubmit={
				(values) => {
					this.handleConfirm(values);
					this.setState({submitted: true}); 
				}
			}
			initialValues={{ code: '', email: this.state.email, cupon: '' }}
		>
			{({
				handleSubmit, handleChange, handleBlur, values, touched, errors
			}) =>
			(
				<Form noValidate onSubmit={handleSubmit}>
					<fieldset disabled={this.state.submitted}>
					<Form.Group as={Row} controlId="formGridCode">
						<Form.Label>{t('code')}</Form.Label>
						<Form.Control 	type="text" 
										name="code"
										value={values.code}
										onChange={handleChange}
										isInvalid={!!errors.code} 
										autoFocus/>
						<Form.Control.Feedback type="invalid">{t(errors.code)}</Form.Control.Feedback>
					</Form.Group>

					<Form.Group as={Row} controlId="formGridEmail">
						<Form.Label>{t('email')}</Form.Label>
						<Form.Control 	type="email" 
										name="email"
										value={values.email}
										onChange={handleChange}
										isInvalid={!!errors.email} />
						<Form.Control.Feedback type="invalid">{t(errors.email)}</Form.Control.Feedback>
					</Form.Group>
					<hr />
					<Form.Group as={Row} controlId="cupon">
						<Form.Label>{t('key')}</Form.Label>
						<Form.Control 	type="text" 
										name="cupon"
										value={values.cupon}
										onChange={handleChange}
										isInvalid={!!errors.cupon} />
						<Form.Control.Feedback type="invalid">{t(errors.cupon)}</Form.Control.Feedback>
					</Form.Group>
					<br />
					<Form.Group as={Row} className="text-capitalize">
						<div>{ this.state.submitted ? (<div>{t('submitting')}... <FontAwesomeIcon icon={faSpinner} spin /></div>) : ''}</div>
						<Button variant="primary" type="submit" className="ml-auto "> {t('submit')} </Button>
					</Form.Group>
					</fieldset>
				</Form>
			)}
			</Formik>
		);		
	}


	render() {
		return (
			<Container className="d-flex justify-content-center">
				{this.state.alertMsg ? 
					(<Alert variant={this.state.alertMsg.variant} onClose={() => this.setState({alertMsg: null})} dismissible>
						<Alert.Heading>{this.state.alertMsg.heading}</Alert.Heading>
						{this.state.alertMsg.message}
					</Alert>) : null
				} 
				<Card className="px-1 auth-form">
					<Card.Body>
						<h4 className="text-capitalize">{ this.state.preSigned ? t('confirm sign up') : t('sign up') }</h4>
						<br />
						{/** this is done to avoid rendering conflits */}
						<div className="mx-4">
							{ this.state.preSigned ? this.ConfirmForm() : null }
						</div>
						<div className="mx-4">
							{ this.state.preSigned ? null : this.SignUpForm() }
						</div>
					</Card.Body>
					<Card.Footer>
                        <a href="#confirm" onClick={() => this.setState({preSigned: true})}><b>{t('already received a registry code?')}</b></a>
					</Card.Footer>
				</Card>				
			</Container>
		);
	}
}

export default withRouter(Signup);