import React from 'react'
import BzrAxios from '../../utils/BzrAxios'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from 'history'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { ConfirmModal, MessageModal } from '../../layouts/Modals/Modals'
import t, { translate } from '../../utils/I18n/I18n'
import BzrTable from '../Common/BzrTable'
import { getCookie, setCookie } from '../../utils/Cookies'

interface Props extends RouteComponentProps<LocationState> {
    history: History<LocationState>,
    appStates: any,
    liftUpAppStates: any
}

interface State {
    clients:any
    clientDelete:any
    deleting:boolean
    defaultHeaders:any
    fields:any
    individuals:any[]
}

class ClientList extends React.Component<Props,State> {
    priceUpdated:boolean = false
    version:string = '20200825'
    defaultHeaders:any[] = [
        {fieldName: 'identificationNumber', filter: true, show: true},
        {fieldName: 'legalName', filter: true, show: true},
        {fieldName: 'commercialName', filter: true, show: false},
        {fieldName: 'clientNumber', filter: true, show: false},
        {fieldName: 'priceListName', fieldLabel: 'price list', filter: true, show: true},
        {fieldName: 'sellerName', fieldLabel: 'seller', filter: true, show: true},
        {fieldName: 'sellerCommission', filter: true, show: true, format: 'percentage', style: {maxWidth: "120px"}},
        {fieldName: 'concatPhone', fieldLabel: 'phone', filter: false, show: false},
        {fieldName: 'email', fieldLabel: 'email', filter: true, show: true},
        {fieldName: 'concatAddress', fieldLabel: 'address', filter: false, show: false},
        {fieldName: 'countryName', fieldLabel: 'country', filter: true, show: false},
        {fieldName: 'stateProvinceName', fieldLabel: 'province', filter: true, show: false},
        {fieldName: 'cityName', fieldLabel: 'city', filter: true, show: false},
        {fieldName: 'postalCodeText', fieldLabel: 'postal code', filter: false, show: false}
    ]
    constructor(props: Props) {
        super(props)
        this.state = {
            clients: null,
            clientDelete: null,
            deleting: false,
            defaultHeaders: [],
            fields: [],
            individuals: []
        }
        this.renderActions = this.renderActions.bind(this)
    }
    async componentDidMount() {
        const el:any = document.getElementById('clients-list')
        translate(el)
        if(!this.props.appStates.clients){
            //  GET CLIENTS
            BzrAxios.records({url: `/BzrClient?qorderby=legalName`})
            .then((response:any) => this.props.liftUpAppStates({clients: response.data.records, clientFields: response.data.fields}))
            .catch((error: any) => console.error(error))
        }
        if(!this.props.appStates.individuals){
            //  GET INDIVIDUALS
            BzrAxios.records({url: `/BzrIndividual`})
            .then((response:any) => {

                this.props.liftUpAppStates({individuals: [...response.data.records, ...this.props.appStates.users]})
            })
            .catch(err => console.log(err))
        }

        // Cookies
        const defaultHeadersCK:any = getCookie("clients-list")
        if(!defaultHeadersCK) {
            this.setState({defaultHeaders: this.defaultHeaders})
            setCookie('clients-list', JSON.stringify({version: this.version, data: {defaultHeaders: this.defaultHeaders}}), 30)
        } else {
            const { version, data } = JSON.parse(defaultHeadersCK)
            if(version !== this.version) {
                this.setState({defaultHeaders: this.defaultHeaders})
                setCookie('clients-list', JSON.stringify({version: this.version, data: {defaultHeaders: this.defaultHeaders}}), 30)
            } else{
                this.setState({defaultHeaders: data.defaultHeaders})
            }
        }
    }
    componentDidUpdate() {
        if(this.props.appStates.clients && this.props.appStates.individuals && this.props.appStates.books && !this.state.clients) this.setClients()
    }
    setClients() {
        let clients:any[] = []
        this.props.appStates.clients.forEach((client:any) => {
            const c = Object.assign({}, client)
            c.priceListName = this.props.appStates.books ? this.props.appStates.bookNames.price_hash[c.priceBookId].alias : '-'
            c.concatPhone = `${c.phoneCountryCode ?? ''} ${c.areaCode ? `(${c.areaCode})` : ''} ${c.telephoneNumber ?? ''}`
            c.concatAddress = `${c.addressLine1} ${c.addressLine2 ? ', ' + c.addressLine2 : ''}`
            const seller:any = this.props.appStates.individuals.find((ind:any) => ind.id === c.sellerId)
            c.sellerName = seller ? `${seller.firstName} ${seller.lastName}` : ''
            clients.push(c)
        })
        this.setState({clients})
    }
    handleDeletion(id:string) {

        //  CHANGE THIS METHOD BY DELIST

/*         this.setState({deleting: true})
        BzrAxios.records({url: `/BzrClient/${id}`, method: 'DELETE'})
		.then((response:any) => {
            const clients = this.props.appStates.clients.filter((c:any) => c.id !== id)
            this.props.liftUpAppStates({clients})
            this.setState({deleting: false, clientDelete: null})
            console.log(response.data)
		})
		.catch((error: any) => {
			console.error(error)
		}) */
    }
    renderActions(record:any, i:number, options?:any) {
        return(
            <div key={i}>
                <a href="#edit" onClick={(event:any)=> {
                    event.preventDefault()
                    this.props.history.push(`/admin/clients/modify/${record.id}`)
                    }}>{t('modify')}
                </a>|
                <a href="#delete" style={{color: 'red'}} onClick={(event:any)=> {
                    event.preventDefault()
                    this.setState({clientDelete: record.id})
                    }}>{t('delete')}
                </a>
            </div>
        )
    }
    render() {
        return(
            <Container id="clients-list" className="clients-list mx-auto px-3">
                <Row>
                    <Col md={4}>
                        <div className="client-option bzr-color-new" onClick={() => this.props.history.push('/admin/clients/new')}>
                            <i className="ti-user"/>
                            <h4>new client</h4>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="client-option bzr-color-mod" onClick={() => this.props.history.push('/admin/clients/modify')}>
                            <i className="ti-id-badge"/>
                            <h4>modify client</h4>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="client-option bzr-color-extra-1" onClick={() => this.props.history.push('/admin/clients/facilities')}>
                            <i className="ti-flag-alt"/>
                            <h4>facilities</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mx-auto mt-4">
                        <BzrTable 
                            id="clients-table"
                            items={this.state.clients}
                            liftUpHeaders={(defaultHeaders:any) => {
                                this.setState({defaultHeaders})
                                setCookie('clients-list', JSON.stringify({version: this.version, data: {defaultHeaders}}), 30)
                            }}
                            headers={this.state.defaultHeaders}
                            fields={this.props.appStates.clientFields}
                            extras={{activeBU: this.props.appStates.activeBU, activeBUId: this.props.appStates.activeBUId}}
                            doubleClick={(record:any)=> this.props.history.push(`/admin/clients/modify/${record.id}`)}
                            />
                    </Col>
                </Row>
                <ConfirmModal 
                    show={!!this.state.clientDelete} 
                    title="delist confirmation" 
                    submitting={this.state.deleting}
                    children={
                        <div >
                            <Button 
                                className="text-capitalize font-weight-bolder py-2" 
                                variant='outline-secondary' 
                                onClick={() => this.handleDeletion(this.state.clientDelete)}>
                                    delete client
                            </Button>
                        </div> } 
                    onHide={() => { 
                        this.setState({clientDelete: null})
                        }} />
                <MessageModal
					show={!this.props.appStates.clients}
					message="loading... "
				/>
            </Container>
        )
    }
}

export default withRouter(ClientList)