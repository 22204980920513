import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap'
import { Auth } from 'aws-amplify'
import { History, LocationState } from "history"
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import BzrAxios from '../../../utils/BzrAxios'
import t, { translate, HelperMsg } from '../../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	appStates: any
}

interface States {
	preSigned: boolean
	email: string
	submitted: boolean
	alertMsg: any
	provCreds: any
	bus: any
	values:any
}

class NewUser extends React.Component<Props,States> {
	
	constructor(props: Props) {
		super(props);

        this.state = {
            preSigned: false, 
			email: '',
			submitted: false,
			alertMsg: null,
			provCreds: null,
			bus: [],
			values: { bus: [], userType: 'administrator'}
		}
		this.handleSelect = this.handleSelect.bind(this)
	}

	componentDidMount() {
		if(this.props.appStates.org && this.props.appStates.user.userStatus > 2000 && !this.props.appStates.bus) {
			BzrAxios.orgs({url: `/BusinessUnits`})
			.then((response:any) => { this.setState({bus: response.data.companies, values: Object.assign(this.state.values, {bus: [this.props.appStates.activeBU.id]})})})
			.catch((err:any) => console.error(err))
		} else if(this.props.appStates.org && this.props.appStates.user.userStatus > 2000 && this.props.appStates.bus && this.props.appStates.bus.length > 0) {
			this.setState({bus: this.props.appStates.bus, values: Object.assign(this.state.values, {bus: [this.props.appStates.activeBU.id]})})
		} else {
			// EXIT Component
			alert('FINISH SETTING MAIN BUSINESS UNIT')
			this.props.history.push('/admin/dashboard')
		}
		const ele:any = document.getElementById('bzr-new-user')
		translate(ele)
	}

	handleSelect(event:any) {
		const value = event.target.value
		switch(event.target.name) {
			case 'bus':
				let bus:any = this.state.values.bus
				const index = bus.indexOf(value)
				if(index > -1) bus.splice(index, 1)
				else bus.push(value)
				this.setState({values: Object.assign(this.state.values, {bus})})
				break
			case 'userType':
				this.setState({values: Object.assign(this.state.values, {userType: value})})
				break;
			default:

				break
		}
	}

	handleSingUp (values: any) {
		this.setState({alertMsg: null, provCreds: values});
		Auth.signUp({
			username: values.email,
			password: values.password,
			attributes: {
				email: values.email,          			// required
				"custom:username": values.username,		// required
				"custom:registered": 'false',		
				"custom:mfa": 'false',
				"custom:status": '5000'		
			}
		})
		.then((cognitoUser:any) => {
			console.log(cognitoUser)
			const user:any = {
				id: cognitoUser.userSub,
				username: values.username,
				email: values.email,
				userType: this.state.values.userType,
				userStatus: 5000
			}
			const policies:any = {}
			this.state.values.bus.forEach((buid:any) => (
				policies[buid] =  
					{
						resources: ['*'],
						components: ['*'],
						routes: ['*']
					}
			))
			const policy:any = {
				bus: this.state.values.bus,
				policies
			}
			const prefs:any = Object.assign({}, this.props.appStates.prefs)
			prefs.userId = cognitoUser.userSub
			delete prefs.primaryContactPointId
			delete prefs.primaryFlag
			// 1. Create User in Bizor Realm
			BzrAxios.orgs({url: `/Users`, method: 'POST', data: { user, policy, prefs }})
			.then((response: any) => {
				console.log(response)
				this.setState({
					provCreds: Object.assign(this.state.provCreds, {userSub: cognitoUser.userSub}),
					preSigned: true, 
					email: values.email, 
					submitted: false,
					alertMsg: {
						variant: 'success', 
						heading: 'Success', 
						message: t('check your email for a verification code & fill it bellow!')}
				});
				// First Approach                      
			}).catch((error: any) => {
					// Error 409: User already exists in Bizor Realm
					// Notify -> Post Request /BzrDoctor 
					console.error(error.response.data)
					this.setState({alertMsg: {
						type: 'danger',
						heading: 'Error on Sign In',
						message: error.response.status + ': ' + error.response.data
					}
				})
			})
		})
		.catch((err:any) => {
			this.setState({
				alertMsg: {
					variant: 'danger', 
					heading: 'Error', 
					message: err.code + ' ' + err.message
				}, 
				submitted: false})
		});
	}

	handleConfirm (values: any) {
		Auth.confirmSignUp(
			values.email,
			values.code
		)
		.then((result:any) => {
			console.log(result) // print SUCCESS 
			
			// 1. Create User in Bizor Realm
			BzrAxios.orgs({url: `/Users/${this.state.provCreds.userSub}`, method: 'PUT', data: { emailVerifiedAt: (new Date()).toISOString() }})
				.then((response: any) => {
					this.setState({submitted: false})
					console.log('put', response)
					// GOTO SOMEWHERE  -> list Users            
				}).catch((error: any) => {
						// Error 409: User already exists in Bizor Realm
						// Notify -> Post Request /BzrDoctor 
						console.error(error.response.data)
						this.setState({alertMsg: {
							type: 'danger',
							heading: 'Error on Sign In',
							message: error.response.status + ': ' + error.response.data
						}
					})
				})

		})
		.catch(err => {
			console.error(err)
		});
	}

	SignUpForm () {
		return (
			<Formik
				validationSchema={
					Yup.object().shape({
						username: Yup.string().required(),
						email: Yup.string().email().required(),
						password: Yup.string().required().matches(/(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,20}/gm),
						cPassword: Yup.string().oneOf([Yup.ref('password'), null]).required('Password confirm is required')
					})
				}
				onSubmit={ 
					(values) => { 
						this.handleSingUp(values); 
						this.setState({submitted: true}); 
					}
				}
				initialValues={{ username: '', email: '', password: '', cPassword: '' }}
			>
				{({
					handleSubmit, handleChange, handleBlur, values, touched, isValid, errors
				}) =>
				(
				<Form noValidate onSubmit={handleSubmit}>
					<fieldset disabled={this.state.submitted}>
					<Form.Group as={Row} controlId="validationFormikUsername">
						<Form.Label>username</Form.Label>
						<Form.Control 	type="text" 
										name="username"
										value={values.username}
										onChange={handleChange}
										onBlur={handleBlur}
                						isInvalid={!!errors.username} 
										autoFocus/>
						<Form.Control.Feedback type="invalid">{t(errors.username)}</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Row} controlId="formGridEmail">
						<Form.Label>{t('email')}</Form.Label>
						<Form.Control 	type="email" 
										name="email"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
                						isInvalid={touched.email && !!errors.email} />
						<Form.Control.Feedback type="invalid">{t(errors.email)}</Form.Control.Feedback>						
					</Form.Group>
					<Form.Group as={Row} controlId="formGridPassword">
						<Form.Label>{t('password')}</Form.Label>
						<Form.Control 	type="password" 
										name="password"
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
                						isInvalid={touched.password && !!errors.password} />
						<Form.Control.Feedback type="invalid">{t(errors.password)}</Form.Control.Feedback>
					</Form.Group>
					<Form.Group as={Row} controlId="formGridConfirmPassword">
						<Form.Label>{t('confirm')} {t('password')}</Form.Label>
						<Form.Control 	type="password" 
										name="cPassword"
										value={values.cPassword}
										onChange={handleChange} 
										onBlur={handleBlur}
										isInvalid={touched.cPassword && !!errors.cPassword} />
						<Form.Control.Feedback type="invalid">{t(errors.cPassword)}</Form.Control.Feedback>
					</Form.Group>
					<br />
					<Form.Group as={Row}>
						<div>{ this.state.submitted ? (<div>Submitting <FontAwesomeIcon icon={faSpinner} spin /></div>) : ''}</div>
						<Button variant="primary" type="submit" className="ml-auto text-capitalize"> {t('submit')} </Button>
					</Form.Group>
					</fieldset>
				</Form>
				)}
			</Formik>
		);
	}

	ConfirmForm () {
		return (
			<Formik
			validationSchema={
				Yup.object().shape({
					code: Yup.string().required(),
					email: Yup.string().email().required()
				})
			}
			enableReinitialize={true}
			onSubmit={
				(values) => {
					this.handleConfirm(values);
					this.setState({submitted: true}); 
				}
			}
			initialValues={{ code: '', email: this.state.email }}
		>
			{({
				handleSubmit, handleChange, handleBlur, values, touched, errors
			}) =>
			(
				<Form noValidate onSubmit={handleSubmit}>
					<fieldset disabled={this.state.submitted}>
					<Form.Group as={Row} controlId="formGridCode">
						<Form.Label>{t('code')}</Form.Label>
						<Form.Control 	type="text" 
										name="code"
										value={values.code}
										onChange={handleChange}
										isInvalid={!!errors.code} 
										/>
						<Form.Control.Feedback type="invalid">{t(errors.code)}</Form.Control.Feedback>
					</Form.Group>

					<Form.Group as={Row} controlId="formGridEmail">
						<Form.Label>{t('email')}</Form.Label>
						<Form.Control 	type="email" 
										name="email"
										value={values.email}
										onChange={handleChange}
										isInvalid={!!errors.email} />
						<Form.Control.Feedback type="invalid">{t(errors.email)}</Form.Control.Feedback>
					</Form.Group>
					<br />
					<Form.Group as={Row} className="text-capitalize">
						<div>{ this.state.submitted ? (<div>{t('submitting')}... <FontAwesomeIcon icon={faSpinner} spin /></div>) : ''}</div>
						<Button variant="primary" type="submit" className="ml-auto "> {t('submit')} </Button>
					</Form.Group>
					</fieldset>
				</Form>
			)}
			</Formik>
		)
	}

	render() {
		return (
			<Container className="mx-auto mb-3 px-5 pb-3" id="new-bzr-user">
				<Row>
					<Col md={12}>
					{this.state.alertMsg ? 
						(<Alert variant={this.state.alertMsg.variant} onClose={() => this.setState({alertMsg: null})} dismissible>
							<Alert.Heading>{this.state.alertMsg.heading}</Alert.Heading>
							{this.state.alertMsg.message}
						</Alert>) : null
					} 
					</Col>
					<Col md={6}>
						<h5>{ t('sign up info') }</h5>
						<br />
						{/** this is done to avoid rendering conflits */}
						<div className="mx-4">
							{ this.state.preSigned ? this.ConfirmForm() : null }
						</div>
						<div className="mx-4">
							{ this.state.preSigned ? null : this.SignUpForm() }
						</div>
					</Col>
					<Col md={6}>
						{ 
						this.state.preSigned ? null :
						<div>
							<h5>{t('business units selection')}</h5>
							<br />
							<Form>
								<Form.Group controlId="buSelect">
									<Form.Label className="bzr-label">
										Select an existing business unit 
										{HelperMsg('can select more than one')}
									</Form.Label>
									<Form.Control 
										as='select'
										name='bus'
										onChange={this.handleSelect}
										value={this.state.values.bus} 
										multiple
										>
										{ this.state.bus.map((option:any, i:any) => <option key={i + 1} value={option.id}>{i + 1} - {option.legalName}</option>) }
									</Form.Control>
								</Form.Group>
								<br />
								<Form.Group controlId="userType">
									<Form.Label className="bzr-label">User Type</Form.Label>
									<Form.Control 
										as='select'
										name='userType'
										onChange={this.handleSelect}
										value={this.state.values.userType}
										>
										<option value='administrator'>{t('administrator')}</option>
										<option value='teller'>{t('teller')}</option>
										<option value='viewer'>{t('viewer')}</option>
									</Form.Control>
								</Form.Group>
							</Form>
						</div>
						}
					</Col>
					<Col md={12}>
					<a href="#confirm" onClick={() => this.setState({preSigned: true})}><b>{t('already received a registry code?')}</b></a>
					</Col>
				</Row>		
			</Container>
		)
	}
}

export default withRouter(NewUser);