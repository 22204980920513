import React from 'react'
import BzrAxios from '../../utils/BzrAxios'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { History, LocationState } from 'history'
import { Container, Row, Col, Form, Overlay, Popover } from 'react-bootstrap'
import PreviewProduct from '../Common/PreviewProduct'
import BzrProductsTable from '../Common/BzrProductsTable'
import { MessageModal, ConfirmModal } from '../../layouts/Modals/Modals'
import t from '../../utils/I18n/I18n'
import { getCookie, setCookie } from '../../utils/Cookies'

interface Props extends RouteComponentProps {
	history: History<LocationState>,
    appStates: any,
    products: any,
	liftUpAppStates: any,
}

interface States {
    defaultHeaders:any
    headers:any[]
    products:any[]
    parsedProducts:any[]
    filteredProducts:any[]
    fields:any
    books:any
    preview:any
    image:any
    doubleClick:any
    filters:any
    loading:boolean
    trs:any[]
    keys:any
    productDelist:any
    inactivating:boolean
    displayPreviewBag:boolean
    priceBookId:any
    costBookId:any
    inventoryBookId:any
    showOnlyAvailable:boolean
}

class ProductList extends React.Component<Props,States> {
    ref:any = null
    loaded = false
    timer:any = null
    version:any = '20210721'
    defaultHeaders:any[] = [
        {fieldName: 'sku', fieldType: 'varchar', filter: true, show: true, style: {minWidth: "100px"}},
        {fieldName: 'name', fieldType: 'varchar', filter: true, show: true, style: {minWidth: "90px"}},
        {fieldName: 'description', fieldType: 'varchar', filter: true, show: true, alarm: {daysBefore: 5}, style: {minWidth: "90px"}},
        {fieldName: 'location', fieldType: 'varchar', filter: true, show: true, style: {minWidth: "100px"}},
        {fieldName: 'type', fieldType: 'varchar', filter: true, show: false, style: {minWidth: "90px"}},
        {fieldName: 'model', fieldType: 'varchar', filter: false, show: false, style: {minWidth: "90px"}},
        {fieldName: 'group', fieldType: 'varchar', filter: true, show: true, style: {minWidth: "90px"}},
        {fieldName: 'barcode', fieldType: 'varchar', filter: true, show: false, style: {minWidth: "90px"}},
        {fieldName: 'oems', fieldType: 'varchar', filter: true, show: false, style: {minWidth: "90px"}},
        {fieldName: 'price', fieldType: 'numeric', filter: false, show: true, style: {minWidth: "90px"}},
        {fieldName: 'inventory', fieldLabel: 'on hand', fieldType: 'numeric', filter: false, show: true, style: {minWidth: "90px"}},
        {fieldName: 'image', fieldType: 'image', filter: false, show: true, style: {minWidth: "90px"}}
    ]

    constructor(props: Props) {
        super(props)
        this.state = {
            defaultHeaders: [],
            products: [],
            parsedProducts: [],
            filteredProducts: [],
            fields: null,
            books: {},
            preview: null,
            image: null,
            doubleClick: null,
            loading: false,
            trs: [],
            headers: [],
            keys: null,
            filters: {},
            productDelist: null,
            inactivating: false,
            displayPreviewBag: false,
            priceBookId: '',
            costBookId: '',
            inventoryBookId: '',
            showOnlyAvailable:true
        }
        this.ref = React.createRef()
        this.selectProduct = this.selectProduct.bind(this)
        this.displayPreviewBag = this.displayPreviewBag.bind(this)
        this.setBook = this.setBook.bind(this)
        this.filterAvailable = this.filterAvailable.bind(this)
    }

    componentDidMount() {
        BzrAxios.records({url: `/fields/2`})
		.then((response:any) => {
			this.setState({fields: response.data['2']})
        })
		.catch((err:any) => console.log(err)) 
        if(this.props.products) {
            const products:any = this.props.products.filter((p:any) => p.isActive === 'true'),
                books:any = this.props.appStates.books,
                bookNames:any = this.props.appStates.bookNames
            products.forEach((p:any) => {
                p.cost = books[bookNames.cost.default][p.id] ? books[bookNames.cost.default][p.id].entryValue : 'ND'
                p.price = books[bookNames.price.default][p.id] ? books[bookNames.price.default][p.id].entryValue : 'ND'
                p.inventory = books[bookNames.inventory.default][p.id] ? books[bookNames.inventory.default][p.id].onHand : '-'
            })
            
            const filteredProducts = this.state.showOnlyAvailable === true ? products.filter((p:any) => p.inventory !== '-' && parseFloat(p.inventory) > 0) : products
            this.setState({products:filteredProducts, parsedProducts: products})
            this.setState({priceBookId: this.props.appStates.bookNames.price ? this.props.appStates.bookNames.price.default : null})
        }
        // Cookies
        const defaultHeadersCK:any = getCookie("products-list")
        if(!defaultHeadersCK) {
            this.setState({defaultHeaders: this.defaultHeaders})
            setCookie('products-list', JSON.stringify({version: this.version, data: {defaultHeaders: this.defaultHeaders}}), 30)
        } else {
            const { version, data } = JSON.parse(defaultHeadersCK)
            if(version !== this.version) {
                this.setState({defaultHeaders: this.defaultHeaders})
                setCookie('products-list', JSON.stringify({version: this.version, data: {defaultHeaders: this.defaultHeaders}}), 30)
            } else{
                this.setState({defaultHeaders: data.defaultHeaders})
            }
        }
        // Listeners
        //window.addEventListener('scroll', this.displayPreviewBag)
    }
    componentWillUnmount() {
        /* window.removeEventListener('scroll', this.displayPreviewBag)
         */if(this.timer) window.clearTimeout(this.timer)
    }
    componentDidUpdate() {
        if(this.props.products && this.props.products.length !== 0 && this.state.products.length === 0) {
            const products:any = this.props.products.filter((p:any) => p.isActive === 'true'),
                books:any = this.props.appStates.books,
                bookNames:any = this.props.appStates.bookNames
            products.forEach((p:any) => {
                p.cost = books[bookNames.cost.default][p.id] ? books[bookNames.cost.default][p.id].entryValue : 'ND'
                p.price = books[bookNames.price.default][p.id] ? books[bookNames.price.default][p.id].entryValue : 'ND'
                p.inventory = books[bookNames.inventory.default][p.id] ? books[bookNames.inventory.default][p.id].onHand : '-'
            })
            const filteredProducts = this.state.showOnlyAvailable === true ? products.filter((p:any) => p.inventory !== '-' && parseFloat(p.inventory) > 0) : products
            this.setState({products:filteredProducts, parsedProducts: products})
        }
        if(this.props.appStates.bookNames && !this.loaded) {
            this.loaded = true
            // Extra cases
        }
    }

    handleDelist(id:string) {
        this.setState({inactivating: true})
        BzrAxios.records({url: `/BzrProduct/${id}`, method: 'PUT', data: {isActive: 'false'}})
            .then((response:any) => {
                const imgbx:any = document.getElementById(id)
                while(imgbx && imgbx.hasChildNodes()) {
                    imgbx.removeChild(imgbx.firstChild)
                }
                const products = this.props.products.filter((p:any) => p.id !== id )
                this.props.liftUpAppStates({products})
                this.setState({products, productDelist: null, inactivating: false})
            })
            .catch((err) => {
                this.setState({inactivating: false})
                console.log(err)
            })
    }   
    displayPreviewBag() {        
        if(this.timer) window.clearTimeout(this.timer)
        const prev:any = document.getElementById('preview-card')
        if(prev && window.innerWidth > 991) {
            this.timer = setTimeout(() => {
                const rect:any = prev.getBoundingClientRect()
                if(rect.bottom < 0) {
                    this.setState({displayPreviewBag: true})
                }
                else this.setState({displayPreviewBag: false})
            }, 1000)
        } else if(window.innerWidth < 992) {
            this.timer = setTimeout(() => {
                const rect:any = document.getElementById('bzr-product-table-container')?.getBoundingClientRect()
 
                if(rect.top < 0) {
                    this.setState({displayPreviewBag: true})
                }
                else this.setState({displayPreviewBag: false})
            }, 1000)
        }
    }
    selectProduct(event: any) {
        const id = event.currentTarget.dataset.recordId
        const product = this.props.products.find((product:any) => product.id === id)
        this.setState({preview: product})
    }
    previewBag() {
        return(
            <div className="preview-bag">
                <PreviewProduct id="bag" item={this.state.preview} appStates={this.props.appStates} />
            </div>
        )
    }
    setBook(event:any) {
        let products:any = []
        const book = this.props.appStates.books[event.target.value]
        for(let p of this.state.products) {
            const product:any = Object.assign({}, p)
            if(event.target.name === 'inventory') product[event.target.name] = book[product.id] ? book[product.id].onHand : '-'
            else product[event.target.name] = book[product.id] ? book[product.id].entryValue : '-'
            products.push(product)
        }
        const state:any = this.state
        state[`${event.target.name}BookId`] = event.target.value
        state.products = products
        this.setState(state)
    }
    filterAvailable() {
        
        this.setState({
            showOnlyAvailable: !this.state.showOnlyAvailable,
            products: !this.state.showOnlyAvailable === true ? this.state.parsedProducts.filter((p:any) => p.inventory !== '-' && parseFloat(p.inventory) > 0) : this.state.parsedProducts
        })
    }
    render() {
        return (
            <Container>
                <Row className="mx-sm-1 mx-md-5 bg-white my-3 py-3">
                    <Col sm={12} md={6} className="ml-auto">
                        <h5>{t('references')}</h5>
                        <br />
                        <Form.Group as={Row} controlId="cost">
                            <Form.Label column md={5} className="text-capitalize">{t('cost book')}</Form.Label>
                            <Col md={5}>
                                <Form.Control custom as="select" name="cost" value={this.state.costBookId} onChange={this.setBook}>
                                    {this.props.appStates.bookNames && Object.keys(this.props.appStates.bookNames).length > 0 ? Object.entries(this.props.appStates.bookNames.cost_hash).map((n:any, k:number) => 
                                        <option key={k} value={n[0]}>
                                            {n[1].alias}
                                        </option>
                                        ) : null
                                    }
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="price">
                            <Form.Label column md={5} className="text-capitalize">{t('price book')}</Form.Label>
                            <Col md={5}>
                                <Form.Control custom as="select" name="price" value={this.state.priceBookId} onChange={this.setBook}>
                                    {this.props.appStates.bookNames && Object.keys(this.props.appStates.bookNames).length > 0 ? Object.entries(this.props.appStates.bookNames.price_hash).map((n:any, k:number) => 
                                        <option key={k} value={n[0]}>
                                            {n[1].alias}
                                        </option>
                                        ) : null
                                    }
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="inventory">
                            <Form.Label column md={5} className="text-capitalize">{t('inventory book')}</Form.Label>
                            <Col md={5}>
                                <Form.Control custom as="select" name="inventory" value={this.state.inventoryBookId} onChange={this.setBook}>
                                    {this.props.appStates.bookNames && Object.keys(this.props.appStates.bookNames).length > 0 ? Object.entries(this.props.appStates.bookNames.inventory_hash).map((n:any, k:number) => 
                                        <option key={k} value={n[0]}>
                                            {n[1].alias}
                                        </option>
                                        ) : null
                                    }
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <br />
                        <Form.Group as={Row} controlId="showOnlyAvailable-checkbox">
                            <Form.Label column md={5} className="text-capitalize">Mostrar disponibles</Form.Label>
                            <Col md={1}>
                                <Form.Check
                                    type="checkbox"
                                    name='showOnlyAvailable' 
                                    checked={this.state.showOnlyAvailable} 
                                    onChange={this.filterAvailable}/>
                            </Col>
                            <Col md={5}>
                                {this.state.showOnlyAvailable ? 
                                    <h6>Disponibles: {this.state.products.length}/{this.state.parsedProducts.length}</h6> : 
                                    <h6>Totales: {this.state.parsedProducts.length}</h6>}
                            </Col>
                        </Form.Group>
                        
                    </Col>
                    <Col md={6} className="mr-auto d-none d-sm-block" id="preview-card">
                        <div style={{height: "250px", overflowY: "auto", border: "1px solid gray"}}>
                            <PreviewProduct item={this.state.preview} appStates={this.props.appStates} />
                        </div>
                    </Col>
                    <Col md={12} className="mx-auto mt-4" id="bzr-product-table-container">
                        {this.props.appStates.bookNames &&
                        <BzrProductsTable 
                            id="products-list"
                            items={this.state.products}
                            liftUpHeaders={(defaultHeaders:any) => {
                                this.setState({defaultHeaders})
                                const { version } = JSON.parse(getCookie("products-list"))
                                setCookie('products-list', JSON.stringify({version, data: {defaultHeaders}}), 30)
                            }}
                            liftUpImage={(image:any) => {
                                const preview:any = this.state.preview
                                preview['bzrProductImage'] = image.imageKey
                                preview['imageURL'] = image.imageURL
                                this.setState({preview})
                            }}
                            doubleClick={(target:any, record:any) => {
                                const doubleClick:any = {target, record}
                                this.setState({doubleClick})
                                //this.props.history.replace(`/admin/products/edit/${record.id}`)
                            }}
                            onRowClick={(record:any) => this.setState({preview: record})}
                            updateClick={() => {
                                console.log('update table')
                            }}
                            title={t('products')}
                            headers={this.state.defaultHeaders}
                            fields={this.state.fields}
                            updated={this.props.appStates.products_loaded}
                            extras={{ 
                                orgId: this.props.appStates.org.id,
                                activeBU: this.props.appStates.activeBU,
                                activeBUId: this.props.appStates.activeBUId,
                                activeBUAddress: this.props.appStates.activeBUAddress,
                                activeBUPhone: this.props.appStates.activeBUPhone,
                                activeBULogo: this.props.appStates.activeBULogo,
                                priceBookId: this.state.priceBookId !== '' ? this.state.priceBookId : this.props.appStates.bookNames.price.default
                            }}
                            />}
                    </Col>
                </Row>
                {this.state.displayPreviewBag ? this.previewBag() : null}
                <MessageModal 
                    show={this.state.loading} 
                    message={t('loading')} 
                    onHide={() => { 
                        this.setState({loading: false})
                        }} />
                <ConfirmModal 
                    show={!!this.state.productDelist} 
                    title="delist confirmation" 
                    onClick={() => this.handleDelist(this.state.productDelist)}
                    confirmtitle="delist product"
                    onHide={() => { this.setState({productDelist: null}) }} 
                    />
                <Overlay
                    show={!!this.state.doubleClick}
                    target={this.state.doubleClick ? this.state.doubleClick.target : null}
                    placement="bottom"
                    container={this.ref.current}
                    containerPadding={20}
                    rootClose={true}
                    onHide={() => this.setState({doubleClick: null})}
                    transition={false}
                >
                    <Popover id="double-click-popover">
                    <Popover.Content className="dc-popover">
                        <a href="#inventory" onClick={(event:any)=> {
                            event.preventDefault()
                            this.props.history.push(`/admin/products/inventories/${this.state.doubleClick.record.id}`)
                            }}>inventory
                        </a>{" | "}
                        <a href="#edit" onClick={(event:any)=> {
                            event.preventDefault()
                            this.props.liftUpAppStates({productToEdit: this.state.doubleClick.record})
                            this.props.history.push(`/admin/products/edit/${this.state.doubleClick.record.id}`)
                            }}>edit
                        </a>{" | "}
                        <a href="#delete" onClick={(event:any)=> {
                            event.preventDefault()
                            this.setState({productDelist: this.state.doubleClick.record.id})
                            }}>delist
                        </a>
                    </Popover.Content>
                    </Popover>
                </Overlay>
            </Container>
        ) 
    }
}

export default withRouter(ProductList)