import React from 'react'
import { Auth } from 'aws-amplify'
import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const SignoutModal = () => {
    
    setTimeout(() => {
        Auth.signOut().then(data => {
            console.log('Signout data:')
            console.log(data)
            window.location.replace('/');
        }).catch(err => {
            window.location.replace('/');
            console.log(err)
        })
    }, 2000)

    return (
        <Modal
        show={true}
        centered
        dialogClassName="modal-100vw"
        >
            <Modal.Body>
            <h4 style={{textAlign:"center", color: "white", margin: "20px 0 20px"}}>Sign Out <FontAwesomeIcon icon={faSpinner} spin /></h4>
            </Modal.Body>
        </Modal>
    );
}

export default SignoutModal