import React from 'react'
import { History, LocationState } from "history"
import { withRouter, RouteComponentProps } from 'react-router-dom'
import BzrAxios from '../../utils/BzrAxios'
import { Formik } from 'formik'
import { Form, Row, Col, Button, Card, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'
import t, {fn, translate} from '../../utils/I18n/I18n'
import { MsgBubble, ParentModal } from '../../layouts/Modals/Modals'
import { PolicyContext } from '../../utils/Contexts'

interface Props extends RouteComponentProps<LocationState> {
    history:History<LocationState>
    appStates:any
    products:any
    liftUpAppStates:any
    product?:any
}

interface State {
    product:any
    warehouses:any
    msg:any
    initializing:any
    adjusts:any[]
}

const SubmitButton = (props:any) => {
    return(
        <PolicyContext.Consumer>
            {(policy:any) => {
                //console.log(policy)
                return(
                <Button 
                type='submit' 
                id='adjust-submit' 
                style={ props.isSubmitting ? {display: 'none'}: {display: 'inline'} } >
                    Submit
                </Button>
            )}}  
        </PolicyContext.Consumer>
    )
}

class NewInventory extends React.Component<Props,State> {
    constructor(props:Props) {
        super(props)
        this.state = {
            product: null,
            warehouses: null,
            msg: null,
            initializing: true,
            adjusts: []
        }
        this.handleAdjustInventory = this.handleAdjustInventory.bind(this)
        this.handleInitInventoryEntry = this.handleInitInventoryEntry.bind(this)
    }

    async componentDidMount() {
        const match:any = this.props.match
        let product:any
        if(!match.params.id || !this.props.products) {
            this.props.history.push('/admin/products/list')
            return
        } else if(this.props.products && match.params.id && !this.state.product) {
            product = this.props.products.find((p:any) => p.id === match.params.id)
            this.setState({product})
        }

        if(!this.props.appStates.books[this.props.appStates.bookNames.inventory.default][product.id]) {
            alert('product inventory must be init')
            // INIT INVENTORY FOR PRODUCT
        } else this.setState({initializing: false})
        const comp:any = document.getElementById('product-inventory')
        translate(comp)

        ///  GET LAST INVENTORY TRANSAC
        BzrAxios.records({url: `/InventoryAdjusts`})
            .then((response:any) => this.setState({adjusts: response.data.transacs}))
            .catch(err => console.log(err))
    }

    componentDidUpdate() {
        const match:any = this.props.match
        if(this.props.products && match.params.id && !this.state.product) this.setState({product: this.props.products.find((p:any) => p.id === match.params.id)})
    }

    async handleAdjustInventory(data:any) {
        data.bzrProductId = this.state.product.id
        data.retailed = false
        data.unitOfMeasure = this.state.product.unitOfMeasure
        data.unitsPerPkg = this.state.product.unitsPerPkg
        data.bzrInventoryEntryId = this.props.appStates.books[data.bzrInventoryBookId][this.state.product.id].id

        try {
            const response:any = await BzrAxios.records({url: `/InventoryAdjusts`, method: 'POST', data})
            const books = this.props.appStates.books
            books[this.props.appStates.bookNames.inventory.default][this.state.product.id] = response.data.inventoryEntry
            this.setState({msg: {variant: 'success', info: 'adjustment done'}})
            this.props.liftUpAppStates({books})
            BzrAxios.records({url: `/InventoryAdjusts`})
            .then((response:any) => this.setState({adjusts: response.data.transacs}))
            .catch(err => console.log(err))
        } catch(err) {
            console.error(err)
            return
        }
    }
    async handleInitInventoryEntry(data:any, formikBag:any) {
        data.bzrProductId = this.state.product.id
        data.activeFrom = data.activeFrom.toISOString().slice(0,10)
        data.isActive = true
        data.unitsPerPkg = this.state.product.unitsPerPkg
        
        try {
            const response:any = await BzrAxios.records({url: `/InventoryEntries`, method: 'POST', data}),
                books:any = this.props.appStates.books,
                inventoryEntry:any = response.data.inventoryEntry

            books[data.bzrInventoryBookId][data.bzrProductId] = inventoryEntry

            formikBag.resetForm()
        } catch(error) {
            //console.error(error, error.response)
            if(error.response.status === 409) {
                alert(`${error.response.statusText}: ${error.response.data}`)
                return
            } else {
                console.log(error)
            }
        }
    }
    render() {
        return(
            <div id="product-inventory" className="mx-5">
                <Row>
                    <Col md={8}>
                        {/* Product Summary */}
                        <h4>Summary</h4>
                        <br />
                        <Row className="">
                            <Col md="4">
                                <Card className="bzr-stat">
                                    <Card.Body>
                                        <div className="stat-widget-one">
                                            <div className="stat-icon dib"><i className="ti-package text-success border-success"></i></div>
                                            <div className="stat-content dib">
                                                <div className="stat-text">available</div>
                                                <div>{!this.state.initializing ? fn(this.props.appStates.books[this.props.appStates.bookNames.inventory.default][this.state.product.id].onHand) : null}</div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="bzr-stat">
                                    <Card.Body>
                                        <div className="stat-widget-one">
                                            <div className="stat-icon dib"><i className="ti-truck text-primary border-primary"></i></div>
                                            <div className="stat-content dib">
                                                <div className="stat-text">on transit</div>
                                                <div>{!this.state.initializing ? fn(this.props.appStates.books[this.props.appStates.bookNames.inventory.default][this.state.product.id].onTransit) : null}</div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="4">
                                <Card className="bzr-stat">
                                    <Card.Body>
                                        <div className="stat-widget-one">
                                            <div className="stat-icon dib"><i className="ti-lock text-danger border-danger"></i></div>
                                            <div className="stat-content dib">
                                                <div className="stat-text">locked</div>
                                                <div>{!this.state.initializing ? fn(this.props.appStates.books[this.props.appStates.bookNames.inventory.default][this.state.product.id].locked) : null}</div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <h3 className="float-right">{this.state.product ? this.state.product.name : null}</h3>
                    </Col>
                </Row>
                <hr />
                <Row style={{backgroundColor: 'white', padding: '1rem'}}>
                    <Col md={7}>
                        <h4>Adjusts</h4><br />
                        <Table size="sm" bordered responsive>
                            <thead>
                                <tr>
                                    <th style={{minWidth: "30px"}}>#</th>
                                    <th>date</th>
                                    <th>reference</th>
                                    <th>type</th>
                                    <th>description</th>
                                    <th className="text-center">unit</th>
                                    <th className="text-center">quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.adjusts.map((a:any,k:any) => <tr key={k}>
                                    <td>{k+1}</td>
                                    <td>{new Date(a.createdAt).toLocaleDateString()}</td>
                                    <td>{a.orderNumber}</td>
                                    <td>{a.transacType === 'in_adjust' ? t('in') : t('out')}</td>
                                    <td>{a.tdescription}</td>
                                    <td className="text-center">{a.unitOfMeasure}</td>
                                    <td className="text-center" style={{fontWeight: 600, color: a.transacType === 'out_adjust' ? 'red' : 'black'}}>{a.transacType === 'out_adjust' && '-'}{a.quantity}</td>
                                </tr>) }
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={5} className="pl-5">
                      <Formik
                            validationSchema={
                                Yup.object({
                                    quantity: Yup.string().required(),
                                    orderNumber: Yup.string().required()
                                })
                            }
                            onSubmit={this.handleAdjustInventory}
                            initialValues={{
                                bzrInventoryBookId: '',
                                orderNumber: '',
                                tdescription: '',
                                quantity: '',
                                transacType: 'in_adjust',
                                bzrOrderId: 'internal' /// MUST BE IMPLEMENTED
                            }}
                            enableReinitialize={true}
                            >
                            {
                                ({
                                    handleSubmit,
                                    handleChange,
                                    isSubmitting,
                                    values
                                    }) => (
                                        <div>
                                            <h4>Entry/Adjust Inventory</h4>
                                            <br />
                                            <Form noValidate onSubmit={handleSubmit} id={'adjust-inventory-form'}>
                                                <FormInputs
                                                    ncols={['12']}
                                                    className={['px-2']}
                                                    properties={[
                                                        {
                                                            label: 'inventories',
                                                            horizontalLabel: '5',
                                                            fieldType: 'select',
                                                            name: 'bzrInventoryBookId',
                                                            value: values.bzrInventoryBookId,
                                                            options: (() => {
                                                                const ops:any = this.props.appStates.bookNames && this.props.appStates.bookNames.inventory ? Object.entries(this.props.appStates.bookNames.inventory).map((b:any) => [b[1], b[0]]) : [['','']]
                                                                if(ops.length > 0) values.bzrInventoryBookId = ops[0][0]
                                                                return ops
                                                            })(),
                                                            onChange: handleChange
                                                        }
                                                    ]}
                                                />
                                                <FormInputs
                                                    ncols={['12']}
                                                    className={['px-2']}
                                                    properties={[
                                                        {
                                                            label: 'reference',
                                                            horizontalLabel: '5',
                                                            feedback: 'required',
                                                            fieldType: 'text',
                                                            name: 'orderNumber',
                                                            className: 'text-right',
                                                            value: values.orderNumber,
                                                            onChange: handleChange
                                                        }
                                                    ]}
                                                />
                                                <FormInputs
                                                    ncols={['12']}
                                                    className={['px-2']}
                                                    properties={[
                                                        {
                                                            label: 'description',
                                                            horizontalLabel: '5',
                                                            feedback: ' ',
                                                            fieldType: 'textarea',
                                                            name: 'tdescription',
                                                            className: "bzr-textarea",
                                                            value: values.tdescription,
                                                            onChange: handleChange
                                                        }
                                                    ]}
                                                />
                                                <FormInputs
                                                    ncols={['12']}
                                                    className={['px-2']}
                                                    properties={[
                                                        {
                                                            label: 'quantity',
                                                            horizontalLabel: '5',
                                                            feedback: ' ',
                                                            fieldType: 'text',
                                                            name: 'quantity',
                                                            className: 'text-right',
                                                            value: values.quantity,
                                                            onChange: handleChange
                                                        }
                                                    ]}
                                                />
                                                <FormInputs
                                                    ncols={['12']}
                                                    className={['px-2']}
                                                    properties={[
                                                        {
                                                            label: 'adjust type',
                                                            horizontalLabel: '5',
                                                            fieldType: 'select',
                                                            name: 'transacType',
                                                            value: values.transacType,
                                                            options: [['in_adjust', 'in'], ['out_adjust', 'out']],
                                                            onChange: handleChange
                                                        }
                                                    ]}
                                                />
                                                <div className="text-center m-3">
                                                    {
                                                    isSubmitting ? 
                                                    <div><h5>{t('submitting')}...<FontAwesomeIcon icon={faSpinner} spin /></h5></div>
                                                    : 
                                                    <SubmitButton isSubmitting={isSubmitting} />
                                                    }
                                                </div>
                                            </Form> 
                                        </div>
                                    )
                            }
                        </Formik>
                    </Col>
                </Row>
                <ParentModal show={!!this.state.initializing}>
                    <Formik
                        validationSchema={
                            Yup.object({
                                bzrInventoryBookId: Yup.string().required(),
                            })
                        }
                        onSubmit={this.handleInitInventoryEntry}
                        initialValues={{
                            bzrInventoryBookId: '',
                            activeFrom: new Date(),
                            onHand: 0,
                            onTransit: 0,
                            minLimit: 0,
                            maxLimit: 0
                        }}
                        enableReinitialize={true}
                        >
                        {
                            ({
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                isSubmitting,
                                values
                                }) => (
                                    <div>
                                        <h4>Initialize Inventory Entry</h4>
                                        <h5>Product: {this.state.product ? this.state.product.name : null}</h5>
                                        <Form noValidate onSubmit={handleSubmit} id={'inventory-entry-form'}>
                                            <FormInputs
                                                ncols={['8']}
                                                className={['px-2']}
                                                properties={[
                                                    {
                                                        label: 'inventories',
                                                        horizontalLabel: '6',
                                                        fieldType: 'select',
                                                        name: 'bzrInventoryBookId',
                                                        value: values.bzrInventoryBookId,
                                                        options: (() => {
                                                            const ops:any = this.props.appStates.bookNames && this.props.appStates.bookNames.inventory ? Object.entries(this.props.appStates.bookNames.inventory).map((b:any) => [b[1], b[0]]) : [['','']]
                                                            if(ops.length > 0) values.bzrInventoryBookId = ops[0][0]
                                                            return ops
                                                        })(),
                                                        onChange: handleChange
                                                    }
                                                ]}
                                            />
                                            <FormInputs
                                                ncols={['8']}
                                                className={['px-2']}
                                                properties={[
                                                    {
                                                        label: 'active from',
                                                        horizontalLabel: '6',
                                                        feedback: ' ',
                                                        fieldType: 'date',
                                                        name: 'activeFrom',
                                                        className: 'text-right',
                                                        value: values.activeFrom,
                                                        onChange: setFieldValue
                                                    }
                                                ]}
                                            />
                                            <FormInputs
                                                ncols={['8']}
                                                className={['px-2']}
                                                properties={[
                                                    {
                                                        label: 'onHand',
                                                        horizontalLabel: '6',
                                                        feedback: ' ',
                                                        fieldType: 'text',
                                                        name: 'onHand',
                                                        className: 'text-right',
                                                        value: values.onHand,
                                                        onChange: handleChange
                                                    }
                                                ]}
                                            />
                                            <FormInputs
                                                ncols={['8']}
                                                className={['px-2']}
                                                properties={[
                                                    {
                                                        label: 'onTransit',
                                                        horizontalLabel: '6',
                                                        feedback: ' ',
                                                        fieldType: 'text',
                                                        name: 'onTransit',
                                                        className: 'text-right',
                                                        value: values.onTransit,
                                                        onChange: handleChange
                                                    }
                                                ]}
                                            />
                                            <FormInputs
                                                ncols={['8']}
                                                className={['px-2']}
                                                properties={[
                                                    {
                                                        label: 'minLimit',
                                                        horizontalLabel: '6',
                                                        feedback: ' ',
                                                        fieldType: 'text',
                                                        name: 'minLimit',
                                                        className: 'text-right',
                                                        value: values.minLimit,
                                                        onChange: handleChange
                                                    }
                                                ]}
                                            />
                                            <FormInputs
                                                ncols={['8']}
                                                className={['px-2']}
                                                properties={[
                                                    {
                                                        label: 'maxLimit',
                                                        horizontalLabel: '6',
                                                        feedback: ' ',
                                                        fieldType: 'text',
                                                        name: 'maxLimit',
                                                        className: 'text-right',
                                                        value: values.maxLimit,
                                                        onChange: handleChange
                                                    }
                                                ]}
                                            />
                                            <Button 
                                                type='submit' 
                                                id='inventory-entry-submit' 
                                                style={ isSubmitting ? {display: 'none'}: {display: 'inline'} } >
                                                    Submit
                                            </Button>
                                            {
                                                isSubmitting ? <div><h5>{t('submitting')}... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
                                            }
                                        </Form> 
                                    </div>
                                )
                        }
                    </Formik>
                </ParentModal>
                <MsgBubble msg={this.state.msg} onClose={() => this.setState({msg: null})} />
            </div>
        )
    }
}

export default withRouter(NewInventory)