import React from 'react'
import { Switch, Route, Redirect, RouteComponentProps, withRouter } from "react-router-dom"
import { History, LocationState } from "history"
import AdminNavbar from './Navbars/AdminNavbar'
import Sidebar from './Sidebar/Sidebar'
import Menu from '../components/Dashboard/Menu'
import Footer from './Footer/Footer'
import { routes as adminRoutes, getRoutes } from '../routes/adminRoutes'
import AWS from 'aws-sdk'
import { PolicyContext } from '../utils/Contexts'
import BzrAxios from '../utils/BzrAxios'

import image from "../assets/img/sidebar-5.jpg"

//import t from '../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
    history: History<LocationState>
    appStates: any
    products: any
    policies: any
    liftUpAppStates: any
}
interface States {
    formSubmitBtnIds: string[]
    route: string
	toggleMessageModal: boolean
	_notificationSystem: any
	image: string
	color: string
	hasImage: boolean
}
class Admin extends React.Component<Props, States> {
    constructor(props: any) {
        super(props)
        this.state = {
            formSubmitBtnIds: [],
            route: '',
			toggleMessageModal: true,
			_notificationSystem: null,
			image: image,
			color: "black",
			hasImage: true
        }
        this.getProducts()
    }
    async getProducts() {
        if(this.props.products) return
        try {
            const response:any = await BzrAxios.records({url: `/Products`})
            console.log(response.data)

            const products:any = response.data.products ?? [],
                books:any = response.data.books ?? {},
                bookNames:any = response.data.bookNames ?? {},
                products_hash:any = {}
                this.props.liftUpAppStates({books, bookNames}) 
            if(products.length === 0) {
                this.props.liftUpAppStates({products, products_hash})
                return
            }
            // Products load without images
            this.props.liftUpAppStates({products})

            const s3 = new AWS.S3({apiVersion: '2006-03-01'})
            for(const product of products) {
                products_hash[product.id] = Object.assign({},product)
                if(product.bzrProductImage) {
                    try {
                        const result:any = await s3.getObject({
                            Bucket: 'bizor-s3-imgs', 
                            Key: `public/${product.bzrProductImage}`,
                            //ResponseCacheControl: 'no-cache'
                        }).promise()
                        const blob = new Blob([result.Body], {type: result.contentType})
                            
                        product['imageURL'] = URL.createObjectURL(blob)
                        //this.props.liftUpAppStates({products})
                    } catch(err) {
                        console.log(err)
                        // if (products.length === i + 1) this.props.liftUpAppStates({products})
                        continue
                    }
                } 
            }
            console.log('products fully loaded')
            this.props.liftUpAppStates({products_hash, products_loaded: true})
        } catch(error) {
            console.error(error)
        }        
    }
	getRouteName = (routes:any):any => {
        for (let i = 0; i < routes.length; i++) {
            let route: any
            if(routes[i].dropdown) {
                var rts = this.getRouteName(routes[i].dropdown.routes)
                if(rts) return rts
                //return null
            } else {
                route = routes[i]
                if ( this.props.location.pathname === (route.path)) return route.name
            }
        }
        return null
    }
    render(){ 
        console.log()
        return (
            <PolicyContext.Provider value={this.props.policies}>
                <Sidebar {...this.props} 
                    routes={adminRoutes} 
                    appStates={this.props.appStates}
                    image={this.state.image}
                    color={this.state.color}
                    hasImage={this.state.hasImage} />
                <div id="main-panel" className="main-panel" ref="mainPanel">
                    <AdminNavbar
                        {...this.props}
                        routeName={this.getRouteName(adminRoutes)}
                        appStates={this.props.appStates}
                    />
                    <div style={{minHeight: "100vh"}}>
                        <Switch>
                            {getRoutes(
                                adminRoutes,
                                { 
                                    appStates: this.props.appStates, 
                                    products: this.props.products,
                                    policies: this.props.policies,
                                    liftUpAppStates: this.props.liftUpAppStates
                                }
                            )}
                            <Route path="/admin/menu">
                                <Menu />
                            </Route>
                            <Route path="/admin">
                                <Redirect to="/admin/menu" />
                            </Route>
                        </Switch>
                    </div>
                    <Footer />
                </div>
            </PolicyContext.Provider>
        )
    }
}
export default withRouter(Admin)