import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history"
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'

import BzrAxios from '../../utils/BzrAxios'

//import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	liftUpAppStates: any,
	disableFields: boolean,
	disableFieldsHandler: any,
	getFormCard?: any,
	params: any,
	idn: any
}

interface State {
	formError: any,
	submitting: boolean,
	options: any[],
	createBU: boolean,
	bu: any,
	buId: any,
	buIds: any[],
	identificationType: string,
	ids: any[],
	cs: any

}

class BusinessUnitForm extends React.Component<Props,State> {

	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null,
			submitting: false,
			options: [['NONE', 'Loading...']],
			createBU: true,
			bu: null,
			buId: null,
			buIds: [],
			identificationType: '0',
			ids: [],
			cs: null
		}
		this.handleSelect = this.handleSelect.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidUpdate() {
		if(this.state.bu !== this.props.appStates.bu) this.setOptions()
	}

	setOptions() {
		if(!this.props.appStates.bu) return
		this.setState({bu: this.props.appStates.bu})
		const idNames = ['RIF', 'NIT', 'RUC', 'ECT']
		BzrAxios.orgs({url: `/BusinessUnits/${this.props.appStates.bu.id}/Ids`})
			.then((response:any) => {
				const buIds = response.data.buIds,
					buId = buIds.length !== 0 ? buIds[0] : null
				let options:any[] = []
				idNames.forEach((n:any, k:number) => {
					options.push([n, n])
				})
				this.setState({options, buId, buIds, submitting: false})
				this.returnFormCard()
			})
	}

	returnFormCard() {
		const ids = this.state.buIds,
		formCard = ids.map((id:any, i:number) =>
			<Container key={i}>
				<Row className="pb-1">
					<Col md={7}>
						<p className="accordion-card-footer">
							<b>{id.identificationType} ID - {id.identificationName}</b><br />
							{id.identificationNumber}
						</p>
					</Col>
					<Col md={5}>
						<p className="accordion-card-footer">
							{id.issuedDate ? <span><b>Issued:</b> {id.issuedDate.slice(0,10)} </span> : null }
							<br />
							{id.expiryDate ? <span><b>Expire:</b> {id.expiryDate.slice(0,10)} </span> : null }
						</p>
					</Col>
				</Row>
			</Container>
		)
		this.props.getFormCard(formCard)
	}

	handleSelect(event:any) {
		const buId = this.state.buIds.find((b:any) => b.identificationName === event.target.value)
		if(buId) this.setState({buId: Object.assign({}, buId)})
		else this.setState({buId: {identificationName: event.target.value, identificationNumber: '', issuedDate: '', expiryDate: ''}})
	}

	handleSubmit(data:any, formikBag?:any) {
		this.setState({submitting: true})
        if(this.props.disableFieldsHandler) this.props.disableFieldsHandler(true)
		let method = 'POST',
			url = `/BusinessUnits/${this.props.appStates.bu.id}/Ids`

		if(this.state.buId && this.state.buId.identificationNumber !== '') {
			method = 'PUT'  
			url = `/BusinessUnits/${this.props.appStates.bu.id}/Ids/${this.state.buId.id}`
		}
		
		BzrAxios.orgs({ url, method, data })
		.then((response: any) => {
			this.setOptions()
		}).catch((error: any) => {
			console.error(error)
			this.setState({submitting: false})
		})
	}

	render() {
		const buId = this.state.buId ?? {}
		return (
			<Formik
				validationSchema={
					Yup.object({
						identificationNumber: Yup.string().required(),
						identificationName: Yup.string().required(),
						issuedDate: Yup.string().required(),
						expiryDate: Yup.string().required()
				})}
				onSubmit={(values, formikBag) => { this.handleSubmit(values)} }
				initialValues={{
					identificationName: buId.identificationName ?? 'RIF',
					identificationNumber: buId.identificationNumber ?? '',
					identificationType: buId.identificationType ?? 'LEGAL',
					issuedDate: buId.issuedDate ?? null,
					expiryDate: buId.expiryDate ?? ''
				}}
				enableReinitialize={true}
			>
				{({
				handleSubmit,
				handleChange,
				setFieldValue,
				values,
				touched,
				isValid,
				errors,
				}) => (
				<Form noValidate onSubmit={handleSubmit} id={"business-unit-form-"  + this.props.idn}>
					<fieldset disabled={this.props.disableFields || this.props.appStates.org === undefined || this.state.submitting}>
					<input type='hidden' name='identificationType' value={values.identificationType} />
					<FormInputs
						ncols={['6']}
						className={['px-2']}
						properties={[
							{
								label: 'document type',
								horizontalLabel: "7",
								fieldType: 'select',
								name: 'identificationName',
								value: values.identificationName,
								options: this.state.options,
								onChange: this.handleSelect
							}
						]}
					/>
					<hr />
					<FormInputs
						ncols={['4', '4', '4']}
						className={['px-2', 'px-4', 'px-4']}
						properties={[
							{
								label: 'identification number',
								fieldType: 'text',
								name: 'identificationNumber',
								value: values.identificationNumber,
								onChange: handleChange,
								isValid: touched.identificationNumber && !errors.identificationNumber,
								isInvalid: !!errors.identificationNumber,
								error: errors.identificationNumber
							},
							{
								label: "issued date",
								fieldType: "date",
								name: "issuedDate",
								value: values.issuedDate,
								onChange: setFieldValue,
								isValid: touched.issuedDate && !errors.issuedDate								
							},
							{
								label: "expiry date",
								fieldType: "date",
								name: "expiryDate",
								value: values.expiryDate,
								onChange: setFieldValue,
								isValid: touched.expiryDate && !errors.expiryDate
							}
						]}
					/>
					<Row>
						<Col md="5" className="mx-auto mt-4 text-center">
							<Button 
								type="submit" 
								id={this.props.params.id + "-form-submit-" + this.props.idn}
								className="single-submit"
								style={ this.props.singleSubmit || 
										this.state.submitting || 
										this.props.disableFields ||
										this.props.appStates.org === undefined
										? {display: "none"}: {display: "inline"} } >
									Submit
							</Button>							
							{
								this.state.submitting ? <div><h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
							}
						</Col>
					</Row>

					</fieldset>
				</Form>
				)}
			</Formik>
		)
	}
}

export default withRouter(BusinessUnitForm)