import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Form, Row, Col, Button } from 'react-bootstrap'
import CBList from '../Common/CBList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { History, LocationState } from 'history'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'

import BzrAxios from '../../utils/BzrAxios'

//import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	liftUpAppStates: any,
	disableFields?: boolean,
	disableFieldsHandler?: any,
	params: any,
	idn: any
}

interface State {
	formError: any
	submitting: boolean,
	formAddress: any,
	phoneValue: string,
	phone: any,
	blockPhoneForm: boolean,
	disablePhoneCBList: boolean,
	initValues: any,
	newAddress: boolean
}

class AddressForm extends React.Component<Props,State> {

	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null,
			submitting: false,
			formAddress: null,
			phoneValue: '',
			phone: {},
			blockPhoneForm: false,
			disablePhoneCBList: false,
			initValues: {},
			newAddress: false
		}
		this.getPhone = this.getPhone.bind(this)
		this.getPhoneValue = this.getPhoneValue.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidMount() {
		this.getAddresses()
		setTimeout(() => {
			const firstInput:any = document.getElementsByName('addressLine1')
			firstInput[0]?.focus()
		}, 1500)
	}

	getAddresses() {
		if(this.props.appStates.user.userStatus < 2000) {
			this.setState({phoneValue: 'principal', disablePhoneCBList: true})
			return
		} 
		if(this.state.newAddress) return
		BzrAxios.users({url: `/${this.props.appStates.user.id}/Addresses/0`})
		.then((response:any) => this.props.liftUpAppStates({userAddress: response.data.primaryAddress}))
		.catch((err) => console.log(err))
	}

	getPhone(value:any) {
        this.setState({phone: value ?? {}})
    }

    getPhoneValue(value:any) {
        this.setState({phoneValue: value})
    }
	handleSubmit(data:any, formikBag?:any) {
		this.props.liftUpAppStates({submitting: true})
		this.props.disableFieldsHandler(true)
		if(this.props.appStates.user.userStatus < 1300) data['userStatus'] = 1300
		
		let url = `/${this.props.appStates.user.id}/Addresses`, method = 'POST' 
		if(this.props.appStates.userAddress && this.props.appStates.userAddress.id) {
			url = `/${this.props.appStates.user.id}/Addresses/${this.props.appStates.userAddress.id}`
			method = 'PUT'
		}
		
		BzrAxios.users({ url, method, data })
		.then((response: any) => {
			// 1. UPDATE 'bzrUser' lifting the state up			
			if(this.props.appStates.user.userStatus < 1300) {
				// Enrollment case
				this.props.liftUpAppStates({user: response.data.user, userAddress: response.data.userAddress, userPhone: response.data.userPhone, submitting: false})
				// 2. REDIRECT window.location.replace('/dashboard/preference')
				this.props.disableFieldsHandler(false)
			} else {
				console.log(formikBag)
				formikBag.resetForm()
				this.props.liftUpAppStates({userAddress: response.data.userAddress, submitting: false})
				// 2. REDIRECT 
			}
		}).catch((error: any) => {
			this.props.liftUpAppStates({submitting: false})
			console.error(error)
		})
	}

	render() {
		let u = this.props.appStates.userAddress ? this.props.appStates.userAddress : {}
		return (
			<Formik
				validationSchema={Yup.object({
					addressLine1: Yup.string().required(),
					addressLine2: Yup.string(),
					cityName: Yup.string().required(),
					postalCodeText: Yup.string().required(),
					stateProvinceName: Yup.string().required(),
					countryName: Yup.string().required()
				})}
				onSubmit={this.handleSubmit}
				initialValues={{addressLine1: u.addressLine1 ?? '', 
								addressLine2: u.addressLine2 ?? '', 
								cityName: u.cityName ?? '', 
								postalCodeText: u.postalCodeText ?? '',
								stateProvinceName: u.stateProvinceName ?? '',
								countryName: u.countryName ?? '',
								phoneCountryCode: this.state.phone.phoneCountryCode ?? '',
								areaCode: this.state.phone.areaCode ?? '',
								telephoneNumber: this.state.phone.telephoneNumber ?? '',
								refName: this.state.phoneValue ?? ''
							}}
				enableReinitialize={true}
			>
				{({
				handleSubmit,
				handleChange,
				handleBlur,
				values,
				touched,
				isValid,
				errors,
				}) => (
				<Form noValidate onSubmit={handleSubmit}  id={'address-form-' + this.props.idn}>
					<fieldset disabled={this.props.disableFields}>
					<FormInputs
						ncols={['8']}
						className={['ml-0']}
						properties={[
							{
								label: 'Address Line 1',
								feedback: '',
								fieldType: 'text',
								name: 'addressLine1',
								autoComplete: 'address-line1',
								value: values.addressLine1,
								onChange: handleChange,
								isValid: touched.addressLine1 && !errors.addressLine1
							}
						]}
                    />
					<FormInputs
						ncols={['8', '4']}
						className={['', '']}
						properties={[
							{
								label: 'Address Line 2',
								feedback: '',
								fieldType: 'text',
								name: 'addressLine2',
								autoComplete: 'address-line2',
								value: values.addressLine2,
								onChange: handleChange,
								isValid: touched.addressLine2 && !errors.addressLine2
							},
							{
								label: 'City',
								feedback: '',
								fieldType: 'text',
								name: 'cityName',
								autoComplete: 'address-level2',
								value: values.cityName,
								onChange: handleChange,
								isValid: touched.cityName && !errors.cityName
							}
						]}
                    />
					<FormInputs
						ncols={['4', '4', '4']}
						className={['', '', '']}
						properties={[
							{
								label: 'Postal Code',
								feedback: '',
								fieldType: 'text',
								name: 'postalCodeText',
								autoComplete: 'postal-code',
								value: values.postalCodeText,
								onChange: handleChange,
								isValid: touched.postalCodeText && !errors.postalCodeText
							},
							{
								label: 'State/Province',
								feedback: '',
								fieldType: 'text',
								name: 'stateProvinceName',
								autoComplete: 'address-level1',
								value: values.stateProvinceName,
								onChange: handleChange,
								isValid: touched.stateProvinceName && !errors.stateProvinceName
							},
							{
								label: 'Country',
								feedback: '',
								fieldType: 'text',
								name: 'countryName',
								autoComplete: 'country-name',
								value: values.countryName,
								onChange: handleChange,
								isValid: touched.countryName && !errors.countryName
							}
						]}
                    />
					<hr />
					<Row className='mx-1'>
						<Col md={4}>
							<CBList 
								id='phones-cblist'
								label='phone reference name'
								pkey='refName'
								value={this.state.phoneValue}
								selected={this.state.phone}
								liftUpOption={this.getPhone} 
								liftUpValue={this.getPhoneValue}
								items={this.props.appStates.busPhones ?? []} 
								placeholder='Search phone number...'
								whenEmpty='No phones registered...'
								disabled={this.state.disablePhoneCBList}
								nextFocus='validationPhoneCountryCode'
								/>
						</Col>
						<Col md={8}>
							<FormInputs
								ncols={['3', '3', '6']}
								className={['ml-0']}
								properties={[
									{
										label: 'Country Code',
										fieldType: 'text',
										name: 'phoneCountryCode',
										autoComplete: 'tel-country-code',
										value: values.phoneCountryCode,
										onChange: handleChange,
										isValid: touched.phoneCountryCode && !errors.phoneCountryCode
									},
									{
										label: 'Area Code',
										fieldType: 'text',
										name: 'areaCode',
										autoComplete: 'tel-area-code',
										value: values.areaCode,
										onChange: handleChange,
										isValid: touched.areaCode && !errors.areaCode
									},
									{
										label: 'Phone Number',
										fieldType: 'text',
										name: 'telephoneNumber',
										autoComplete: 'tel-local',
										value: values.telephoneNumber,
										onChange: handleChange,
										isValid: touched.telephoneNumber && !errors.telephoneNumber
									}
								]}
							/>
						</Col>
					</Row>
					<Row>
					    <Col md='5' className='mx-auto mt-4 text-center'>
							<Button 
								type='submit' 
								id={this.props.params.id + '-form-submit-' + this.props.idn}
								className='single-submit'
								style={ this.props.singleSubmit || this.props.disableFields ? {display: 'none'}: {display: 'inline'} } >
									Submit
							</Button>
							{
								this.props.appStates.submitting ? <div><h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
							}
						</Col>
					</Row>
					</fieldset>
				</Form>
				)}
			</Formik>
		)
	}
}

export default withRouter(AddressForm);