import React, { useState, useEffect } from 'react'
import BzrAxios from '../../../utils/BzrAxios'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { PreviewModal, MessageModal } from '../../../layouts/Modals/Modals'
import { printCreditDebitNote } from '../../Printer/PdfPrinter'
import t, { fn, currencyHash, locales, EditableCell } from '../../../utils/I18n/I18n'

const transacPrototype:any = () => {
    return {bzrProductId: '', quantity: 0, entryValue: 0, taxRate1: 0, isTaxExempt: false, tIsTaxExempt: false, amount: 0, changeRate: null, billedCurrency: '',
    n: 0, bookId: '', bzrInventoryBookId: '', unitOfMeasure: 'u', unitsPerPkg: null, 
    transacType: 'ref_nc_adjust', alicuote: 0, tsku: 'N/A', tdescription: `${t('other')}...`, tname: null}
}
const RenderItems = (props:any) => {
    const [alicuote,setAlicuote] = useState(0)
    const [changeRate,setchangeRate] = useState(0)

    let subTotal:any = 0,
        exempt:any = 0,
        tax1:any = 0,
        taxable:any = 0,
        total:any = 0

    useEffect(() => {
        if(props.alicuote !== alicuote) {
            setAlicuote(props.alicuote !== '' ? Number(props.alicuote) : 0)
            props.transacs.forEach((t:any) => {t.alicuote = props.alicuote})
        }
        if(props.changeRate !== changeRate) {
            setchangeRate(props.changeRate !== '' ? Number(props.changeRate) : 0)
            props.transacs.forEach((t:any) => {t.changeRate = props.changeRate})
        }
    }, [props, alicuote, changeRate])
    let transacs:any = props.transacs, order:any = props.order,
        trs:any = [<tr key="tr-empty" className="center-text"><td colSpan={7} className="text-center text-capitalize"><h4 style={{color: 'dimgray', marginTop: '10px'}}>{t('empty')}!</h4></td></tr>]
    const addNewTransac = () => {
        const transac:any = transacPrototype()
        transac.n = transacs.length
        transac.editable = true
        transacs.push(transac)
        props.liftUpTransacs(transacs)
    }
    if(transacs.length > 0) {
        trs  = transacs.map((transac:any, i:number) => {
            transac['n'] = i + 1
            transac['alicuote'] = transac.alicuote ?? props.alicuote
            transac['changeRate'] = transac.changeRate ?? props.changeRate
    
            let price = transac.entryValue * transac.changeRate
                if(transac.retailed === 'true') {
                    price = price/parseFloat(transac.unitsPerPkg)
                }
            transac.amount = transac.quantity * price * transac.alicuote
    
            taxable += (transac.isTaxExempt === 'true' || transac.isTaxExempt === true) ? 0 : transac.quantity * price * transac.alicuote
            tax1 = taxable * order.taxRate1
            exempt += (transac.isTaxExempt === 'true' || transac.isTaxExempt === true) ? transac.quantity * price * transac.alicuote : 0
            subTotal += transac.quantity * price * transac.alicuote
            total = subTotal + tax1
            
            return(
                <tr data-record-id={transac.id} key={i}>
                    <td>{transac.n}</td>
                    <td>{transac.editable ? 
                        <EditableCell isString={true} value={transac.tsku} liftUpValue={(val:any) => {
                            transac.tsku = parseFloat(val)
                            props.liftUpTransacs(transacs)
                        }} /> : transac.tsku    
                    }</td>
                    <td>
                        {transac.editable ? 
                            <EditableCell isString={true} value={transac.tdescription} liftUpValue={(val:any) => {
                                transac.tdescription = parseFloat(val)
                                props.liftUpTransacs(transacs)
                            }} /> : transac.tdescription    
                        }
                        <br />
                        {transac.editable ? <Form.Check type="checkbox" label={t('tax exempt')} id={`tax-exempt-${i}`} inline
                            defaultChecked={(transac.isTaxExempt === 'true' || transac.isTaxExempt === true)} 
                            onChange={(event:any) => {
                                transac.isTaxExempt = event.target.checked
                                props.liftUpTransacs(transacs)
                            }}
                        /> : null}
                    </td>
                    <td className="number-format">
                        <EditableCell value={transac.quantity} liftUpValue={(val:any) => {
                            transac.quantity = parseFloat(val)
                            props.liftUpTransacs(transacs)
                        }} /> ({(transac.unitOfMeasure && transac.unitOfMeasure === '') || (order.retailed && transac.unitsPerPkg) ? 'U' : transac.unitOfMeasure.toUpperCase()})
                    </td>
                    { transac.editable ? 
                        <td className="number-format">
                            {
                                <EditableCell value={price.toFixed(2)} liftUpValue={(val:any) => {
                                    transac.entryValue = parseFloat(val)/transac.changeRate
                                    props.liftUpTransacs(transacs)
                                }} />
                            } {transac.isTaxExempt ? '(e)' : ''}
                            {
                            order.billedCurrency !== '840' ? 
                                <p>
                                    <b className="text-uppercase">($ {(order.retailed && transac.unitsPerPkg) ? (transacs[i].entryValue/transacs[i].unitsPerPkg).toFixed(2) : 
                                        <EditableCell value={parseFloat(transac.entryValue).toFixed(2)} liftUpValue={(val:any) => {
                                            transac.entryValue = parseFloat(val)
                                            props.liftUpTransacs(transacs)
                                        }} />
                                    })
                                    </b>
                                </p> 
                            : null
                            }
                        </td> 
                        :
                        <td className="number-format">
                            {fn(price)} {transac.isTaxExempt ? '(e)' : ''}
                            {order.billedCurrency !== '840' ? <p><b className="text-uppercase">($ {(order.retailed && transac.unitsPerPkg) ? (transacs[i].entryValue/transacs[i].unitsPerPkg).toFixed(2) : parseFloat(transacs[i].entryValue).toFixed(2)})</b></p> : null}
                        </td> 
                    }
                    <td className="number-format">
                            <EditableCell value={transac.alicuote * 100} liftUpValue={(val:any) => {
                                transac.alicuote = parseFloat(val)/100
                                props.liftUpTransacs(transacs)
                            }} /> %</td>
                    <td className="number-format">
                        {fn(transac.amount)}
                    </td>
                    <td><Button data-transac-index={i} className="btn-trash" onClick={
                        (event:any) => {
                            const index = parseInt(event.currentTarget.dataset.transacIndex)
                            transacs.splice(index, 1)
                            props.liftUpTransacs(transacs)
                        }
                    } variant="outline-danger"><span className="pe-7s-trash text-center" /></Button>
                    </td>
                </tr>
            )
        })
        props.liftUpTotals({subTotal,exempt,tax1,taxable,total})
    }
    if(props.addItem) trs.push(<tr key="last" className="my-4"><td colSpan={8}><Button variant="outline-secondary" onClick={addNewTransac}>{t('add item')}</Button></td></tr>)

    return(
        <div className="latera-credit-note-table">
            <Table id="lateral-items" className="lateral-items flash-text-animation" >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{t('code')}</th>
                        <th>{t('description')}</th>
                        <th className="text-right">{t('quantity')}</th>
                        <th className="text-right">{t('price')}({currencyHash[order.billedCurrency].currencySymbol})</th>
                        <th className="text-right">{t('alicuote')}(%)</th>
                        <th className="text-right">{t('amount')}({currencyHash[order.billedCurrency].currencySymbol})</th>
                        <th style={{width: '30px'}}></th>
                    </tr>
                </thead>
                <tbody>
                    { trs }
                </tbody>
            </Table>
            <div className="d-flex px-2">
                <Table className="lateral-totals">
                    <tbody>
                        <tr className="sub-total">
                            <th>sub-total</th><th className="number-format">{currencyHash[order.billedCurrency].currencySymbol} {fn(subTotal)}</th>
                        </tr>
                        <tr className="">
                            <td>taxable base<b> {fn(taxable)}</b> {locales[order.locale].taxName} {fn(order.taxRate1)}%</td>
                            <td className="number-format">{currencyHash[order.billedCurrency].currencySymbol} {fn(tax1)}</td>
                        </tr>
                        <tr className="mb-3">
                            <td>exempt<b> {fn(exempt)}</b> {fn(0)}%</td>
                            <td className="number-format">{currencyHash[order.billedCurrency].currencySymbol} {fn(0)}</td>
                        </tr>
                        <tr><td><br /></td></tr>
                        <tr>
                            <th>total</th><th className="number-format">{currencyHash[order.billedCurrency].currencySymbol} {fn(total)}</th>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

interface Props {
    fullOrder:any
    appStates:any
    onSubmitted?:any
    onClose?:any
}
interface State {
    transacs:any
    ctransacs:any
    type:string
    option:string
    remarks:string
    alicuote:string
    changeRate:string
    previewURL:any
    processing:any
    creditNote:any
}
class CreditDebitNote extends React.Component<Props,State> {
    orderTotals:any
    printOptions:any
    options:any = {
        credit: {total_cancellation: 'total invoice cancellation', partial_discount: 'partial discount', return_goods: 'return of goods', other_credit: 'other credits...'},
        debit: {partial_increase: 'partial price increase', overcharge: 'overcharge expense incurred', interest_debt: 'interest/debt', other_debit: 'other debits...'}
    }
    msgs:any = {
        credit: {
            total_cancellation: {en: 'total invoice cancellation', es: 'anulación total de factura'}, 
            partial_discount: {en: 'partial discount', es: 'descuento parcial'}, 
            return_goods: {en: 'return of goods', es: 'retorno de mercancía'}, 
            other_credit: {en: 'other...', es: 'otro...'}
        },
        debit: {
            partial_increase: {en: 'partial price increase', es: 'incremento parcial de precios'}, 
            overcharge: {en: 'overcharge expense incurred', es: 'sobrecargo incurrido'}, 
            interest_debt: {en: 'interest/debt', es: 'interés/mora/deuda'}, 
            other_debit: {en: 'other...', es: 'otro...'}
        }
    }
    constructor(props:Props) {
        super(props)
        this.state = {
            type: 'credit',
            option: 'partial_discount',
            transacs: null,
            ctransacs: null,
            remarks: this.msgs.credit.partial_discount[props.fullOrder.saleOrder.locale.slice(0,2)],
            alicuote: '0.00',
            changeRate: props.fullOrder.saleOrder.changeRate,
            previewURL: null,
            processing: null,
            creditNote: null
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleOption = this.handleOption.bind(this)
        this.handleType = this.handleType.bind(this)
        this.handleRateChange = this.handleRateChange.bind(this)
    }
    componentDidUpdate() {
        if(JSON.stringify(this.state.ctransacs) !== JSON.stringify(this.props.fullOrder.transacs)) {
            this.setState({ctransacs: this.props.fullOrder.transacs})
            this.mapTransacs()
        }
        this.printOptions = this.props.appStates.activeBU.preferences.printer ? this.props.appStates.activeBU.preferences.printer : {subHeaderOffset: 50}
    }
    mapTransacs() {
        const transacs:any[] = this.props.fullOrder.transacs.map((tc:any) => {
            const newTc:any = transacPrototype()
            Object.keys(newTc).forEach((k) => newTc[k] = tc[!!k.match(/tname|tdescription|tsku/) ? k.slice(1) : k])
            newTc.tIsTaxExempt = newTc.isTaxExempt = tc.isTaxExempt
            return newTc
        })
        this.setState({transacs})
    }
    handleType(event:any) {
        const type:any = event.target.value,
            option:string = type === 'credit' ? 'partial_discount' : 'partial_increase'
        this.setState({type, option, remarks: this.msgs[type][option][this.props.fullOrder.saleOrder.locale.slice(0,2)]})
    }
    handleOption(event:any) {
        const option:any = event.target.value
        if(option === 'total_cancellation' || option === 'return_goods') this.setState({alicuote: '100'})
        if(option === 'partial_discount') this.setState({alicuote: '0'})
        this.setState({ option, remarks: this.msgs[this.state.type][option][this.props.fullOrder.saleOrder.locale.slice(0,2)]})
        this.mapTransacs()
    }
    handleRateChange(event:any) {
        const { name, value } = event.target
        this.setState({[name]: String(value).replace(/,/g,'.')} as any)
    }
    async handleSubmit() {
        this.setState({processing: 'issuing'})
        const recordHeader:any = {}
        recordHeader['orgId'] = this.props.appStates.org.id
		recordHeader['businessUnitId'] = this.props.appStates.activeBU.id
        recordHeader['creatorId'] = recordHeader['updaterId'] = this.props.appStates.user.id

        const order:any = Object.assign({}, this.props.fullOrder.saleOrder, this.orderTotals)
        order.relatedOrderId = this.props.fullOrder.saleOrder.id
        order.remarks = this.state.remarks
        order.status = `${this.state.type}_${this.state.option}`
        order.date = new Date().toISOString()
        order.relatedBillNumber = order.billNumber
        order.relatedControlNumber = order.controlNumber
        delete order.id; delete order.orderNumber; delete order.billNumber; delete order.controlNumber; delete order.deliveryNoteNumber;

        const data:any = {
            recordHeader,
            order,
            transacs: this.state.transacs
        }
        try {
            console.log(data)
            const response:any = await BzrAxios.records({url:`/${this.state.type === 'credit' ? 'Credit' : 'Debit' }Notes`, method: 'POST', data})

            //////////////////////////////////////////////////////////////////////

            let creditNote:any
            const { transacs, seqRefs } = response.data
            if(this.state.type === 'credit') creditNote = response.data.creditNote
            else creditNote = response.data.debitNote
                
            const blob = printCreditDebitNote(creditNote, transacs, this.props.fullOrder.client, {letterhead: false, mediaType: 'paper-letter', locale: this.props.appStates.activeBU.locale, billedIn: locales[this.props.appStates.activeBU.locale], subHeaderOffset: this.printOptions.subHeaderOffset})
            
            const activeBU = this.props.appStates.activeBU
            activeBU.billSeq = seqRefs.billSeq
            activeBU.controlSeq = seqRefs.controlSeq
            activeBU.orderSeq = seqRefs.orderSeq
            activeBU.debitSeq = seqRefs.debitSeq
            activeBU.creditSeq = seqRefs.creditSeq
            activeBU.quoteSeq = seqRefs.quoteSeq
            
            ///////  UPDATE INVENTORY BOOK LOCALLY
/*             if(!!saleOrder.status.match(/printed/) || !!saleOrder.status.match(/issued/)) {
                const books:any = this.props.appStates.books
                transacs.forEach((t:any) => {
                    books[saleOrder.bzrInventoryBookId][t.bzrProductId] = inventoryEntries.find((entry:any) => entry.bzrProductId === t.bzrProductId)
                })
            } */
            this.props.onClose()
            this.setState({ previewURL: URL.createObjectURL(blob), creditNote, processing: null})
            //this.props.onSubmitted(creditNote)
            //////////////////////////////////////////////////////////////////////
        } catch (err) {
            console.log(err)
        }
    }
    checkBillStatus(order:any) {
        const prefixes:any = this.props.appStates.activeBU.preferences.prefixes[this.props.appStates.activeBU.locale],
            match:any = order.status.match(/credit|debit/)

        if(!!match) order.status = match[0]
        switch(order.status) {
            case 'printed_legal':
                return `${prefixes.pl}-${order.billNumber}`
            default:
                return ''
        }
    }
    incOrderNumber(on:any, sep:string = '-', appstates:any = this.props.appStates) {
        on = parseInt(on.replace(/\W+/g,''))
        on++
        if(on.length > 6) {
            on = on.toString().padStart(8, '0')
            on = on.slice(0,2) + sep + on.slice(2)
        } else on = on.toString().padStart(6, '0')
        return on
    }
    render() {
        const order:any = this.props.fullOrder.saleOrder, type:string = this.state.type
        return(
            <div className="my-5 mx-3">
                <div className="d-flex">
                    <div className="p-2 flex-grow-1"><h2>{t(`${this.state.type} note`)} / <span className="h3">Nº: {this.state.type === 'credit' ? this.incOrderNumber(this.props.appStates.activeBU.creditSeq) : this.incOrderNumber(this.props.appStates.activeBU.debitSeq) }</span></h2></div>
                    <div className="p-2">
                        <span className="h5">{t('invoice related')}: {this.checkBillStatus(this.props.fullOrder.saleOrder)} </span><br />
                        <span className="h6">{t('client')}: {this.props.fullOrder.client.legalName} </span> <br />
                        <span className="h6">{t('invoice date')}: {new Date(this.props.fullOrder.saleOrder.date).toLocaleString(this.props.appStates.activeBU.locale)} </span>     
                    </div>
                </div>
                <hr />
                <div className="d-flex mb-4 pr-5">
                    <div className="p-2">
{/*                         <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                            <Tab eventKey="home" title="Home" />
                            <Tab eventKey="profile" title="Profile" />
                        </Tabs> */}
                        <Form.Check 
                            type="radio"
                            label={<h5>{t('credit')}</h5>}
                            id="credit-radio"
                            name="type-radios"
                            inline
                            defaultChecked
                            value='credit'
                            onChange={this.handleType}
                        />
                        <Form.Check 
                            type="radio"
                            label={<h5>{t('debit')}</h5>}
                            id="debit-radio"
                            inline
                            name="type-radios"
                            value='debit'
                            onChange={this.handleType}
                        />
                        <Form.Group>
                            <Form.Label className="mt-3 mb-0"><h6>{t('remarks')}</h6></Form.Label>
                            <Form.Control 
                                style={{width: '400px'}}
                                as="textarea"
                                id="remarks"
                                name="remarks"
                                rows={3}
                                value={this.state.remarks}
                                onChange={(event:any) => this.setState({remarks: event.target.value})}
                            />
                        </Form.Group>
                    </div>
                    <div className="p-2 ml-auto">
                        <Form.Group as={Row} controlId="reason-options" style={{width: "400px"}}>
                            <Form.Label as={Col} md="3"><h5 className="text-right">{t('reason')}</h5></Form.Label>
                            <Col md="9">
                                <Form.Control as="select" custom value={this.state.option}
                                    onChange={this.handleOption}>
                                    {Object.entries(this.options[type]).map((entry:any, i:number) => (<option key={`o${i}`} value={entry[0]}>{t(entry[1])}</option>))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="alicuote" style={{width: "400px"}}>
                            <Form.Label column sm={7}><h6 className="text-right">{t('alicuote')}</h6></Form.Label>
                            <InputGroup as={Col} sm={5}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" name="alicuote" className="number-format" value={this.state.alicuote} onChange={this.handleRateChange} />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Row} controlId="changeRate" style={{width: "400px"}}>
                            <Form.Label column sm={7}><h6 className="text-right">{t('changeRate')}</h6></Form.Label>
                            <InputGroup as={Col} sm={5} className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" name="changeRate" className="number-format" value={this.state.changeRate} onChange={this.handleRateChange} />
                            </InputGroup>
                        </Form.Group>
                    </div>
                </div>
                {/* <div className="bzr-lateral-scrollable-50vh"> */}
                <div>
                    <RenderItems transacs={this.state.transacs ?? []} 
                        liftUpTransacs={(transacs:any) => {
                            if(transacs.length !== 0) transacs.forEach((tc:any) => tc.changeRate = tc.changeRate ?? this.props.fullOrder.saleOrder.changeRate)
                            this.setState({transacs})
                        }}
                        liftUpTotals={(totals:any) => this.orderTotals = totals}
                        order={order}
                        alicuote={parseFloat(this.state.alicuote)/100}
                        changeRate={this.state.changeRate}
                        remarks={this.state.remarks}
                        addItem={!!this.state.option.match(/other/)}
                        />
                    <div className="d-flex mt-3">
                        <div className="p-2 flex-fill text-center">
                            <Button className="mx-3" variant="danger" onClick={() => {
                                this.props.onClose()
                                this.setState({creditNote: null})
                                }}>{t('cancel')}</Button>
                            <Button className="mx-3" onClick={this.handleSubmit}>{t('issue')}</Button>
                        </div>
                    </div>
                </div>
                <PreviewModal 
                    show={!!this.state.previewURL} 
                    url={this.state.previewURL} 
                    title={`${t(this.state.type)} note`}
                    closeBtnTitle={'close'}
                    showDefaultActions={false}
                    onHide={async () => {
                        this.setState({previewURL: null})
                        this.props.onClose(Object.assign({},this.state.creditNote))
                        }} />
                <MessageModal show={!!this.state.processing} message={t(this.state.processing)} />
            </div>
        )
    }
}

export default CreditDebitNote
