import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history"
import { Form, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../../layouts/Forms/FormInputs'
import t, { translate, locales } from '../../../utils/I18n/I18n'

import BzrAxios from '../../../utils/BzrAxios'

//import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	appStates: any,
	params: any,
	idn: any
}

interface State {
	formError: any
}

class ProfileForm extends React.Component<Props,State> {
	idPrefix:string = ''
	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null
		}		
	}
	componentDidMount() {
		setTimeout(() => {
			const firstInput:any = document.getElementsByName('firstName')
			firstInput[0]?.focus()
		}, 1500)
		const el:any = document.getElementById("profile-form-" + this.props.idn)
		translate(el)
	}

	handleSubmit(data: any) {
		data['countryName'] = locales['es-VE'].countryName
		BzrAxios.records({ url: `/Individuals`, method: 'POST', data })
		.then((response: any) => {
			
		}).catch((error: any) => {
			console.error(error)
			
		})
	}

	render() {
		const u:any = {}
		return (
			<Formik
				validationSchema={Yup.object({
					firstName: Yup.string().required(),
					middleName: Yup.string(),
					lastName: Yup.string().required(),
					secondLastName: Yup.string(),
					email: Yup.string()
				})}
				onSubmit={(values) => { this.handleSubmit(values)} }
				initialValues={{firstName: u.firstName ?? '', 
								middleName: u.middleName ?? '', 
								lastName: u.lastName ?? '', 
								locale: u.locale ?? 'es-VE',
								identificationNumber: u.identificationNumber ?? '',
								idPrefix: u.idPrefix ?? 'V',
								role: u.role ?? 'seller',
								secondLastName: u.secondLastName ?? '',
								addressLine1: u.addressLine1 ?? '', 
								addressLine2: u.addressLine2 ?? '', 
								cityName: u.cityName ?? '', 
								postalCodeText: u.postalCodeText ?? '',
								stateProvinceName: u.stateProvinceName ?? '',
								phoneCountryCode: u.phoneCountryCode ?? '',
								areaCode: u.areaCode ?? '',
								telephoneNumber: u.telephoneNumber ?? '',
								email: u.email ?? ''
							}}
				enableReinitialize={true}
			>
				{({
				handleSubmit,
				handleChange,
				validateForm,
				isSubmitting,
				values,
				touched,
				isValid,
				errors,
				}) => (
				<Form noValidate onSubmit={handleSubmit} id={"profile-form-" + this.props.idn} className="mx-auto" style={{maxWidth: '992px'}}>
					<Row>
						<Col>
							<FormInputs
								ncols={["6", "6"]}
								className={["", ""]}
								properties={[
									{
										label: "First Name",
										feedback: "",
										fieldType: "text",
										name: "firstName",
										value: values.firstName,
										onChange: handleChange,
										isValid: touched.firstName && !errors.firstName,
										isInvalid: !!errors.firstName,
										error: errors.firstName
									},
									{
										label: "Middle Name",
										feedback: "",
										fieldType: "text",
										name: "middleName",
										value: values.middleName,
										onChange: handleChange,
										isValid: touched.middleName && !errors.middleName
									}
								]}
							/>
							<FormInputs
								ncols={["6", "6"]}
								className={["", ""]}
								properties={[
									{
										label: "Last Name",
										feedback: "",
										fieldType: "text",
										name: "lastName",
										value: values.lastName,
										onChange: handleChange,
										isValid: touched.lastName && !errors.lastName
									},
									{
										label: "Second Last Name",
										feedback: "",
										fieldType: "text",
										name: "secondLastName",
										value: values.secondLastName,
										onChange: handleChange,
										isValid: touched.secondLastName && !errors.secondLastName
									}
								]}
							/>
							<FormInputs
								ncols={['4', '2', '4']}
								className={['px-2', 'ml-3 pl-2 pr-0', 'pl-0 pr-2']}
								properties={[
									{
										label: 'country',
										fieldType: 'select',
										name: 'locale',
										value: values.locale ?? 'es-VE',
										options: [
											['xx-XX', t('Select')],
											['es-VE', 'Venezuela'], 
											['es-CO', 'Colombia'],
											['es-MX', 'México'], 
											['es-ES', 'España'],
											['pt-BR', 'Brasil'],
											['en-US', 'United States of America']
										],
										onChange: handleChange
									},
									{
										fieldType: 'select',
										style:{marginTop: '21px', paddingLeft: '5px'},
										className: "text-uppercase",
										name: 'idPrefix',
										value: values.idPrefix,
										options: [
											['NA', t('N/A')], 
											['J', t('J')],
											['V', t('V')],
											['G', t('G')],
											['E', t('E')],
											['O', t('O')]
										],
										onChange: (event:any) => { handleChange(event); this.idPrefix = event.target.value; validateForm() }
									},
									{
										label: `${t('identification number')} *`,
										fieldType: 'text',
										name: 'identificationNumber',
										value: values.identificationNumber,
										onChange: handleChange,
										isValid: touched.identificationNumber && !errors.identificationNumber,
										isInvalid: !!errors.identificationNumber,
										error: errors.identificationNumber,
										disabled: values.idPrefix === 'NA'
									}
								]}
							/>
							<hr /><br />
							<h5>operation info</h5>
							<FormInputs
								ncols={['6']}
								className={['px-2']}
								properties={[
									{
										label: 'role',
										fieldType: 'select',
										name: 'role',
										value: values.role,
										options: [
											['seller', t('seller')], 
											['worker', t('worker')],
											['other', t('other')]
										],
										onChange: handleChange
									}
								]}
							/>
						</Col>
						<Col md={6} className="px-3">
							<h5>address & contact</h5>
							<fieldset disabled={false}>
								<FormInputs
									ncols={['12']}
									className={['ml-0']}
									properties={[
										{
											label: `${t('address line 1')} *`,
											fieldType: 'text',
											name: 'addressLine1',
											value: values.addressLine1,
											onChange: handleChange,
											isInvalid: !!touched.identificationNumber && !!errors.addressLine1,
											error: errors.addressLine1
										}
									]}
								/>
								<FormInputs
									ncols={['8', '4']}
									className={['', '']}
									properties={[
										{
											label: 'Address Line 2',
											fieldType: 'text',
											name: 'addressLine2',
											value: values.addressLine2,
											onChange: handleChange,
											isInvalid: !!errors.addressLine2,
											error: errors.addressLine2
										},
										{
											label: 'City',
											fieldType: 'text',
											name: 'cityName',
											value: values.cityName,
											onChange: handleChange,
											isInvalid: !!errors.cityName,
											error: errors.cityName
										}
									]}
								/>
								<FormInputs
									ncols={['3', '4']}
									className={['mr-3', '']}
									properties={[
										{
											label: 'Postal Code',
											fieldType: 'text',
											name: 'postalCodeText',
											value: values.postalCodeText,
											onChange: handleChange,
											isInvalid: !!errors.postalCodeText,
											error: errors.postalCodeText
										},
										{
											label: 'State/Province',
											fieldType: 'text',
											name: 'stateProvinceName',
											value: values.stateProvinceName,
											onChange: handleChange,
											isInvalid: !!errors.stateProvinceName,
											error: errors.stateProvinceName
										}
									]}
								/>
								<hr />
								<FormInputs
									ncols={['3', '2', '4']}
									className={['','','']}
									properties={[
										{
											label: 'Country Code',
											fieldType: 'text',
											name: 'phoneCountryCode',
											value: values.phoneCountryCode,
											onChange: handleChange,
											isInvalid: !!errors.phoneCountryCode,
											error: errors.phoneCountryCode
										},
										{
											label: 'Area Code',
											fieldType: 'text',
											name: 'areaCode',
											value: values.areaCode,
											onChange: handleChange,
											isInvalid: !!errors.areaCode,
											error: errors.areaCode
										},
										{
											label: 'Phone Number',
											fieldType: 'text',
											name: 'telephoneNumber',
											value: values.telephoneNumber,
											onChange: handleChange,
											isInvalid: !!errors.telephoneNumber,
											error: errors.telephoneNumber
										}
									]}
								/>
								<FormInputs
									ncols={['6']}
									className={['px-2']}
									properties={[
										{
											label: 'email',
											fieldType: 'text',
											name: 'email',
											value: values.email,
											onChange: handleChange,
											isInvalid: !!errors.email,
											error: errors.email
										}
									]}
								/>
							</fieldset>
						</Col>
					</Row>
					<Row>
						<Col md="5" className="mx-auto mt-4 text-center">
							{
								isSubmitting 
								? 
								<div><h5>{t('submitting')}... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> 
								: 
								<Button type="submit" id={this.props.params.id + "-form-submit-" + this.props.idn}>{t('submit')}</Button>
							}
						</Col>
					</Row>
				</Form>
				)}
			</Formik>
		)
	}
}

export default withRouter(ProfileForm);