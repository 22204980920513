import React, { useRef, useState, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { History, LocationState } from "history"
import { Container, Row, Col, Button, ButtonGroup, DropdownButton, InputGroup, Dropdown, Form, Nav, Tabs, Tab } from 'react-bootstrap'
import BzrAxios from '../../utils/BzrAxios'
import { MessageModal } from '../../layouts/Modals/Modals'
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // the locale you want
import { printSaleOrder, printDeliveryNote, printCreditDebitNote } from '../Printer/PdfPrinter'
import BzrOrdersTable from '../Common/BzrOrdersTable'
import BzrTable from '../Common/BzrTable'

import t, { translate, locales, currencyHash, months } from '../../utils/I18n/I18n'
import CreditDebitNote from './Dialogs/CreditDebitNote'
import RegisterPayment from './Dialogs/RegisterPayment'
import ConsignmentRelation from './Dialogs/ConsignmentRelation'
import VoidInvoice from './Dialogs/VoidInvoice'
/////// Reports
import ProductTotals from './Reports/ProductTotals'
import ClientTotals from './Reports/ClientTotals'
import LocationTotals from './Reports/LocationTotals'

interface Props extends RouteComponentProps {
	history: History<LocationState>
    appStates:any
    liftUpAppStates:any
    products:any
}

interface State {
    saleOrders:any[]
    payments:any[]
    soFields:any[]
    pFields:any[]
    filteredTransacs:any
    selectedTransac:any
    fetching:boolean
    pdfURL:any
    showPreview:boolean
    showReports:any
    status:string
    range:any
    filters:any
    clients:any
    processing:any
    options:any
    defaultHeaders:any
    refreshing:boolean
    showPeriodMenu:boolean
    lateralForm:any
    showRange:boolean
    repository:any
    individuals:any
}

class ReportAlfa extends React.Component<Props,State> {
    clients_hash:any = {}
    sales_hash:any = {}
    status:any = {
        'as_proforma': "proforma",
        'as_quotation': "quotation",
        'as_sale_order': "sale order",
        'issued_legal': "issued invoice",
        'printed_legal': "invoice",
        'printed_receipt': "receipt",
        'printed_consignment': "consignment",
        'void_legal': "void invoice",
        'void_receipt': "void receipt",
        'credit': "credit note",
        'debit': "debit note"
    }
    /// For Payments table
    defaultHeaders:any[] = [
        {fieldName: 'paymentDate', fieldType: 'date', filter: true, show: true, style: {minWidth: "90px"}},
        {fieldName: 'orderNumber', fieldType: 'varchar',  filter: true, show: true, style: {minWidth: "115px"}},
/*         {fieldName: 'payBefore', fieldType: 'date', filter: true, show: true, alarm: {daysBefore: 5}, style: {minWidth: "90px"}},
        {fieldName: 'paymentStatus', fieldType: 'varchar', filter: true, show: true, style: {maxWidth: "120px"}},
        
        {fieldName: 'billNumber', fieldType: 'varchar', filter: true, show: false, style: {minWidth: "115px"}},
        {fieldName: 'clientLegalName', fieldType: 'varchar', filter: true, show: true, style: {minWidth: "250px"}}, */
        {fieldName: 'currency', fieldType: 'varchar', filter: true, show: true, style: {minWidth: "100px"}},
        {fieldName: 'changeRate', fieldType: 'numeric', filter: true, show: true, style: {maxWidth: "90px"}},
        {fieldName: 'totalUSD', fieldType: 'numeric', fieldLabel: 'invoice total', filter: false, show: true},
        {fieldName: 'amount', fieldType: 'numeric', fieldLabel: 'payment', filter: false, show: true},
        {fieldName: 'balance', fieldType: 'numeric', filter: false, show: true},
        {fieldName: 'sellerName', fieldType: 'varchar', fieldLabel: 'seller name', filter: false, show: true},
        {fieldName: 'sellerCommission', fieldType: 'varchar', fieldLabel: 'commission', filter: false, show: true}
    ]
    constructor(props: Props) {
        super(props)
        this.state = {
            saleOrders: [],
            soFields: [],
            payments: [],
            pFields: [],
            filteredTransacs: [],
            selectedTransac: null,
            fetching: false,
            pdfURL: null,
            showPreview: false,
            showReports: null,
            status: 'all',
            range: {from: new Date(new Date().getFullYear(), new Date().getMonth(), 1), to: new Date()},
            filters: null,
            clients: null,
            processing: null,
            options: {letterhead: true, printUsd: true, printLocal: false, currencyCode: 840, user: `${props.appStates.user.firstName}, ${props.appStates.user.lastName}`},
            defaultHeaders: [],
            refreshing: false,
            showPeriodMenu: false,
            lateralForm: null,
            showRange: true,
            repository: {},
            individuals:[]
        }
        this.handleSelect = this.handleSelect.bind(this)
        this.reportsMenu = this.reportsMenu.bind(this)
        this.selectPeriod = this.selectPeriod.bind(this)
        this.report = this.report.bind(this)
    }

    componentDidMount() {
        const el:any = document.getElementById('sale-order-trasacs')
        translate(el)

        // GET Clients
        if(this.props.appStates.clients) {
            this.setState({clients: this.props.appStates.clients})
            this.props.appStates.clients.forEach((c:any) => this.clients_hash[c.id] = c)
        } else {
            BzrAxios.records({url: `/BzrClient`})
                .then((response: any) => {
                    this.setState({clients: response.data.records})
                    this.props.liftUpAppStates({clients: response.data.records, clientFields: response.data.fields})
                    response.data.records.forEach((c:any) => this.clients_hash[c.id] = c)
                })
                .catch((error: any) => console.error(error))
        }
        //  GET INDIVIDUALS
        BzrAxios.records({url: `/BzrIndividual`})
        .then((response:any) => this.setState({individuals: [...response.data.records, ...this.props.appStates.users]}))
        .catch(err => console.log(err))

        this.getSaleOrders()
        registerLocale("es", es)
    }
    handleSelect(event:any) {
        this.setState({status: event.target.value})
    }
    handleDateChange(date:any, name:string) {
        const range:any = this.state.range
        range[name] = date
        this.setState({range})
    }
    handlePrintPhysical(saleOrder:any) {
        this.setState({showPreview: false, processing: 'printing...'})
        const data = { status: 'printed_legal' }
        // PRINCIPAL ACTION: CHANGE ORDER STATUS TO PRINTED_LEGAL

        BzrAxios.records({url: `/SaleOrders/Status/${saleOrder.id}`, method: 'PUT', data})
        .then((response:any) => {
            const postOrder:any = response.data, billedIn:any = locales[this.props.appStates.activeBU.locale]
            saleOrder = postOrder.saleOrder

            let blob:any
            if(!!postOrder.saleOrder.status.match(/credit|debit/)) blob = printCreditDebitNote(postOrder.saleOrder, this.state.repository[saleOrder.id].transacs, this.state.clients.find((c:any) => c.id === postOrder.saleOrder.clientId), {letterhead: false, mediaType: 'paper-letter', locale: this.props.appStates.activeBU.locale, billedIn})
            else blob = printSaleOrder(postOrder.saleOrder, this.state.repository[saleOrder.id].transacs, this.state.clients.find((c:any) => c.id === postOrder.saleOrder.clientId), {letterhead: false, mediaType: 'paper-letter', locale: this.props.appStates.activeBU.locale, billedIn})
            const pdfURL = URL.createObjectURL(blob)

            const repository:any = this.state.repository
            repository[saleOrder.id].transacs = postOrder.transacs
            
            if(postOrder.inventoryEntries) {
                const books:any = this.props.appStates.books
                postOrder.inventoryEntries.forEach((entry:any) => {
                    books[saleOrder.bookId][entry.bzrProductId] = entry
                })
            }

            ///  Parse Modified Order
            const r:any = Object.assign({}, saleOrder)
            saleOrder.orderStatus = r.status
            saleOrder.status = t(this.status[r.status.match(/credit|debit/) ? r.status.match(/credit|debit/)[0] : r.status])
            saleOrder.orderNumber = this.checkOrderStatus(r)
            saleOrder.billNumber = this.checkBillStatus(r)
            saleOrder.billedCurrency = currencyHash[r.billedCurrency].currencySymbol
            saleOrder.payBefore = new Date(new Date(r.date).getTime() + parseInt(r.paymentCondition)*24*60*60*1000).toISOString()
            ///// Special Treatment for USD Amounts
            saleOrder['subTotalUSD'] = r.billedCurrency !== '840' ? (parseFloat(r.subTotal)/parseFloat(r.changeRate)) : parseFloat(r.subTotal)

            let saleOrders:any = this.state.saleOrders,
                idx:number = saleOrders.findIndex((r:any) => r.id === saleOrder.id)
                saleOrders.splice(idx, 1)
            
            this.setState({ pdfURL, showPreview: true, processing: null, repository, selectedTransac: saleOrder, saleOrders})

            saleOrders.splice(idx, 0, saleOrder)
            setTimeout(() => this.setState({saleOrders}))
        })
        .catch((err) => {
            console.log(err)
            this.setState({showPreview: false})
        })
    }
    selectPeriod(event:any) {
        if(event.target.id === 'range') this.setState({showRange: true, showPeriodMenu: false})
        else {
            this.setState({showRange: false, showPeriodMenu: false})
        }
    }
    updateFilters(filter:any, name?:any) {
        const filters:any = this.state.filters ?? {}
        filters[name] = filter
        //console.log(filters)
        this.setState({filters})
    }
    getSaleOrders() {
        this.setState({refreshing: true})
        const to = this.state.range.to
        const from = this.state.range.from
            
        let query:any = `qbetween=createdAt&qfrom=${from.toISOString()}&qto=${to.toISOString()}`
        if(this.state.status !== 'all') query = `status=${this.state.status}&${query}`
        query = `?${query}`
        BzrAxios.records({url: `/BzrSaleOrder${query}`})
            .then((response:any) => {
                const saleOrders:any = response.data.records
                console.log(this.state.individuals)
                saleOrders.forEach((record:any) => {
                    const r:any = Object.assign({}, record),
                        seller:any = this.state.individuals.find((s:any) => s.id === record.sellerId)
                    record.orderStatus = r.status
                    const match:any = r.status.match(/credit|debit/)
                    record.status = t(this.status[match ? match[0] : r.status])
                    record.oPaymentStatus = r.paymentStatus
                    record.paymentStatus = this.checkPaymentStatus(r.paymentStatus)
                    record.orderNumber = this.checkOrderStatus(r)
                    record.billNumber = this.checkBillStatus(r)
                    record.billedCurrency = currencyHash[r.billedCurrency].currencySymbol
                    record.payBefore = new Date(new Date(r.date).getTime() + parseInt(r.paymentCondition)*24*60*60*1000).toISOString()
                    ///// Special Treatment for USD Amounts
                    record.subTotalUSD = r.billedCurrency !== '840' ? (parseFloat(r.subTotal)/parseFloat(r.changeRate)) : parseFloat(r.subTotal)
                    record.taxUSD = r.billedCurrency !== '840' ? (parseFloat(r.tax1)/parseFloat(r.changeRate)) : parseFloat(r.tax1)
                    record.totalUSD = r.billedCurrency !== '840' ? (parseFloat(r.total)/parseFloat(r.changeRate)) : parseFloat(r.total)
                    record.paidAmountUSD = r.billedCurrency !== '840' ? (parseFloat(r.paidAmount)/parseFloat(r.changeRate)) : parseFloat(r.paidAmount)
                    record.balanceUSD = record.totalUSD - record.paidAmountUSD
                    record.sellerName = seller ? `${seller.firstName} ${seller.lastName}` : ''
                    record.sellerPayment = record.subTotalUSD * record.sellerCommission
                })
                this.setState({saleOrders, soFields: response.data.fields, refreshing: false})
                //this.getPayments()
            })
            .catch(err => console.log(err))
    }
    getPayments() {
        let saleOrderId:string[] = [], saleOrderHash:any = {}
        this.state.saleOrders.forEach((t:any) => {
            saleOrderId.push(t.id)
            saleOrderHash[t.id] = t
        })

        //   GET REQUEST BY POST
        BzrAxios.records({url: `/get/BzrPayment`, method: 'POST', data: {where: { saleOrderId }} })
            .then((response:any) => {
                let payments:any = response.data.records
                let pFields:any = response.data.fields
                
                payments.forEach((p:any) => { 
                        
                    p = Object.assign(p, saleOrderHash[p.saleOrderId])
                    if(p.billedCurrency !== '840') {
                        p.amount = p.amount/p.changeRate
                        p.tax1 = p.tax1/p.changeRate
                        p.balance = p.balance/p.changeRate
                    }
                    p.totalUSD = p.tax1 + p.subTotalUSD
                    const seller:any = this.state.individuals.find((ind:any) => ind.id === p.sellerId)
                    p.sellerName = seller ? `${seller.firstName} ${seller.middleName} ${seller.lastName}` : ''
                    p.sellerCommission = p.sellerCommission * 100
                })
                this.setState({payments, pFields})
            })
            .catch(err => console.log(err))
    }
    checkOrderStatus(order:any) {
        const prefixes:any = this.props.appStates.activeBU.preferences.prefixes[this.props.appStates.activeBU.locale],
            match:any = order.status.match(/credit|debit/)
        //console.log(prefixes)

        if(!!match) order.status = match[0]
        switch(order.status) {
            case 'printed_legal':
                return `${prefixes.pl}-${order.billNumber}`
            case 'printed_receipt':
                return `${prefixes.pr}-${order.orderNumber}`
            case 'printed_consignment':
                return `CI-${order.orderNumber}`
            case 'issued_legal':
                return `${prefixes.il}-${order.billNumber}`
            case 'issued_receipt':
                return `${prefixes.ir}-${order.orderNumber}`
            case 'invoice_no_printed':
                return `${prefixes.or}-${order.orderNumber}`
            case 'as_quotation':
                return `${prefixes.aq}-${order.orderNumber}`
            case 'as_proforma':
                return `${prefixes.ap}-${order.orderNumber}`
            case 'void_legal':
                return `${prefixes.vl}-${order.billNumber}`
            case 'void_receipt':
                return `${prefixes.vr}-${order.orderNumber}`
            case 'credit':
                return `${prefixes.cr}-${order.creditNumber}`
            case 'debit':
                return `${prefixes.db}-${order.debitNumber}`
            default:
                return `${prefixes.or}-${order.orderNumber}`
        }
    }
    checkBillStatus(order:any) {
        const prefixes:any = this.props.appStates.activeBU.preferences.prefixes[this.props.appStates.activeBU.locale],
            match:any = order.status.match(/credit|debit/)

        if(!!match) order.status = match[0]
        switch(order.status) {
            case 'printed_legal':
                return `FI-${order.billNumber}`
            case 'void_legal':
                return `FA-${order.billNumber}`
            case 'credit':
                return `${prefixes.cr}-${order.creditNumber}`
            case 'debit':
                return `${prefixes.db}-${order.debitNumber}`   
            default:
                return ''
        }
    }
    checkPaymentStatus(status:string) {
        switch(status) {
            case 'not_paid':
                return `NO`
            case 'complete_paid':
                return `OK`
            case 'partial_paid':
                return `PARTIAL` 
            default:
                return ''
        }
    }
    async printPDF(docType:string, record:any, options:any = null) {
        this.setState({selectedTransac: record})
        const id:string = record.id
        let response:any = null
        this.setState({lateralForm: null})
        // Get SaleOrder & ReportAlfa
        let saleOrder:any, client:any, transacs:any
        if(this.state.repository[id]) {
            saleOrder = this.state.repository[id].saleOrder
            client = this.state.repository[id].client
            transacs = this.state.repository[id].transacs
        } else {
            this.setState({fetching: true})
            try {
                response = await BzrAxios.records({url: `/SaleOrders/${id}`})
                console.log(response.data)
            } catch(err) {
                console.error(err)
                alert('wrong request - wrong sale saleOrder')
                return
            }
            saleOrder = response.data.saleOrder
            client = response.data.client
            transacs = response.data.transacs
            this.setState({repository: {...this.state.repository, [saleOrder.id]: response.data}})
        }

        transacs.forEach((t:any) => {
            const product:any = Object.assign({}, this.props.products.find((p:any) => p.id === t.bzrProductId))
            delete product.id
            t = Object.assign(t, product)
        })
        console.log(this.props.products, transacs)
        //console.log(company, client, saleOrder, transacs, locales[saleOrder.locale])
        setTimeout(() => {
            switch(docType) {
                case 'sale_order':
                    let blob:any
                    if(!!saleOrder.status.match(/credit|debit/)) blob = printCreditDebitNote(saleOrder, transacs, client, {letterhead: options.letterhead, mediaType: options.mediaType, locale: this.props.appStates.activeBU.locale, billedIn: locales[currencyHash[saleOrder.billedCurrency].code]})
                    else blob = printSaleOrder(saleOrder, transacs, client, {letterhead: options.letterhead, mediaType: options.mediaType, locale: this.props.appStates.activeBU.locale, billedIn: locales[currencyHash[saleOrder.billedCurrency].code]})
                    const pdfURL = URL.createObjectURL(blob)
                    this.setState({pdfURL, showPreview: true, fetching: false})
                    break
                case 'delivery_note':
                    options = this.state.options
                    options.user = `${this.props.appStates.user.firstName}, ${this.props.appStates.user.lastName}`
                    options.locale = this.props.appStates.activeBU.locale
                    const blobDN = printDeliveryNote(saleOrder, transacs, client, options)
                    this.setState({pdfURL: URL.createObjectURL(blobDN), showPreview: true, fetching: false})
                    break
                default:
                    return
            }
        }, 250)
    }
    report(eventKey:any, event:any) {
        let bzrOrderId:string[] = []
        this.state.filteredTransacs.forEach((t:any) => {
            if(t.cc) {
                this.sales_hash[t.id] = t
                bzrOrderId.push(t.id)
            }
        })
        if(bzrOrderId.length === 0) {
            alert(t('For every report in Bizor just:\n\n Filter -> Select -> Generate \n\n That\'s it!!! '))
            return
        }
        this.setState({showReports: eventKey})

        //   GET REQUEST BY POST
        BzrAxios.records({url: `/get/BzrSaleTransac`, method: 'POST', data: {where: { bzrOrderId }} })
            .then((response:any) => {
                //this.totalizeGlobals(response.data.records)
                
                switch(this.state.showReports) {
                    case 'product_totals':
                        this.setState({showReports:  <ProductTotals 
                            transacs={response.data.records} 
                            range={this.state.range} 
                            locale={this.props.appStates.activeBU.locale} 
                            products_hash={this.props.appStates.products_hash} />})
                        break
                    case 'client_totals':
                        this.setState({showReports:  <ClientTotals 
                            transacs={response.data.records} 
                            sales_hash={this.sales_hash} 
                            range={this.state.range} 
                            locale={this.props.appStates.activeBU.locale} 
                            products_hash={this.props.appStates.products_hash} 
                            clients_hash={this.clients_hash}
                            urlParams={{oid: this.props.appStates.org.id, bid: this.props.appStates.activeBU.id}}
                            />})
                            break
                    case 'location_totals':
                        this.setState({showReports:  <LocationTotals 
                            transacs={response.data.records} 
                            sales_hash={this.sales_hash} 
                            range={this.state.range} 
                            locale={this.props.appStates.activeBU.locale} 
                            products_hash={this.props.appStates.products_hash} 
                            clients_hash={this.clients_hash}
                            urlParams={{oid: this.props.appStates.org.id, bid: this.props.appStates.activeBU.id}}
                            />})
                        break
                }
            })
            .catch(err => console.log(err))
    }
    reportsMenu() {
        return(
            <Container className="small pb-4" style={{minWidth: "250px"}}>
                <Row>
                    <Col md={11}>
                        <h6>Reports</h6>
                        <Dropdown.Item eventKey='product_totals' onSelect={this.report}>{t('totals per product')}</Dropdown.Item>
                        <Dropdown.Item eventKey='client_totals' onSelect={this.report}>{t('totals per client')}</Dropdown.Item>
                        {/* <Dropdown.Item eventKey='location_totals' onSelect={this.report}>{t('totals per location')}</Dropdown.Item> */}
                        {/* <Dropdown.Item eventKey='payments' onSelect={this.report}>{t('payments report')}</Dropdown.Item> */}
                    </Col>
                </Row>
            </Container>
        )
    }
    deleteOrder(id:string) {
        this.setState({processing: 'deleting...', showPreview: false, selectedTransac: null})
        BzrAxios.records({url: `/SaleOrders/${id}`, method: 'DELETE'})
        .then((response:any) => {
            //console.log(response.data)

            let saleOrders:any = this.state.saleOrders
            const index = saleOrders.findIndex((r:any) => r.id === id)
            if(index > -1) saleOrders.splice(index, 1)
            this.setState({processing: null, saleOrders})
        })
    }
    renderLateralActions(record:any, options:any) {
        if(!!record.orderStatus.match(/credit|debit/)) return null
        return(
            <Nav className="bzr-lateral-nav">
                { record.orderStatus === 'issued_legal' ? 
                <Nav.Link onClick={() => this.handlePrintPhysical(record)}>{t('print physical')}</Nav.Link> : null}
                { record.orderStatus === 'issued_legal' ? <hr />: null}
                <Nav.Link onClick={() => this.printPDF('sale_order', record, {letterhead: false, mediaType: 'paper-letter'})}>
                    {t('show')} {t(`${this.status[record.orderStatus.match(/credit|debit/) ? record.orderStatus.match(/credit|debit/)[0] : record.orderStatus]}`)}
                </Nav.Link>
                <hr />
                <Nav.Link onClick={() => this.printPDF('sale_order', record, {letterhead: true, mediaType: 'digital-letter'})}>{t('show digital format')}</Nav.Link>
                <hr />
                <Nav.Link onClick={() => this.printPDF('delivery_note', record)}
                    disabled={record.orderStatus !== 'printed_legal' && record.orderStatus !== 'printed_receipt' && record.orderStatus !== 'printed_consignment'}
                    >{t('print delivery note')}
                </Nav.Link>
                    <Form className="small text-left pl-3">
                        <Form.Check 
                            custom
                            checked={options.letterhead}
                            type='checkbox'
                            id={`cb-letterhead-l`}
                            label={t('print letterhead')}
                            onChange={(event:any) => this.setState({options: Object.assign(options, {letterhead: event.target.checked})})}
                        />
                        <Form.Check 
                            custom
                            checked={options.printLocal}
                            type='checkbox'
                            id={`cb-amount-usd-l`}
                            label={t('print in local currency')}
                            onChange={(event:any) => {
                                if(event.target.checked) {
                                    options.printLocal = true
                                    options.printUsd = false
                                    options.currencyCode = 928
                                    this.setState({options})
                                } else this.setState({options: Object.assign(options, {printLocal: false, currencyCode: 928})})
                            }}
                        />
                        <Form.Check 
                            custom
                            checked={options.printUsd}
                            type='checkbox'
                            id={`cb-amount-local-l`}
                            label={t('print in USD')}
                            onChange={(event:any) => {
                                if(event.target.checked) {
                                    options.printUsd = true
                                    options.printLocal = false
                                    options.currencyCode = 840
                                    this.setState({options})
                                } else this.setState({options: Object.assign(options, {printUsd: false, currencyCode: 840})})
                            }}
                        />
                    </Form>
                <hr />
                {record.orderStatus === 'printed_legal' || record.orderStatus === 'printed_receipt' ?
                    <Nav.Link onClick={() => this.setState({lateralForm: 'register_payment'})}>{t('register payment')}</Nav.Link>  : null}
                {record.orderStatus === 'printed_consignment' ?
                    <Nav.Link onClick={() => this.setState({lateralForm: 'bill_consignment'})}>{t('consignment relation')}</Nav.Link>  : null}
                {record.orderStatus === 'printed_consignment' ?
                    <Nav.Link onClick={() => this.props.history.push(`/admin/consignments/bill/${record.id}`)}>{t('bill consignment')}</Nav.Link>  : null}
                {record.orderStatus === 'printed_legal' ?
                    <Nav.Link onClick={() => this.setState({lateralForm: 'credit_debit_note', showPreview: false})} >{t('credit/debit note')}</Nav.Link> : null}
                {record.orderStatus === 'as_proforma' || record.orderStatus === 'as_quotation' || record.orderStatus === 'as_sale_order' || record.orderStatus === 'issued_legal' ? 
                    <Nav.Link onClick={() => this.props.history.push(`/admin/sales/modify/${record.id}`)}>{t('modify')}/{t('print')}</Nav.Link> : null}
                <hr />
                <Nav.Link onClick={() => this.setState({lateralForm: 'void_invoice'}) }
                    disabled={record.orderStatus !== 'printed_legal' && record.orderStatus !== 'printed_receipt'}>{t('void')} {t('invoice')}/{t('receipt')}
                </Nav.Link>
                { 
                (record.orderStatus === 'printed_legal' || record.orderStatus === 'printed_receipt' || record.orderStatus === 'printed_consignment' || record.orderStatus === 'void_legal' || record.orderStatus === 'void_receipt') 
                ?
                null : <Nav.Link onClick={() => this.deleteOrder(record.id)}>{t('eliminar')}</Nav.Link>
                }
            </Nav>
        )
    }
    switchForm() {
        switch(this.state.lateralForm) {
            case 'void_invoice':
                return (
                    <VoidInvoice 
                        order={this.state.selectedTransac}
                        isSubmitting={(s:any) => {
                            if(s) this.setState({processing: 'void...', showPreview: false})
                            else this.setState({processing: null})
                        }} 
                        isSubmitted={() => {
                            this.setState({selectedTransac: null})
                            this.getSaleOrders()
                        }}
                        extras={{orgId: this.props.appStates.org.id, activeBUId: this.props.appStates.activeBU.id}}
                        />
                )
            default:
                return null
        } 
    }   
    render() {
        return (
            <Container id='sale-order-trasacs' className="mx-auto m-3 p-3 bg-white">
                <Row>
                    <Col md={6} className="p-4 text-center">
                        
                    </Col>
                    <Col md={6}>
                        <ButtonGroup className="pl-3">
                            <Button variant="outline-info" onClick={() => { 
                                const d:any = new Date()
                                this.setState({range: {from: new Date(d.getFullYear(), d.getMonth(), d.getDate() - (d.getDay() === 0 ? 6 : d.getDay() - 1 )) , to: new Date()}}, 
                                () => this.getSaleOrders())
                                }}>current week</Button>
                            <Button variant="outline-info" onClick={() => { 
                                const d:any = new Date()
                                this.setState({range: {from: new Date(d.getFullYear(), d.getMonth(), 1), to: new Date()}}, () => this.getSaleOrders())
                                }}>current month</Button>
                            <DropdownButton as={ButtonGroup} title={t('by month')} id="month-dropdown" variant="outline-info">
                                {(() => {
                                    let el:any[] = []
                                    const d:any = new Date(),
                                        yy = d.getFullYear()
                                    for(let m = d.getMonth() - 1; m > 0; m--) {
                                        el.push(
                                        <Dropdown.Item key={`${m}`}  eventKey={`${m}`} onClick={() => { 
                                            this.setState({range: { from: new Date(yy, m, 1), to: new Date(yy, m + 1, 0)}}, () => this.getSaleOrders()) }}
                                            >
                                            {t(months[m])}
                                        </Dropdown.Item>)
                                    }
                                    return el
                                })()}
                            </DropdownButton>
                        </ButtonGroup>                       
                        <div className="d-flex p-3">
                            <div className="pb-2 pt-2">
                                <InputGroup className={`date-picker`} size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text id="from-date">{t('from')}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <DatePicker
                                        name={t('from')}
                                        className='form-control'
                                        dateFormat={locales[this.props.appStates.activeBU.locale].dateFormat}
                                        selected={this.state.range.from}
                                        onChange={(date) => this.handleDateChange(date, 'from')}
                                        locale={this.props.appStates.activeBU.locale.slice(0,2)}
                                        placeholderText=""
                                        />
                                </InputGroup>
                            </div>
                            <div className="p-2">
                                <InputGroup className={`date-picker`} size="sm">
                                    <InputGroup.Prepend>
                                    <InputGroup.Text id="to-date">{t('to')}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <DatePicker
                                        name={t('to')}
                                        className='form-control'
                                        dateFormat={locales[this.props.appStates.activeBU.locale].dateFormat}
                                        selected={this.state.range.to}
                                        onChange={(date) => this.handleDateChange(date, 'to')}
                                        locale={this.props.appStates.activeBU.locale.slice(0,2)}
                                        placeholderText=""
                                        />
                                </InputGroup>
                            </div>
                            <div className="p-2">
                                <Button variant="outline-secondary" onClick={() => { this.getSaleOrders() }}>{t('search')}</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mx-auto mt-4">
                        <Tabs className="transacs-tabs" defaultActiveKey="sale-transacs" id="transacs-tab">
                            <Tab eventKey="sale-transacs" title={t('sale transacs')}>
                                { 
                                this.state.saleOrders.length > 0 
                                ? 
                                    <BzrOrdersTable 
                                        id="transacs-list"
                                        items={this.state.saleOrders}
                                        fields={this.state.soFields}
                                        liftUpItems={(filteredTransacs:any) => this.setState({filteredTransacs})}
                                        doubleClick={(record:any) => { this.printPDF('sale_order', record, {letterhead: false, mediaType: 'paper-letter'}) }}
                                        updateClick={() => {
                                            this.handleDateChange(new Date(), 'to')
                                            this.getSaleOrders()
                                        }}
                                        refreshing={this.state.refreshing}
                                        extras={{ options: this.state.options }}
                                        reportsMenu={this.reportsMenu}
                                        />
                                : 
                                    null
                                }
                            </Tab>
                            <Tab eventKey="payments" title={t('payments')} >
                                <BzrTable 
                                    id="payments"
                                    items={this.state.payments}
                                    fields={this.state.pFields}
                                    headers={this.defaultHeaders}
                                    liftUpItems={(filteredTransacs:any) => this.setState({filteredTransacs})}
                                    refreshing={this.state.refreshing}
                                    extras={{ options: this.state.options }}
                                    reportsMenu={this.reportsMenu}
                                    />
                                
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
                <MessageModal show={this.state.fetching} message="fetching" />
                <MessageModal show={!!this.state.processing} message={t(this.state.processing)} />
                <LateralBox show={!!this.state.showPreview} >
                    <div className="d-flex mx-2">
                        <div className="flex-grow-1 p-3 menu">
                            <div className="my-2">
                                <i className="fas fa-times" onClick={() => this.setState({showPreview: false})} />
                            </div>
                            <br />
                            <div className="text-center">
                                {this.state.selectedTransac ? this.renderLateralActions(this.state.selectedTransac, this.state.options) : null}
                            </div>
                            <br />
                            <div className="mb-2 text-center text-capitalize btns">
                                <a className="download" role="button" href={this.state.pdfURL}
                                download={"order.pdf"} >
                                {t('download')} </a>{" | "}
                                <a href="#print" onClick={(event:any)=> {
                                    event.preventDefault()
                                    const w:any = window.open()
                                    w.location.href = this.state.pdfURL
                                    setTimeout(() => w.print(), 1500)
                                    }}> 
                                    {t('print')} </a>
                            </div>
                        </div>
                        <div className="m-0 object border-left border-secondary bg-white">
                            {this.state.lateralForm ? 
                            this.switchForm() :
                            <object id="preview-pdf" title="preview" data={this.state.pdfURL} className="" /> 
                            }
                        </div>
                    </div>
                </LateralBox>
                <LateralBox show={this.state.lateralForm === 'credit_debit_note'}>
                    <div className="d-flex lateral-form">
                        <div className="p-3">
                            <i className="fas fa-times" onClick={() => this.setState({lateralForm: null})} />
                        </div>
                        <div className="form-1">
                            {this.state.selectedTransac && this.state.repository[this.state.selectedTransac.id] ? 
                            <CreditDebitNote 
                                fullOrder={this.state.repository[this.state.selectedTransac.id]}
                                appStates={this.props.appStates}
                                onClose={(order:any) => {
                                    this.setState({lateralForm: null})
                                    if(order) {
                                        const r:any = Object.assign({}, order)
                                        order.orderStatus = r.status
                                        order.status = t(this.status[r.status.match(/credit|debit/) ? r.status.match(/credit|debit/)[0] : r.status])
                                        order.orderNumber = this.checkOrderStatus(r)
                                        order.billNumber = this.checkBillStatus(r)
                                        order.billedCurrency = currencyHash[r.billedCurrency].currencySymbol
                                        order.payBefore = new Date(new Date(r.date).getTime() + parseInt(r.paymentCondition)*24*60*60*1000).toISOString()
                                        ///// Special Treatment for USD Amounts
                                        order.subTotalUSD = r.billedCurrency !== '840' ? (parseFloat(r.subTotal)/parseFloat(r.changeRate)) : parseFloat(r.subTotal)
                                        
                                        let saleOrders:any[] = this.state.saleOrders
                                        saleOrders.unshift(order)
                                        this.setState({saleOrders})
                                    }
                                }}
                                />
                            : null}
                        </div>
                    </div>
                </LateralBox>
                <LateralBox show={this.state.lateralForm === 'register_payment'}>
                    <div className="d-flex lateral-form">
                        <div className="p-3">
                            <i className="fas fa-times" onClick={() => this.setState({lateralForm: null})} />
                        </div>
                        <div className="form-1">
                            {this.state.selectedTransac && this.state.repository[this.state.selectedTransac.id] ? 
                            <RegisterPayment 
                                fullOrder={this.state.repository[this.state.selectedTransac.id]}
                                liftUpFullOrder={(fo:any) => {
                                    const repository = this.state.repository
                                    repository[this.state.selectedTransac.id] = fo
                                    this.setState({repository})
                                }}
                                appStates={this.props.appStates}
                                onClose={(order:any) => {
                                    this.setState({lateralForm: null})
                                    if(order) {
                                        const r:any = Object.assign({}, order)
                                        order.orderStatus = r.status
                                        order.status = t(this.status[r.status.match(/credit|debit/) ? r.status.match(/credit|debit/)[0] : r.status])
                                        order.orderNumber = this.checkOrderStatus(r)
                                        order.billNumber = this.checkBillStatus(r)
                                        order.billedCurrency = currencyHash[r.billedCurrency].currencySymbol
                                        order.payBefore = new Date(new Date(r.date).getTime() + parseInt(r.paymentCondition)*24*60*60*1000).toISOString()
                                        ///// Special Treatment for USD Amounts
                                        order.subTotalUSD = r.billedCurrency !== '840' ? (parseFloat(r.subTotal)/parseFloat(r.changeRate)) : parseFloat(r.subTotal)
                                        
                                        let saleOrders:any[] = this.state.saleOrders
                                        saleOrders.unshift(order)
                                        this.setState({saleOrders})
                                    }
                                }}
                                />
                            : null}
                        </div>
                    </div>
                </LateralBox>
                <LateralBox show={this.state.lateralForm === 'bill_consignment'}>
                    <div className="d-flex lateral-form">
                        <div className="p-3">
                            <i className="fas fa-times" onClick={() => this.setState({lateralForm: null})} />
                        </div>
                        <div className="form-1">
                            {this.state.selectedTransac && this.state.repository[this.state.selectedTransac.id] ? 
                            <ConsignmentRelation
                                fullOrder={this.state.repository[this.state.selectedTransac.id]}
                                appStates={this.props.appStates}
                                onClose={(order:any) => {
                                    this.setState({lateralForm: null})
                                }}
                                />
                            : null}
                        </div>
                    </div>
                </LateralBox>
                <LateralBox show={!!this.state.showReports}>
                    <div className="d-flex lateral-form">
                        <div className="p-3">
                            <i className="fas fa-times" onClick={() => this.setState({showReports: null})} />
                        </div>
                        <div className="form-1">
                            {this.state.showReports}
                        </div>
                    </div>
                </LateralBox>
            </Container>
        ) 
    }
}

const LateralBox = (props:any) => {
    const [clss, setClassName] = useState('bzr-lateral-box')
    const box:any = useRef()

    useEffect(() => {
        let cn:any = box.current.className?.replace('show', '')
        if(props.show) setClassName(`${cn} show`)
        else setClassName(cn)
    }, [props.show])

    return ( 
        <div ref={box} className={ clss }>
            {props.children}
        </div>
        )
}

export default withRouter(ReportAlfa)