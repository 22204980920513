import React from 'react'
import BzrAxios from '../../utils/BzrAxios'
import { RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Row, Col, Table } from 'react-bootstrap'
import { MessageModal, ConfirmModal } from '../../layouts/Modals/Modals'
import ImageBox from '../Common/ImageBox'
import { p } from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps {
	history: History<LocationState>,
    appStates: any,
    products: any,
	liftUpAppStates: any,
}

interface States {
    products: any[],
    preview: any,
    image: any,
    pdfFile: any,
    filters: string[],
    loading: boolean,
    trs: any[],
    reactivate: any,
    reactivating: boolean,
    listing: boolean
}

class ProductList extends React.Component<Props,States> {
    loaded = false
    constructor(props: Props) {
        super(props)
        this.state = {
            products: [],
            preview: null,
            image: null,
            pdfFile: null,
            filters: [],
            loading: false,
            trs: [],
            reactivate: null,
            reactivating: false,
            listing: false
        }
        this.selectProduct = this.selectProduct.bind(this)
        this.searchList = this.searchList.bind(this)
    }

/*     componentDidMount() {
        // Get Inactive Products
        this.setState({listing: true})
        BzrAxios.records({url:`/Products`})
            .then((response:any) => {
                console.log('INACTIVEs', console.log(response.data))
                const products:any = response.data.products ?? [],
                    s3 = new AWS.S3({apiVersion: '2006-03-01'})

                products.filter((p:any) => p.isActive === 'false').forEach((product:any, i:number) => {
                    if(product.bzrProductImage) {
                        s3.getObject({Bucket: 'bizor-s3-imgs', Key: `public/${product.bzrProductImage}`}, (err:any, result:any) => {
                            if(err) {
                                console.log(err)
                                return
                            }
                            const blob = new Blob([result.Body], {type: result.contentType})
                            product['imageURL'] = URL.createObjectURL(blob)
                            if (products.length === i + 1) this.setState({products, listing: false})
                        })
                    } else {
                        if (products.length === i + 1) this.setState({products, listing: false})
                    }
                })
                if(products.length === 0) this.setState({products, listing: false})
                console.log('inactive products fetched')
            })
            .catch((err) => console.log(err))
    } */
    componentDidUpdate() {
        if(this.props.products && this.props.products.length !== 0 && this.state.products.length === 0) {
            const products:any = this.props.products.filter((p:any) => p.isActive === 'false'),
                books:any = this.props.appStates.books,
                bookNames:any = this.props.appStates.bookNames
            products.forEach((p:any) => {
                p.cost = books[bookNames.cost.default][p.id] ? books[bookNames.cost.default][p.id].entryValue : 'ND'
                p.price = books[bookNames.price.default][p.id] ? books[bookNames.price.default][p.id].entryValue : 'ND'
            })
            this.setState({products})
        }
        if(this.props.appStates.bookNames && !this.loaded) {
            this.loaded = true
            // Extra cases
        }
    }

    selectProduct(event: any) {
        const id = event.currentTarget.dataset.recordId
        const product = this.props.products.find((product:any) => product.id === id)
        this.setState({preview: product})
    }

    handleReactivate(id:string) {
        this.setState({reactivating: true})
        BzrAxios.records({url: `/BzrProduct/${id}`, method: 'PUT', data: {isActive: 'true'}})
            .then((response:any) => {
                const reactivated = this.state.products.find((p:any) => p.id === id)
                this.props.products.push(reactivated)
                //this.props.liftUpAppStates({products})
                this.setState({products: this.state.products.filter((p:any) => p.id !== id), reactivate: null, reactivating: false})
            })
            .catch((err) => {
                this.setState({reactivating: false})
                console.log(err)
            })
    }
    handleEdit(id:string) {

    }
    renderList() {
        let trs:any[] = []
        if(this.state.products.length > 0) {
            this.state.products.forEach((product:any, key:number) => {
                trs.push(
                    <tr key={key} data-record-id={product.id} onClick={this.selectProduct} >
                        <td>{key + 1}</td>
                        <td>{product.name}<p><b>MODEL:</b> {product.type}</p></td>
                        <td>{product.description}<p><b>SKU: </b>{product.sku}</p><p><b>BARCODE: </b>{product.barcode}</p></td>
                        <td className="text-center">{product.unitOfMeasure}</td>
                        <td className="number-format">{this.fn(product.cost)}</td>
                        <td className="number-format">{this.fn(product.price)}</td>
                        <td style={{maxWidth: "100px"}}>{product.imageURL ? <ImageBox url={product.imageURL} id={product.id} size={75} /> : ''}</td>
                        <td style={{fontWeight: 600}}>
                            <a href="#reactivate" style={{color: 'red'}} onClick={(event:any)=> {
                                event.preventDefault()
                                this.setState({reactivate: product.id})
                                }}>reactivate
                            </a>
                        </td>
                    </tr>
                )
            })
        } else if(this.state.products.length === 0) {
            trs.push(<tr key="1"><td colSpan={9} className="pt-5 text-center"><h5>There are NO inactive products!</h5></td></tr>)
        }
        return trs
    }

    fn(n:any) {
        return Number(n).toFixed(2)
    }

    searchList(event: any) {
        // Declare variables 
        const needle:string = event.target.value.toUpperCase(),
        table:any = document.getElementById("product-list"),
        trs:any = table.getElementsByTagName("tr")
        let products = []
        for(let tr of trs){
            const tName:any = tr.getElementsByTagName("td")[1]
            if(!tName) continue
            const id = tr.dataset.recordId
            const tSkuBar:any = tr.getElementsByTagName("td")[2].getElementsByTagName("p")
            const txtValue:any =  [tName.textContent, tSkuBar[0].textContent, tSkuBar[1].textContent].join(';').replace(/MODEL:|SKU:|BARCODE:/g,'')
            
            if (!needle) {
                products = this.props.products
            } else if (txtValue.toUpperCase().indexOf(needle) > -1) {
                //tr.style.display = ""
                products.push(this.props.products.find((p:any) => p.id === id))
            }
        }
        this.setState({products})
    }

    render() {
        return (
            <div>
                <Row>
                    <Col md={8} className="mr-auto">
                        <div className="d-flex pl-2 mb-4">
                            <div className="flex-fill"><h5><b>Product Search</b></h5></div>
                            <div className="flex-fill pt-2">
                                <div className="">
                                    <input type="text" id="search-box" onKeyUp={this.searchList} placeholder="Search..." />
                                </div>
                            </div>
                        </div>
                        <div className="pl-2 font-icon-detail">
                            
                            <span className="pe-7s-more text-center" onClick={() => this.setState({loading: !this.state.loading})} ></span>
                        </div> 
                        <h3>inactive products</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mx-auto mt-4">
                        <Table id="product-list" className="items" size="sm" responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th style={{width: "150px"}}>name</th>
                                    <th style={{width: "300px"}}>description</th>
                                    <th style={{width: "40px"}}>unit</th>
                                    <th className="text-right">cost</th>
                                    <th className="text-right">price</th>
                                    <th className="text-center" style={{width: "100px"}}>image</th>
                                    <th className="text-center" style={{width: "100px"}}>actions</th>
                                </tr>
                            </thead>
                            <tbody id="product-list-tbody">
                                { this.state.listing 
                                    ? 
                                    <tr><td colSpan={9} className="pt-5 text-center"><h5>listing... <FontAwesomeIcon icon={faSpinner} spin /></h5></td></tr> 
                                    : 
                                    this.renderList() }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <MessageModal show={this.state.loading} message="" />
                <ConfirmModal 
                    show={!!this.state.reactivate} 
                    title="reactivate confirmation"
                    submitting={this.state.reactivating}
                    onClick={() => this.handleReactivate(this.state.reactivate)}
                    confirmtitle="reactivate product" 
                    onHide={() => { this.setState({reactivate: null}) }} 
                    />
            </div>
        ) 
    }
}

export default ProductList