import React from "react"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import logo from '../../../assets/img/wasmer-logo.png'
import { I18n } from 'aws-amplify';

interface Props {
}

interface State {
    scrollClass: string,
    show: boolean
}

class TopNavbar extends React.Component<Props, State>{
    constructor(props: any) {
        super(props);
        this.state = {
            scrollClass: "",
            show: false
		}
        this.handleChange = this.handleChange.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
	}
	componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}
    
	handleChange(event:any, lang:string, setLanguage:any) {
		const language:string = event.target.value
		I18n.setLanguage(language)
	}
	
	handleScroll(event: any) {
        let scrollTop = window.scrollY;
        if(scrollTop > 100) {
            this.setState({scrollClass: "scrolled-nav"});
        } else {
            this.setState({scrollClass: ""});
        }
    }

    render() {
        return ( 
            <Navbar id="top-navbar"
                    className={"guest-navbar navbar-custom " + this.state.scrollClass}
                    collapseOnSelect 
                    expand="lg" 
                    sticky="top">
               
                <Navbar.Brand className="brz-brand" href="#wasmer">
                    <img
                        alt="Bizor Logo"
                        src={logo}
                        width="160"
                        height="43"
                        className="d-inline-block align-top"
                        style={{marginRight: "2px"}}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => this.setState({show: !this.state.show}) } />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto text-capitalize">
                        <Nav.Link href="#features">features</Nav.Link>
                        <Nav.Link href="#preview">preview</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default TopNavbar