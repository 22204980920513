import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Form, Card, Alert } from 'react-bootstrap'
import { History, LocationState } from "history"
import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	liftUpAppStates: any,
	formType: any,
	params: any,
	idn: any,
	comps?: any
}

interface States {
	formError: any,
	disableFields: boolean,
	formState: any
}

class CardBox extends React.Component<Props,States> {

	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null,
			disableFields: !this.props.singleSubmit,
			formState: {}
		}
		this.disableFields = this.disableFields.bind(this)
	}

	disableFields(state:boolean) {
		this.setState({disableFields: state})
	}

	children() {
		if(!this.props.comps) return null
		let children:any[] = []
		this.props.comps.forEach(
			(comp:any, key:any) => children.push(
				<comp.component
					key={this.props.idn + key}
					idn={this.props.idn}
					disableFields={this.state.disableFields} 
					disableFieldsHandler={this.disableFields}
					singleSubmit={this.props.singleSubmit}
					appStates={this.props.appStates} 
					liftUpAppStates={this.props.liftUpAppStates}
					params = {comp.params}
					comps = {comp.children ?? null}
				/>
			)
		)
		return children
	}

	render() {
		return (
			<Card className={this.props.params.className}>
				<Card.Body>
					<div className="d-flex justify-content-between mb-3">
						<div className="p-2"><h4>{t(this.props.params.title)}</h4></div>
						<div className="p-2" style={ this.props.singleSubmit ? {display: "none"} : {display: "block"}}>  
							<Form>
								<Form.Check 
									checked={!this.state.disableFields}
									onChange={()=> this.setState({disableFields: !this.state.disableFields})}
									type="switch"
									id={this.props.params.id + "-switch-id-" + this.props.idn}
									label="Edit"
								/>
							</Form>
						</div>
					</div>
					{
						this.state.formError !== null ? 
						(<Alert variant={'danger'} onClose={() => this.setState({formError: null})} dismissible>
							<Alert.Heading>Oh snap! You got an error!</Alert.Heading>
							{this.state.formError.code}: {this.state.formError.message}
						</Alert>) : null
					} 
					<div className="mx-0">
						{this.children()}
					</div>
				</Card.Body>
			</Card>
		)
	}
}
export default CardBox