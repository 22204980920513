import React from 'react'
import { History, LocationState } from "history"
import { withRouter, RouteComponentProps } from 'react-router-dom'
import BzrAxios from '../../utils/BzrAxios'
import { Formik } from 'formik'
import { Form, Container, Row, Col, Button, Table, Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'
import t, { HelperMsg } from '../../utils/I18n/I18n'
import XLSX from 'xlsx'

/*
  Simple HTML5 file drag-and-drop wrapper
  usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
    handleFile(file:File):void;
*/
interface FileProps {
    handleFile?:any
    cols?:any
    data?:any
}
class DragDropFile extends React.Component<FileProps> {
	constructor(props:any) {
		super(props);
		this.onDrop = this.onDrop.bind(this);
	};
	suppress(evt:any) { evt.stopPropagation(); evt.preventDefault(); };
	onDrop(evt:any) { evt.stopPropagation(); evt.preventDefault();
		const files = evt.dataTransfer.files;
		if(files && files[0]) this.props.handleFile(files[0]);
	};
	render() { 
        return (
            <div onDrop={this.onDrop} onDragEnter={this.suppress} onDragOver={this.suppress}>
                {this.props.children}
            </div>
        )
    }
}

/*
  Simple HTML5 file input wrapper
  usage: <DataInput handleFile={callback} />
    handleFile(file:File):void;
*/
class DataInput extends React.Component<FileProps> {
	constructor(props:any) {
		super(props)
		this.handleChange = this.handleChange.bind(this)
	}
	handleChange(e:any) {
		const files = e.target.files
		if(files && files[0]) this.props.handleFile(files[0])
	}
	render() { 
        return (
            <form className="form-inline">
                <div className="form-group">
                    <input type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
                </div>
            </form>
        )
    }
}

/*
  Simple HTML Table
  usage: <OutTable data={data} cols={cols} />
    data:Array<Array<any> >;
    cols:Array<{name:string, key:number|string}>;
*/
class OutTable extends React.Component<FileProps> {
	render() { 
        return (
            <Table bordered striped responsive>
                <thead>
                    <tr>{this.props.cols.map((c:any) => <th key={c.key}>{c.name}</th>)}</tr>
                </thead>
                <tbody>
                    {this.props.data.map((r:any,i:number) => <tr key={i}>
                        {this.props.cols.map((c:any) => <td key={c.key}>{ r[c.key] }</td>)}
                    </tr>)}
                </tbody>
            </Table>
        )
    }
}

/* generate an array of column objects */
const make_cols = (refstr:any) => {
    let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1
    console.log()
    for(var i = 0; i < C; ++i) o[i] = {name:XLSX.utils.encode_col(i), key:i}
	return o
};
/* list of supported file types */
const SheetJSFT = [
	"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
].map(function(x) { return "." + x }).join(",")

//////////////

interface Props extends RouteComponentProps<LocationState> {
    history:History<LocationState>
    appStates:any
    products:any
    liftUpAppStates:any
    product?:any
}

interface State {
    product:any
    warehouses:any
    data: any[] /* Array of Arrays e.g. [["a","b"],[1,2]] */
    cols: any[]  /* Array of column objects e.g. { name: "C", K: 2 } */
}

class NewInventory extends React.Component<Props,State> {
    constructor(props:Props) {
        super(props)
        this.state = {
            product: null,
            warehouses: null,
            data: [],
            cols: []
        }
        this.handleAddWarehouse = this.handleAddWarehouse.bind(this)
        this.handleAddInventoryBook = this.handleAddInventoryBook.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.handleLoadInventory = this.handleLoadInventory.bind(this)
    }

    async componentDidMount() {

        //  Get Warehouses
        try {
            const response:any = await BzrAxios.records({url: `/BzrWarehouse`})
            this.setState({warehouses: response.data.records})
            //this.props.liftUpAppStates({parties: response.data.records})
        } catch(err) {
            console.error(err)
            return
        }
    }

    componentDidUpdate() {
    }

    handleFile(file:File) {
		/* Boilerplate to set up FileReader */
		const reader = new FileReader()
		const rABS = !!reader.readAsBinaryString
		reader.onload = (e:any) => {
			/* Parse data */
			const bstr = e.target.result
			const wb = XLSX.read(bstr, {type:rABS ? 'binary' : 'array'})
			/* Get first worksheet */
			const wsname = wb.SheetNames[0]
            const ws = wb.Sheets[wsname]
			/* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, {header:1}),
                cols = make_cols(ws['!ref'])

            console.log(cols,data)
            /* Update state */
			this.setState({ data, cols })
		}
		if(rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file)
	}

    async handleAddWarehouse(data:any, formikBag:any) {
        //this.props.liftUpdata(data)
        data.creatorId = data.updaterId = this.props.appStates.user.id
        data.index = this.state.warehouses.length
        data.isActive = true
        //  POST Entry

        try {
            const response:any = await BzrAxios.records({url: `/BzrWarehouse`, method: 'POST', data})
            this.setState({warehouses: this.state.warehouses.concat(response.data.warehouse)})
            formikBag.resetForm()
            //this.props.liftUpAppStates({parties: response.data.records})
        } catch(error) {
            //console.error(error, error.response)
            if(error.response && error.response.status === 409) alert(`${error.response.statusText}: ${error.response.data}`)
            
            return
        }        
    }
    async handleAddInventoryBook(data:any, formikBag:any) {
        data.index = this.props.appStates.bookNames.inventory ? Object.keys(this.props.appStates.bookNames.inventory).length : 0
        data.isActive = true
        //  POST Entry

        try {
            const response:any = await BzrAxios.records({url: `/BzrInventoryBook`, method: 'POST', data}),
            bookNames:any = this.props.appStates.bookNames

            const inventoryBook:any = response.data.inventoryBook

            bookNames.inventory[inventoryBook.inventoryBookName] = inventoryBook.id
            bookNames.inventory_hash[inventoryBook.id] = inventoryBook

            console.log(response.data)
            formikBag.resetForm()

            this.props.liftUpAppStates({bookNames})
        } catch(error) {
            //console.error(error, error.response)
            if(error.response.status === 409) {
                alert(`${error.response.statusText}: ${error.response.data}`)
                return
            } else {
                console.log(error)
            }
        }
    }
    async handleLoadInventory(data:any) {
        data.data = this.state.data
        data.orderNumber = data.orderNumber ?? new Date().getTime().toString().slice(2,10)
        data.priceBookNames = this.props.appStates.bookNames.price
        try {
            const response:any = await BzrAxios.records({
                url: `/InventoryBulkLoad`,
                method: 'POST',
                data
            })
            console.log(response.data)
        } catch(error) {
            console.log(error)
        }
    }

    render() {
        return(
            <Container>
                <Row>
                    <Col md={6}>
                        <h4>warehouses</h4>
                        <Table bordered hover className="my-4">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>name</th>
                                    <th>alias</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.warehouses ? this.state.warehouses.sort((a:any, b:any) => a.index - b.index).map((w:any, k:number) => (<tr key={k}><td>{parseInt(w.index) + 1}</td><td>{w.warehouseName}</td><td>{w.alias}</td></tr>)) 
                                    : <tr><td colSpan={3}><h6 className="text-center">{t('loading')}<FontAwesomeIcon icon={faSpinner} spin /></h6></td></tr>}
                            </tbody>
                        </Table>
                        <Accordion>
                            <Accordion.Toggle as={Button} variant="outline-primary" eventKey="1" className="border font-weight-bold text-capitalize mb-3">
                                add new warehouse
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Formik
                                    validationSchema={
                                        Yup.object({
                                            warehouseName: Yup.string().matches(/^\w+$/).required(),
                                            alias: Yup.string().required()
                                        })
                                    }
                                    onSubmit={this.handleAddWarehouse}
                                    initialValues={{
                                        warehouseName: '',
                                        alias: '', 
                                        warehouseType: 'default'
                                    }}
                                    enableReinitialize={true}
                                    >
                                    {
                                        ({
                                            handleSubmit,
                                            handleChange,
                                            isSubmitting,
                                            values,
                                            touched,
                                            errors
                                            }) => (                                        
                                                <Form noValidate onSubmit={handleSubmit} id={'new-warehouse-form'}>
                                                    <FormInputs
                                                        ncols={['10']}
                                                        className={['px-2']}
                                                        properties={[
                                                            {
                                                                label: 'name',
                                                                horizontalLabel: '6',
                                                                feedback: ' ',
                                                                fieldType: 'text',
                                                                name: 'warehouseName',
                                                                className: 'text-right',
                                                                value: values.warehouseName,
                                                                onChange: handleChange,
                                                                isValid: touched.warehouseName && !errors.warehouseName,
                                                                isInvalid: !!errors.warehouseName,
                                                                error: errors.warehouseName
                                                            }
                                                        ]}
                                                    />
                                                    <FormInputs
                                                        ncols={['10']}
                                                        className={['px-2']}
                                                        properties={[
                                                            {
                                                                label: 'alias',
                                                                horizontalLabel: '6',
                                                                feedback: ' ',
                                                                fieldType: 'text',
                                                                name: 'alias',
                                                                className: 'text-right',
                                                                value: values.alias,
                                                                onChange: handleChange
                                                            }
                                                        ]}
                                                    />
                                                    <FormInputs
                                                        ncols={['10']}
                                                        className={['px-2']}
                                                        properties={[
                                                            {
                                                                label: 'warehouse type',
                                                                horizontalLabel: '6',
                                                                fieldType: 'select',
                                                                name: 'warehouseType',
                                                                value: values.warehouseType,
                                                                options: [
                                                                    ['default', 'products'],
                                                                    ['raw-materials', 'raw materials'],
                                                                    ['finished-products', 'finished products']
                                                                ],
                                                                onChange: handleChange
                                                            }
                                                        ]}
                                                    />
                                                    <div className="py-3 text-center">
                                                        <Button 
                                                            type='submit' 
                                                            id='warehouse-submit' 
                                                            style={ isSubmitting ? {display: 'none'}: {display: 'inline'} } >
                                                                Submit
                                                        </Button>
                                                        {
                                                            isSubmitting ? <div><h5>{t('submitting')}...<FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
                                                        }
                                                    </div>
                                                </Form>
                                            )
                                    }
                                </Formik>
                            </Accordion.Collapse>
                        </Accordion>
                    </Col>
                    <Col md={6}>
                        <h4>inventory books</h4>
                        <Table bordered hover className="my-4">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>name</th>
                                    <th>alias</th>
                                    <th>description</th>
                                    <th>warehouse</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.props.appStates.bookNames && this.props.appStates.bookNames.inventory_hash ? 
                                    Object.entries(this.props.appStates.bookNames.inventory_hash)
                                        .sort((a:any, b:any) => a[1].index - b[1].index)
                                        .map((b:any, k:number) => (
                                            <tr key={k}>
                                                <td>{parseInt(b[1].index) + 1}</td>
                                                <td>{b[1].inventoryBookName}</td>
                                                <td>{b[1].alias}</td>
                                                <td>{b[1].description}</td>
                                                <td>{this.state.warehouses ? this.state.warehouses.find((w:any) => w.id === b[1].bzrWarehouseId).alias : ''}</td>
                                            </tr>
                                            )
                                        ) 
                                    : <tr><td colSpan={5}><h6 className="text-center">{t('loading')}<FontAwesomeIcon icon={faSpinner} spin /></h6></td></tr>}
                            </tbody>
                        </Table>
                        <Accordion>
                            <Accordion.Toggle as={Button} variant="outline-primary" eventKey="1" className="border font-weight-bold text-capitalize mb-3">
                                add new inventory book
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Formik
                                    validationSchema={
                                        Yup.object({
                                            inventoryBookName: Yup.string().matches(/^\w+$/).required(),
                                            alias: Yup.string().required()
                                        })
                                    }
                                    onSubmit={this.handleAddInventoryBook}
                                    initialValues={{
                                        inventoryBookName: '',
                                        alias: '',
                                        description: '',
                                        inventoryType: 'default',
                                        bzrWarehouseId: ''
                                    }}
                                    enableReinitialize={true}
                                    >
                                    {
                                        ({
                                            handleSubmit,
                                            handleChange,
                                            isSubmitting,
                                            values,
                                            touched,
                                            errors
                                            }) => (
                                                <Form noValidate onSubmit={handleSubmit} id={'new-inventory-form'}>
                                                    <FormInputs
                                                        ncols={['10']}
                                                        className={['px-2']}
                                                        properties={[
                                                            {
                                                                label: 'name',
                                                                horizontalLabel: '6',
                                                                feedback: ' ',
                                                                fieldType: 'text',
                                                                name: 'inventoryBookName',
                                                                className: 'text-right',
                                                                value: values.inventoryBookName,
                                                                onChange: handleChange,
                                                                isValid: touched.inventoryBookName && !errors.inventoryBookName,
                                                                isInvalid: !!errors.inventoryBookName,
                                                                error: errors.inventoryBookName
                                                            }
                                                        ]}
                                                    />
                                                    <FormInputs
                                                        ncols={['10']}
                                                        className={['px-2']}
                                                        properties={[
                                                            {
                                                                label: 'alias',
                                                                horizontalLabel: '6',
                                                                feedback: ' ',
                                                                fieldType: 'text',
                                                                name: 'alias',
                                                                className: 'text-right',
                                                                value: values.alias,
                                                                onChange: handleChange
                                                            }
                                                        ]}
                                                    />
                                                    <FormInputs
                                                        ncols={['10']}
                                                        className={['px-2']}
                                                        properties={[
                                                            {
                                                                label: 'description',
                                                                horizontalLabel: '6',
                                                                feedback: ' ',
                                                                fieldType: 'text',
                                                                name: 'description',
                                                                className: 'text-right',
                                                                value: values.description,
                                                                onChange: handleChange
                                                            }
                                                        ]}
                                                    />
                                                    <FormInputs
                                                        ncols={['10']}
                                                        className={['px-2']}
                                                        properties={[
                                                            {
                                                                label: 'inventory type',
                                                                horizontalLabel: '6',
                                                                fieldType: 'select',
                                                                name: 'inventoryType',
                                                                value: values.inventoryType,
                                                                options: [
                                                                    ['default', 'products'],
                                                                    ['raw-materials', 'raw materials'],
                                                                    ['finished-products', 'finished products']
                                                                ],
                                                                onChange: handleChange
                                                            }
                                                        ]}
                                                    />
                                                    <FormInputs
                                                        ncols={['10']}
                                                        className={['px-2']}
                                                        properties={[
                                                            {
                                                                label: 'warehouse',
                                                                horizontalLabel: '6',
                                                                fieldType: 'select',
                                                                name: 'bzrWarehouseId',
                                                                value: values.bzrWarehouseId,
                                                                options: (() => {
                                                                    const ops:any = this.state.warehouses ? this.state.warehouses.sort((a:any, b:any) => a.index - b.index).map((w:any) => [w.id, w.warehouseName]) : []
                                                                    if(ops.length > 0) values.bzrWarehouseId = ops[0][0]
                                                                    return ops
                                                                })(),
                                                                onChange: handleChange
                                                            }
                                                        ]}
                                                    />
                                                    <div className="py-3 text-center">
                                                        <Button 
                                                            type='submit' 
                                                            id='inventory-submit' 
                                                            style={ isSubmitting ? {display: 'none'}: {display: 'inline'} } >
                                                                Submit
                                                        </Button>
                                                        {
                                                            isSubmitting ? <div><h5>{t('submitting')}...<FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
                                                        }
                                                    </div>
                                                </Form>
                                            )
                                    }
                                </Formik>
                            </Accordion.Collapse>
                        </Accordion>              
                    </Col>
                </Row>
                <hr />
                <Row>
                <Col md={12}>
                        <Formik
                            onSubmit={this.handleLoadInventory}
                            initialValues={{
                                costBookId: '',
                                priceBookId: '',
                                currency: '840',
                                bzrInventoryBookId: '',
                                orderNumber: ''
                            }}
                            enableReinitialize={true}
                            >
                            {
                                ({
                                    handleSubmit,
                                    handleChange,
                                    isSubmitting,
                                    values
                                    }) => (
                                        <div>
                                            <h4>Load Inventory</h4>
                                            <Form noValidate onSubmit={handleSubmit} id={'load-inventory-form'} className="my-3">
                                                <Row>
                                                    <Col md={5}>
                                                            <FormInputs
                                                            ncols={['12','12','12']}
                                                            className={['px-2','px-2','px-2']}
                                                            properties={[
                                                                {
                                                                    label: 'Cost Book',
                                                                    horizontalLabel: "5",
                                                                    fieldType: 'select',
                                                                    name: 'costBookId',
                                                                    value: values.costBookId,
                                                                    options: (() => {
                                                                            const ops:any = this.props.appStates.bookNames ? Object.entries(this.props.appStates.bookNames.cost_hash).map((e:any) => [e[0],e[1].alias]) : []
                                                                            if(ops.length > 0) values.costBookId = ops[0][0]
                                                                            return ops
                                                                        })(),
                                                                    onChange: handleChange
                                                                },
                                                                {
                                                                    label: 'Price Book',
                                                                    horizontalLabel: "5",
                                                                    fieldType: 'select',
                                                                    name: 'priceBookId',
                                                                    value: values.priceBookId,
                                                                    options: (() => {
                                                                        const ops:any = this.props.appStates.bookNames ? Object.entries(this.props.appStates.bookNames.price_hash).map((e:any) => [e[0],e[1].alias]) : []
                                                                        if(ops.length > 0) values.priceBookId = ops[0][0]
                                                                        return ops
                                                                    })(),
                                                                    onChange: handleChange
                                                                },
                                                                {
                                                                    label: 'Currency',
                                                                    horizontalLabel: "5",
                                                                    fieldType: 'select',
                                                                    name: 'currency',
                                                                    value: values.currency,
                                                                    options: [
                                                                        ['840', 'USD'], 
                                                                        ['928', 'VES']
                                                                    ],
                                                                    onChange: handleChange,
                                                                    info: HelperMsg('We recomend using a strong currency to reference prices and the system will bill in your local curency')
                                                                }
                                                            ]}
                                                        />
                                                    </Col>
                                                    <Col md={5}>
                                                        <FormInputs
                                                            ncols={['10', '10']}
                                                            className={['px-2', 'px-2']}
                                                            properties={[
                                                                {
                                                                    label: 'inventories',
                                                                    horizontalLabel: '5',
                                                                    fieldType: 'select',
                                                                    name: 'bzrInventoryBookId',
                                                                    value: values.bzrInventoryBookId,
                                                                    options: (() => {
                                                                        const ops:any = this.props.appStates.bookNames && this.props.appStates.bookNames.inventory ? Object.entries(this.props.appStates.bookNames.inventory).map((b:any) => [b[1], b[0]]) : [['','']]
                                                                        if(ops.length > 0) values.bzrInventoryBookId = ops[0][0]
                                                                        return ops
                                                                    })(),
                                                                    onChange: handleChange
                                                                },
                                                                {
                                                                    label: 'order number',
                                                                    horizontalLabel: '5',
                                                                    fieldType: 'text',
                                                                    name: 'orderNumber',
                                                                    value: values.orderNumber,
                                                                    onChange: handleChange
                                                                }
                                                            ]}
                                                        />
                                                        <div className="w-100 text-center mt-4">
                                                            {
                                                            isSubmitting ? <div><h5>{t('submitting')}...<FontAwesomeIcon icon={faSpinner} spin /></h5></div> :
                                                            <Button 
                                                                type='submit' 
                                                                id='load-inventory-submit' 
                                                                >
                                                                    {t('submit')}
                                                            </Button>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form> 
                                        </div>
                                    )
                            }
                        </Formik>
                    </Col>
                    <Col md={12}>
                    <DragDropFile handleFile={this.handleFile}>
                        <div className="row"><div className="col-xs-12">
                            <DataInput handleFile={this.handleFile} />
                        </div></div>
                        <br />
                        <h5>Data preview</h5>
                        <div className="row"><div className="col-xs-12 load-table">
                            <OutTable data={this.state.data} cols={this.state.cols} />
                        </div></div>
                    </DragDropFile>
                    </Col>
                 </Row>
            </Container>
        )
    }
}

export default withRouter(NewInventory)