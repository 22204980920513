import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Form, Row, Col, Card, Button, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { History, LocationState } from "history"
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'

import BzrAxios from '../../utils/BzrAxios'

//import t from '../../utils/I18n/I18n'

import { AppStateContext } from '../../utils/Contexts'

interface ProfileProps extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	externalSubmit?: any,
	appStates: any,
	liftUpAppStates: any
}

interface ProfileStates {
	formError: any,
	disableFields: boolean,
	issubmitting: boolean
}

class Profile extends React.Component<ProfileProps,ProfileStates> {
	
	static contextType = AppStateContext

	constructor(props: ProfileProps) {
		super(props)
        this.state = {
			formError: null,
			disableFields: !this.props.externalSubmit,
			issubmitting: false
		}
		if(this.props.externalSubmit)this.props.externalSubmit('profile-form-submit')
	}

	handleSubmit(data:any) {
		this.setState({disableFields: true, issubmitting: true})
		BzrAxios.users({url: `/${this.context.user.id}`, method: 'PUT', data})
		.then((response: any) => {
			// 1. UPDATE 'appStates' lifting the state up
			this.props.liftUpAppStates({user: response.data})
			// 2. REDIRECT window.location.replace('/dashboard/profile')

			this.setState({issubmitting: false})
		}).catch((error: any) => {
			// User already exists in Bizor Realm
			// Notify -> Post Request /BzrDoctor 
			console.error(error)
			// Retry Update user state
			//window.location.replace('/dashboard/profile')
			this.setState({issubmitting: false})
		})
	}

	ProfileForm () {
		let u = this.props.appStates.user
		return (
			<Formik
				validationSchema={Yup.object({
					firstName: Yup.string().required(),
					middleName: Yup.string(),
					lastName: Yup.string().required(),
					secondLastName: Yup.string(),
					birthDate: Yup.string().required()
				})}
				onSubmit={(values) => { this.handleSubmit(values)} }
				initialValues={{firstName: u.firstName ?? '', 
								middleName: u.middleName ?? '', 
								lastName: u.lastName ?? '', 
								secondLastName: u.secondLastName ?? '', 
								birthDate: u.birthDate ?? ''
							}}
				enableReinitialize={true}
			>
				{({
				handleSubmit,
				handleChange,
				handleBlur,
				values,
				touched,
				isValid,
				errors,
				}) => (
				<Form noValidate onSubmit={handleSubmit} id="profile-form">
					<fieldset disabled={this.state.disableFields}>
					<FormInputs
						ncols={["6", "6"]}
						className={["", ""]}
						properties={[
							{
								label: "First Name",
								feedback: "Looks good!",
								fieldType: "text",
								name: "firstName",
								value: values.firstName,
								onChange: handleChange,
								isValid: touched.firstName && !errors.firstName,
								isInvalid: !!errors.firstName,
								error: errors.firstName
							},
							{
								label: "Middle Name",
								feedback: "Looks good!",
								fieldType: "text",
								name: "middleName",
								value: values.middleName,
								onChange: handleChange,
								isValid: touched.middleName && !errors.middleName
							}
						]}
                    />
					<FormInputs
						ncols={["6", "6"]}
						className={["", ""]}
						properties={[
							{
								label: "Last Name",
								feedback: "Looks good!",
								fieldType: "text",
								name: "lastName",
								value: values.lastName,
								onChange: handleChange,
								isValid: touched.lastName && !errors.lastName
							},
							{
								label: "Second Last Name",
								feedback: "Looks good!",
								fieldType: "text",
								name: "secondLastName",
								value: values.secondLastName,
								onChange: handleChange,
								isValid: touched.secondLastName && !errors.secondLastName
							}
						]}
                    />
					<FormInputs
						ncols={["6", "6"]}
						className={["", ""]}
						properties={[
							{
								label: "Birthdate",
								feedback: "Looks good!",
								fieldType: "date",
								name: "birthDate",
								selected: values.birthDate.substring(0, 10),
								onChange: (date: Date) => {   
									values.birthDate = date.toISOString().substring(0, 10)
								}
							},
							{
								label: "Select ",
								feedback: "Looks good!",
								fieldType: "select",
								name: "gender",
								options: [["gender","Gender"], ["female", "Female"], ["male", "Male"], ["nd", "ND"]],
								onChange: handleChange
							}
						]}
                    />
					<Row>
						<Col md="5" className="mx-auto mt-4 text-center">
							{ this.props.externalSubmit || this.state.disableFields ? 
							null : 
							<Button type="submit" id="profile-form-submit">Submit</Button>
							}
							{
								this.state.issubmitting ? <div><h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
							}
						</Col>
					</Row>

					</fieldset>
				</Form>
				)}
			</Formik>
		);
	}

	render() {
		return (
			<Card className="px-1 bzr-form mx-auto mt-4">
				<Card.Body>
					<div className="d-flex justify-content-between mb-3">
						<div className="p-2"><h4>Personal Information</h4></div>
						<div className="p-2" style={ this.props.externalSubmit ? {display: "none"} : {display: "block"}}>  
						<Form>
							<Form.Check 
								checked={!this.state.disableFields}
								onChange={()=> this.setState({disableFields: !this.state.disableFields})}
								type="switch"
								id="profile-switch"
								label="Edit"
							/>
						</Form>
						</div>
					</div>
					{
						this.state.formError !== null ? 
						(<Alert variant={'danger'} onClose={() => this.setState({formError: null})} dismissible>
							<Alert.Heading>Oh snap! You got an error!</Alert.Heading>
							{this.state.formError.code}: {this.state.formError.message}
						</Alert>) : null
					} 
					<div className="mx-0">
						{ this.ProfileForm() }
					</div>
				</Card.Body>
			</Card>
		)
	}
}

export default withRouter(Profile);