import t, { locales } from '../../../utils/I18n/I18n'
import logoImage from '../../../assets/img/reactlogo.png'
import Globals from '../../../utils/Globals'

export const fn = (n:any, locale:string) => Number(Number(n).toFixed(2)).toLocaleString(locale, {minimumFractionDigits: 2})

export const docTypes:any = {
    bill: {es: 'BORRADOR-Factura'},
    quote: {es: 'BORRADOR-Cotización'},
    printed_legal: {es: 'FACTURA'},
    printed_receipt: {es: 'RECIBO'},
    printed_consignment: {es: 'CONSIGNACION'},
    issued_legal: {es: 'FACTURA'},
    issued_receipt: {es: 'RECIBO'},
    delivery_note: {es: 'NOTA DE ENTREGA'},
    as_quotation: {es: 'COTIZACION'},
    as_proforma: {es: 'PROFORMA'},
    as_sale_order: {es: 'ORDEN DE VENTA'},
    void_legal: {es: 'FACTURA-ANULADA'},
    void_receipt: {es: 'RECIBO-ANULADO'},
    credit: {es: 'NOTA DE CREDITO'},
    debit: {es: 'NOTA DE DEBITO'}
}

export function getCompany() {

    const company = {
        legalName: Globals.defaults.activeBU.legalName,
        identificationNumber: Globals.defaults.activeBUId ? Globals.defaults.activeBUId.identificationNumber : '',
        address: Globals.defaults.activeBUAddress ?? '',
        phone: Globals.defaults.activeBUPhone ?? '',
        logo: Globals.defaults.activeBULogo ?? logoImage
    }
    return company
}

export function placeImage(doc:any, url:string, right:number, top:number, width:number,  height:number, rel = 1) {
    const img:any = new Image() // HTML5 Constructor
    img.src = url
    const imgProps:any = doc.getImageProperties(img)

    width -= 10
    if(imgProps.width/imgProps.height > rel) {
        doc.addImage(img, imgProps.fileType, right, top + 5, width, 2 + Math.floor(width * imgProps.height/imgProps.width))
    } else {
        width = Math.floor(height * imgProps.width/imgProps.height)
        doc.addImage(img, imgProps.fileType, right, top, width, height)
    }
    return width
}

export function textBlock(doc:any, text:string, right:number, top:number, width:number, {fontSize, lineHeightFactor}:any = {}) {
    const splitText:string[] = doc.splitTextToSize(text, width),
    lineCount:number = splitText.length,
    textHeight:number = lineCount * (fontSize ?? doc.getFontSize()) * (lineHeightFactor ?? doc.getLineHeightFactor()) * 34/100
    doc.text(splitText, right, top)
    return textHeight
}

export function header(doc:any, order:any, options:any = {locale: 'es-VE'}) {
    
    const company = {
        legalName: Globals.defaults.activeBU.legalName,
        identificationNumber: Globals.defaults.activeBUId ? Globals.defaults.activeBUId.identificationNumber : '',
        address: Globals.defaults.activeBUAddress ?? '',
        phone: Globals.defaults.activeBUPhone ?? '',
        logo: Globals.defaults.activeBULogo ?? logoImage
    }
    if(!options.locale) options.locale = Globals.defaults.activeBU.locale

    let offset = 12
    const incOffset = (o:number) => {
        return o + doc.getFontSize() * doc.getLineHeightFactor() * 34/100
    }

    // Company Logo
    const logoWidth = placeImage(doc, company.logo, 12, 10, 45, 20, 2) + 18

    // User
    if(true) {
        const user:string = `${Globals.defaults.user.firstName} ${Globals.defaults.user.lastName}`
        doc.setTextColor(100)
        doc.setFontSize(6)
        doc.setFontStyle("bold")
        const d:any = new Date()
        const lo = { weekday: 'long', day: '2-digit', month: '2-digit', year: 'numeric'}
        doc.text([
            d.toLocaleString(options.locale, lo).toUpperCase() + ', ' + d.toLocaleTimeString(options.locale),
            user.toUpperCase() ], 205, 270, {align: 'right'})
    }


    // Doc Title (e.g. 'Invoice)
    doc.setTextColor(50)
    doc.setFontStyle("bold")
    doc.setFontSize(14)
    if(order.title) {
        doc.text(`${t(order.title).toUpperCase()}`, 200, 35, {align: 'right'})
    } else if(options.title && options.title !== 'no-title') {
        doc.text(docTypes[options.title].es.toUpperCase(), 200, 35, {align: 'right'})
    }
    
    // Company Info
    const localeObj = locales[options.locale]
    doc.setTextColor(50)
    doc.setFontSize(10)
    doc.setFontStyle("bold")
    doc.text(company.legalName.toUpperCase(), logoWidth, offset)

    doc.setFontSize(8)
    doc.setFontStyle("normal")
    const companyLegalId = company.identificationNumber ? `${localeObj.identificationName}: ${company.identificationNumber.toUpperCase()}` : ''
    offset = incOffset(offset)
    doc.text(companyLegalId, logoWidth, offset)
    const companyAddress = company.address ?? {addressLine1: '', addressLine2: '', cityName: '', postalCodeText: '', stateProvinceName: '', countryName: ''}
    offset = incOffset(offset)
    const address = [companyAddress.addressLine1, companyAddress.addressLine2, (companyAddress.cityName + ' ' + companyAddress.postalCodeText).toUpperCase()].join(', ')
    offset += textBlock(doc, address.toUpperCase(), logoWidth, offset, 80)

    doc.text([companyAddress.stateProvinceName, companyAddress.countryName].join(', ').toUpperCase(), logoWidth, offset)
    offset = incOffset(offset)
    doc.text(`Tel: ${company.phone.phoneCountryCode} (${company.phone.areaCode}) ${company.phone.telephoneNumber}`, logoWidth, offset)

    return 35
}

export function productHeader(doc:any, options:any = {locale: 'es-VE'}) {
    
    const company = {
        legalName: Globals.defaults.activeBU.legalName,
        identificationNumber: Globals.defaults.activeBUId ? Globals.defaults.activeBUId.identificationNumber : '',
        address: Globals.defaults.activeBUAddress ?? '',
        phone: Globals.defaults.activeBUPhone ?? '',
        logo: Globals.defaults.activeBULogo ?? logoImage
    }
    console.log(Globals.defaults.brandLogos)
    if(!options.locale) options.locale = Globals.defaults.activeBU.locale

    let offset = 12
    const incOffset = (o:number) => {
        return o + doc.getFontSize() * doc.getLineHeightFactor() * 34/100
    }

    // Company Logo
    const logoWidth = placeImage(doc, company.logo, 12, 10, 45, 20, 2) + 18
    // Brand Logos
    if(Globals.defaults.brandLogos) {
        let dis = 0
        Globals.defaults.brandLogos.forEach((logoUrl:string) => {
            placeImage(doc, logoUrl, 182 + dis, 8, 18, 18, 1)
            dis = -21
        })
    }

    // User
    if(true) {
        const user:string = `${Globals.defaults.user.firstName} ${Globals.defaults.user.lastName}`
        doc.setTextColor(100)
        doc.setFontSize(6)
        doc.setFontStyle("bold")
        const d:any = new Date()
        const lo = { weekday: 'long', day: '2-digit', month: '2-digit', year: 'numeric'}
        doc.text([
            d.toLocaleString(options.locale, lo).toUpperCase() + ', ' + d.toLocaleTimeString(options.locale),
            user.toUpperCase() ], 205, 270, {align: 'right'})
    }


    // Doc Title (e.g. 'Invoice)
    doc.setTextColor(50)
    doc.setFontStyle("bold")
    doc.setFontSize(12)
    if(options.title) {
        doc.text(`${t(options.title).toUpperCase()}`, 200, 35, {align: 'right'})
    } else if(options.title && options.title !== 'no-title') {
        doc.text(docTypes[options.title].es.toUpperCase(), 200, 35, {align: 'right'})
    }
    
    // Company Info
    const localeObj = locales[options.locale]
    doc.setTextColor(50)
    doc.setFontSize(8)
    doc.setFontStyle("bold")
    doc.text(company.legalName.toUpperCase(), logoWidth, offset)

    doc.setFontSize(7)
    doc.setFontStyle("normal")
    const companyLegalId = company.identificationNumber ? `${localeObj.identificationName}: ${company.identificationNumber.toUpperCase()}` : ''
    offset = incOffset(offset)
    doc.text(companyLegalId, logoWidth, offset)
    const companyAddress = company.address ?? {addressLine1: '', addressLine2: '', cityName: '', postalCodeText: '', stateProvinceName: '', countryName: ''}
    offset = incOffset(offset)
    const address = [companyAddress.addressLine1, companyAddress.addressLine2, (companyAddress.cityName + ' ' + companyAddress.postalCodeText).toUpperCase()].join(', ')
    offset += textBlock(doc, address.toUpperCase(), logoWidth, offset, 80)

    doc.text([companyAddress.stateProvinceName, companyAddress.countryName].join(', ').toUpperCase(), logoWidth, offset)
    offset = incOffset(offset)
    doc.text(`Tel: ${company.phone.phoneCountryCode} (${company.phone.areaCode}) ${company.phone.telephoneNumber}`, logoWidth, offset)
    
    // Seller Data
    if(Globals.defaults.headerInfoById && Globals.defaults.headerInfoById[options.priceBookId]) {
        const info = Globals.defaults.headerInfoById[options.priceBookId]
        offset = incOffset(offset)
        doc.text(`IG: ${info.ig}`, logoWidth, offset)
        offset = incOffset(offset)
        doc.text(`Contacto: ${info.sellerName} <${info.email}>`, logoWidth, offset + 2)
    }

    return 35
}

export function subHeader(doc:any, client:any, order:any, pageCount:number, offset:any, options:any) {

    const match:any = order.status.match(/credit|debit/)
    const billedIn:any = options.billedIn, title = !!match ? match[0] : order.status
    const headerFields = {
        docType: `${docTypes[title][options.locale.slice(0,2)]} Nº`, 
        totalToPay: t('total to pay'),
        issueDate: t('issue date'),
        payBefore: t('pay before'),
        paymentCondition: t('payment condition'),
        page: t('page'),
    }
    const clientHeaders = {
        clientInfo: t('client info'),
        clientAdds:t('client address'),
        clientNumber: t('client number'),
        purchaseOrderNumber: t('purchase order number'),
        deliveryAdds: t('delivery address'),
    }
    offset = offset ?? Globals.defaults.activeBU.preferences.printer.subHeaderOffset

    // Horizontal Ruler
    let leftOffset = offset + 2// options.mediaType === 'digital-letter' ? 40 : 50
    let rightOffset = leftOffset + 6
    doc.setDrawColor(100)
    doc.line(10, offset, 205, offset)

    // ORDER NUMBER
    doc.setFontStyle("bold")
    doc.setTextColor(50)
    doc.setFontSize(16)
    if(order.status === 'bill') {
        const titles:any = headerFields.docType.split('-')
        doc.text(`${titles[0].toUpperCase()}`, 90, rightOffset)
        doc.setFontStyle("normal")
        doc.text(`${titles[1].toUpperCase()}:`, 180, rightOffset, {align: 'right'})
    } else if(order.status === 'quote') {
        const titles:any = headerFields.docType.split('-')
        doc.text(`${titles[0].toUpperCase()}`, 90, rightOffset)
        doc.setFontStyle("normal")
        doc.text(`${titles[1].toUpperCase()}:`, 180, rightOffset, {align: 'right'})
    } else doc.text(`${headerFields.docType.toUpperCase()}:`, 180, rightOffset, {align: 'right'})

    doc.setFontStyle("bold")
    let docNumber:string = ''
    if(order.status === 'printed_legal' || order.status === 'issued_legal' || order.status === 'void_legal') docNumber = order.billNumber 
    else if(!!match && match[0] === 'credit') docNumber = order.creditNumber
    else if(!!match && match[0] === 'debit') docNumber = order.debitNumber
    else docNumber = order.orderNumber
    
    doc.text(docNumber, 182, rightOffset)

    client = client ?? {}
    if(!!match) {
        rightOffset += 6
        doc.setTextColor(50)
        doc.setFontSize(11)
        doc.setFontStyle("bold")
        doc.text(`${t('related to invoice').toUpperCase()}:`, 180, rightOffset, {align: 'right'})
        doc.setFontStyle("normal")
        doc.text([order.relatedBillNumber], 182, rightOffset)
    }
    // DOC DATA (e.g. 'Date')
    if(!!options.mediaType.match('digital')) rightOffset += 3
    rightOffset += 3
    doc.setFontStyle("normal")
    doc.setTextColor(50)
    doc.setFontSize(9)
    let docDataH:any[] = [
        order.controlNumber ? t('control nº').toUpperCase() : '', 
        t('order nº').toUpperCase(), 
        '', 
        `${headerFields.issueDate.toUpperCase()}:`, 
        `${headerFields.payBefore.toUpperCase()}:`, 
        `${headerFields.paymentCondition.toUpperCase()}:`,
        '',
        client.clientNumber ? `${clientHeaders.clientNumber.toUpperCase()}:` : ''
    ]
    if(!!options.mediaType.match('paper')) docDataH.splice(0,2)
    doc.text(docDataH, 180, rightOffset, {align: 'right'})

    doc.setFontStyle("bold")
    doc.setTextColor(50)
    const lo:any = { day: '2-digit', month: '2-digit', year: 'numeric'}
    // new Date(Date.now() - 30*24*60*60*1000)

    let payBefore = new Date(order.date).getTime() + parseInt(order.paymentCondition)*24*60*60*1000,
    docData:any[] = [
        order.controlNumber ?? '',
        order.orderNumber ?? '',
        '',
        new Date(order.date).toLocaleDateString(billedIn.code, lo), 
        new Date(payBefore).toLocaleDateString(billedIn.code, lo), 
        (!order.paymentCondition || order.paymentCondition === '0') ? t('cash*').toUpperCase() : `${order.paymentCondition} ${t('days')}`,
        '',
        client.clientNumber ? client.clientNumber.toString(): ''
        ]
    if(!!options.mediaType.match('paper')) docData.splice(0,2)
    doc.text(docData, 182, rightOffset)
    if(!!options.mediaType.match('paper')) rightOffset += 18
    else rightOffset += 25

    //////////////////////////////////////////////
    // Client Info
    leftOffset += 4
    doc.setTextColor(50)
    doc.setFontSize(9)
    doc.setFontStyle("bold")
    doc.text(clientHeaders.clientInfo.toUpperCase(), 12, leftOffset)

    leftOffset += 4
    doc.setFontStyle("normal")
    doc.text([client.legalName ? client.legalName.toUpperCase() : '', client.identificationNumber ? `${client.identificationName}: ${client.identificationNumber.toUpperCase()}` : ''], 12, leftOffset)

    // Client Address
    leftOffset += 10
    doc.setTextColor(50)
    doc.setFontStyle("bold")
    doc.text(clientHeaders.clientAdds.toUpperCase(), 12, leftOffset)

    //  CONSTRUIR 'ADDRESS' Y 'PHONE NUMBER'
    const address = `${client.addressLine1}${client.addressLine2 ? ', ' + client.addressLine2 : ''}${client.cityName ? ', ' + client.cityName : ''}${client.postalCodeText ? ', ' + client.postalCodeText : ''}${client.stateProvinceName ? ', ' + client.stateProvinceName : ''}${client.countryName ? ', ' + client.countryName : ''}`
    const phone = client.telephoneNumber ? `${client.phoneCountryCode ? client.phoneCountryCode : ''} ${client.areaCode ? '('+client.areaCode+')' : ''} ${client.telephoneNumber}` : ''
    let addressTextSplit = doc.splitTextToSize(address.toUpperCase(), 120)
    if(phone) addressTextSplit.push(phone)

    const textHeight = (addressTextSplit.length - 1) * 9 * 1.15 * 34/100
    //console.log(addressTextSplit.length, textHeight)
    leftOffset += 4
    doc.setFontStyle("normal")
    doc.text(addressTextSplit, 12, leftOffset)

    ///////////////////
    leftOffset += textHeight
    // PURCHASE ORDER
    if(order.purchaseOrderNumber) {
        leftOffset += 5
        doc.setTextColor(50)
        doc.setFontStyle("bold")
        doc.text(`${clientHeaders.purchaseOrderNumber.toUpperCase()}:`, 12, leftOffset)

        doc.setFontStyle("normal")
        doc.text([order.purchaseOrderNumber], 50, leftOffset)
    }

    // DELIVERY ADDRESS
    if(!!client && !!client.facility) {
        leftOffset += 5
        doc.setTextColor(50)
        doc.setFontStyle("bold")
        doc.text(clientHeaders.deliveryAdds.toUpperCase(), 12, leftOffset)
    
        const address = `${client.facility.facilityName ? client.facility.facilityName + ':' : ''} ${client.facility.addressLine1} ${client.facility.addressLine2 ? ', ' + client.facility.addressLine2 : ''} ${client.facility.cityName ? ', ' + client.facility.cityName : ''} ${client.facility.postalCodeText ? ', ' + client.facility.postalCodeText : ''} ${client.facility.stateProvinceName ? ', ' + client.facility.stateProvinceName : ''} ${client.facility.countryName ? ', ' + client.facility.countryName : ''}`
        addressTextSplit = doc.splitTextToSize(address.toUpperCase(), 120)
        const th = (addressTextSplit.length - 1) * 9 * 34/100
        leftOffset += 4
        doc.setFontStyle("normal")
        doc.text(address, 12, leftOffset, {maxWidth: '120'})
        leftOffset += th
    }

    return (leftOffset < rightOffset ? rightOffset : leftOffset) + 4
}