import React from 'react'

import { ButtonGroup, Button, Alert, Accordion, Card, FormControl } from 'react-bootstrap'

import { Auth } from 'aws-amplify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import PartyInput from '../Common/PartyInput'
import ImageInput from '../Common/ImageInput'
import CBList from '../Common/CBList'
//import BzrTable from '../Common/BzrTable'
import PreviewCard from '../Common/PreviewCard'

import BzrAxios from '../../utils/BzrAxios'
import { Storage } from 'aws-amplify'
import { PreviewModal, MessageModal } from '../../layouts/Modals/Modals'
import Printer from '../Printer/ProductToPDF'
import AWS from 'aws-sdk'
import { SheetJSApp } from './ExcelExport'
import { EditableCell } from '../../utils/I18n/I18n'

interface Props {
    appStates?: any
}
interface States {
    attribute: any,
    preview: any,
    optionValue: any,
    pdf: any,
    show: boolean,
    products: any,
    store: any,
    showPreviewModal: boolean,
    clients: any,
    client: any,
    toggleMessageModal:boolean,
    response:any
}

class TestBoard extends React.Component<Props, States> {

    constructor(props:any) {
        super(props)
        this.state = {
            attribute: '',
            preview: null,
            optionValue: '',
            pdf: null,
            show: false,
            products: [],
            store: 'test',
            showPreviewModal: false,
            clients: [],
            client: null,
            toggleMessageModal: false,
            response: null
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getOption = this.getOption.bind(this)
    }

    componentDidMount() {
        /* Auth.currentSession().then((r:any) => {
            const key = 'cognito-idp.us-east-2.amazonaws.com/us-east-2_TkQB8g9bL';
            const logins:any = {};
            logins[key] = r.getIdToken().getJwtToken();
            const credentials = new AWS.CognitoIdentityCredentials(
                {
                    IdentityPoolId: 'us-east-2:53441b11-74f9-42fa-83b4-eb3d26b06703',
                    Logins: logins
                },
                {
                    region: 'us-east-2'
                }
            )
            AWS.config.update({
                region: 'us-east-2',
                credentials
            })
        }) */
    }

/*     getProducts() {
        fetch('/products-json.txt', {
            mode: 'no-cors' // 'cors' by default
            })
            .then((r) => r.json())
            .then((products:any) => {
                console.log(products)
                products.forEach((p:any) => { 
                    if(p.body){ 
                    var typedArray = new Uint8Array(p.body.data)
                    const blob = new Blob([typedArray], {type : p.type})
                    p['imageURL'] = URL.createObjectURL(blob)
                    p['imageType'] = p.type.replace('image/', '')
                    delete p.body
                    delete p.type}
                })
                this.setState({products})
            })
    } */

    deleteTransacs() {

/*         BzrAxios.get(`/orgs/${this.props.appStates.org.id}/companies/${this.props.appStates.activeBU.id}/objects/12`)
            .then((response) => {
                const sales:any = response.data.records
                sales.array.forEach((s:any) => {
                    const pdfStorageKey = [this.props.appStates.org.id,this.props.appStates.activeBU.id,'BzrSaleOrder',this.state.order.saleOrderNumber.replace(/-/g,'_') + '.pdf'].join('/')
                    const pdfDeliveryNoteKey = [this.props.appStates.org.id,this.props.appStates.activeBU.id,'BzrDeliveryNote',this.state.order.saleOrderNumber.replace(/-/g,'_') + '.pdf'].join('/')
        
                })
            })
            .catch((err) => console.log(err))

        // get saleOrderNumbers

        

        Storage.remove('test.txt')
        .then(result => console.log(result))
        .catch(err => console.log(err)); */
    }

    getProducts2() {
        const conf:any = AWS.config.credentials
        const s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            region: 'us-east-2', //OPTIONAL -  Amazon service region
        })
        console.log(conf)
        
        s3.getObject({Bucket: 'bizor-s3-imgs', Key: `private/${conf.identityId}/Bizor-Precios.png`}, (err:any, result:any) => {
            if(err) {
                console.log(err)
                return
            }
            console.log(result)
        })
        console.log('products fetched')
    }

    handleChange(e:any) {
        this.setState({attribute: e.target.value})
        console.log(this.state)
    }

    handleSubmit(e:any) {
        e.preventDefault()
        console.log(this.state.attribute)
        this.changeCognitoUserAttribute("custom:status", '' + this.state.attribute)
    }

    getOption(preview:any) {
        this.setState({preview})
    }

    testBzrAxiosInterceptor() {
        // Add a response interceptor

        BzrAxios.records({url: '/test'})
        .then((response:any) => console.log(response.data))
        .catch((error:any) => console.log(error))
    }
    testBzrAxiosAuth() {
        // Add a response interceptor

        BzrAxios.records({url: '/auth'})
        .then((response:any) => console.log(response.data))
        .catch((error:any) => console.log(error))
    }


    CreateJSONFile() {
        var aFileParts:any = [JSON.stringify(this.state.products)];
        var oMyBlob = new Blob(aFileParts, {type : 'text/txt'});
        var url:any = URL.createObjectURL(oMyBlob)
        return(
        <div className="mb-2 text-center">
            <a className="btn btn-info" role="button" href={url}
            download={"order.txt"}>
            Download
            </a>
        </div>
        )
    }

    logError(error:any) {
        console.log(error)
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
        }
        if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
        } 
        if(error.message) {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
        }
        console.log(error.config)
    }

    changeCognitoUserAttribute(key: string, value: string) {
        Auth.currentAuthenticatedUser().then( (user:any) => {
            let attribute:any = {}
            attribute[key] = value
            Auth.updateUserAttributes(user, attribute)
            .then((v:string) => {
                console.log(v)
            }).catch( (err) => console.log(err) )
        }).catch( (err) => console.log('not authenticated') )
    }

    handlePartyInput({party}:any) {
        this.setState({client: Object.assign({}, party ?? {})})
    }

    renderItems() {
        return(
            <table id="item-list" className="items flash-text-animation">
                <thead>
                    <tr>
                        <th style={{minWidth: "80px"}} className="text-right">quantity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr data-record-id='sdfsf'>
                        <td className="number-format" >
                            <div>
                                <EditableCell value='1000.90' liftUpValue={(v:any) => console.log(v)}  />
                            </div>
                        </td>
                     </tr>
                </tbody>
            </table>
        )
    }

    render(){
        return(
            <header id="header" className="header">
                <div className="header-content">
                    <div className="container">
                        <div className="row border">
                            <div className="col-md-12">
                                {this.renderItems()}
                            </div>
                        </div>
                        <div className="placeholder-item placeholder-loading p-5">
                            <h2>Auth Response</h2>
                            
                            <p>
                                
                            </p>
                                test outside
                                <FormControl
                                    placeholder='test'
                                    aria-label='test'
                                    />
                                <h1>this is a test</h1>
                                <h2>this is a test</h2>
                                <h3>this is a test</h3>
                                <h4>this is a test</h4>
                                <h5>this is a test</h5>
                                <h6>this is a test</h6>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-6 border">
                                <div className="row">
                                    <div className="text-container">
                                    <Alert variant={'success'} dismissible>
                                        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                                        Alert Message 
                                    </Alert>
                                    <br />
                                    <PartyInput 
                                        initState={this.state.client}
                                        name='client' 
                                        liftUpState={(party:any) => {this.handlePartyInput(party)}} 
                                        parties={this.state.clients} />

                                    <span className="badge badge-success" onClick={() => console.log(this.state.client)}>Success</span>
                                    <span className="badge badge-danger">Danger</span>
                                    
                                    <span className="badge badge-info rounded-circle text-center" style={{width: '40px', height: '40px', paddingTop: '10px'}}>
                                        <span className="fas fa-angle-double-left text-center" style={{fontSize: "20px", fontWeight: 900}}></span>
                                    </span>
                                    <span className="pe-7s-angle-left text-center"></span>
                                    <br />
                                        <a className="btn-solid-lg page-scroll" href="#your-link"><FontAwesomeIcon icon={faApple} /> APP STORE</a> 
                                        <a className="btn-solid-lg page-scroll" href="#your-link"><FontAwesomeIcon icon={faGooglePlay} /> PLAY STORE</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <form onSubmit={this.handleSubmit}>
                                        <label>Cognito User Attribute
                                            <input type="text" name="attribute" id="attribute" onChange={this.handleChange} />
                                        </label>
                                        <input type="submit" />
                                    </form>
                                </div>
                                <ImageInput liftUpState={(state:any) => console.log(state)} boxW={200} boxH={200} />
                            </div> 
                            <div className="col-lg-6 border">
                                <br />
                                <div className="input-sale-number">
                                    <div id="event-test" className="flash-text-animation" style={{animationName: this.state.store}}>{this.state.optionValue}</div>
                                    <label>labeleeee</label>
                                    <input 
                                        type="text" 
                                        name="price" 
                                        placeholder="name@example.com" 
                                        value={this.state.optionValue} 
                                        onChange={(event:any) => {
                                            this.setState({optionValue: event.target.value, store: 'flash-text'})
                                            setTimeout(() => this.setState({store: 'none'}), 50)
                                            }} />
                                </div>
                                <br />
                                <SheetJSApp />
                            <div>
                            <ButtonGroup vertical>
                                <Button variant="outline-success" onClick={() => this.testBzrAxiosAuth() }>Test Auth</Button>
                                <Button variant="outline-secondary" onClick={() => {this.testBzrAxiosInterceptor(); this.setState({toggleMessageModal: true})} }>Test Interceptor</Button>

                                <Button variant="outline-warning">Button</Button>
                                <Button variant="dark">Button</Button>
                            </ButtonGroup>
                                <PreviewCard item={this.state.preview} appStates={this.props.appStates} />
                                <Button onClick={() => {
                                    const titles = {title: 'product card'}
                                    const pdfBLOB = Printer.productToPDF(this.props.appStates, this.state.preview, titles, this.state.products)
                                    this.setState({showPreviewModal: true, pdf: URL.createObjectURL(pdfBLOB)})
                                    }} > Show Preview Modal</Button>
                                <Accordion id='acc' defaultActiveKey="0">
                                    <Card className="let-overflow">
                                        <Card.Header>
                                        <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={(event:any) => {
                                        console.log(event.target)
                                }}>
                                            Click me!
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                        <Card.Body>

                                        <p>preview: {this.state.preview ? this.state.preview.name : null}</p>
                                            <h5>Test CBList</h5>
                                            <CBList 
                                                id="cblTest"
                                                pkey="name"
                                                value={this.state.optionValue}
                                                selected={this.state.preview}
                                                liftUpOption={this.getOption} 
                                                liftUpValue={(v:any) => this.setState({optionValue: v})}
                                                items={this.state.products} 
                                                filters={['sku']}
                                                />

                                        </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                                {this.props.appStates.activeBU ? <PreviewModal 
                                    show={this.state.showPreviewModal} 
                                    url={this.state.pdf} 
                                    title="sale order"
                                    onHide={() => {
                                        this.setState({showPreviewModal: false})
                                    }
                                } /> : null}
                            </div>
                            </div>
                        </div> 
                        
                        <div className="row">
                            <div className="col-md-12">
                                <div id="pdf-doc">
                                    <button onClick={() => this.setState({show: true})}>Generate PDF</button>
                                    <Button className="btn-trash" onClick={() => {
                                        Storage.get("407407890/407502125/BzrSaleOrder/2020_04_754984").then(
                                            (url) => {
                                                const w:any = window.open()
                                                const a:any = document.createElement('a')
                                                a.href = url
                                                w.onload(() => {
                                                    w.appendChild(a)
                                                    a.click()
                                                })
                                        })
                                    }}><span className="pe-7s-download text-center" /></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MessageModal
                        show={this.state.toggleMessageModal}
                        onHide={() => this.setState({toggleMessageModal: false})}
                        message='loading'
                    />
                </div> 
            </header> 
        )
    }
}


export default TestBoard;