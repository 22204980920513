import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from 'history'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Auth } from 'aws-amplify'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'

import BzrAxios from '../../utils/BzrAxios'

//import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	liftUpAppStates: any,
	disableFields: boolean,
	disableFieldsHandler: any,
	params: any,
	idn: any
}

interface State {
	formError: any,
	addressList: any,
	options: any,
	disableFields: boolean
}

class PreferenceForm extends React.Component<Props,State> {
	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null,
			addressList: [['0', 'loading...']],
			options: [[0, 'loading...']],
			disableFields: true
		}
	}

	componentDidMount() {
		this.getAddress()
		this.setSelectOptions()
	}

	getAddress() {
		BzrAxios.users({url: `/${this.props.appStates.user.id}/Addresses`})
		.then((response:any) => {
			const addresses:any[] = response.data.addresses,
				addressList = addresses.map( (v:any) => [v.id, v.addressLine1 + '..., ' + v.cityName + ', ' + v.countryName ])
			this.setState({addressList: addressList})
		})	
		.catch((err:any) => console.error(err))
		.finally( () => this.setState({disableFields: false}) )
	}

	setSelectOptions() {
		if(this.props.appStates.org) {
			BzrAxios.orgs({url: '/BusinessUnits'})
			.then((response:any) => {
				this.props.liftUpAppStates({bus: response.data.companies})
				this.setOptions(response.data.companies)
			})
			.catch((err:any) => console.error(err))
		} else {
			this.setOptions([])
		}
	}

	setOptions(values:any) {
		let options:any[] = [[0, 'You have not set a BU yet!']]
		if(values.length !== 0)
			values.forEach((value:any) => options.push([value.id, value.legalName]) )
		this.setState({options: options})
	}

	handleSubmit(data:any) {
		this.props.liftUpAppStates({submitting: true})
		this.props.disableFieldsHandler(true)
		const userStatus = this.props.appStates.user.userStatus
		if(userStatus < 1500) {
			data['activeBU'] = 0
			///data['userStatus'] = 1500  ERRRORRRRRR  -> MUST UPDATE BZR_USER
		}

		let url = `/${this.props.appStates.user.id}/Preferences`, 
			method = 'POST'
		if(!!window.location.pathname.match(/enrollment/)) {
			url = `/${this.props.appStates.user.id}/Preferences/${this.props.appStates.user.id}`
			method = 'PUT'
		}
		 
		BzrAxios.users({ url, method, data })
		.then((response: any) => {
			if(userStatus < 1500) {
				// 1. UPDATE 'bzrUser' lifting the state up
				this.props.disableFieldsHandler(false)
				this.props.liftUpAppStates({user: response.data.user, userPreference: response.data.userPreference, submitting: false})
				
				// UPDATE Cognito User Status
				Auth.currentAuthenticatedUser()
				.then((user:any) => {	
					Auth.updateUserAttributes(user, {'custom:status': '1500'})
					.then((v:string) => console.log(v))
					.catch((err:any) => console.error(err)) 
				})
				.catch((err) => console.error(err))
			} else {
				// 2. REDIRECT 
				this.props.liftUpAppStates({userPreference: response.data.userPreference, submitting: false})
			}
		})
		.catch((error: any) => {
			this.props.liftUpAppStates({submitting: false})
			console.error(error)
		})
	}

	render() {
		let p = this.props.appStates.prefs ?? null
		return (
			<Formik
				validationSchema={Yup.object({
					mfa: Yup.string(),
				})}
				onSubmit={(values) => { this.handleSubmit(values)} }
				initialValues={{
					mfa: p ? p.mfa : false,
					primaryContactPointId: p ? p.primaryContactPointId : this.state.addressList[0][0],
					language: p ? p.language : 'english',
					activeBU: p ? p.activeBU : 0
							}}
				enableReinitialize={true}
			>
				{({
				handleSubmit,
				handleChange,
				handleBlur,
				values,
				touched,
				isValid,
				errors,
				}) => (
				<Form noValidate onSubmit={handleSubmit} id={"preference-form" + this.props.idn}>
					<fieldset disabled={this.props.disableFields || this.state.disableFields}>
					<FormInputs
						ncols={['12']}
						className={['']}
						properties={[
							{
								label: '2FA',
								horizontalLabel: "5",
								fieldType: 'switch',
								name: 'mfa',
								value: values.mfa,
								onChange: () => values.mfa = !values.mfa,
								isValid: touched.mfa && !errors.mfa,
								isInvalid: !!errors.mfa,
								error: errors.mfa
							}
						]}
                    />
					<FormInputs
						ncols={['12']}
						className={['']}
						properties={[
							{
								label: 'Primary Address',
								horizontalLabel: "6",
								fieldType: 'select',
								name: 'primaryContactPointId',
								value: values.primaryContactPointId,
								options: this.state.addressList,
								onChange: handleChange
							}
						]}
                    />
					<FormInputs
						ncols={['12']}
						className={['']}
						properties={[
							{
								label: 'Language',
								horizontalLabel: "6",
								fieldType: 'select',
								name: 'language',
								value: values.language,
								options: [['language','select your language'], ['english', 'English'], ['spanish', 'Español']],
								onChange: handleChange
							}
						]}
                    />
					{this.props.appStates.user.userStatus < 1500 ? null : 
					<FormInputs
						ncols={['12']}
						className={['']}
						properties={[
							{
								label: 'Prefered Business Unit',
								horizontalLabel: "6",
								fieldType: 'select',
								name: 'activeBU',
								value: values.activeBU,
								options: this.state.options,
								onChange: handleChange
							}
						]}
					/>
					}
					<Row>
						<Col md='5' className='mx-auto mt-4 text-center'>
							<Button 
								type='submit' 
								id={this.props.params.id + "-form-submit-" + this.props.idn}
								className="single-submit"
								style={ this.props.singleSubmit || this.props.disableFields ? {display: 'none'}: {display: 'inline'} } >
									Submit
							</Button>							
							{
								this.props.appStates.submitting ? <div><h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
							}
						</Col>
					</Row>

					</fieldset>
				</Form>
				)}
			</Formik>
		);
	}
}

export default withRouter(PreferenceForm);