import React from 'react'
import AWS from 'aws-sdk'
import { History, LocationState } from "history"
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Form, Row, Col, Button, Modal, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'
import CBList2 from '../Common/CBList2'
import { MessageModal } from '../../layouts/Modals/Modals'
import BzrAxios from '../../utils/BzrAxios'
import t, { translate, locales } from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	id: any,
	idn: any,
	appStates: any,
	liftUpAppStates: any,
	singleSubmit?: any,
	disableFields?: boolean,
	parent?: any
}

interface State {
	formError: any
	client: any
	clients: any
	showModalList: boolean
	cstates:any,
	countries:any,
	disableCommission:boolean
}

class ClientForm extends React.Component<Props,State> {
	idPrefix:string = ''
	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null,
			client: {},
			clients: null,
			showModalList: false,
			cstates: null,
			countries: null,
			disableCommission: true
		}
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	async componentDidMount() {
		const el:any = document.getElementById('client-form')
        translate(el)
		let clients:any = null
		if(!this.props.appStates.clients){
			//  GET CLIENTS
			try {
				const response:any = await BzrAxios.records({url: `/BzrClient?qorderby=legalName`})
				clients = response.data.records
				this.setState({clients})
				this.props.liftUpAppStates({clients, clientFields: response.data.fields})
			} catch (error) { 
				console.error(error)
			}
		} else {
			clients = this.props.appStates.clients
			this.setState({clients})
		}
        if(!this.props.appStates.individuals){
			//  GET INDIVIDUALS
			try {
				const response:any = await BzrAxios.records({url: `/BzrIndividual`})
				this.props.liftUpAppStates({individuals: response.data.records})
			} catch (err) { 
				console.log(err)
			}
        }
		const match:any = this.props.match
		if(match.url.match('/admin/clients/modify')) {		
			if(match.params.id){
				const client:any = Object.assign({}, clients.find((c:any) => c.id === match.params.id)),
				matches = client.identificationNumber ? client.identificationNumber.match(/[a-zA-Z]|[0-9]+/g) : ['NA', null]
				client.idPrefix = matches[0]
				client.identificationNumber = matches[1]
				this.setState({client, clients, disableCommission: !client.sellerId})
			} else this.setState({showModalList: true, clients})
		}
		/////////////
		const s3 = new AWS.S3({apiVersion: '2006-03-01'})
		s3.getObject({ Bucket: 'bizor-s3-imgs', Key: `public/states.json` }, 
			(err:any, result:any) => {
				if(err) console.log(err)
				else {
					const cstates:any = JSON.parse(result.Body.toString())
					this.setState({cstates})
				}
			}
		)
		s3.getObject({ Bucket: 'bizor-s3-imgs', Key: `public/countries.json` }, 
			(err:any, result:any) => {
				if(err) console.log(err)
				else {
					const countries:any = JSON.parse(result.Body.toString())
					this.setState({countries})
				}
			}
		)
	}

	// ASYNC IMPLEMENTATION OF ONSUBMIT
	async handleSubmit(data:any, formikBag:any) {

		let method = 'POST', url = `/BzrClient`
		if(this.state.client.id) {
			method = 'PUT'
			url += `/${this.state.client.id}`
		} else {
			data.clientNumber = (new Date()).getTime().toString().slice(2,10)
			data.isActive = true		
		}
		if(data.idPrefix === 'NA') delete data.identificationNumber
		else data.identificationNumber = data.idPrefix + data.identificationNumber
		delete data.idPrefix

		data['countryName'] =   locales[data.locale].countryName
		if(data.priceBookId !== '') data["priceBookName"] = this.props.appStates.bookNames.price_hash[data.priceBookId].name
		data.sellerCommission = data.sellerCommission/100
		try {
			const response:any = await BzrAxios.records({ method, url, data})
			formikBag.resetForm()
			if(method === 'PUT') {
				const clients = this.state.clients.map((c:any) => {
					if(c.id === this.state.client.id) return Object.assign({},response.data.record)
					else return Object.assign({}, c)
				})
				this.props.liftUpAppStates({clients})
			} else {
				let clients:any[] = this.state.clients
				clients.unshift(response.data.records[0])
				this.props.liftUpAppStates({clients})
			}
			const path:string = window.location.pathname
			if(path.match('/admin/clients')) this.props.history.push('/admin/clients/list')
		} catch(error) {
			if(error.response && error.response.status === 409) {
				alert(`${error.response.statusText}: ${t(error.response.data.message)}`)
				if(error.response.data.code === 0) formikBag.setErrors({legalName: error.response.data.message})
				if(error.response.data.code === 1) formikBag.setErrors({identificationNumber: error.response.data.message})
			}
			console.log(error)
		}
	}

	render() {
		const p:any = this.props.appStates.bookNames ? this.props.appStates.bookNames.price.default : ''
		const a:any = this.state.client ?? {}
		a.priceBookId = this.props.match.url.match('/admin/clients/modify') ? a.priceBookId : p

		return (
			<Container id="client-form" className="bzr-max-width mb-5 p-5">
			<Formik
				validationSchema={
					() => {
						return Yup.object({
							legalName: Yup.string().trim().required(),
							commercialName: Yup.string().trim(),
							identificationNumber: this.idPrefix !== 'NA' ? Yup.string().matches(/^[0-9]+$/, t('only numbers allowed')).required() : Yup.string().nullable(),
							addressLine1: Yup.string().required(),
							phoneCountryCode: Yup.string().matches(/^\+[0-9]+$/, t('only numbers allowed')).nullable(),
							areaCode: Yup.string().matches(/^[0-9]+$/, t('only numbers allowed')),
							telephoneNumber: Yup.string().matches(/^[0-9]+$/, t('only numbers allowed')),
							email: Yup.string().email(t('enter a valid email address'))
						})
					}
				}
				onSubmit={this.handleSubmit}
				initialValues={{
					legalName: a.legalName ?? '',
					commercialName: a.commercialName ?? '',
					identificationName: a.identificationName ?? 'RIF',
					identificationNumber: a.identificationNumber ?? '',
					identificationType: a.identificationType ?? 'LEGAL',
					idPrefix: a.idPrefix ?? 'J',
					issuedDate: a.issuedDate ?? '',
					expiryDate: a.expiryDate ?? '',
					businessUnitType: a.businessUnitType ?? '',
					locale: a.locale ?? 'es-VE',
					addressLine1: a.addressLine1 ?? '', 
					addressLine2: a.addressLine2 ?? '', 
					cityName: a.cityName ?? '', 
					postalCodeText: a.postalCodeText ?? '',
					stateProvinceName: !a.stateProvinceName && !a.id ? 'Distrito Capital' : '0',
					phoneCountryCode: a.phoneCountryCode ?? '+58',
					areaCode: a.areaCode ?? '',
					telephoneNumber: a.telephoneNumber ?? '',
					email: a.email ?? '',
					priceBookId: a.priceBookId ?? '',
					sellerId: a.sellerId ?? 0,
					sellerCommission: a.sellerCommission ? (a.sellerCommission * 100) : 0
				}}
				enableReinitialize={true}
			>
				{({
				handleSubmit,
				isSubmitting,
				handleChange,
				validateForm,
				setFieldValue,
				values,
				touched,
				isValid,
				errors,
				}) => (
				<Form noValidate onSubmit={handleSubmit} id={'address-form-' + this.props.idn} >
					<Row>
						<Col md={6} className="px-3">
							<h5>basic info</h5>
							<FormInputs
								ncols={['10', '10']}
								className={['px-2', 'px-2']}
								properties={[
									{
										label: `${t('legal name')} *`,
										feedback: ' ',
										fieldType: 'text',
										name: 'legalName',
										value: values.legalName,
										onChange: handleChange,
										isValid: touched.legalName && !errors.legalName,
										isInvalid: !!errors.legalName,
										error: errors.legalName,
										autoFocus: true,
										autoComplete: "chrome-off"
									},
									{
										label: 'commercial name',
										fieldType: 'text',
										name: 'commercialName',
										value: values.commercialName,
										onChange: handleChange,
										isInvalid: !!errors.commercialName,
										error: errors.commercialName,
										autoComplete: "chrome-off"
									}
								]}
							/>
							<FormInputs
								ncols={['4', '2', '4']}
								className={['px-2', 'ml-3 pl-2 pr-0', 'pl-0 pr-2']}
								properties={[
									{
										label: 'country',
										fieldType: 'select',
										name: 'locale',
										value: values.locale ?? 'es-VE',
										options: [
											['xx-XX', t('Select')],
											['es-VE', 'Venezuela'], 
											['es-CO', 'Colombia'],
											['es-MX', 'México'], 
											['es-ES', 'España'],
											['pt-BR', 'Brasil'],
											['en-US', 'United States of America']
										],
										onChange: handleChange
									},
									{
										fieldType: 'select',
										style:{marginTop: '21px', paddingLeft: '5px'},
										className: "text-uppercase",
										name: 'idPrefix',
										value: values.idPrefix,
										options: [
											['NA', t('N/A')], 
											['J', t('J')],
											['V', t('V')],
											['G', t('G')],
											['E', t('E')],
											['O', t('O')]
										],
										onChange: (event:any) => { handleChange(event); this.idPrefix = event.target.value; validateForm() }
									},
									{
										label: `${t('identification number')} *`,
										fieldType: 'text',
										name: 'identificationNumber',
										value: values.identificationNumber,
										onChange: handleChange,
										isValid: touched.identificationNumber && !errors.identificationNumber,
										isInvalid: !!errors.identificationNumber,
										error: errors.identificationNumber,
										disabled: values.idPrefix === 'NA',
										autoComplete: "chrome-off"
									}
								]}
							/>
							<hr /><br />
							<h5>operation info</h5>
							<FormInputs
								ncols={['6', '6']}
								className={['px-2', 'px-2']}
								properties={[
									{
										label: 'business type',
										fieldType: 'select',
										name: 'businessUnitType',
										value: values.businessUnitType,
										options: [
											['retail', t('retail')], 
											['service', t('service')],
											['inventory', t('inventory')], 
											['logistic', t('logistic')], 
											['manufacture', t('manufacture')],
											['concesionario', 'concesionario'],
											['other', t('other')]
										],
										onChange: handleChange
									},
									{
										label: 'price segment',
										fieldType: 'select',
										name: 'priceBookId',
										value: values.priceBookId,
										options: this.props.appStates.bookNames ? Object.entries(this.props.appStates.bookNames.price_hash).map((e:any) => [e[0],e[1].alias]) : [],
										onChange: handleChange
									}
								]}
							/>
							<hr />
							<Form.Row>
								<Form.Group as={Col} md={6} controlId="sellerId" className="px-2">
									<Form.Label>seller</Form.Label>
									<Form.Control as="select" value={values.sellerId} onChange={(event:any) => {
											handleChange(event)
											if(event.target.value === '0') {
												setFieldValue('sellerCommission', undefined)
												this.setState({disableCommission: true})
											} else {
												setFieldValue('sellerCommission', '0')
												this.setState({disableCommission: false})
											}
										}} custom>
										<option value={'0'}>{t('no seller')}</option>
										{this.props.appStates.individuals && this.props.appStates.individuals.map((ind:any, k:number) => <option key={k} value={ind.id}>{`${ind.firstName} ${ind.lastName}`}</option>) }
										{this.props.appStates.users && this.props.appStates.users.map((ind:any, k:number) => <option key={k} value={ind.id}>{`${ind.firstName} ${ind.lastName}`}</option>) }
									</Form.Control>
								</Form.Group>
								<Form.Group as={Col} md={3} controlId="sellerCommission" className="px-2">
									<Form.Label >commission</Form.Label>
									<InputGroup>
										<Form.Control type="text" name="sellerCommission" className="number-format" value={values.sellerCommission} onChange={handleChange} autoComplete="chrome-off" disabled={this.state.disableCommission} />
										<InputGroup.Append>
											<InputGroup.Text>%</InputGroup.Text>
										</InputGroup.Append>
									</InputGroup>
								</Form.Group>
							</Form.Row>
						</Col>
						<Col md={6} className="px-3">
							<h5>address & contact</h5>
							<fieldset disabled={this.props.disableFields }>
								<FormInputs
									ncols={['12']}
									className={['ml-0']}
									properties={[
										{
											label: `${t('Address Line 1')} *`,
											fieldType: 'text',
											name: 'addressLine1',
											value: values.addressLine1,
											onChange: handleChange,
											isInvalid: !!touched.identificationNumber && !!errors.addressLine1,
											error: errors.addressLine1,
											autoComplete: "chrome-off"
										}
									]}
								/>
								<FormInputs
									ncols={['8', '4']}
									className={['', '']}
									properties={[
										{
											label: 'Address Line 2',
											fieldType: 'text',
											name: 'addressLine2',
											value: values.addressLine2,
											onChange: handleChange,
											isInvalid: !!errors.addressLine2,
											error: errors.addressLine2,
											autoComplete: "chrome-off"
										},
										{
											label: 'City',
											fieldType: 'text',
											name: 'cityName',
											value: values.cityName,
											onChange: handleChange,
											isInvalid: !!errors.cityName,
											error: errors.cityName,
											autoComplete: "chrome-off"
										}
									]}
								/>
								<FormInputs
									ncols={['3', '6']}
									className={['mr-3', '']}
									properties={[
										{
											label: 'Postal Code',
											fieldType: 'text',
											name: 'postalCodeText',
											value: values.postalCodeText,
											onChange: handleChange,
											isInvalid: !!errors.postalCodeText,
											error: errors.postalCodeText,
											autoComplete: "chrome-off"
										},
										{
											label: 'State/Province',
											fieldType: 'select',
											name: 'stateProvinceName',
											value: values.stateProvinceName,
											options: [['0','select']].concat(Object.entries(this.state.cstates ? 
												this.state.cstates[values.locale.slice(3).toLowerCase()] : [])
													.map((e:any) => {
														e[1] = e[1].replace('Estado ', '') 
														e[0] = e[1]
														return e
													})),
											onChange: handleChange
										}
									]}
								/>
								<hr />
								<FormInputs
									ncols={['6', '2', '4']}
									className={['','','']}
									properties={[
										{
											label: 'Country Code',
											fieldType: 'select',
											name: 'phoneCountryCode',
											value: values.phoneCountryCode,
											options: this.state.countries ? [['0',t('select')]].concat(this.state.countries.map((c:any) => [c.dial_code, `${c.name} (${c.dial_code})`])) : [],
											onChange: handleChange
										},
										{
											label: 'Area Code',
											fieldType: 'text',
											name: 'areaCode',
											value: values.areaCode,
											onChange: handleChange,
											isInvalid: !!errors.areaCode,
											error: errors.areaCode,
											autoComplete: "chrome-off"
										},
										{
											label: 'Phone Number',
											fieldType: 'text',
											name: 'telephoneNumber',
											value: values.telephoneNumber,
											onChange: handleChange,
											isInvalid: !!errors.telephoneNumber,
											error: errors.telephoneNumber,
											autoComplete: "chrome-off"
										}
									]}
								/>
								<FormInputs
									ncols={['6']}
									className={['px-2']}
									properties={[
										{
											label: 'email',
											fieldType: 'text',
											name: 'email',
											value: values.email,
											onChange: handleChange,
											isInvalid: !!errors.email,
											error: errors.email,
											autoComplete: "chrome-off"
										}
									]}
								/>
							</fieldset>
						</Col>
						<Col md={5} className='mx-auto mt-4 text-center'>
							{
								isSubmitting ? <div><h5>{t('submitting')}...<FontAwesomeIcon icon={faSpinner} spin /></h5></div> : 
								<>
									<Button 
										type='button'
										className="mr-5"
										variant="secondary"
										onClick={() => {if(this.props.parent) this.props.parent.onClose(); else this.props.history.goBack()}}
										>
											{this.props.parent ? t('close') : t('go back')}
									</Button>
									<Button 
										type='submit' 
										id={this.props.id + '-form-submit-' + this.props.idn} 
										style={ this.props.singleSubmit || 
												this.props.disableFields || 
												isSubmitting ? {display: 'none'}: {display: 'inline'} } >
											submit
									</Button>
								</>
							}
						</Col>
					</Row>
				</Form>
				)}
				
			</Formik>
			<Modal
				show={this.state.showModalList}
				centered
				dialogClassName="modal-cblist"
				onHide={() => {
					this.setState({showModalList: false})
					this.props.history.push('/admin/clients/list')
				}}
				>
				<Modal.Header closeButton>
					<Modal.Title>select client to edit</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<CBList2 
                            id={"client-cblist-1"}
							pkey="legalName"
							value={this.state.client ? this.state.client.legalName : ''}
                            liftUpOption={(client:any) => {
								if(client && client.id) {
									const matches = client.identificationNumber.match(/[a-zA-Z]|[0-9]+/g)
									client.idPrefix = matches[0]
									client.identificationNumber = matches[1]
									this.setState({client, showModalList: false})
									this.props.history.replace(`/admin/clients/modify/${client.id}`)
								} else {
									this.setState({ client: Object.assign({}, client ?? {}) })
								}
							}}
                            items={this.state.clients} 
                            placeholder="Search for client..."
                            whenEmpty="No parties..."
                            />
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => {
							this.setState({showModalList: false})
							this.props.history.push('/admin/clients/list')
							}}>
							Close
						</Button>
					</Modal.Footer>
			</Modal>
			<MessageModal
					show={!this.state.clients}
					message="loading... "
				/>
			</Container>
		)
	}
}

export default withRouter(ClientForm)