import React from 'react'
import Table from 'react-bootstrap/Table'
import t, { fn, dn } from '../../../utils/I18n/I18n'

interface Props {
    transacs:any
    range:any
    locale:any
    products_hash:any
}
class ProductTotals extends React.Component<Props> {

    render() {
        let tpp:any = {}, tq = 0, ta = 0
        this.props.transacs.forEach((t:any) => {
            const quantity:any = t.retailed === 'true' ? parseFloat(t.quantity) / parseFloat(t.unitsPerPkg) : parseFloat(t.quantity)
            
            const match:any = t.billStatus.match(/credit|debit|void/)
            if(!!match) t.billStatus = match[0]
            switch(t.billStatus) {
                case 'printed_legal':
                case 'printed_receipt':
                    if(!tpp[t.bzrProductId]) {
                        tpp[t.bzrProductId] = {quantity, amount: quantity * parseFloat(t.entryValue) }
                    } else {
                        tpp[t.bzrProductId].quantity += quantity 
                        tpp[t.bzrProductId].amount += quantity * parseFloat(t.entryValue)
                    }
                    break
                case 'debit':
                    if(!tpp[t.bzrProductId]) {
                        tpp[t.bzrProductId] = {amount: quantity * t.entryValue * t.alicuote }
                    } else {
                        tpp[t.bzrProductId].amount += quantity * t.entryValue * t.alicuote
                    }
                    break
                case 'credit':
                    if(!tpp[t.bzrProductId]) {
                        tpp[t.bzrProductId] = {amount: -quantity * t.entryValue * t.alicuote }
                    } else {
                        tpp[t.bzrProductId].amount -= quantity * t.entryValue * t.alicuote
                    }
                    break
                case 'void':
                default:
                    break
            }
        })

        return(
            <div className="my-5 mx-3 bzr-lateral-scrollable-75vh">
                <h2>{t('totals per product')}</h2>
                <br />
                <h5>{t('period')} ({dn(this.props.range.from, this.props.locale)} - {dn(this.props.range.to, this.props.locale)})</h5>
                <Table bordered id="product-totals-report">
                    <thead>
                        <tr>
                        <th>{t('sku')}</th><th>{t('name')}</th><th>{t('description')}</th><th>{t('quantity')}</th><th>{t('amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(tpp).map((p:any, k:number) => {
                            tq += tpp[p].quantity
                            ta += tpp[p].amount
                            return(
                                <tr key={k}>
                                    <td>{this.props.products_hash[p].sku}</td>
                                    <td>{this.props.products_hash[p].name}</td>
                                    <td>{this.props.products_hash[p].description}</td>
                                    <td className="text-right pr-3">{fn(tpp[p].quantity)}</td>
                                    <td className="text-right pr-3">{fn(tpp[p].amount)}</td>
                                </tr>
                            )}
                        )}
                    </tbody>
                    <tfoot className="bg-light small">
                        <tr>
                            <td colSpan={3} className="pl-3 text-uppercase">{t('totals')}</td>
                            <td className="text-right pr-3">{fn(tq)}</td>
                            <td className="text-right pr-3">{fn(ta)}</td>
                        </tr>
                    </tfoot>
                </Table>
                <br />
            </div>
        )
    }
}

export default ProductTotals