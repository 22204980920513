import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Form, Row, Col, Button } from 'react-bootstrap'
import CBList from '../Common/CBList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { History, LocationState } from "history"
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'

import BzrAxios from '../../utils/BzrAxios'

//import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	liftUpAppStates: any,
	disableFields?: boolean,
	disableFieldsHandler?: any,
	getFormCard?:any,
	params: any,
	comps?: any,
	idn: any
}

interface State {
	bu: any,
	formError: any,
	formAddress: any,
	phoneValue: string,
	phone: any,
	blockPhoneForm: boolean,
	disablePhoneCBList: boolean,
	initValues: any
}

interface Values {
	addressLine1: string, 
	addressLine2: string, 
	cityName: string, 
	postalCodeText: string,
	stateProvinceName: string,
	countryName: string,
	phoneCountryCode:string,
	areaCode: string,
	telephoneNumber: string,
	refName: string
}

class AddressForm extends React.Component<Props,State> {

	constructor(props: Props) {
		super(props)
        this.state = {
			bu: null,
			formError: null,
			formAddress: {},
			phoneValue: '',
			phone: {},
			blockPhoneForm: false,
			disablePhoneCBList: false,
			initValues: {}
		}
		this.getPhone = this.getPhone.bind(this)
		this.getPhoneValue = this.getPhoneValue.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidMount() {
		this.getBusAddress()
	}
	componentDidUpdate() {
		if(this.state.bu === this.props.appStates.bu) return
		this.setState({bu: this.props.appStates.bu})
		this.getBusAddress()
	}

	getBusAddress() {
		if(this.props.appStates.user.userStatus < 2000) return
		const bu = this.props.appStates.bu ?? this.props.appStates.activeBU
		if(!this.props.appStates.busAddresses)
			BzrAxios.orgs({url: `/Addresses`})
				.then((response:any) => {
					this.props.liftUpAppStates({busAddresses: response.data.addresses, busPhones: response.data.phones})
					const formAddress = response.data.addresses.filter((a:any) =>  a.partyId === bu.id)[0] ?? null
					let phone:any = null
					if(formAddress)
						phone = response.data.phones.find((p:any) => p.refName === 'principal' && formAddress.id === p.contactPointAddressId) ?? null
					this.setState({
						formAddress, 
						phone, 
						phoneValue: phone ? phone.refName : 'principal', 
						disablePhoneCBList: phone ? false : true
					})
					this.returnFormCard(formAddress, phone)
				})
				.catch((err) => console.log(err))
		else {
			const formAddress = this.props.appStates.busAddresses.filter((a:any) =>  a.partyId === bu.id)[0] ?? null
			let phone:any = null
			if(formAddress)
				phone = this.props.appStates.busPhones.find((p:any) => p.refName === 'principal' && formAddress.id === p.contactPointAddressId) ?? null
			this.setState({
				formAddress, 
				phone, 
				phoneValue: phone ? phone.refName : 'principal', 
				disablePhoneCBList: phone ? false : true
			})
			this.returnFormCard(formAddress, phone)
		}
	}

    getPhone(value:any) {
        this.setState({phone: value ?? {}})
    }

    getPhoneValue(value:any) {
        this.setState({phoneValue: value})
	}
	
	returnFormCard(address:any, phone:any) {
		if(!this.props.getFormCard) return

		const formCard = (<div>
			{address ? <h5><b>Principal</b></h5> : null}
			<div className="accordion-card-footer">
				{address ? <p>{address.addressLine1}, {address.addressLine2 ? address.addressLine2 + ", " : null}
				{address.cityName} {address.postalCodeText}, {address.stateProvinceName}
				<br />
				{address.countryName} </p> :
				<b><h5>No address registered yet!</h5></b> }
				<br />
				{phone ?
				<div> <b>Tel - {phone.refName}: </b> {phone.phoneCountryCode} ({phone.areaCode}) {phone.telephoneNumber} </div> :
				<b><h5>No phone either</h5></b>
				}
			</div>
		</div>)
		this.props.getFormCard(formCard)
	}

	handleSubmit(values:any) {
        if(this.props.disableFieldsHandler)this.props.disableFieldsHandler(true)
		let method = 'POST', url = `/BusinessUnits/${this.props.appStates.bu.id}/Addresses`

		const bua:any = this.props.appStates.busAddresses.find((a:any) =>  a.partyId === this.props.appStates.bu.id)
		if(bua) {
			method = 'PUT'
			url = `/BusinessUnits/${this.props.appStates.bu.id}/Addresses/${bua[0].id}`
		}
		
		if(this.props.appStates.busAddresses.length === 0) values["primaryFlag"] = true
		else values["primaryFlag"] = false
		const phone = this.state.phone ? this.props.appStates.busPhones.find((p:any) => p.refName === this.state.phone.refName) : null,
			extras:any = {}
		if(phone) {
			extras['phoneMethod'] = 'PUT'
			extras['contactPointPhoneId'] = phone.id
		} else extras['phoneMethod'] = 'POST'

		BzrAxios.orgs({ url, method: method, data: Object.assign({}, values, extras) })
		.then((response: any) => {
			// 1. UPDATE 'bzrUser' lifting the state up
			let busAddresses:any[] = this.props.appStates.busAddresses,
				busPhones:any[] = this.props.appStates.busPhones
				
			if(method === 'PUT') {
				busAddresses.filter((a:any) => a.id !== response.data.contactPointAddress.id)
			}

			console.log(busAddresses)
			console.log(response.data.contactPointAddress)

			busAddresses.unshift(response.data.contactPointAddress)
			if( phone === undefined ) busPhones.push(response.data.contactPointPhone)
			
			this.props.liftUpAppStates({
				busAddresses: busAddresses,
				busPhones: busPhones
			})
			this.setState({
				formAddress: response.data.contactPointAddress,
				phone: response.data.contactPointPhone,
				phoneValue: response.data.contactPointPhone.refName,
				disablePhoneCBList: false})
			this.returnFormCard(response.data.contactPointAddress, response.data.contactPointPhone)
			// 2. NOT IMPLEMENTED IN ENROLLMENT YET
			if(this.props.appStates.user.userStatus < 3000) {
				const data = {userStatus: 1900}
				BzrAxios.users({url: '/', method: 'PUT', data})
				.then( (response:any) => {
					this.props.liftUpAppStates({user: response.data.user})
				})
				.catch( (err) => console.log(err) )
			}
		}).catch((error: any) => {
			console.error(error)
		})
	}

	render() {
		const a:any = this.state.formAddress ?? {}, p = this.state.phone ?? {}
		return (
			<Formik
				validationSchema={Yup.object({
					addressLine1: Yup.string().required(),
					addressLine2: Yup.string(),
					cityName: Yup.string().required(),
					postalCodeText: Yup.string().required(),
					stateProvinceName: Yup.string().required(),
					countryName: Yup.string().required(),
					refName: Yup.string().required(),
					phoneCountryCode: Yup.string().required(),
					areaCode: Yup.string().required(),
					telephoneNumber: Yup.string().required(),

				})}
				onSubmit={this.handleSubmit}
				initialValues={{
					addressLine1: a.addressLine1 ?? '', 
					addressLine2: a.addressLine2 ?? '', 
					cityName: a.cityName ?? '', 
					postalCodeText: a.postalCodeText ?? '',
					stateProvinceName: a.stateProvinceName ?? '',
					countryName: a.countryName ?? '',
					phoneCountryCode: p.phoneCountryCode ?? '',
					areaCode: p.areaCode ?? '',
					telephoneNumber: p.telephoneNumber ?? '',
					refName: this.state.phoneValue ?? ''
				}}
				enableReinitialize={true}
			>
				{({
				handleSubmit,
				isSubmitting,
				handleChange,
				values,
				touched,
				isValid,
				errors,
				}) => (
				<Form noValidate onSubmit={handleSubmit}  id={'address-form-' + this.props.idn}>
					<fieldset disabled={this.props.disableFields }>
					<FormInputs
						ncols={['8']}
						className={['ml-0']}
						properties={[
							{
								label: 'Address Line 1',
								fieldType: 'text',
								name: 'addressLine1',
								autoComplete: 'address-line1',
								value: values.addressLine1,
								onChange: handleChange,
								isValid: touched.addressLine1 && !errors.addressLine1
							}
						]}
                    />
					<FormInputs
						ncols={['8', '4']}
						className={['', '']}
						properties={[
							{
								label: 'Address Line 2',
								autoComplete: 'address-line1',
								fieldType: 'text',
								name: 'addressLine2',
								value: values.addressLine2,
								onChange: handleChange,
								isValid: touched.addressLine2 && !errors.addressLine2
							},
							{
								label: 'City',
								fieldType: 'text',
								name: 'cityName',
								autoComplete: 'address-level2',
								value: values.cityName,
								onChange: handleChange,
								isValid: touched.cityName && !errors.cityName
							}
						]}
                    />
					<FormInputs
						ncols={['4', '4', '4']}
						className={['', '', '']}
						properties={[
							{
								label: 'Postal Code',
								fieldType: 'text',
								name: 'postalCodeText',
								autoComplete: 'postal-code',
								value: values.postalCodeText,
								onChange: handleChange,
								isValid: touched.postalCodeText && !errors.postalCodeText
							},
							{
								label: 'State/Province',
								fieldType: 'text',
								name: 'stateProvinceName',
								autoComplete: 'address-level1',
								value: values.stateProvinceName,
								onChange: handleChange,
								isValid: touched.stateProvinceName && !errors.stateProvinceName
							},
							{
								label: 'Country',
								fieldType: 'text',
								name: 'countryName',
								autoComplete: 'country-name',
								value: values.countryName,
								onChange: handleChange,
								isValid: touched.countryName && !errors.countryName
							}
						]}
                    />
					<hr />
					<Row className='mx-1'>
						<Col md={4}>
							<CBList 
								id='cblPhones'
								label='phone reference name'
								pkey='refName'
								value={this.state.phoneValue}
								selected={this.state.phone}
								liftUpOption={this.getPhone} 
								liftUpValue={this.getPhoneValue}
								items={this.props.appStates.busPhones ?? []} 
								placeholder='Search phone number...'
								whenEmpty='No phones registered...'
								disabled={this.state.disablePhoneCBList}
								nextFocus='validationPhoneCountryCode'
								/>
							</Col>
						<Col md={8}>
							<FormInputs
								ncols={['4', '3', '5']}
								className={['ml-0']}
								properties={[
									{
										label: 'Country Code',
										fieldType: 'text',
										name: 'phoneCountryCode',
										autoComplete: 'tel-country-code',
										value: values.phoneCountryCode,
										onChange: handleChange,
										isValid: touched.phoneCountryCode && !errors.phoneCountryCode
									},
									{
										label: 'Area Code',
										fieldType: 'text',
										name: 'areaCode',
										autoComplete: 'tel-area-code',
										value: values.areaCode,
										onChange: handleChange,
										isValid: touched.areaCode && !errors.areaCode
									},
									{
										label: 'Phone Number',
										fieldType: 'text',
										name: 'telephoneNumber',
										autoComplete: 'tel-local',
										value: values.telephoneNumber,
										onChange: handleChange,
										isValid: touched.telephoneNumber && !errors.telephoneNumber
									}
								]}
							/>
						</Col>
					</Row>
					<Row>
					    <Col md='5' className='mx-auto mt-4 text-center'>
							<Button 
								type='submit' 
								id={this.props.params.id + '-form-submit-' + this.props.idn} 
								style={ this.props.singleSubmit || 
										this.props.disableFields || 
										isSubmitting ? {display: 'none'}: {display: 'inline'} } >
									Submit
							</Button>
							{
								isSubmitting ? <div><h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
							}
						</Col>
					</Row>
					</fieldset>
				</Form>
				)}
			</Formik>
		)
	}
}

export default withRouter(AddressForm);