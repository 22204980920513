import React from 'react'
import bzrCollage from '../../assets/img/bzr-collage-1.png'
import bzrDemo from '../../assets/img/iphone-bzr-demo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import t from '../../utils/I18n/I18n'

class Home extends React.Component {
    render(){
        return(
			<div className="bzr-home mx-auto">
				<header id="header" className="header">
					<div className="container mb-4">
						<div className="row">
							<div className="p-2 header-info text-center col-md-8">
								<h1>{t('the power of your')} <span id="js-rotating">{t('business')}</span></h1>
								<h1>{t('in the palm of your hand')}</h1>
								<br />
								<p>{t('bizor is the smart way to analyze the performance your business')}</p>
								<p>{t('start with a few taps on your mobile, adopt mobility and live a clean and simple experience while optimizing the operation of your company')}</p>
								<br />
								<div className="text-center">
									<a className="btn-solid-lg page-scroll" href="#your-link"><FontAwesomeIcon icon={faApple} /> APP STORE</a> 
									<a className="btn-solid-lg page-scroll" href="#your-link"><FontAwesomeIcon icon={faGooglePlay} /> PLAY STORE</a>
								</div>
							</div>
							<div className="p-2 header-image  col-md-4">
								<img className="img-fluid" src={bzrCollage} alt="alternative" />
							</div>
						</div>
					</div>
				</header> 
				<div id="features" className="tabs">
					<div className="row">
						<div className="col-lg-12">
							<h2>{t('features')}</h2>
							{/* <div className="p-heading p-large">
								{t('bizor was designed based on input from business owners and managers so it offers a smart way to see your business in action')}
							</div> */}
						</div> 
					</div> 
					<div className="row tab-pane info" id="tab-1">
						<div className="col-lg-4">
							<div className="card left-pane first">
								<div className="card-body">
									<div className="text-wrapper">
										<h4 className="card-title">{t('easy configuration')}</h4>
										<p>{t('simple & fast step by step interface to set your business premises')}</p>
									</div>
									<div className="card-icon">
										<i className="far fa-compass"></i>
									</div>
								</div>
							</div>
							<div className="card left-pane">
								<div className="card-body">
									<div className="text-wrapper">
										<h4 className="card-title">{t('custom features')}</h4>
										<p>{t('bizor models are based on internatioal business standars, nevertheless, you can add any custom field to meet your operation requirements')}</p>
									</div>
									<div className="card-icon">
										<i className="far fa-file-code"></i>
									</div>
								</div>
							</div>
							<div className="card left-pane">
								<div className="card-body">
									<div className="text-wrapper">
										<h4 className="card-title">{t('bizor API')}</h4>
										<p>{t('we offer a full set of APIs, so you can handle your company\'s data anywhere & anytime by third party softwares')}</p>
									</div>
									<div className="card-icon">
										<i className="far fa-gem"></i>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 d-md-none d-lg-block">
							<img src={bzrDemo} alt="alternative" height="500" />
						</div>
						<div className="col-lg-4">
										<div className="card right-pane first">
											<div className="card-body">
												<div className="card-icon">
													<i className="far fa-calendar-check"></i>
												</div>
												<div className="text-wrapper">
													<h4 className="card-title">{t('quick reports')}</h4>
													<p>{t('besides our predifined set of reports, you can define your own reports with in a few steps')}</p>
												</div>
											</div>
										</div>
										<div className="card right-pane">
											<div className="card-body">
												<div className="card-icon">
													<i className="far fa-bookmark"></i>
												</div>
												<div className="text-wrapper">
													<h4 className="card-title">{t('improve on demand')}</h4>
													<p>{t('most of the features can be modified once you configure then the first time, leting you improve your experience and results')}</p>
												</div>
											</div>
										</div>
										<div className="card right-pane">
											<div className="card-body">
												<div className="card-icon">
													<i className="fas fa-cube"></i>
												</div>
												<div className="text-wrapper">
													<h4 className="card-title">{t('assisted configuration')}</h4>
													<p>{t('for complex business topologies, don\'t worry, our support team will be pleased to help you in this process')}</p>
												</div>
											</div>
										
							</div> 
						</div> 
					</div>  
				</div> 
			</div>
        )
    }
}

export default Home;