import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { productHeader } from './Functions'
import t from '../../../utils/I18n/I18n'

class Printer {
    static print(tableId:string, products:any, options:any) {
        const doc = new jsPDF('p', 'mm', 'letter')
        productHeader(doc, options)  ///  RENDER DOCUMENT HEADER
            
        this.renderTable(doc, tableId, products)
        return doc.output('blob')
    }    

    static renderTable(doc:any, tableId:string, products:any) {
        let tb = document.getElementById(tableId)
        let decodedTable:any = doc.autoTableHtmlToJson(tb, true)
        let columns:any[] = decodedTable.columns.slice(2)

        columns.forEach((c:any, i:number) => {c.header.content = c.header.content.toUpperCase() })

        const body = decodedTable.rows.map((r:any) => {
            let res:any = []
            r.forEach((d:any, i:number) => {
                if(!(i === 0 || i === 1)  && i < decodedTable.columns.length) {
                    if(d._element.className.match('text-right')) d.styles = {halign: 'right'}
                    res.push(d)
                }
            })
            return res
        })
        const hooks:any = {
            didParseCell: (data:any) => {
                const i = data.row.index
                if(data.section === 'head') data.cell.text[0] = data.cell.raw.innerText
                if(data.section === 'head' && data.cell.text[0].match(/sku/ig)) data.cell.styles.cellWidth = 20
                if(data.section === 'head' && data.cell.text[0].match(/oem/ig)) data.cell.styles.cellWidth = 25
            
                if(data.section === 'head' && data.cell.text[0].match(/image/ig)) {
                    data.cell.styles.cellWidth = 25
                    data.cell.styles.halign = 'center'
                }
                if(data.section === 'body' && products[i].imageURL && data.table.head[0].cells[data.column.index].text[0].match(/image/ig)) {
                    data.row.height = 25
                }
                if (data.section === 'body' && data.table.head[0].cells[data.column.index].text[0].match(/image/ig) && data.row.index > -1 && !products[data.row.index].imageURL) {
                    data.cell.text = ['']
                }
            },
            didDrawCell: (data:any) => {
                if (data.section === 'body' && data.table.head[0].cells[data.column.index].text[0].match(/image/ig) && data.row.index > -1 && products[data.row.index].imageURL) {
                    const i = data.row.index, img:any = new Image() // HTML5 Constructor
                    img.src = products[i].imageURL
                    const imgProps:any = doc.getImageProperties(img)
                    let width = 20, height= 20
                    if(imgProps.width/imgProps.height > 1) {
                        height = Math.floor(width * imgProps.height/imgProps.width)
                    } else {
                        width = Math.floor(height * imgProps.width/imgProps.height)
                    }
                    doc.addImage(img, imgProps.fileType, data.cell.x + 2, data.cell.y + 2, width, height)
                }
            }
        }
        doc.autoTable(
            Object.assign(
                {
                    columns, 
                    body,
                    margin: {top: 15, bottom: 25},
                    startY: 40,
                    headStyles: {fontSize: 8, fontStyle: 'bold', fillColor: [70, 70, 70] },
                    bodyStyles: {fontSize: 8}
                }, 
                hooks
            )
        )
    }

    ///// Old implementation
    static renderTable1(doc:any, table:any, products?:any) {
        if(table.html) {}
            const hooks:any = {
                didParseCell: (data:any) => {
                    console.log(data)
                    const i = data.row.index
                    if(data.section === 'head'){
                        data.cell.text[0] = t(data.cell.text[0])
                    }
                    if(data.section === 'body' && products[i].imageURL) data.row.height = 25
                    if (data.section === 'body' && data.row.index % 2 === 0 ){
                        data.cell.styles.fillColor = 240
                    } 
                    if (data.column.index === 1) {
                        data.cell.styles.cellWidth = 25
                    }
                    if (data.column.index === 7) {
                        data.cell.styles.cellWidth = 25
                        data.cell.styles.halign = 'center'
                    }
                    if (data.section === 'body' && data.column.index === 1) {
                        data.cell.text = this.splitByKey(data.cell.text[0], ['MODEL'])
                    }
                    if (data.section === 'body' && data.column.index === 2) {
                        data.cell.text = this.splitByKey(data.cell.text[0], ['SKU', 'BARCODE'])
                    }
                },
                didDrawCell: (data:any) => {
                    if (data.section === 'body' && 
                            data.column.index === 7 && 
                            products[data.row.index].imageURL) {
                        const i = data.row.index, img:any = new Image() // HTML5 Constructor
                        img.src = products[i].imageURL
                        const imgProps:any = doc.getImageProperties(img)
                        let width = 20, height= 20
                        if(imgProps.width/imgProps.height > 1) {
                            height = Math.floor(width * imgProps.height/imgProps.width)
                        } else {
                            width = Math.floor(height * imgProps.width/imgProps.height)
                        }
                        doc.addImage(img, imgProps.fileType, data.cell.x + 2, data.cell.y + 2, width, height)
                    }
                }
            }
            
            doc.autoTable(Object.assign(table, hooks))


/*         doc.autoTable({
            head: [['Name', 'Email', 'Country']],
            body: [
                ['David', 'david@example.com', 'Sweden'],
                ['Castille', 'castille@example.com', 'Norway'],
                // ...
            ],
            }) */
    }

    static splitByKey(text:any, keys:string[]) {
        let result:string[] = [], i0 = 0, prev = ''
        text = text.trim()
        keys.forEach((key:string, i:number) => {
            i0 = text.search(key + ':')
            if (i0 > 0) {
                const ts = text.slice(0, i0).trim()
                if(ts !== '') {
                    result.push(prev + ts) 
                    text = text.substr(i0 + key.length + 1).trim()
                } else {
                    text = text.substr(key.length + 1).trim()
                }
                prev = key + ': '
            } else if(i0 === 0) {
                prev = key + ': '
                text = text.substr(key.length + 1).trim()
            }  else if(i0 !== -1) {
                text = text.substr(0, i0 + key.length + 1)
            } 
        })
        if(text.length > 0) result.push(prev + text) 
        return result 
    }
}

export default Printer