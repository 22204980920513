import React from 'react'
import { Container, Row, Col, ProgressBar, Button, Alert } from 'react-bootstrap'
import { RouteComponentProps, withRouter } from "react-router-dom"
import { History, LocationState } from "history"
import TopNavbar from './Navbars/TopNavbar'
import Footer from './Footer/Footer'
import { enrollmentRoutes, getRoutes } from '../routes/enrollmentRoutes'

interface Props extends RouteComponentProps<LocationState>{
    history: History<LocationState>,
    appStates: any,
    liftUpAppStates: any
}

interface States {
    childrenSubmitBtnIds: string[],
    route: string,
    progress: number,
    alertMsg: any
}

class Enrollment extends React.Component<Props, States> {

    progress = [1100, 1300, 1500, 1700]
    path = ''

    constructor(props: Props) {
        super(props)
        this.state = {
            childrenSubmitBtnIds: [],
            route: '',
            progress: 50,
            alertMsg:  props.appStates.org ? {
                variant: 'success', 
                heading: 'One more step to 100%', 
                message: 'Create your Business Unit and be welcomed to Bizor'
            } : null 
        }
    }

    componentDidUpdate() {
        if(this.props.appStates.org && !this.state.alertMsg)
            this.setState({alertMsg:{
                variant: 'success', 
                heading: 'One more step to 100%', 
                message: 'Create your Business Unit and be welcomed to Bizor'
                }
            })
        this.evalStatus(this.props.appStates.user.userStatus)
    }

    componentWillUpdate() {
        
    }

    evalStatus(status: number) {
        let path = window.location.pathname
        if(status > 2000) {
            this.props.history.push('/admin/dashboard')
            return
        }
        //this.props.liftUpAppStates({enrollment: true})
        
		switch(status) {
			case 900:
				if(path !== '/enrollment/users/personal-information')
                this.props.history.push('/enrollment/users/personal-information')
                else this.path = '/enrollment/users/personal-information'
				return
			case 1100:
				if(path !== '/enrollment/users/primary-address')
                this.props.history.push('/enrollment/users/primary-address')
                else this.path = '/enrollment/users/primary-address'
				return
			case 1300:
				if(path !== '/enrollment/users/preferences')
                this.props.history.push('/enrollment/users/preferences')
                else this.path = '/enrollment/users/preferences'
                return
            case 1500:
                if(path !== '/enrollment/users/org')
                this.props.history.push('/enrollment/users/org')
                else this.path = '/enrollment/users/org'
                return
			case 1700:
				if(path !== '/enrollment/users/org')
                this.props.history.push('/enrollment/users/org')
                else this.path = '/enrollment/users/org'
                return		
            default:
                window.location.href = '/home' 
                return		
		}
	}

	submit() {
        const forms:any = document.getElementsByClassName('single-submit')
        for (let i = 0; i < forms.length; i++) forms[i].click()
    }

    
    render() {
        let status = this.progress.reduce((total, value, index, array) => {
                if(value === this.props.appStates.user.userStatus) {
                    return total + (index + 1) * 100 / array.length
                } else return total  
            }, 0)
        return(
            <div>
                <TopNavbar user={this.props.appStates.user}/>
                <Container className="my-3 main-container">  
                    <Row>
                        <Col lg="9" md="8" className="flex mx-auto enrollment-progress">
                            <h3>Enrollment Progress</h3>
                            <ProgressBar animated now={status} label={`${status}%`} ></ProgressBar>
                        </Col>
                        <Col lg="9" md="8" className="mx-auto mb-0">
                        {this.state.alertMsg ? 
                            (<Alert className="mt-4 mb-0" variant={this.state.alertMsg.variant} onClose={() => this.setState({alertMsg: null})} dismissible>
                                <Alert.Heading>{this.state.alertMsg.heading}</Alert.Heading>
                                {this.state.alertMsg.message}
                            </Alert>) : null
                        } 
                        </Col>
                    </Row>
                    <div style={{maxWidth: this.props.appStates.user.userStatus === 1500 ? "900px" : "768px" , margin: "auto"}}>
                        {
                            getRoutes(
                                [enrollmentRoutes(window.location.pathname)], 
                                { 
                                    appStates: this.props.appStates, 
                                    liftUpAppStates: this.props.liftUpAppStates,
                                    singleSubmit: this.props.appStates.user.userStatus < 1500 ? true : false
                                }
                            )
                        }
                        <Row>
                            <Col md="5" className="mx-auto my-4 text-center">
                                <Button 
                                    onClick={() => this.submit()}
                                    style={this.props.appStates.submitting || this.props.appStates.user.userStatus >= 1500 ? {display: "none"}: {display: "inline"}}
                                    >
                                    Submit
                                </Button>
                                {/* <Button variant="secondary" className="ml-3">Skip</Button> */}
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default withRouter(Enrollment)