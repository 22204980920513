import SaleOrders from './PDF/SaleOrders'
import CreditDebitNote from './PDF/CreditDebitNote'
import DeliveryNotes from './PDF/DeliveryNotes'
import Products from './PDF/Products'
import Table from './PDF/Table'
import DispatchNote from './PDF/DispatchNote'


export const printSaleOrder = (order:any, transacs:any, client:any, options?:any) => 
    SaleOrders.print(order, transacs, client, options)

export const printCreditDebitNote = (order:any, transacs:any, client:any, options?:any) => 
    CreditDebitNote.print(order, transacs, client, options)

export const printDeliveryNote = (order:any, transacs:any, client:any, options?:any) => 
    DeliveryNotes.print(order, transacs, client, options)

export const printDispatchNote = (order:any, transacs:any, client:any, options?:any) => 
    DispatchNote.print(order, transacs, client, options)

export const printProductsList = (tableId:any, products:any, options:any) => 
    Products.print(tableId, products, options)

export const printTable = (html:any, options:any) => 
    Table.print(html, options)


