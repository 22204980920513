import React from 'react'
import Table from 'react-bootstrap/Table'
import t, { fn, dn } from '../../../utils/I18n/I18n'
import BzrAxios from '../../../utils/BzrAxios'

interface Props {
    transacs:any
    sales_hash:any[]
    range:any
    locale:any
    products_hash:any
    clients_hash:any
    urlParams:any
}
interface State {
    clients:any,
    facilities:any
}
class ClientTotals extends React.Component<Props,State> {
    constructor(props:Props) {
        super(props)
        this.state = {
            clients: [],
            facilities: []
        }
    }
    async componentDidMount() {
        let clients:any = {}
        const transacs:any = this.props.transacs
        transacs.forEach((t:any) => {
            const sale:any = this.props.sales_hash[t.bzrOrderId]
            const client:any = this.props.clients_hash[sale.clientId]
            const quantity:any = t.retailed === 'true' ? parseFloat(t.quantity) / parseFloat(t.unitsPerPkg) : parseFloat(t.quantity)
            if(!clients[sale.clientId]){
                clients[sale.clientId] = {
                    client,
                    tpp: {[t.bzrProductId]: {quantity, amount: quantity * parseFloat(t.entryValue), retailed: t.retailed, unitsPerPkg: t.unitsPerPkg }}, 
                    tpl: {}
                }
            } else if(clients[sale.clientId] && !clients[sale.clientId].tpp[t.bzrProductId]) {
                clients[sale.clientId].tpp[t.bzrProductId] = {quantity, amount: quantity * parseFloat(t.entryValue), retailed: t.retailed, unitsPerPkg: t.unitsPerPkg }
            } else if(clients[sale.clientId] && clients[sale.clientId].tpp[t.bzrProductId]) {
                clients[sale.clientId].tpp[t.bzrProductId].quantity += quantity 
                clients[sale.clientId].tpp[t.bzrProductId].amount += quantity * parseFloat(t.entryValue)
            }
            if(clients[sale.clientId] && !clients[sale.clientId].tpl[sale.facilityId]) {
                clients[sale.clientId].tpl[sale.facilityId] = {[t.bzrProductId]: {quantity, amount: quantity * parseFloat(t.entryValue), retailed: t.retailed, unitsPerPkg: t.unitsPerPkg}}                
            } else if(clients[sale.clientId] && clients[sale.clientId].tpl[sale.facilityId] && !clients[sale.clientId].tpl[sale.facilityId][t.bzrProductId]) {
                clients[sale.clientId].tpl[sale.facilityId][t.bzrProductId] = {quantity, amount: quantity * parseFloat(t.entryValue), retailed: t.retailed, unitsPerPkg: t.unitsPerPkg}
            } else if(clients[sale.clientId] && clients[sale.clientId].tpl[sale.facilityId] && clients[sale.clientId].tpl[sale.facilityId][t.bzrProductId]) {
                clients[sale.clientId].tpl[sale.facilityId][t.bzrProductId].quantity += quantity 
                clients[sale.clientId].tpl[sale.facilityId][t.bzrProductId].amount += quantity * parseFloat(t.entryValue)
            }
        })
        const data:any = { where: { clientId: Object.keys(clients) } }
        BzrAxios.records({url: `/get/BzrClientFacility`, method: 'POST', data})
            .then((response:any) => {
                this.setState({clients, facilities: response.data.records})
            })
            .catch( err => console.log(err))
    }

    render() {
        // Totals Per Location
        const renderTpl:any = Object.values(this.state.clients).map((c:any, l:any) => {
            let ths:any = [], ths1:any = [], tfs:any = [], totals:any = {}
            Object.keys(c.tpp).forEach((p:any, k:number) => {
                ths.push(<th key={`a${l}${k}`} className="text-center">{t('quantity')}</th>)
                ths.push(<th key={`b${l}${k}`} className="text-center">{t('amount')}</th>)
                ths1.push(<th colSpan={2} key={`b${l}${k}`}>{this.props.products_hash[p].name}</th>)
            })
            return(
                <div className="ml-3" key={l}>
                    <h5>{c.client.legalName} ({dn(this.props.range.from, this.props.locale)} - {dn(this.props.range.to, this.props.locale)})</h5>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th></th>
                                {ths1}
                            </tr>
                            <tr>
                                <th>{t('location')}</th>
                                {ths}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(c.tpl).map((f:any, k:number) => {
                                let tds:any = []
                                tfs = []
                                Object.keys(c.tpp).forEach((p:any, l:number) => {

                                    if(!totals[p]) totals[p] = {}
                                    if(c.tpl[f][p]){
                                        const quantity:any = c.tpl[f][p].quantity
                                        totals[p].quantity = totals[p].quantity ? (totals[p].quantity + quantity) : quantity
                                        totals[p].amount = totals[p].amount ? (totals[p].amount + c.tpl[f][p].amount) : c.tpl[f][p].amount
                                        tds.push(<td key={`k1${k}${l}`} className="text-right pr-3">{fn(quantity)}</td>)
                                        tds.push(<td key={`k2${k}${l}`} className="text-right pr-3">{fn(c.tpl[f][p].amount)}</td>)
                                    } else {
                                        tds.push(<td key={`k1${k}${l}`} className="text-right pr-4">-</td>)
                                        tds.push(<td key={`k2${k}${l}`} className="text-right pr-4">-</td>)
                                    }
                                    // Update totals with the las iteration
                                    tfs.push(<td key={`k3${k}${l}`} className="text-right pr-3">{fn(totals[p].quantity)}</td>)
                                    tfs.push(<td key={`k4${k}${l}`} className="text-right pr-3">{fn(totals[p].amount)}</td>)
                                })
                                const facility:any  = this.state.facilities.find((fa:any) => fa.id === f)
                                return(
                                <tr key={k}>
                                    <td>{facility ? facility.facilityName : 'N/A'}</td>
                                    {tds}
                                </tr>
                                )}
                            )}
                        </tbody>
                        <tfoot className="bg-light small">
                            <tr>
                                <td className="pl-3 text-uppercase">{t('totals')}</td>
                                {tfs}
                            </tr>
                        </tfoot>
                    </Table>
                    <br />
                </div>
            )
        })
        return(
            <div className="my-5 mx-3 bzr-lateral-scrollable-75vh">
                <h2>{t('totals per location')}</h2>
                <br />
                <div className="">
                    {renderTpl}
                </div>
            </div>
        )
    }
}

export default ClientTotals