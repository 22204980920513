import React, { Component } from 'react'
import { Form, FormControl, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
//import t from '../../utils/I18n/I18n'

function FieldGroup({ label, feedback, fieldType, horizontalLabel, isValid, isInvalid, ...props }:any) {  
    //label = t(label)
    if(fieldType === "text") {
        return (
        <Form.Row>
            <Form.Label className="bzr-labels" column sm={ horizontalLabel ?? 12 }>{label} {props.info}</Form.Label>
            <Col sm={ horizontalLabel ? 12 - horizontalLabel : 12 }>
                <FormControl type="text" isValid={isValid} isInvalid={isInvalid} {...props} />
                <Form.Control.Feedback>{feedback}</Form.Control.Feedback>
                <Form.Control.Feedback type='invalid'>{props.error}</Form.Control.Feedback>
            </Col>
        </Form.Row>
        )
    } else if(fieldType === "textarea") {
        return (
        <Form.Row>
            <Form.Label className="bzr-labels" column sm={ horizontalLabel ?? 12 }>{label} {props.info}</Form.Label>
            <Col sm={ horizontalLabel ? 12 - horizontalLabel : 12 }>
                {/* <FormControl as="textarea" {...props} /> */}
                <textarea id={props.name} name={props.name} rows={props.rows ?? 3} cols={props.cols ?? 35} {...props}/>
            </Col>
        </Form.Row>
        )
    } else if (fieldType === "date") {
        return (
            <Form.Row>
                <Form.Label className="bzr-labels" column sm={ horizontalLabel ?? 12 }>{label} {props.info}</Form.Label>
                <Col sm={ horizontalLabel ? 12 - horizontalLabel : 12 }>
                    <DatePicker 
                        dateFormat="yyyy-MM-dd" 
                        className="form-control" 
                        selected={(props.value && new Date(props.value)) || null}
                        showYearDropdown
                        dropdownMode="select"
                        onChange={ val => { props.onChange(props.name, val) }}
                        />
                    <Form.Control.Feedback>{feedback}</Form.Control.Feedback>
                </Col>
            </Form.Row>
            )
    } else if(fieldType === "select") {
        return (
            <Form.Row>
                <Form.Label className="bzr-labels" column sm={ horizontalLabel ?? 12 }>{label} {props.info}</Form.Label>
                <Col sm={ horizontalLabel ? 12 - horizontalLabel : 12 }>
                    <Form.Control 
                        as="select"
                        {...props}
                        isValid={isValid} 
                        isInvalid={isInvalid}
                        className={`form-control custom-select ${props.className}`}
                        >
                            { props.options.map(
                                (option:any, i:any) => (<option key={i} value={option[0]}>{option[1]}</option>)
                            )}
                    </Form.Control>
                </Col>
            </Form.Row>
            )
    } else if(fieldType === "switch") {
        return (
            <div>
                <Form.Check 
                    defaultChecked={props.value}
                    onChange={props.onChange}
                    type="switch"
                    id={props.name + '-switch-id'}
                    name={props.name}
                    label={label}
                />
            </div>
            )
    } else if(fieldType === "checkbox") {
        return (
            <div>
                <Form.Check
                defaultChecked={props.defaultChecked}
                    onChange={props.onChange}
                    type="checkbox"
                    id={props.id ?? props.name + '-radio-id'}
                    name={props.name}
                    label={label}
                />
            </div>
            )
    } else if(fieldType === "radio") {
        return (
            <div>
                <Form.Check
                    {...props}
                    type="radio"
                    id={props.id ?? props.name + '-radio-id'}
                    label={label}
                />
            </div>
            )
    }  else {
        return (<div></div>)
    }
}

const capitalize = (s:string) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

interface Props {
    ncols: string[],
    className: string[],
    children?: any,
    properties: any[]
}

class FormInputs extends Component<Props> {
    render() {
        var row = [];
        for (var i = 0; i < this.props.ncols.length; i++) {
            if (this.props.properties[i]) {
                row.push(
                    <Form.Group 
                        key={i}
                        as={Col} 
                        md={this.props.ncols[i]} 
                        className={ this.props.className[i] }
                        controlId={"validation" + capitalize(this.props.properties[i]['name'])} >
                        <FieldGroup {...this.props.properties[i]} />
                    </Form.Group>
                )
            } else {
                row.push(<Col key={i} md={Number.parseInt(this.props.ncols[i])} className={ this.props.className[i] === "" ? "mx-auto px-2" : "px-2 " + this.props.className[i] }>
                    { this.props.children![i] }
                    </Col>)
            }
        }
        return <Form.Row>{row}</Form.Row>;
    }
}
export default FormInputs