import React from 'react'
import { Table, Container, Row, Col, Form, ButtonToolbar, ButtonGroup, Button, DropdownButton } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import FilterSearch from './FilterSearch'
import XLSX from 'xlsx'
import { printTable } from '../Printer/PdfPrinter'
import t, { fn } from '../../utils/I18n/I18n'
import Globals from '../../utils/Globals'

interface Props {
    id:string
    items:any[]
    headers:any[]
    extras?:any
    title?:string
    defaultHeaders?:any
    liftUpHeaders?:any
    liftUpItems?:any
    updateClick?:any
    fields?:any
    actions?:any
    reportsMenu?:any
    refreshing?:boolean
    doubleClick?:any
}
interface State {
    items:any[]
    filteredItems:any[]
    headers:any[]
    trs:any[]
    keys:any
    filters:any
    printing:boolean
    maths:any
    defaultHeaders:any
    refreshing:boolean
}
class BzrTable extends React.Component<Props,State> {
    constructor(props:Props) {
        super(props)
        this.state = {
            items: [],
            filteredItems: [],
            headers: [],
            trs: [],
            keys: null,
            filters: {},
            printing: false,
            maths: {},
            defaultHeaders: [],
            refreshing: false
        }
        this.handleFiltering = this.handleFiltering.bind(this)
        this.handleClearFilter = this.handleClearFilter.bind(this)
    }
    componentDidMount() {
        if(this.props.fields && this.props.fields.length > 0 && this.props.headers.length > 0 && this.state.headers.length === 0) {
            
            const headers:any[] = this.props.headers
            
            headers.forEach((h:any) => {
                const fn:any = this.props.fields.find((fi:any) => fi.fieldName === h.fieldName)
                h = Object.assign(h,fn)
            })
            this.setState({headers})
        }
        if(this.props.items && this.props.items.length > 0 && JSON.stringify(this.state.items) !== JSON.stringify(this.props.items)) {
            this.setState({items: this.props.items.map((i:any) => i), filteredItems: this.props.items, refreshing: true})
            this.renderBody(this.props.items)
            this.setState({refreshing: false})
        }
    }
    componentDidUpdate() {
        if(this.props.fields && this.props.fields.length > 0 && this.props.headers.length > 0 && this.state.headers.length === 0) {
            
            const headers:any[] = this.props.headers
            
            headers.forEach((h:any) => {
                const fn:any = this.props.fields.find((fi:any) => fi.fieldName === h.fieldName)
                h = Object.assign(h,fn)
            })
            this.setState({headers})
        }
        if(this.props.items && this.props.items.length > 0 && JSON.stringify(this.state.items) !== JSON.stringify(this.props.items)) {
            this.setState({items: this.props.items.map((i:any) => i), filteredItems: this.props.items, refreshing: true})
            this.renderBody(this.props.items)
            this.setState({refreshing: false})
        }
    }
    handleFiltering(filter:any) {
        const filters:any = Object.assign(this.state.filters, filter)
        var filteredItems:any[] = this.props.items
        
        for(let f in filters) {
            if(filters[f] && filters[f].values) {
                
                const items:any[] = filteredItems
                filteredItems = []
                const filterValues:any = filters[f].values,
                dataType:string = filters[f].dataType
                // eslint-disable-next-line no-loop-func
                items.forEach((i:any) => {
                    let key:any = i[f]
                    
                    if(dataType === 'date') {
                        key = new Date(i[f]).toLocaleDateString(Globals.defaults.activeBU.locale ?? 'en-US', { day: '2-digit', month: '2-digit', year: 'numeric'})
                    }
                    
                    if(filterValues && filterValues[key] && filterValues[key].selected) filteredItems.push(i)
                })
            } 
        }
        
        if(filteredItems.length === 0) filteredItems = this.props.items
        this.setState({filteredItems})
        if(this.props.liftUpItems) this.props.liftUpItems(filteredItems)
        this.renderBody(filteredItems)
    }
    handleClearFilter() {
        const items:any = this.state.filteredItems, cball:any = document.getElementById('select-all-items')
        cball.checked = false
        items.forEach((item:any, i:number) => {
            item.cc = false
        })
        this.setState({filteredItems: items})
        if(this.props.liftUpItems) this.props.liftUpItems(items)
        this.renderBody(items)
    }
    exportFile() {
        const headers = this.state.headers.map((h:any) => h.fieldLabel ?? h.fieldName )
        const items = this.state.filteredItems.map((i:any, k:number) => {
            let tds:any[] = []
            this.state.headers.forEach((h:any, l) => {
                if(this.props.fields) {
                    const f:any = this.props.fields.find((fi:any) => fi.fieldName === h.fieldName)
                    if(f.fieldType === 'numeric') {
                        tds.push(fn(i[h.fieldName]))
                        //this.maths(h.fieldName, i[h.fieldName])
                    } else tds.push(i[h.fieldName])
                } else {
                    tds.push(i[h.fieldName])
                }
            })
            return tds
        })
        /* convert state to workbook */
        let data:any[] = []
        data.push([Globals.defaults.activeBU.legalName])
        data.push([Globals.defaults.activeBUId.identificationNumber])
        data.push([])
        data.push(headers)
        data = data.concat(items)

        console.log(data)

        const ws = XLSX.utils.aoa_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, Globals.defaults.activeBU.legalName.replace(/\W+/g,'_') + '-transacs.xlsx')
    }
    dropdownMenu() {
        return(
            <Container className="small" style={{minWidth: "250px"}}>
                <Row>
                    <Col md={11}>
                        <h6>{t('fields')}</h6>
                        {this.props.headers.map((h:any,i:number) => {
                            return(
                            <Form.Group controlId={"fieldCheck" + i} key={i}>
                                <Form.Check type="checkbox" name={h.fieldName} label={h.fieldLabel ? t(h.fieldLabel) : h.fieldName} checked={h.show} onChange={(event:any) => {
                                    const defaultHeaders:any = this.props.headers, headers:any = this.state.headers
                                    defaultHeaders[i].show = event.target.checked
                                    headers[i].show = event.target.checked
                                    h.show =  event.target.checked 
                                    this.props.liftUpHeaders(defaultHeaders)
                                    this.setState({headers})
                                    this.renderBody(this.props.items)
                                }}/>
                            </Form.Group>
                            )}
                        )}
                    </Col>
                </Row>
            </Container>
        )
    }
    renderHeader() {
        let tr:any = [
            <th key="h-all"  className="text-center">
                <input id="select-all-items" type="checkbox" 
                    onClick={(event:any) => {
                        const items:any = this.state.filteredItems
                        items.forEach((item:any, i:number) => {
                            item.cc = event.target.checked
                        })
                        this.setState({filteredItems: items})
                        if(this.props.liftUpItems) this.props.liftUpItems(items)
                        this.renderBody(items)
                    }}/>
            </th>,
            <th key="h-item-n" className="text-center">#</th>
        ]
        this.state.headers.forEach((h:any, k:number) => {
            if(h.show) {
                tr.push(
                <th key={k} style={h.style ?? {}}>
                    {h.filter ? 
                    <FilterSearch 
                    id={k}
                    pkey={h.fieldName}
                    label={h.fieldLabel ? t(h.fieldLabel): h.fieldName}
                    liftUpOption={this.handleFiltering}
                    handleClear={this.handleClearFilter}
                    items={this.state.keys ? this.state.keys[h.fieldName] : []}
                    placeholder={t('search...')}
                    whenEmpty="No parties..."
                    /> : <div>{t(h.fieldLabel) ?? t(h.fieldName)}</div>}
                </th>
                )
            }
        })
        if(this.props.actions) tr.push(<th key="ha">actions</th>)
        return (<tr>{tr}</tr>)
    }
    renderBody(items:any[]) {
        let trs:any = [], keys:any = {}, maths:any = {}
        items.forEach((i:any,k:number) => {
            let tds:any = []
            i.cc = i.cc ?? false
            tds.push(
                <td key={`cc-${k}`} className="text-center">
                    <input id={i.id} className={`${this.props.id}-cb`} type="checkbox"
                        checked={i.cc}       
                        onChange={(event:any) => {
                            i.cc = event.target.checked
                            this.setState({filteredItems: items})
                            if(this.props.liftUpItems) this.props.liftUpItems(items)
                            this.renderBody(items)
                            }}/>
                </td>
            )
            tds.push(<td key={`cn-${k}`} className="text-center">{k + 1}</td>)
            tds = tds.concat(this.props.headers.map((h:any, l:number) => {
                if(h.show) {
                    const fieldType:string = h.fieldType, alarm = h.alarm
                    const fdn = h.fieldName  
                    let td:any, cn:string = ''
                    if(fieldType === 'numeric') {
                        if(h.totalizeIf && i[h.totalizeIf.ref].indexOf(h.totalizeIf.stringLike) > -1) {
                            maths[fdn] = maths[fdn] ?? {sum: 0, avg: 0}
                            maths[fdn].sum += i[fdn] ? parseFloat(i[fdn]) : 0
                            maths[fdn].avg = maths[fdn].sum/(k + 1)
                            cn += 'font-weight-bold '
                        } else {
                            cn += 'text-muted '
                        }
                        td = h.format === 'percentage' ? `${fn(i[fdn] * 100)}%` : fn(i[fdn])
                        cn += 'text-right pr-3'
                    } else if( fieldType === 'date') {
                        td = new Date(i[fdn]).toLocaleDateString(Globals.defaults.activeBU.locale ?? 'en-US', { day: '2-digit', month: '2-digit', year: 'numeric'})
                        if(alarm && alarm.daysBefore && (i.transacStatus === 'printed_legal' || i.transacStatus === 'printed_receipt')) {
                            const now:any = new Date()
                            const e:any = new Date(i[fdn])
                            const diff = (e - now)/(24*60*60*1000)
                            if(diff >= 0 && diff < alarm.daysBefore) cn = 'show-alarm-warning'
                            if(diff < 0 && i.oPaymentStatus && i.oPaymentStatus !== 'complete_paid') cn = 'show-alarm-danger'
                            //if(i.oPaymentStatus && i.oPaymentStatus !== 'complete_payment' && diff < 0) cn = 'show-alarm-success'
                            if(i.oPaymentStatus && i.oPaymentStatus === 'complete_paid') cn = 'show-alarm-success'
                        }
                    } else if(!i[fdn]) {
                        td = '-'
                    } else {
                        td = i[fdn]
                    }
                    if(!keys[fdn]) {
                        keys[fdn] = {values: [], dataType: fieldType}
                        keys[fdn].values.push(td)
                    } else if(!keys[fdn].values.includes(td)) {
                        keys[fdn].values.push(td)
                    }
                    return(<td key={l} className={cn} >{td}</td>)
                } else return null
            }))
            if(this.props.actions) tds.push(<td key="ba">{this.props.actions(i, k, this.props.extras.options)}</td>)

            trs.push(<tr key={k} onDoubleClick={() => {if(this.props.doubleClick) this.props.doubleClick(i, k, this.props.extras.options) }}>{tds}</tr>)
            this.setState({maths})
        })

        /////////////
        let td:any[] = [<td key="f0"></td>, <td key="f1"></td>]
        this.props.headers.forEach((h:any, k:number) => {
            if(k === 0) {
                td.push(<td key={k}>{t('totals')}</td>)
            } else if(h.show) {
                td.push(
                    <td key={k}>
                        {h.fieldType === 'numeric' ? 
                        <div>
                            <span>{maths[h.fieldName] ? fn(maths[h.fieldName].sum ): null}</span>
                        </div>
                        : null }
                    </td>
                )
            }
        })
        if(this.props.actions) td.push(<td key="f3"></td>)
        trs.push(<tr key="sum-row" className="bg-secondary text-light text-uppercase">{td}</tr>)
        //this.setState({trs, keys: !this.state.keys ? keys : this.state.keys})
        this.setState({trs, keys})
    }
    renderFooter() {
        let td:any[] = [<td key="f1" colSpan={2}></td>]
        this.props.headers.forEach((h:any, k:number) => {
            if(h.show) {
                td.push(
                    <td key={k}>
                        {h.fieldType === 'numeric' ? 
                        <div>
                            <div>sum:<span>{this.state.maths[h.fieldName] ? fn(this.state.maths[h.fieldName].sum ): null}</span></div><div>avg:<span>{this.state.maths[h.fieldName] ? fn(this.state.maths[h.fieldName].avg) : null}</span></div>
                        </div>
                        : null }
                    </td>
                )
            }
        })
        if(this.props.actions) td.push(<td key="f2"></td>)
        return (<tr>{td}</tr>)
    }
    render() {
        return (
            <Container>
                { this.props.refreshing || this.state.refreshing ? <div className="modal-frame"><div>{t('updating')} <FontAwesomeIcon icon={faSpinner} spin /></div></div> : null }
                <Row>
                    <Col md={6}>
                        { this.props.title ? <h4>{this.props.title}</h4> : null}
                    </Col>
                    <Col md={6} className="text-right">
                        <ButtonToolbar className="mb-4 bzr-table-toolbar">
                            <ButtonGroup className="mx-2">
                                {this.props.updateClick ? 
                                <Button variant="outline-secondary">
                                    <span className="text-center" >
                                        <i className="fas fa-sync-alt" title={t('refresh')} onClick={() => this.props.updateClick() }/>
                                    </span> 
                                </Button> 
                                : null}
                            </ButtonGroup>
                            <ButtonGroup className="mx-2">
                                <Button variant="outline-secondary">
                                    <span className="text-center" onClick={() => {
                                        this.setState({printing:true})
                                        let colHeaders:any[] = ['#']
                                        this.state.headers.forEach((h:any) => {
                                            if(h.show) colHeaders.push(t(h.fieldLabel) ?? t(h.fieldName))
                                        })
                                        setTimeout(() => {
                                            const options = {
                                                title: t(this.props.id.replace('-', ' ')),
                                                colHeaders
                                            }
                                            const pdfURL = URL.createObjectURL(printTable({ html: `#${this.props.id}`}, options))
                                            const w:any = window.open()
                                            w.location.href = pdfURL
                                            setTimeout(() => {
                                                w.print()
                                                this.setState({printing:false})
                                            }, 500)
                                        }, 100)     
                                    }}>
                                        {this.state.printing ? <FontAwesomeIcon icon={faSpinner} spin /> : <i className="fas fa-file-pdf" title={t('print pdf')} />}
                                    </span>
                                </Button>
                                <Button variant="outline-secondary">
                                    <span className="text-center" onClick={() => {
                                        this.exportFile()
                                    }}>
                                        <i className="fas fa-file-excel" title={t('export to xslx')}></i>
                                    </span>
                                </Button>
                                <DropdownButton
                                    as={ButtonGroup}
                                    id="dropdown-reports"
                                    variant="outline-secondary"
                                    title={<i className="fas fa-file-alt" title={t('quick reports')}></i>}
                                >
                                    {this.props.reportsMenu ? this.props.reportsMenu() : null}
                                </DropdownButton>
                            </ButtonGroup>
                            <ButtonGroup className="mx-2">
                                <DropdownButton
                                    as={ButtonGroup}
                                    id="dropdown-configs"
                                    variant="outline-secondary"
                                    title={
                                        <span className="text-center">
                                            <i className="fas fa-cog" title={t('options')}></i>
                                        </span>
                                        }
                                >
                                    {this.dropdownMenu()}
                                </DropdownButton>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table id={this.props.id} className="bzr-table" responsive={true} bordered>
                            <thead className={`${this.props.refreshing || this.state.refreshing ? 'd-none' : ''}`}>
                                {this.renderHeader()}
                            </thead>
                            <tbody className={`${this.props.refreshing || this.state.refreshing ? 'd-none' : ''}`}>
                                {this.state.trs}
                            </tbody>
                            {/* <tfoot className={`${this.props.refreshing || this.state.refreshing ? 'bg-secondary d-none' : 'bg-secondary'}`} >
                                {this.renderFooter()}
                            </tfoot> */}
                        </Table>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default BzrTable