import React from 'react'
import BzrAxios from '../../../utils/BzrAxios'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import t, { fn, currencyHash } from '../../../utils/I18n/I18n'
import Globals from '../../../utils/Globals'

const defaultHeaders:any[] = [
    {fieldName: 'date', fieldType: 'date', fieldLabel: 'date', filter: true, show: true, style: {minWidth: "90px"}},
    {fieldName: 'payBefore', fieldType: 'date', fieldLabel: 'pay before', filter: true, show: true, alarm: {daysBefore: 5}, style: {minWidth: "90px"}},
    {fieldName: 'paymentStatus', fieldType: 'varchar', fieldLabel: 'payment status', filter: true, show: true, style: {maxWidth: "120px"}},
    {fieldName: 'orderNumber', fieldType: 'varchar', fieldLabel: 'order nº',  filter: true, show: true, style: {minWidth: "115px"}},
    {fieldName: 'pStatus', fieldType: 'varchar', fieldLabel: 'status', filter: true, show: false, style: {minWidth: "90px"}},
    {fieldName: 'subTotalUSD', fieldType: 'numeric', fieldLabel: 'sub-total($)', filter: false, show: true, totalizeIf: {ref: 'orderStatus'}, style: {minWidth: "100px"}},
/*     {fieldName: 'taxUSD', fieldType: 'numeric', fieldLabel: 'tax($)', filter: false, show: false, totalizeIf: {ref: 'orderStatus', noop: 'void'}, style: {minWidth: "100px"}},
    {fieldName: 'totalUSD', fieldType: 'numeric', fieldLabel: 'total($)', filter: false, show: true, totalizeIf: {ref: 'orderStatus'}, style: {minWidth: "100px"}}, */
    {fieldName: 'paidAmountUSD', fieldType: 'numeric', fieldLabel: 'paid($)', filter: false, show: true, totalizeIf: {ref: 'orderStatus', noop: 'void'}, style: {maxWidth: "100px"}},
    {fieldName: 'balanceUSD', fieldType: 'numeric', fieldLabel: 'balance($)', filter: false, show: true, totalizeIf: {ref: 'orderStatus', noop: 'void'}, style: {maxWidth: "100px"}}
]

const parseOrderNumber = (order:any) => {
    const prefixes:any = Globals.defaults.activeBU.preferences.prefixes[Globals.defaults.activeBU.locale],
        match:any = order.status.match(/credit|debit/)
    //console.log(prefixes)

    if(!!match) order.status = match[0]
    switch(order.status) {
        case 'printed_legal':
            return `${prefixes.pl}-${order.billNumber}`
        case 'printed_receipt':
            return `${prefixes.pr}-${order.orderNumber}`
        case 'printed_consignment':
            return `CI-${order.orderNumber}`
        case 'issued_legal':
            return `${prefixes.il}-${order.billNumber}`
        case 'issued_receipt':
            return `${prefixes.ir}-${order.orderNumber}`
        case 'invoice_no_printed':
            return `${prefixes.or}-${order.orderNumber}`
        case 'as_quotation':
            return `${prefixes.aq}-${order.orderNumber}`
        case 'as_proforma':
            return `${prefixes.ap}-${order.orderNumber}`
        case 'void_legal':
            return `${prefixes.vl}-${order.billNumber}`
        case 'void_receipt':
            return `${prefixes.vr}-${order.orderNumber}`
        case 'credit':
            return `${prefixes.cr}-${order.creditNumber}`
        case 'debit':
            return `${prefixes.db}-${order.debitNumber}`
        default:
            return `${prefixes.or}-${order.orderNumber}`
    }
}
const parsePaymentStatus = (status:string) => {
    switch(status) {
        case 'not_paid':
            return `NO`
        case 'complete_paid':
            return `OK`
        case 'partial_paid':
            return `PARTIAL` 
        default:
            return ''
    }
}
const parseStatus:any = {
    'as_proforma': "proforma",
    'as_quotation': "quotation",
    'as_sale_order': "sale order",
    'issued_legal': "issued invoice",
    'printed_legal': "invoice",
    'printed_receipt': "receipt",
    'printed_consignment': "consignment",
    'void_legal': "void invoice",
    'void_receipt': "void receipt",
    'credit': "credit note",
    'debit': "debit note"
}
const renderRow = (order:any) => {
    return defaultHeaders.map((h:any,k:any) => {
        const i:any = order
        const fieldType:string = h.fieldType, alarm = h.alarm
        const fdn = h.fieldName  
        let td:any, cn:string = ''
        if(fieldType === 'numeric') {
            td = h.format === 'percentage' ? `${fn(i[fdn] * 100)}%` : fn(i[fdn])
            cn += `text-right pr-3 ${(i.status !== 'printed_receipt' && i.status !== 'printed_legal' && i.status !== 'printed_consignment') ? 'text-muted' : ''}`
        } else if( fieldType === 'date') {
            td = new Date(i[fdn]).toLocaleDateString('es-VE', { day: '2-digit', month: '2-digit', year: 'numeric'})
            if(alarm && alarm.daysBefore && (i.transacStatus === 'printed_legal' || i.transacStatus === 'printed_receipt')) {
                const now:any = new Date()
                const e:any = new Date(i[fdn])
                const diff = (e - now)/(24*60*60*1000)
                if(diff >= 0 && diff < alarm.daysBefore) cn = 'show-alarm-warning'
                if(diff < 0 && i.oPaymentStatus && i.oPaymentStatus !== 'complete_paid') cn = 'show-alarm-danger'
                //if(i.oPaymentStatus && i.oPaymentStatus !== 'complete_payment' && diff < 0) cn = 'show-alarm-success'
                if(i.oPaymentStatus && i.oPaymentStatus === 'complete_paid') cn = 'show-alarm-success'
            }
        } else if(!i[fdn]) {
            td = '-'
        } else {
            td = i[fdn]
        }
        return(<td key={k} className={cn} >{td}</td>)
    })
}
const RenderItems = (props:any) => {
    const { order, orders } = props
    let sum = order && order.subTotalUSD
    return(
        <div className="latera-credit-note-table">
            <Table id="lateral-items" responsive >
                <thead>
                    <tr>
                        <th>#</th>
                        {defaultHeaders.map((h:any,k:any) => <th key={k}>
                            {h.fieldLabel ? t(h.fieldLabel) : h.fieldName}
                        </th>)}
                    </tr>
                </thead>
                <tbody>
                    {order && <tr className="bzr-row-bold">
                        <td>1</td>
                        {renderRow(order)}
                    </tr>}
                    {orders.map((o:any,k:any) => {
                        if(o.status === 'printed_receipt' || o.status === 'printed_legal') sum -= parseFloat(o.subTotalUSD)
                        return (
                        <tr key={k}>
                            <td>{k+2}</td>
                            {renderRow(o)}
                        </tr>)
                        })}
                </tbody>
            </Table>
            <div className="d-flex px-2">
                <Table className="lateral-totals" style={{marginRight: '200px'}}>
                    <tbody>
                        <tr className="sub-total">
                            <th>{t('pending')}</th><th className="number-format">$ {fn(sum)}</th>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

interface Props {
    fullOrder:any
    appStates:any
    onSubmitted?:any
    onClose?:any
}
interface State {
    alicuote:string
    changeRate:string
    previewURL:any
    processing:any
    saleOrders:any
    transacs:any
    order:any
}
class BillConsignment extends React.Component<Props,State> {
    orderTotals:any

    constructor(props:Props) {
        super(props)
        this.state = {
            alicuote: '0.00',
            changeRate: props.fullOrder.saleOrder.changeRate,
            previewURL: null,
            processing: null,
            saleOrders: [],
            transacs: [],
            order: null
        }
    }
    async componentDidMount() {
        const order = {...this.props.fullOrder.saleOrder}
        this.getBilledTransacs(order.id)
        order.orderNumber = parseOrderNumber(order)
        order.paymentStatus = parsePaymentStatus(order.paymentStatus)
        order.pStatus = t(parseStatus[order.status])
        order.billedCurrency = currencyHash[order.billedCurrency].currencySymbol
        order.payBefore = new Date(new Date(order.date).getTime() + parseInt(order.paymentCondition)*24*60*60*1000).toISOString()
        ///// Special Treatment for USD Amounts
        order.subTotalUSD = order.billedCurrency !== '840' ? (parseFloat(order.subTotal)/parseFloat(order.changeRate)) : parseFloat(order.subTotal)
        order.taxUSD = order.billedCurrency !== '840' ? (parseFloat(order.tax1)/parseFloat(order.changeRate)) : parseFloat(order.tax1)
        order.totalUSD = order.billedCurrency !== '840' ? (parseFloat(order.total)/parseFloat(order.changeRate)) : parseFloat(order.total)
        order.paidAmountUSD = order.billedCurrency !== '840' ? (parseFloat(order.paidAmount)/parseFloat(order.changeRate)) : parseFloat(order.paidAmount)
        order.balanceUSD = order.totalUSD - order.paidAmountUSD

        this.setState({order})
    }
    async getBilledTransacs(id:string) {
        try {
            const { data:{saleOrders,transacs} }:any = await BzrAxios.records({url: `/BilledConsignments/${id}` })
            saleOrders.forEach((record:any) => {
                const r:any = Object.assign({}, record)
                record.orderNumber = parseOrderNumber(r)
                record.paymentStatus = parsePaymentStatus(r.paymentStatus)
                record.pStatus = t(parseStatus[r.status])
                record.billedCurrency = currencyHash[r.billedCurrency].currencySymbol
                record.payBefore = new Date(new Date(r.date).getTime() + parseInt(r.paymentCondition)*24*60*60*1000).toISOString()
                ///// Special Treatment for USD Amounts
                record.subTotalUSD = r.billedCurrency !== '840' ? (parseFloat(r.subTotal)/parseFloat(r.changeRate)) : parseFloat(r.subTotal)
                record.taxUSD = r.billedCurrency !== '840' ? (parseFloat(r.tax1)/parseFloat(r.changeRate)) : parseFloat(r.tax1)
                record.totalUSD = r.billedCurrency !== '840' ? (parseFloat(r.total)/parseFloat(r.changeRate)) : parseFloat(r.total)
                record.paidAmountUSD = r.billedCurrency !== '840' ? (parseFloat(r.paidAmount)/parseFloat(r.changeRate)) : parseFloat(r.paidAmount)
                record.balanceUSD = record.totalUSD - record.paidAmountUSD
            })
            this.setState({saleOrders, transacs})
            return {saleOrders, transacs}
        } catch(err) {
            console.log(err)
            return {saleOrders:[], transacs:[]}
        }
    }
    render() {
        const order:any = {...this.props.fullOrder.saleOrder}
        return(
            <div className="my-5 mx-3">
                <div className="d-flex">
                    <div className="p-2 flex-grow-1"><h2>{t(`consignment`)} <span className="h3">Nº: {order.orderNumber}</span></h2></div>
                    <div className="p-2">
                        <span className="h5">{t('client')}: {this.props.fullOrder.client.legalName} </span> <br />
                        <span className="h5">{t('invoice date')}: {new Date(this.props.fullOrder.saleOrder.date).toLocaleString(this.props.appStates.activeBU.locale)} </span>     
                    </div>
                </div>
                <hr />
                <div className="d-flex mb-4 pr-5">
                    <div className="p-2"></div>
                    <div className="p-2 ml-auto"></div>
                </div>
                {/* <div className="bzr-lateral-scrollable-50vh"> */}
                <div>
                    <RenderItems 
                        orders={this.state.saleOrders} 
                        liftUpTransacs={() => {}}
                        liftUpTotals={(totals:any) => this.orderTotals = totals}
                        order={this.state.order}
                        alicuote={parseFloat(this.state.alicuote)/100}
                        changeRate={this.state.changeRate}
                        />
                    <div className="d-flex mt-3">
                        <div className="p-2 flex-fill text-center">
                            <Button className="mx-3" variant="danger" onClick={() => this.props.onClose()}>{t('cancel')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BillConsignment
