export function getCookie(cname:string) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}
export function setCookie(cname:string, cvalue:any, exdays:number) {
    var d = new Date()
    d.setTime(d.getTime() + (exdays*24*60*60*1000))
    var expires = "expires="+ d.toUTCString()
    const cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
    document.cookie = cookie
}
export function stringifyCookie(cname:string, cvalue:any, exdays:number) {
  var d = new Date()
  d.setTime(d.getTime() + (exdays*24*60*60*1000))
  var expires = "expires="+ d.toUTCString()
  const cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  return cookie
}

export function mountCookie(ckname:string, value:any = null, version:string = '0') {
  // Cookies
  const cookie:any = getCookie(ckname)
  if(value === null) return cookie
  if(!cookie) { ///// THIS IMPLEMENTATION OF COOKIES DOES NOT WORK WELL ON SAFA
      setCookie(ckname, JSON.stringify({version, value}), 30)
      return value
      //console.log(JSON.stringify({version: this.version, data: {activeBU: this.activeBU}}))
  } else {
      try {
          const ck:any = JSON.parse(cookie)
          if(version !== ck.version) {
              setCookie(ckname, JSON.stringify({version, value}), 30)
              return value
          } else return ck.value
      } catch (err) {
          console.log(err)
          setCookie(ckname, JSON.stringify({version, value}), 30)
          return value
      }
  }
}