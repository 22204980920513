import React from 'react'
/** Router Components & Vars **/
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router-dom'
import { History, LocationState } from "history"
//import { Alert } from 'react-bootstrap'

/** Internal Components & Vars **/
import Admin from './layouts/Admin'
import Guest from './layouts/Guest'
import Enrollment from './layouts/Enrollment'
import TestBoard from './components/Guest/TestBoard'
import WasmerApp from './components/Guest/Wasmer/WasmerApp'
import { MessageModal } from './layouts/Modals/Modals'
import { AppStateContext, PolicyContext, LangContext } from './utils/Contexts'
import image from './assets/img/sidebar-5.jpg'

/** External Libraries */
import "react-datepicker/dist/react-datepicker.css"

/** AWS Amplify **/
import Amplify, { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'
import awsconfig from './aws-manual-config'
import t, { setI18nLanguage } from './utils/I18n/I18n'
import { getCookie, setCookie, mountCookie } from './utils/Cookies'

import Axios from 'axios'
import BzrAxios from './utils/BzrAxios'
import Globals from './utils/Globals'

Amplify.configure(awsconfig)

/* AWS.config.update({
	region: awsconfig.Auth.region,
	credentials: new AWS.CognitoIdentityCredentials({
	  	IdentityPoolId: awsconfig.Auth.identityPoolId
	})
}) */

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
	)
)

const popUpMessage = (message:any) => {
		return(
			<div className="pop-up-message">
				<h3>{message}</h3>
			</div>
		)
}

interface AppProps extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	name: string
}

interface AppStates {
	route: string,
	appStates: any,
	products: any,
	policies: any,
	traverse: any,
	toggleMessageModal: boolean,
	_notificationSystem: any,
	image: string,
	color: string,
	hasImage: boolean,
	fixedClasses: string,
	error: any,
	lang: any,
	setLang: any
}

class App extends React.Component<AppProps, AppStates> {
	bid:string = ''
	oid:string = ''
	uid:string = ''
	version:string = '20200822'
	constructor(props:any) {
		super(props)
		this.state = {
			route: '',
			appStates: {},
			products: null,
			policies: null,
			traverse: null,
			toggleMessageModal: true,
			_notificationSystem: null,
			image: image,
			color: "black",
			hasImage: true,
			fixedClasses: "dropdown show-dropdown open",
			error: null,
			lang: 'en',
			setLang: null
		}
		
		this.updateAppStates = this.updateAppStates.bind(this)
		Axios.defaults.timeout = process.env.REACT_APP_AXIOS_TIMEOUT ? parseInt( process.env.REACT_APP_AXIOS_TIMEOUT) : 30000
		Axios.defaults.headers.common['Retry'] = process.env.REACT_APP_AXIOS_RETRY ? parseInt( process.env.REACT_APP_AXIOS_RETRY) : 2
		Axios.defaults.headers.post['Content-Type'] = process.env.REACT_APP_AXIOS_CONTENT_TYPE
		Axios.interceptors.response.use( (response) => response, (error:any) => {
			console.log(error)
			console.log(error.message)
			console.log(error.code)
			console.log(error.stack)
			console.log(error.response)
/* 			console.log(error.isAxiosError)
			console.log(Object.keys(error.response))
			console.log(error.response.status)
			console.log(error.response.statusText) */
			if(error.response && error.response.status === 404) {
				// Resource NOT Found
				console.log('resource not found')
			} else if(error.response && error.response.status === 409) { 
				// Resource ALREADY EXIST
				console.log('resource already exist')
				return Promise.reject(error)
			} else if(error.code === 'ECONNABORTED') {
				// Timeout - Show message
				
				//this.setState({error: {message: 'Request timeout exceeded.\n Your connection could be experimenting some trouble' }})
			}

			// AXIOS Cancel Request
			if(error.__CANCEL__) { 
				console.log(error.message)
				return Promise.reject(error) 
			}

			const config:any = error.config
			if(config && config.headers.Retry < 1) return Promise.reject(error)
			
			config.headers.Retry--
			const backoff = new Promise((resolve) => { setTimeout(() => resolve('SUCCESS'), 2000) })
			return backoff.then(() => {
				console.log('Error Request Intercepted' + config.headers.Retry)
				return Axios(config)
			})
		})
		this.setLang = this.setLang.bind(this)
	}

	setLang(l:any) {
		console.log(l)
		setI18nLanguage(l)
		setCookie('bzr-language', l, 30)
		this.setState({lang: l})
	}

	async componentDidMount() {
		this.authenticate()
		const lang:string = getCookie("bzr-language")
		setI18nLanguage(lang)
		if (lang !== this.state.lang) this.setState({lang}) 
		this.setState({ setLang: this.setLang })
		const ck:any = mountCookie('active-bu', {oid: this.oid, bid: this.bid, uid: this.uid}, this.version)

		this.bid = ck.bid
		this.oid = ck.oid
		this.uid = ck.uid
	}
	///////////////////////////////
	async authenticate() {
		let user:any = null, credentials:any
		try {
			user = await Auth.currentAuthenticatedUser()
		} catch(err) {
			console.log(err)
			// 1. Complete Correct Signout
			this.setState({toggleMessageModal: false})
			if(this.props.match.path !== '/') this.props.history.push('/')
			return
		}

		try {
			credentials = await Auth.currentCredentials() // <-- retrieve Creds from amplify Auth instead
			// AWS Credentials   
		/* 	const key = `cognito-idp.${awsconfig.Auth.region}.amazonaws.com/${awsconfig.Auth.userPoolId}`
			const credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId: awsconfig.Auth.identityPoolId,
				Logins: {[key]: user.signInUserSession.idToken.jwtToken}
			}) */
		} catch(err) {
			console.log(err)
		}
		AWS.config.update({ region: 'us-east-2', credentials })

		//console.log(user)
		Axios.defaults.headers.common['Authorization'] = user.signInUserSession.idToken.jwtToken
		

		let fullUser:any
		try {
			if(this.uid !== user.attributes.sub) fullUser = (await BzrAxios.users({url: `/FullUsers/${user.attributes.sub}`})).data
			else if(user.attributes['custom:status'] > 5000) {
				fullUser = (await BzrAxios.users({url: `/FullUsers/${user.attributes.sub}?bid=${this.bid}&oid=${this.oid}`})).data
				//console.log(fullUser)
				// TEMPORARY - MUST BE IMPLEMENTED 
				setCookie('active-bu', JSON.stringify({version: this.version, value: {oid: fullUser.org.id, bid: fullUser.activeBU.id, uid: fullUser.user.id}}), 30)
			}
			Axios.defaults.headers.common['Bzr-Token'] = JSON.stringify({
				userId: fullUser.user.id,
				orgId: fullUser.org ? fullUser.org.id : '', 
				buId: fullUser.activeBU ? fullUser.activeBU.id : ''
			})
			// Globals MUST BE initialized before update app states
			Globals.defaults = fullUser
			this.updateAppStates(fullUser)
		} catch(err) {
			console.error('Lambdas Not Working', err)
			// SHOULD: logout + error + show alert
			this.setState({ toggleMessageModal: false, error: err })
			return
		}
		console.log(fullUser)
		try {
			if(fullUser.user.userStatus < 2000)
				this.props.history.push('/enrollment')
			else if (fullUser.activeBU && fullUser.activeBU.logoImage) {
				const s3 = new AWS.S3({apiVersion: '2006-03-01'})
				s3.getObject({
					Bucket: 'bizor-s3-imgs', 
					Key: `public/${fullUser.activeBU.logoImage}`,
					ResponseCacheControl: 'no-cache, max-age=0'
				}, (err:any, result:any) => {
					if(err) {
						console.log(err)
					} else {
						const blob = new Blob([result.Body], {type: result.contentType}),
						logoUrl = URL.createObjectURL(blob)
						this.updateAppStates({activeBULogo: logoUrl})
						Globals.defaults.activeBULogo = logoUrl
					}
				})
				if(fullUser.activeBU.preferences.printer.brands) {
					const brands = fullUser.activeBU.preferences.printer.brands
					console.log(fullUser.activeBU.preferences.printer)
					Globals.defaults.headerInfoById = fullUser.activeBU.preferences.printer.headerInfoById
					brands.forEach((brandLogo:string) => s3.getObject({
						Bucket: 'bizor-s3-imgs', 
						Key: `public/${fullUser.org.id}/${fullUser.activeBU.id}/brands/${brandLogo}`,
						ResponseCacheControl: 'no-cache, max-age=0'
					}, (err:any, result:any) => {
						if(err) {
							console.log(err)
						} else {
							const logo = new Blob([result.Body], {type: result.contentType}),
							logoUrl = URL.createObjectURL(logo)
							let aux:any[] = []
							if(this.state.appStates.brandLogos) aux = [...this.state.appStates.brandLogos]

							aux.push(logoUrl)
							this.updateAppStates({brandLogos: aux})
							Globals.defaults.brandLogos = aux
						}
					}))
				}
			}
		} catch(err) {
			console.log(err)
		}
		this.setState({toggleMessageModal: false})
	}
	/////////////////////////

	updateAppStates = (states:any) => {
		const keys:string[] = Object.keys(states)
		const values:any = Object.values(states)
		let appStates = this.state.appStates
		keys.forEach((key, i) => {
			switch(key) {
				case 'products':
					this.setState({products: values[i]})
					break
				case 'policies':
					this.setState({policies: values[i]})
					break
				default:
					if(values[i] !== undefined || values[i] !== null) appStates[key] = values[i]
					break
			}
		})
		this.setState({appStates})
	}

    render(){
		return (  
			<div>
				{this.state.error !== null ? 
				popUpMessage(this.state.error.message) : null
				}
				{/* {this.state.error !== null ? 
					(<Alert variant={'danger'} onClose={() => this.setState({error: null})} dismissible>
						<Alert.Heading>Oh snap! You got an error!</Alert.Heading>
						{this.state.error.code}: {this.state.error.message}
					</Alert>) : null
				}  */}
				<LangContext.Provider value={{lang: this.state.lang, setLang: this.state.setLang}}>
				<AppStateContext.Provider value={this.state.appStates}>
				<PolicyContext.Provider value={this.state.policies}>
					<Switch>
						{ this.state.appStates.user ? 
							<Route path="/enrollment" key="1001"
								children={() => (<Enrollment appStates={this.state.appStates} liftUpAppStates={this.updateAppStates} {...this.props} />)} /> : null }
						{ this.state.appStates.activeBU && this.state.appStates.org ? 
							<Route path="/admin" key="1002"
								children={() => (<Admin products={this.state.products} policies={this.state.policies} appStates={this.state.appStates} liftUpAppStates={this.updateAppStates} {...this.props} />)} /> : null }
						{ this.state.appStates && isLocalhost ? 
							<Route path="/devboard" key="1003"
								children={() => (<TestBoard appStates={this.state.appStates} {...this.props} />)} /> : null }
						<Route path="/wasmer"  key="1007" children={() => <WasmerApp />} />		
						<Route path="/"  key="1004" children={() => <Guest user={this.state.appStates.user} {...this.props} />} />
					</Switch>
					<MessageModal
						show={this.state.toggleMessageModal}
						onHide={() => this.setState({toggleMessageModal: false})}
						message={t('loading') + "... "}
					/>
				</PolicyContext.Provider>
				</AppStateContext.Provider>
				</LangContext.Provider>
			</div>
		)
    }
}

export default withRouter(App)
