export const dict = {
    'es': {
        'location': "ubicación",
        'hi': "hola",
        'by': "de",
        'features': "características",
        'plans': "planes",
        'pricing': "precios",
        'preview': "vista previa",
        'sign in': "ingresar",
        'signin': "entrar",
        'signout': "salir",
        'signing in...': "ingresando...",
        'sign up': "regístrate",
        'sign out': "cerrar sesión",
        'remember me': "recordarme",
        'forgot password?': "contraseña olvidada?",
        'don\'t have an account yet?': "no tienes una cuenta aún?",
        'subscribe': "subscribirse",
        'username': "usuario",
        'password': "contraseña",
        'confirm': "confirmar",
        'submit': "enviar",
        'submitting': "enviando",
        'loading': "cargando",
        'processing': "procesando",
        'username is a required field': "usuario es requerido",
        'email is a required field': "email es requerido",
        'email must be a valid email': "use un email válido",
        'password is a required field': "contraseña es requerida",
        // FRONTEND CONTENT
        'cloud solution for': "solucion en la nube para analíticos de",
        'the power of your': "el poder de tu",
        'in the palm of your hand': "en la palma de tu mano",
        'business': "negocio",
        'kpi analitics': " ",
        'bizor is the smart way to analyze the performance your business': "bizor es la forma inteligente de analizar el desempeño de tu negocio",
        'start with a few taps on your mobile, adopt mobility and live a clean and simple experience while optimizing the operation of your company': "inicia con un par de toques en tu móvil, adopta la movilidad y vive una experiencia limpia y sencilla mientras optimizas la operación de tu empresa",
        'easy configuration': "fácil configuración",
        'simple & fast step by step interface to set your business premises': "interfaz intuitiva y rápida para el ajuste de premisas y operacion",
        'custom features': "rasgos personalizables",
        'bizor models are based on internatioal business standars, nevertheless, you can add any custom field to meet your operation requirements': "los modelos de datos en bizor estan basados en estándares internacionales, aún así, usted puede agregar características personalizadas para cumplir con sus requisitos de operación",
        'we offer a full set of APIs, so you can handle your company\'s data anywhere & anytime by third party softwares': 
        "ofrecemos un set completo de APIs, de esta manera usted puede disponer de los datos generados donde y cuando quiera por software de terceros",
        'quick reports': "reportes rápidos",
        'besides our predifined set of reports, you can define your own reports with in a few steps': "ademas de los reportes predefinidos, usted puede definir sus propios reportes en pocos pasos",
        'improve on demand': "mejora bajo demanda",
        'most of the features can be modified once you configure then the first time, leting you improve your experience and results': "la mayoria de rasgos y características pueden ser modificados luego de la primera configuración, permitiendo la mejor de su experiencia y resultados",
        'assisted configuration': "configuración asistida",
        'for complex business topologies, don\'t worry, our support team will be pleased to help you in this process': "para topologías de negocio complejas, no debe preocuparse, nuestro equipo de soporte estará ahí para ayudarle",
        'users': "usuarios",
        'check your email for a verification code & fill it bellow!': "revisa en tu email el correo de verificación",
        // Dashboard Sidebar
        'dashboard': "tablero",
        'sales': "ventas",
        'transacs': "transacciones",
        'organization': "organización",
        'org profile': "perfil de organización",
        'business units': "compañías",
        'user': "usuario",
        'new product': "agregar producto",
        'list': "listado",
        'updating': "actualizando",
        'refresing': "refrescando",
        'top 5 sales': "ventas top 5",
        '': "",
        // Others
        'add new': "agregar nuevo",
        'create new facility': "crear nueva instalación",
        'general info': "información general",
        'address': "dirección",
        'address & phones': "dirección y teléfonos",
        'legal identification': "identificación legal",
        'profile': "perfil",
        'preferences': "preferencias",
        'references': "referencias",
        'manage': "administrar",
        'back': "atrás",
        'go back': "volver",
        'facilities': "sedes",
        'success': "exito",
        'adjustment done': "ajuste hecho",
        'add picture': "agregar foto",
        'enable': "habilitar",
        'apply changes': "aplicar cambios",
        'bill correlatives': "correlativos de facturación",
        'bill preferences': "preferencias de facturación",
        'changes successfully applied': "cambios aplicados exitosamente",
        'fields': "campos",
        'date': "fecha",
        'change operation to:': "cambiar operación a:",
        'search for': "",
        'summary': "resumen",
        'create new': "crear nueva",
        // Preview
        'features of': "características de",
        'diameter': "diámetro",
        'brand': "marca",
        'tax exempt': "exento de impuesto",
        'true': "si",
        'false': "no",
        // POS
        'pos': "punto de venta",
        'name': "nombre",
        'description': "descripción",
        'reference': "referenia",
        'quantity': "cantidad",
        'price': "precio",
        'client': "cliente",
        'legal name': "nombre legal",
        'legal id': "identificación legal",
        'short address': "dirección corta",
        'phone number': "teléfono",
        'delivery to': "entrega en",
        'delivery address': "dirección de entrega",
        'amount': "importe",
        'phone': "telefono",
        'purchase order #': "orden de compra #",
        'sales order #': "orden de venta #",
        'duplicated sale order number': "número de orden duplicado",
        'order number': "orden #",
        'control number': "control #",
        'exempt': "exento",
        'taxable base': "base",
        'auto-generate': "auto-generar",
        'create order': "generar orden",
        'bill': "facturar",
        'quote': "cotizar",
        'clear': "limpiar",
        'add item': "agregar artículo",
        'empty!': "tabla vacía!",
        'preview item': "vista de artículo",
        'select an item!': "seleccione un artículo!",
        'fetching products': "cargando productos",
        'bill in': "facturar en",
        'rate': "tasa",
        'name/id...': "nombre/...",
        'name...': "nombre...",
        'generate legal invoice': "generar factura",
        'print delivery note': "imprimir nota de entrega",
        'add one item at least': "agrege al menos un producto",
        'clients': "clientes",
        'print': "imprimir",
        'close': "cerrar",
        'download': "descargar",
        'sale order preview': "vista previa",
        'print bill': "imprimir factura",
        'add new client': "agregar cliente",
        'add client': "agregue cliente",
        'payment condition (days)': "condición de pago (días)",
        'save': "guardar",
        'delivery note': "nota de entrega",
        'price list': "lista de precios",
        'fetching clients': "cargando clientes",
        'add remarks': "añadir observaciones",
        'remarks': "observaciones",
        'bill retail': "facturar detallado",
        'billing date': "fecha de facturación",
        'wrong sequence number. must be greater than:': "número de secuencia errada. debe ser mayor a:",
        'manual entry will update the sequence base.': "cambio manual actualizará el número secuencia",
        'verifiying': "verificando",
        'auto-increment': "auto-incrementar",
        'bill number': "factura #",
        'credit number': "crédito #",
        'debit number': "débito #",
        'note number': "nota #",
        'recreating order': "recreando orden",
        'legal invoice': "factura legal",
        'issued invoice': "factura emitida",
        'issued receipt': "recivo emitido",
        'invoice': "factura",
        'receipt': "recibo",
        'optional fields': "campos opcionales",
        'print in local currency': "imprimir en moneda local",
        'print in usd': "imprimir en USD",
        'after-bill-bill': "resumen",
        'after-bill-quote': "resumen",
        'after-bill-standby': "resumen",
        'after-bill-save': "resumen",
        'after-bill': "resumen",
        'void': "anular",
        'void...': "anulando",
        'printing pdf': "imprimiendo pdf",
        'printing': "imprimiendo",
        'standby': "en espera",
        'quotation': "cotización",
        'void invoice': "factura anulada",
        'cancel invoice': "anular factura",
        'void receipt': "recibo anulado",
        'quantity must be greater than 0!': "la cantidad debe ser mayor a 0!",
        'print letterhead': "imprimir membrete",
        'issue': "emitir",
        'show physical': "mostrar en físico",
        'show digital': "mostrar en digital",
        'print dispatch note': "imprimir nota de despacho",
        'show in digital format': "mostrar en formato digital",
        'proforma invoice': "factura proforma",
        'sale order':"orden de venta",
        'quantity exceds maximun available': "cantidad exede el máximo disponible",
        // New Client
        'basic info': "información básica",
        'operation info': "información de operación",
        'address & contact': "dirección & contacto",
        'commercial name': "nombre comercial",
        'identification number': "RIF",
        'issued date': "emitido",
        'expiry date': "vence",
        'business type': "tipo de negocio",
        'country of operation': "país de operación",
        'address line 1': "dirección (línea 1)",
        'address line 2': "dirección (línea 2)",
        'postal code': "cód. postal",
        'city': "ciudad",
        'state/province': "estado/provincia",
        'country': "país",
        'country code': "cód. país",
        'area code': "área",
        'retail': "detal", 
		'service': "servicio",
		'inventory': "inventario",
        'inventories': "inventarios",
        'adjusts': "ajustes",
		'logistic': "logística",
        'manufacture': "manufactura",
        'select': "selecciona",
        'prices': "precios",
        'price segment': "banda de precios",
        'only numbers': "solo números",
        // CLIENTS
        'modify': "modificar",
        'delete': "delete",
        'apply': "aplicar",
        'delist': "delistar",
        'run margin': "correr margen",
        'rename': "renombrar",
        'cost/price books': "libros costo/precio",
        'new client': "agregar cliente",
        'client list': "clientes",
        'modify client': "modificar cliente",
        'seller commission': "comisión vendedor",
        'commission': "comisión",
        'role': "rol",
        'individuals': "individuals",
        'new individual': "nuevo individuo",
        'first name': "nombre",
        'middle name': "segundo nombre",
        'last name': "apellido",
        'second last name': "segundo apellido",
        'facility name': "nombre de sede",
        'facility type': "tipo de sede",
        'pick client': "elija cliente",
        'creating new facility': "creando nueva sede",
        'modifiying': "modificando",
        'double-click on facility to modify': "doble-click sobre sede para modificar",
        // PRODUCTS
        'products': "productos",
        'product': "producto",
        'products list': "listado de productos",
        'inactive products': "productos inactivos",
        'edit product': "editar producto",
        'quick search': "busqueda rápida",
        'cost book': "libro de costo",
        'price book': "libro de precio",
        'inventory book': "libro de inventario",
        'update': "actualizar",
        'update image': "actualizar imagen",
        'type': "tipo",
        'on hand': "stock",
        'available': "disponible",
        'entry/adjust inventory': "ingreso/ajuste inventario",
        // TRANSACS
        'show': "mostrar",
        'show sale order': "ver orden de venta",
        'show invoice/receipt': "mostrar factura/recibo",
        'show digital format': "mostrar en formato digital",
        'digital invoice': "factura digital",
        'printable invoice': "factura en físico",
        'print on physical': "imprimir en físico",
        'save as': "guardar como",
        'sale order #': "referencia",	
        'issue date': "fecha de emisión",
        'client legal name': "cliente",
        'client legal id': "RIF",
        'place of delivery': "lugar de entrega",
        'status': "status",
        'currency': "moneda",
        'exchange rate': "tasa de cambio",
        'actions': "acciones",
        'related to invoice': "relacionado a factura",
        'search': "buscar",
        'bill nº': "factura",
        'totals': "totales",
        'show totals': "mostrar totales",
        'select transacs to totalize': "seleccione transacciones a totalizar",
        'from': "desde",
        'to': "hasta",
        'totals per product': "totales por producto",
        'totals per location': "totales por tienda",
        'sale transacs': "transacciones de venta",
        'transacs list': "listado de transacciones",
        'register payment': "registrar pago",
        'payments': "pagos",
        'payment': "pago",
        'payment($)': "pago($)",
        'payment status': "pagado?",
        'current week': "semana actual",
        'current month': "mes actual",
        'by month': "por mes",
        'month': "mes",
        'january': "enero",
        'february': "febrero",
        'march': "marzo",
        'april': "abril",
        'may': "mayo",
        'june': "junio",
        'july': "julio",
        'august': "agosto",
        'september': "septiembre",
        'october': "octubre",
        'november': "noviembre",
        'december': "december",
        'fetching': "cargando",
        'credit/debit note': "nota de crédito/débito",
        'reason': "razón",
        'total invoice cancellation': "anulacion de factura", 
        'partial discount': "descuento parcial",
        'return of goods': "devolución de producto",
        'other credits...': "otros créditos...",
        'other': "otro",
        'partial price increase': "incremento parcial de precios", 
        'overcharge expense incurred': "sobrecargo en gastos", 
        'interest/debt': "intereses/mora/deuda",
        'other debits...': "otros débitos...",
        'print physical': "imprimir en físico",
        'alicuote': "alicuota",
        'invoice to pay': "factura a pagar",
        'invoice related': "factura relacionada",
        'invoice date': "fecha de facturación",
        'paid($)': "pagado($)",
        'commission($)': "comision($)",
        'adjust type': "tipo de ajuste",
        'in': "entrada",
        'out': "salida",
        'consignment': "consignación",
        'bill consignment': "facturar consignación",
        'consignment relation': "relación consignación",
        'return products': "retornar productos",
        'the quantity exceeds the maximum available': "la cantidad excede el máximo disponible",
        // PRINTER
        'total to pay': "TOTAL A PAGAR",
        'pay before': "pagar antes de",
        'payment condition': "condición de pago",
        'page': "pag.",
        'client info': "información del cliente", 
        'client address':"dirección fiscal", 
        'client number': "cliente nº", 
        'purchase order number': "orden de compra",
        'code': "código", 
        'unit': "unidad",
        'subtotal': "subtotal",
        'discounts': "descuentos",
        'tax': "impuesto",
        'credits': "créditos",
        'credit': "crédito",
        'debit': "débito",
        'note': "nota",
        'credit note': "nota de crédito",
        'debit note': "nota de débito",
        'total': "total",
        'received by': "recibido por",
        'signature': "firma",
        'base': "base",
        'days': "días",
        'cash*': "contado",
        'order nº': "orden nº",
        'seller': "vendedor",
        'pending': "pendiente",
        // PRICES
        'update all': "actualizar todos",
        'update manual entries': "actualizar entradas manuales",
        'select fields': "seleccionar campos",
        'costs': "costos",
        'cost': "costo",
        'run margin function': "función - aplicación de margen",
        'step': "paso",
        'select type of reference': "seleccione tipo de referencia",
        'select book of reference': "seleccione libro de referencia",
        'set margin to apply': "defina el margen a correr",
        'price books list': "libros de precios",
        'cost books list': "libros de costos",
        'group': "grupo",
        'new cost book': "nuevo libro de costo",
        'new price book': "nuevo libro de precio",
        'add': "agregar",
        'listing': "listando",
        'run margin over shown element - overrides individual selection': "aplica margen sobre elementos mostrados en la lista - anula selección individual"
    }
}