import jsPDF from 'jspdf'
import logoImage from '../../../assets/img/reactlogo.png'
import { fn, header } from './Functions'
import t, { locales, currencyHash } from '../../../utils/I18n/I18n'

const docTypes:any = {
    printed_legal: {es: 'NOTA DE ENTREGA'},
    printed_receipt: {es: 'NOTA DE ENTREGA'},
    printed_consignment: {es: 'NOTA DE ENTREGA'},
    issued_legal: {es: 'NOTA DE ENTREGA'},
    issued_receipt: {es: 'NOTA DE ENTREGA'},
    delivery_note: {es: 'NOTA DE ENTREGA'}
}


class DeliveryNote {
    static print(order:any, items:any, client:any, options?:any) {
        const doc:any = new jsPDF('p', 'mm', 'letter')
        let itemsCount = 0, pageCount = 1, offset
        if(options && options.letterhead) { 
            offset = header(doc, order, options)
        } else {
            offset = 45
        }

        const localeObj = locales[options.locale] // locale object
        offset = this.subHeader(doc, client, order, pageCount, localeObj, offset)
        offset = this.listItems(doc, items, itemsCount, offset, localeObj, options)
        itemsCount += offset.count
        pageCount++
    
        this.totalize(doc, order, offset.height, localeObj, options)
        return doc.output('blob')
    }

    static subHeader(doc:any, client:any, order:any, pageCount:number, locale:any, offset:number, options:any = {}) {

        const headerFields = {
            docType: `${docTypes[order.status][locale.language]} Nº`, 
            totalToPay: t('total to pay'),
            issueDate: t('issue date'),
            payBefore: t('pay before'),
            paymentCondition: t('payment condition'),
            page: t('page'),
            seller: t('seller')
        }
        const clientHeaders = {
            clientInfo: t('client info'),
            clientAdds:t('client address'),
            clientNumber: t('client number'),
            purchaseOrderNumber: t('purchase order number'),
            deliveryAdds: t('delivery address'),
        }

        // Horizontal Ruler
        let leftOffset = offset
        doc.setDrawColor(100)
        doc.line(10, leftOffset, 205, leftOffset)
    
        // ORDER NUMBER
        let rightOffset = leftOffset + 8
        doc.setFontStyle("normal")
        doc.setTextColor(50)
        doc.setFontSize(12)
        doc.text(`${headerFields.docType.toUpperCase()}:`, 180, rightOffset, {align: 'right'})
        doc.setFontStyle("bold")
        doc.setTextColor(50)
        doc.setFontSize(12)
        doc.text(`N-${order.orderNumber}`, 182, rightOffset)

        rightOffset += 7
        // DOC DATA (e.g. 'Date') 
        doc.setFontStyle("normal")
        doc.setTextColor(50)
        doc.setFontSize(8)
        doc.text([`${headerFields.issueDate.toUpperCase()}:`, `${headerFields.payBefore.toUpperCase()}:`, `${headerFields.page.toUpperCase()}:`], 180, rightOffset, {align: 'right'})

        doc.setFontStyle("bold")
        doc.setTextColor(50)
        const lo = { day: '2-digit', month: '2-digit', year: 'numeric'}
        // new Date(Date.now() - 30*24*60*60*1000)

        let payBefore = new Date(order.date).getTime() + parseInt(order.paymentCondition)*24*60*60*1000
        doc.text([
            new Date(order.date).toLocaleDateString(locale.code, lo), 
            new Date(payBefore).toLocaleDateString(locale.code, lo), 
            pageCount.toString()
            ], 182, rightOffset)

        // CLIENT NUMBER
        rightOffset += 15

        //////////////////////////
        let infoTitles = [], infoData = [], gap = 0

        if(client.clientNumber) {
            infoTitles.push(`${clientHeaders.clientNumber.toUpperCase()}:`)
            infoData.push(client.clientNumber.toString())
            gap += 3
        }  
        if(order.paymentCondition) {
            infoTitles.push(`${headerFields.paymentCondition.toUpperCase()}:`)
            infoData.push(order.paymentCondition === '0' ? 'CONTADO' : `${order.paymentCondition} ${t('days')}`)
            gap += 3
        }
        doc.setFontStyle("normal")
        doc.text(infoTitles, 180, rightOffset, {align: 'right'})
        doc.text(infoData, 182, rightOffset)
        rightOffset += gap
        //////////////////////////
       

        if(order.billNumber) {
            doc.setFontSize(7)
            rightOffset += 5
            doc.setFontStyle("normal")
            doc.text((`${order.sellerShortName ? headerFields.seller.toUpperCase() + ':': ''} ${order.sellerShortName ? order.sellerShortName.toUpperCase() + ', ': ''}${t('related to invoice')}:`).toUpperCase(), 190, rightOffset,  {align: 'right'})
            doc.setFontStyle("bold")
            doc.text(`${order.billNumber}`, 192, rightOffset)
        } else {
            doc.setFontSize(7)
            rightOffset += 5
            doc.setFontStyle("normal")
            doc.text((`${order.sellerShortName ? headerFields.seller.toUpperCase() + ':': ''} ${order.sellerShortName ? order.sellerShortName.toUpperCase(): ''}`).toUpperCase(), 205, rightOffset,  {align: 'right'})
        }
        rightOffset += 1
        ///////////////////////////////////////////////////////  left side
        // Client Info
        leftOffset += 7
        doc.setTextColor(50)
        doc.setFontSize(8)
        doc.setFontStyle("bold")
        doc.text(clientHeaders.clientInfo.toUpperCase(), 12, leftOffset)
    
        leftOffset += 4
        doc.setFontStyle("normal")
        doc.text([client.legalName ? client.legalName.toUpperCase() : '', client.identificationNumber ? 'RIF: ' + client.identificationNumber.toUpperCase() : ''], 12, leftOffset)

        // Client Address
        leftOffset += 10
        doc.setTextColor(50)
        doc.setFontStyle("bold")
        doc.text(clientHeaders.clientAdds.toUpperCase(), 12, leftOffset)
    
        //  CONSTRUIR 'ADDRESS' Y 'PHONE NUMBER'
        const address = `${client.addressLine1}${client.addressLine2 ? ', ' + client.addressLine2 : ''}${client.cityName ? ', ' + client.cityName : ''}${client.postalCodeText ? ', ' + client.postalCodeText : ''}${client.stateProvinceName ? ', ' + client.stateProvinceName : ''}${client.countryName ? ', ' + client.countryName : ''}`
        const phone = client.telephoneNumber ? `${client.phoneCountryCode ? client.phoneCountryCode : ''} ${client.areaCode ? '('+client.areaCode+')' : ''} ${client.telephoneNumber}` : ''
        let addressTextSplit = doc.splitTextToSize(address.toUpperCase(), 105)
        addressTextSplit.push(phone)
        const textHeight = (addressTextSplit.length - 1) * 9 * 1.15 * 34/100

        leftOffset += 4
        doc.setFontStyle("normal")
        doc.text(addressTextSplit, 12, leftOffset)

        ///////////////////
        leftOffset += textHeight

        // PURCHASE ORDER
        if(order.purchaseOrderNumber) {
            leftOffset += 5
            doc.setTextColor(50)
            doc.setFontStyle("bold")
            doc.text(`${clientHeaders.purchaseOrderNumber.toUpperCase()}:`, 12, leftOffset)

            doc.setFontStyle("normal")
            doc.text([order.purchaseOrderNumber], 50, leftOffset)
        }

        // DELIVERY ADDRESS
        if(!!client && !!client.facility) {
            leftOffset += 5
            doc.setTextColor(50)
            doc.setFontStyle("bold")
            doc.text(clientHeaders.deliveryAdds.toUpperCase(), 12, leftOffset)
        
            const address = `${client.facility.facilityName ? client.facility.facilityName + ':' : ''} ${client.facility.addressLine1} ${client.facility.addressLine2 ? ', ' + client.facility.addressLine2 : ''} ${client.facility.cityName ? ', ' + client.facility.cityName : ''} ${client.facility.postalCodeText ? ', ' + client.facility.postalCodeText : ''} ${client.facility.stateProvinceName ? ', ' + client.facility.stateProvinceName : ''} ${client.facility.countryName ? ', ' + client.facility.countryName : ''}`
            addressTextSplit = doc.splitTextToSize(address.toUpperCase(), 120)
            const th = (addressTextSplit.length - 1) * 9 * 1.15 * 34/100
            leftOffset += 4
            doc.setFontStyle("normal")
            doc.text(address, 12, leftOffset, {maxWidth: '100'})
            leftOffset += th
        }

        return (leftOffset < rightOffset ? rightOffset : leftOffset) + 4
    }
    static listItems(doc:any, items:any, count:number, offset:number, locale:any, options:any):any {
        const headers = {
            n: '#', 
            code: t('code'), 
            description: t('description'), 
            quantity: t('quantity'), 
            price: t('price'), 
            vat: locales[locale.code].taxName,
            amount: t('amount'), 
            unit: t('unit')
        }

        let itemsOffset = offset, fontSize = 9, lineHeightFactor = 1.15, maxItemsHeight = 190,
            printCurrency:any = currencyHash[options.currencyCode]
        doc.setDrawColor(100)
        doc.setLineWidth(0.2)
        doc.rect(9, itemsOffset, 197, 8)
        itemsOffset += 5
        // List Items Header
        doc.setTextColor(50)
        doc.setFontStyle("bold")
        doc.setFontSize(fontSize - 1)
        doc.text(headers.code.toUpperCase(), 12, itemsOffset)                          // CODE/SKU
        doc.text(headers.description.toUpperCase(), 35, itemsOffset)                         // DESCRIPTION
        doc.text(headers.unit.toUpperCase(), 135, itemsOffset, {align: 'center'})      // UNIT OF MEASURE
        doc.text(headers.quantity.toUpperCase(), 150, itemsOffset, {align: 'center'})      // QTY
        //doc.text(headers.vat.toUpperCase(), 140, itemsOffset, {align: 'center'})      // VAT
        doc.text(`${headers.price.toUpperCase()} (${printCurrency.currencySymbol})`, 180, itemsOffset, {align: 'right'})      // PRICE
        doc.text(`${headers.amount.toUpperCase()} (${printCurrency.currencySymbol})`, 205, itemsOffset, {align: 'right'})      // AMOUNT
        itemsOffset += 10
        // ITEM X
        doc.setFontStyle("normal")
        for(let i = count; i < items.length; i++) {

            const sku:string[] = items[i].sku !== '' ? items[i].sku.toUpperCase().match(/.{1,12}/g) : ['N/A'],
            desc:string[] = items[i].description.match(/.{1,70}/g),
            lineCount:number = desc.length > sku.length ? desc.length : sku.length,
            textHeight:number = lineCount * fontSize * lineHeightFactor * 34/100

            if(itemsOffset + textHeight > maxItemsHeight) {
                // Horizontal Ruler
                doc.setDrawColor(100)
                doc.setLineWidth(0.2)
                doc.line(10, itemsOffset, 205, itemsOffset)
                return {count: i, height: itemsOffset}
            }
            doc.setFontSize(fontSize - 1)
            doc.text(sku, 10, itemsOffset)
            doc.text(desc, 33, itemsOffset)                                                                     // DESCRIPTION
            doc.text(items[i].retailed === 'true' ? 'UND' : `${items[i].unitOfMeasure.toUpperCase()}`, 135, itemsOffset, {align: 'center'})             // UNIT OF MEASURE
            ///////
            doc.text(`${fn(items[i].quantity, locale.code)}`, 150, itemsOffset, {align: 'center'})             // QTY
            ////
            // TAX/EXEMPT 
            //doc.setFontSize(fontSize)           // VAT
            //doc.text(items[i].isTaxExempt === 'true' ? '(E)' : fn(items[i].taxRate1 * 100, locale.code) + '%', 140, itemsOffset, {align: 'center'})
            
            let price = options.currencyCode === 840 ? parseFloat(items[i].entryValue) : parseFloat(items[i].entryValue) * parseFloat(items[i].changeRate)
            if(items[i].retailed === 'true') {
                price = price/parseFloat(items[i].unitsPerPkg)
            }

            doc.text(fn(price, locale.code), 178, itemsOffset, {align: 'right'})        // PRICE
            const amount = price * parseFloat(items[i].quantity)
            doc.text(fn(amount, locale.code), 204, itemsOffset, {align: 'right'})   // AMOUNT
            itemsOffset += textHeight + 2
        }
        // Horizontal Ruler
        doc.setDrawColor(100)
        doc.setLineWidth(0.2)
        doc.line(10, itemsOffset, 205, itemsOffset)
        return {count: items.length, height: itemsOffset}
    }
    static totalize(doc:any, order:any, offset:number, locale:any, options:any) {
        const totalFields = {
            subTotal: t('subtotal'),
            discounts: t('discounts'),
            taxable: t('tax'),
            exempt: t('exempt'),
            credits: t('credits'),
            total: t('total'),
            remarks: t('remarks'),
            receivedBy: t('received by'),
            signature: t('signature'),
            vat: locales[locale.code].taxName,
            base: t('base')
        }
        offset = 220 // Fixed Offset
        if(order.remarks) offset = 200 // Fixed Offset

        
        // Horizontal Ruler
        doc.setDrawColor(100)
        doc.setLineWidth(0.2)
        doc.line(10, offset, 205, offset)
        // SUBTOTALS
        offset += 7
        const upperOffset = offset
        doc.setTextColor(50)
        doc.setFontSize(10)
        doc.setFontStyle("bold")
        doc.text(`${totalFields.subTotal.toUpperCase()} ${currencyHash[options.currencyCode].currencySymbol}`, 90, offset)
        let subTotalUsd = order.billedCurrency === 840 ? order.subTotal : parseFloat(order.subTotal)/parseFloat(order.changeRate)
        let subTotal = options.currencyCode === 840 ? subTotalUsd : order.subTotal
        doc.text(fn(subTotal, locale.code), 200, offset, {align: 'right'})

        // DISCOUNT
        if(order.discounts && parseFloat(order.discounts) !== 0) {
            offset += 10
            doc.setFontSize(9)
            doc.setFontStyle("normal")
            doc.text(totalFields.discounts.toUpperCase(), 90, offset)
            let discountsUsd = order.billedCurrency === 840 ? order.discounts : parseFloat(order.discounts)/parseFloat(order.changeRate)
            let discounts = options.currencyCode === 840 ? discountsUsd : order.discounts
            doc.text(fn(discounts, locale.code), 200, offset, {align: 'right'})
        }

        // TAX
        offset += 5
        if(order.status === 'printed_legal') {
            offset += 5
            doc.setFontSize(9)
            doc.setFontStyle("normal")
            let taxableUsd = order.billedCurrency === 840 ? order.taxable : parseFloat(order.taxable)/parseFloat(order.changeRate)
            let taxable = options.currencyCode === 840 ? taxableUsd : order.taxable
            doc.text(`${totalFields.taxable.toUpperCase()} ${currencyHash[options.currencyCode].currencySymbol} (${totalFields.base.toUpperCase()} ${fn(taxable, locale.code)}) ${totalFields.vat.toUpperCase()} ${fn(order.taxRate1 * 100, locale.code) + '%'}`, 90, offset)
            let tax1Usd = order.billedCurrency === 840 ? order.tax1 : parseFloat(order.tax1)/parseFloat(order.changeRate)
            let tax1 = options.currencyCode === 840 ? tax1Usd : order.tax1
            doc.text(fn(tax1, locale.code), 200, offset, {align: 'right'})
        }
        
        // EXEMPT
        if(order.exempt && order.status === 'printed_legal') {
            offset += 5
            let exemptUsd = order.billedCurrency === 840 ? order.exempt : parseFloat(order.exempt)/parseFloat(order.changeRate)
            let exempt = options.currencyCode === 840 ? exemptUsd : order.exempt
            doc.text(`${totalFields.exempt.toUpperCase()} ${currencyHash[options.currencyCode].currencySymbol} (${totalFields.base.toUpperCase()} ${fn(exempt, locale.code)}) 0%`, 90, offset)
            doc.text(fn(0, locale.code), 200, offset, {align: 'right'})
        }

        // CREDITS
        if(order.credits && parseFloat(order.credits) !== 0) {
            offset += 10
            doc.setFontSize(9)
            doc.setFontStyle("bold")
            doc.text(totalFields.credits.toUpperCase(), 90, offset)
            let creditsUsd = order.billedCurrency === 840 ? order.credits : parseFloat(order.credits)/parseFloat(order.changeRate)
            let credits = options.currencyCode === 840 ? creditsUsd : order.credits
            doc.text('(' + fn(credits, locale.code) + ')', 200, offset, {align: 'right'})
        }

        // Horizontal Ruler
        offset += 3
        doc.setDrawColor(100)
        doc.setLineWidth(0.3)
        doc.line(90, offset, 205, offset)

        // TOTAL
        offset += 5
        doc.setFontSize(11)
        doc.setFontStyle("bold")

        let totalUsd = order.billedCurrency === 840 ? order.total : parseFloat(order.total)/parseFloat(order.changeRate)
        let total = options.currencyCode === 840 ? totalUsd : order.total
        doc.text(`${totalFields.total.toUpperCase()} ${currencyHash[options.currencyCode].currencySymbol}`, 90, offset)
        doc.text(fn(total, locale.code), 200, offset, {align: 'right'})

        // REMARKS
        doc.setFontSize(9)
        doc.setFontStyle("bold")
        offset += 10
        if(order.remarks) {
            doc.setLineWidth(0.2)
            doc.rect(20, offset - 3, 135, 20)
            doc.text(totalFields.remarks.toUpperCase(), 22, offset )
            doc.setFontSize(9)
            doc.setFontStyle("normal")
            const remarks:string[] = doc.splitTextToSize(order.remarks, 130)
            doc.text(remarks, 22, offset + 4) 
        }
        doc.setFontSize(9)
        doc.setFontStyle("bold")
        // RECEIVED BY
        doc.text(totalFields.receivedBy.toUpperCase(), 20, upperOffset)
        // SIGNATURE
        doc.text(totalFields.signature.toUpperCase(), 20, upperOffset + 20)
    }
    static getCompany(appStates:any) {
        const company = {
            legalName: appStates.activeBU.legalName,
            identificationNumber: appStates.activeBUId.identificationNumber,
            address: appStates.activeBUAddress ?? '',
            phone: appStates.activeBUPhone ?? '',
            logo: appStates.activeBULogo ?? logoImage
        }
        return company
    }
}

export default DeliveryNote