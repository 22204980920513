import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history"
import { Form, Col, Button, Accordion, Card, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ImageInput from '../Common/ImageInput'

import { Storage } from 'aws-amplify'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'
import PartyInput from '../Common/PartyInput'
import Printer from '../Printer/ProductToPDF'
import { PreviewModal, MessageModal } from '../../layouts/Modals/Modals'
import BzrAxios from '../../utils/BzrAxios'
import t, { translate, HelperMsg } from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	products: any,
	liftUpAppStates: any,
	disableFields: boolean,
	disableFieldsHandler: any,
	params: any,
	idn: any
}

interface States {
	message:any
	fixedFields:any
	values:any
	supplier:any
	suppliers:any[]
	image:any
	showPostview:any
	accordionKey:string
	books:any
	locale:any
	inventory:any
	isUnitsPerPkg:boolean
}

class NewProductForm extends React.Component<Props,States> {

	loaded = false
	currency_hash:any = {'840': 'USD', '928': 'VES'}

	constructor(props: Props) {
		super(props)
        this.state = {
			message: null,
			fixedFields: [],
			values: {unitOfMeasure: 'u', isTaxExempt: false, fixedSchemaId: '6', costBookId: '', entryValueCost: 0, priceBookId: '', entryValuePrice: 0, currency: '840', onHand: 0, onTransit: 0, minLimit: 0, maxLimit: 0},
			supplier: null,
			suppliers: [],
			image: {imageURL: null},
			showPostview: null,
			accordionKey: '0',
			books: {costBookNames: [], priceBookNames: []},
			locale: null,
			inventory: {},
			isUnitsPerPkg: false
		}
		this.handleSubmit = this.handleSubmit.bind(this)
		this.getImageUploadState = this.getImageUploadState.bind(this)
	}

	componentDidMount() {
		const view:any = document.getElementById('new-product-view')
		translate(view)

		// 1. Get FixedFields fields
		BzrAxios.records({url: `/fields/6+7`})
		.then((response:any) => {
			this.setState({fixedFields: response.data})
		})
		.catch((err:any) => console.log(err)) 

		// 2. Get Suppliers
		BzrAxios.records({url: `/BzrClient`})
		.then((response: any) => this.setState({suppliers: response.data.records}))
		.catch((error: any) => console.error(error))
		
		// 3. Books
		if(this.props.appStates.bookNames && !this.loaded) {
			this.loaded = true
			// 1. Get Cost & Price Books <- come from Admin(Component) initialization	
			const values:any = {},
				bookNames:any = this.props.appStates.bookNames

			values.costBookId = bookNames.cost.default
			values.priceBookId = bookNames.price.default
			values.currency = '840'
			this.setState({values: Object.assign({}, this.state.values, values)})
		}
	}

	componentDidUpdate() {
		if(this.props.appStates.bookNames && !this.loaded) {
			this.loaded = true
			// 1. Get Cost & Price Books <- come from Admin(Component) initialization	
			const values:any = {},
				bookNames:any = this.props.appStates.bookNames

			values.costBookId = bookNames.cost.default
			values.priceBookId = bookNames.price.default
			values.currency = '840'
			this.setState({values: Object.assign({}, this.state.values, values)})
		}
	}

	getImageUploadState(imageState:any) {
		this.setState(imageState)
	}

	handleSubmit(values: any, formikBag: any) {
 		/* formikBag.setSubmitting(false)
		return */
		values.fixedSchemaId = values.fixedSchemaId.replace('object', '')
		values.isActive = true
		const imageFile = this.state.image ? this.state.image.imageFile : null 
		if(imageFile) {
			values.imageExt = imageFile.type.replace('image/','')
		}

		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		/////////////////////////////////////////////////    PRICE LOGIC    ////////////////////////////////////////////////////

		const costEntry:any = {}
		costEntry.entryValue = values.entryValueCost
		costEntry.bookId = values.costBookId
		costEntry.currency = values.currency
		costEntry.isActive = true

		const priceEntry:any = {}
		priceEntry.entryValue = values.entryValuePrice
		priceEntry.bookId = values.priceBookId
		priceEntry.currency = values.currency
		priceEntry.isActive = true

		const bookEntries = { costEntry, priceEntry }
		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		const data = { productData: values, supplierData: this.state.supplier, bookEntries }
	
		BzrAxios.records({url: '/Products', method: 'POST', data })
		.then((response: any) => {
			console.log(response.data)
			const products:any[] = this.props.products,
				product:any = response.data.product

			if(this.state.image && this.state.image.imageURL) product['imageURL'] = this.state.image.imageURL
			const idx:number = products.findIndex((p:any) => p.name > product.name)
			if(idx > -1) products.splice(idx, 0, product)
			else products.push(product)

			// Update costEntry in App
			let entry:any = response.data.costEntry
			if(entry) this.props.appStates.books[entry.bookId][entry.bzrProductId] = entry
			// Update priceEntry in App
			entry = response.data.priceEntry
			if(entry) this.props.appStates.books[entry.bookId][entry.bzrProductId] = entry
			// Update inventoryEntry in App
			entry = response.data.inventoryEntry
			if(entry) this.props.appStates.books[entry.bzrInventoryBookId][entry.bzrProductId] = entry

			// Create Image
			if(imageFile) {
				Storage.put(product.bzrProductImage, imageFile, {
						contentType: imageFile.type
				})
				.then (result => {console.log(result)})
				.catch(err => {
					alert(t('error uploading image - try later on edit'))
					console.log(err)
				})
			}

			//this.props.liftUpAppStates({products})
			const productURL = URL.createObjectURL(Printer.productToPDF(this.props.appStates, product, {title: 'product card'}))
			this.setState({showPostview: productURL})
		}).catch((error: any) => {
			console.error(error)
		}).finally(() => {
			this.setState({values: {unitOfMeasure: 'u', isTaxExempt: false, fixedSchemaId: '6', costBookName: 'base', priceBookName: 'base', onHand: 0, onTransit: 0, minLimit: 0, maxLimit: 0}, supplier: null})
			formikBag.resetForm()
			//this.props.history.push('/admin/products/list')
		})
	}

	renderFields(values:any, handleChange:any, touched:any, errors:any, oid:string) {
		if(!this.state.fixedFields[oid]) return null

		//handleChange = handleChange.bind(this)
		let cols:any = []
		let formInputs:any = []
		//const length = this.state.fixedFields[oid].length
		this.state.fixedFields[oid].forEach((field:any, i:any) => {
			formInputs.push(<FormInputs
				key={i}
				ncols={["12"]}
				className={[""]}
				properties={[
					{
						label: field.fieldName,
						horizontalLabel: "5",
						feedback: " ",
						fieldType: "text",
						name: field.fieldName,
						value: values[field.fieldName] ?? '',
						onChange: handleChange,
						isValid: touched[field.fieldName] && !errors[field.fieldName]
					}
				]}
			/>)
		})
		cols.push(<Col key="3.0" md={12}>{formInputs}</Col>)			
		return (<Form.Row>{cols}</Form.Row>)
	}

	messageBox() {
		if (this.state.message) {
		  	return (
				<Alert variant="success" onClose={() => this.setState({message: null})} dismissible>
					<Alert.Heading>Info</Alert.Heading>
					<p>
						{ this.state.message }
					</p>
				</Alert>
			)
		}
	}

	render() {
		const v:any = this.state.values
		return (
			<div id="new-product-view">
				<Formik
					validationSchema={
						Yup.object({
							name: Yup.string().max(100, 'string too large').required(),
							description: Yup.string().max(500, 'string too large').required(),
							sku: Yup.string().max(32, 'string too large').trim().required(),
							oems: Yup.string().max(500, 'string too large').nullable(),
							unitsPerPkg: this.state.isUnitsPerPkg ? Yup.string().matches(/^[0-9]+$/, ' ').required(' ') : Yup.string().nullable(),
							entryValueCost: Yup.number().required(),
							entryValuePrice: Yup.number().required()
						})
					}
					onSubmit={this.handleSubmit}
					initialValues={v}
					enableReinitialize={true}
				>
					{({
						handleSubmit,
						handleChange,
						setFieldValue,
						isSubmitting,
						values,
						touched,
						errors
					}) => (
					<Form noValidate onSubmit={handleSubmit} id={"business-unit-form-"  + this.props.idn}>
						<Accordion className="new-product" defaultActiveKey="0" >
						<Form.Row>
							<Col md={9}>
								<fieldset disabled={this.props.disableFields}>
								<Form.Row className="mb-3">
									<Col md={7}>
										<FormInputs
											ncols={['12', '12', '12']}
											className={['px-2', 'px-2', 'px-2']}
											properties={[
												{
													label: "Name*",
													horizontalLabel: "4",
													feedback: " ",
													fieldType: "text",
													name: "name",
													value: values.name ?? '',
													onChange: handleChange,
													isValid: touched.name && !errors.name,
													isInvalid: !!errors.name,
													error: errors.name
												},
												{
													label: "Description*",
													horizontalLabel: "4",
													feedback: " ",
													fieldType: "textarea",
													name: "description",
													className: "bzr-textarea",
													value: values.description ?? '',
													onChange: handleChange,
													isValid: touched.description && !errors.description,
													isInvalid: !!errors.description,
													error: errors.description
												},
												{
													label: "code/sku*",
													horizontalLabel: "4",
													feedback: " ",
													fieldType: "text",
													name: "sku",
													value: values.sku ?? '',
													onChange: handleChange,
													isValid: touched.sku && !errors.sku,
													isInvalid: !!errors.sku,
													error: errors.sku
												}
											]}
										/>
									</Col>
									<Col md={5}>
										<ImageInput
											image={this.state.image}
											liftUpState={(image:any) => this.setState({image})} 
											boxW={170}
											boxH={170} 
											scale={0.95}
											className="rounded-sm mx-auto dashed-border" />
									</Col>
									<Col md ={7}>
										<FormInputs
											ncols={['7', '5']}
											className={['px-2', 'px-2']}
											properties={[
												{
													label: 'Unit of Measure',
													horizontalLabel: "6",
													fieldType: 'select',
													name: 'unitOfMeasure',
													value: values.unitOfMeasure,
													options: [
														['u','Units'],
														['kg','Kilograms'],
														['gm','Grams'],
														['lt','Liters'],
														['mt','Meters'],
														['pkg','Package'],
														['bulto', 'Bulto']
													],
													onChange: (event:any) => {
														if(event.target.value === 'pkg' || event.target.value === 'bulto') this.setState({isUnitsPerPkg: true})
														else this.setState({isUnitsPerPkg: false})
														this.setState({values: Object.assign(values, {unitOfMeasure: event.target.value})})
													}
												},
												{
													label: "Units/Pkg",
													horizontalLabel: "8",
													feedback: " ",
													fieldType: "text",
													name: "unitsPerPkg",
													value: values.unitsPerPkg ?? '',
													onChange: handleChange,
													disabled: !this.state.isUnitsPerPkg,
													info: HelperMsg('For packages use a content ref. e.g.: 1Pkg = 10Unds, Fill de Units/Pkg fi'),
													isValid: touched.unitsPerPkg && !errors.unitsPerPkg,
													isInvalid: !!errors.unitsPerPkg,
													error: errors.unitsPerPkg
												}
											]}
										/>
									</Col>
								</Form.Row>
								{ this.messageBox() }
								<hr />
								<Accordion.Toggle 
									as={Card.Header} 
									eventKey="0" 
									onClick={(event:any) => {
										const key:string = this.state.accordionKey === '0' ? '10': '0'
										this.setState({accordionKey: key})
									}}
									children={
										<div className="d-flex flex-row">
											<div><h5>Cost & Price</h5></div>
											<div className="flex-grow-1 pl-5">
												{ this.state.accordionKey !== '0' ?
												<div className="d-flex pt-1">
													<div className="px-3"><h6>cost</h6>{this.props.appStates.bookNames && values.costBookId ? this.props.appStates.bookNames.cost_hash[values.costBookId].alias : ''}: {values.entryValueCost ?? t('not set')} </div>
													<div className="px-3"><h6>price</h6>{this.props.appStates.bookNames && values.priceBookId ? this.props.appStates.bookNames.price_hash[values.priceBookId].alias : ''}: {values.entryValuePrice ?? t('not set')} </div>
													<div className="px-3"><h6>currency</h6>{this.currency_hash[values.currency] ?? t('not set')} </div>
												</div> : null}
											</div>
											<div className=""><h5><span className="pe-7s-angle-down caret" /></h5></div>
										</div>
										} />
								<Accordion.Collapse eventKey="0">
									<Form.Row className="pt-3">
										<Col md={4}>
											<FormInputs
												ncols={['12']}
												className={['px-2']}
												properties={[
													{
														label: 'Cost Book',
														horizontalLabel: "5",
														fieldType: 'select',
														name: 'costBookId',
														value: values.costBookId,
														options: (() => {
																const ops:any = this.props.appStates.bookNames ? Object.entries(this.props.appStates.bookNames.cost_hash).map((e:any) => [e[0],e[1].alias]) : []
																if(ops.length > 0) values.costBookId = this.props.appStates.bookNames.cost.default
																return ops
															})(),
														onChange: handleChange,
														disabled: true
													}
												]}
											/>
											<FormInputs
												ncols={['12']}
												className={['px-2']}
												properties={[
													{
														label: "Cost Value",
														horizontalLabel: "5",
														feedback: " ",
														fieldType: "text",
														name: "entryValueCost",
														value: values.entryValueCost ?? '',
														onChange: handleChange,
														isValid: touched.entryValueCost && !errors.entryValueCost,
														isInvalid: !!errors.entryValueCost,
														error: errors.entryValueCost
													}
												]}
											/>										
										</Col>
										<Col md={4}>
											<FormInputs
												ncols={['12']}
												className={['px-2']}
												properties={[
													{
														label: 'Price Book',
														horizontalLabel: "5",
														fieldType: 'select',
														name: 'priceBookId',
														value: values.priceBookId,
														options: (() => {
															const ops:any = this.props.appStates.bookNames ? Object.entries(this.props.appStates.bookNames.price_hash).map((e:any) => [e[0],e[1].alias]) : []
															if(ops.length > 0) values.priceBookId = this.props.appStates.bookNames.price.default
															return ops
														})(),
														onChange: handleChange,
														disabled: true
													}
												]}
											/>
											<FormInputs
												ncols={['12']}
												className={['px-2']}
												properties={[
													{
														label: "List Price",
														horizontalLabel: "5",
														feedback: " ",
														fieldType: "text",
														name: "entryValuePrice",
														value: values.entryValuePrice ?? '',
														onChange: handleChange,
														isValid: touched.entryValuePrice && !errors.entryValuePrice,
														isInvalid: !!errors.entryValuePrice,
														error: errors.entryValuePrice
													}
												]}
											/>
										</Col>
										<Col md={4}>
											<FormInputs
												ncols={['12']}
												className={['px-2']}
												properties={[
													{
														label: 'Currency',
														horizontalLabel: "5",
														fieldType: 'select',
														name: 'currency',
														value: values.currency,
														options: [
															['840', 'USD'], 
															['928', 'VES']
														],
														onChange: handleChange,
														info: HelperMsg('We recomend using a strong currency to reference prices and the system will bill in your local curency'),
														disabled: true
													}
												]}
											/>
											<FormInputs
												ncols={['12']}
												className={['px-2']}
												properties={[
													{
														label: "Tax Exempt?",
														horizontalLabel: "5",
														fieldType: "checkbox",
														name: "isTaxExempt",
														value: values.isTaxExempt,
														onChange: () => { setFieldValue('isTaxExempt', !values.isTaxExempt) }
													}
												]}
											/>
										</Col>
									</Form.Row>
								</Accordion.Collapse>
								<Accordion.Toggle 
									as={Card.Header} 
									eventKey="1" 
									onClick={(event:any) => {
										const key:string = this.state.accordionKey === '1' ? '10': '1'
										this.setState({accordionKey: key})
									}}
									children={
										<div className="d-flex flex-row">
											<div><h5>Codification</h5></div>
											<div className="flex-grow-1 pl-5">
												{ this.state.accordionKey !== '1' ?
												<div className="d-flex pt-1">
													<div className="px-3"><h6>type</h6>{values.type ?? 'not set'} </div>
													<div className="px-3"><h6>model</h6>{values.model ?? 'not set'} </div>
													<div className="px-3"><h6>group</h6>{values.group ?? 'not set'} </div>
													<div className="px-3"><h6>location</h6>{values.location ?? 'not set'} </div>
													<div className="px-3"><h6>barcode</h6>{values.barcode ?? 'not set'} </div>
												</div> : null}
											</div>
											<div className=""><h5><span className="pe-7s-angle-down caret" /></h5></div>
										</div>
										} />
								<Accordion.Collapse eventKey="1">
									<Form.Row>
									<Col md={8}>
										<FormInputs
											ncols={['6', '6', '6', '6']}
											className={['px-2', 'px-2', 'px-2', 'px-2']}
											properties={[
												{
													label: "Type",
													feedback: " ",
													fieldType: "text",
													name: "type",
													value: values.type,
													onChange: handleChange,
													isValid: touched.type && !errors.type
												},
												{
													label: "Model",
													feedback: " ",
													fieldType: "text",
													name: "model",
													value: values.model,
													onChange: handleChange,
													isValid: touched.model && !errors.model,
													isInvalid: !!errors.model,
													error: errors.model
												},
												{
													label: "Group",
													feedback: " ",
													fieldType: "text",
													name: "group",
													value: values.group ?? '',
													onChange: handleChange,
													isValid: touched.group && !errors.group
												},
												{
													label: "Location",
													feedback: " ",
													fieldType: "text",
													name: "location",
													value: values.location ?? '',
													onChange: handleChange,
													isValid: touched.location && !errors.location
												},
												{
													label: "Barcode",
													feedback: " ",
													fieldType: "text",
													name: "barcode",
													value: values.barcode ?? '',
													onChange: handleChange,
													isValid: touched.barcode && !errors.barcode,
													isInvalid: !!errors.barcode,
													error: errors.barcode
												}
											]}
										/>	
									</Col>
									<Col md={4}>
										<FormInputs
											ncols={['12']}
											className={['px-2']}
											properties={[
												{
													label: "oems*",
													feedback: " ",
													fieldType: "textarea",
													name: "oems",
													className: "bzr-textarea",
													value: values.oems ?? '',
													onChange: handleChange,
													isValid: touched.oems && !errors.oems,
													isInvalid: !!errors.oems,
													error: errors.oems,
													info: HelperMsg('e.g.: oem-00001|oem-00002 or oem-00001,oem-00002')
												}
											]}
										/>	
									</Col>
									</Form.Row>
								</Accordion.Collapse>
								<Accordion.Toggle 
									as={Card.Header} 
									eventKey="2" 
									onClick={(event:any) => {
										const key:string = this.state.accordionKey === '2' ? '10': '2'
										this.setState({accordionKey: key})
									}}
									children={
										<div className="d-flex flex-row">
											<div><h5>Clasification</h5></div>
											<div className="flex-grow-1 pl-5">
												{ this.state.accordionKey !== '2' ?
												<div className="d-flex pt-1">
													<div className="px-3"><h6>in implementation process</h6></div>
												</div> : null}
											</div>
											<div className=""><h5><span className="pe-7s-angle-down caret" /></h5></div>
										</div>
										} />
								<Accordion.Collapse eventKey="2">
									<Form.Row>
										GPC - BEING IMPLEMENTED
									</Form.Row>
								</Accordion.Collapse>
								<Accordion.Toggle 
									as={Card.Header} 
									eventKey="3" 
									onClick={(event:any) => {
										const key:string = this.state.accordionKey === '3' ? '10': '3'
										this.setState({accordionKey: key})
									}}
									children={
										<div className="d-flex flex-row">
											<div><h5>Inventory</h5></div>
											<div className="flex-grow-1 pl-5">
												{ this.state.accordionKey !== '3' ?
												<div className="d-flex pt-1">
													<div className="px-3"><h6>supplier</h6>{this.state.supplier ? this.state.supplier.legalName : 'not set'} </div>
												</div> : null}
											</div>
											<div className=""><h5><span className="pe-7s-angle-down caret" /></h5></div>
										</div>
										} />
								<Accordion.Collapse eventKey="3">
									<Form.Row>
										<Col md={6} className="pr-5">
											<PartyInput 
												name='' 
												initState={this.state.supplier}
												liftUpState={(state:any) => this.setState({supplier: state.party})} 
												parties={this.state.suppliers} 
												placeholder="Add/Searh Supplier"
												emptyPlaceholder="Add Supplier"
												/>
										</Col>
										<Col md={6} className="px-3">
											<FormInputs
												ncols={['8']}
												className={['px-2']}
												properties={[
													{
														label: 'inventory book',
														horizontalLabel: '6',
														fieldType: 'select',
														name: 'bzrInventoryBookId',
														value: values.bzrInventoryBookId,
														options: (() => {
															const ops:any = this.props.appStates.bookNames ? Object.entries(this.props.appStates.bookNames.inventory).map((b:any) => [b[1], b[0]]) : [['','']]
															if(ops.length > 0) values.bzrInventoryBookId = ops[0][0]
															return ops
														})(),
														onChange: handleChange
													}
												]}
											/>
											<FormInputs
												ncols={['8']}
												className={['px-2']}
												properties={[
													{
														label: 'initial quantity',
														horizontalLabel: '6',
														feedback: ' ',
														fieldType: 'text',
														name: 'onHand',
														className: 'text-right',
														value: values.onHand ?? 0,
														onChange: handleChange
													}
												]}
											/>
											<FormInputs
												ncols={['8']}
												className={['px-2']}
												properties={[
													{
														label: 'minLimit',
														horizontalLabel: '6',
														feedback: ' ',
														fieldType: 'text',
														name: 'minLimit',
														className: 'text-right',
														value: values.minLimit ?? 0,
														onChange: handleChange
													}
												]}
											/>
											<FormInputs
												ncols={['8']}
												className={['px-2']}
												properties={[
													{
														label: 'maxLimit',
														horizontalLabel: '6',
														feedback: ' ',
														fieldType: 'text',
														name: 'maxLimit',
														className: 'text-right',
														value: values.maxLimit ?? 0,
														onChange: handleChange
													}
												]}
											/>
										</Col>
									</Form.Row>
								</Accordion.Collapse>
								
								<Form.Row>
									<Col md="5" className="mx-auto mt-3 text-center">							
										{
											isSubmitting ? <h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5> : 
												<Button 
													type="submit" 
													id={this.props.params.id + "-form-submit-"  + this.props.idn} 
													style={ this.props.singleSubmit || this.props.disableFields ? {display: "none"}: {display: "inline"} } >
														Submit
												</Button>
										}
									</Col>
								</Form.Row>
								</fieldset>
							</Col>
							<Col md={3} className="rounded-sm bg-light p-3">
								<FormInputs
									ncols={['6']}
									className={['']}
									properties={[
										{
											id: 'product',
											label: 'Product',
											fieldType: 'radio',
											name: 'fixedSchemaId',
											value: '6',
											onChange: () => this.setState({values: Object.assign(values, {fixedSchemaId: '6'})}),
											defaultChecked: true
										}
									]}
								/>
								<hr />
								{ 
									this.state.fixedFields ?
									this.renderFields(values, handleChange, touched, errors, values.fixedSchemaId) : 
									<h5><FontAwesomeIcon icon={faSpinner} spin /></h5>
								}
							</Col>
						</Form.Row>
						</Accordion>
						<MessageModal show={isSubmitting} message="Adding new product..." />
					</Form>
					)}
				</Formik>
				<PreviewModal 
					show={!!this.state.showPostview} 
					title="new product"
					url={this.state.showPostview ? this.state.showPostview : undefined} 
					onHide={() => {
						this.setState({
							showPostview: false
						})
					}
				} />
			</div>
		)
	}
}

export default withRouter(NewProductForm)