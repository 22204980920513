import React from 'react' 
import BzrAxios from '../../utils/BzrAxios'
import { Formik } from 'formik'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history"
import { Container, Row, Col, ListGroup, InputGroup, FormControl, ButtonToolbar, ButtonGroup, Button, Form, Table, Dropdown, DropdownButton } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import t, { EditableCell, HelperMsg } from '../../utils/I18n/I18n'
import { ConfirmModal, ParentModal, MessageModal, MsgBubble } from '../../layouts/Modals/Modals'
import { getCookie, setCookie } from '../../utils/Cookies'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	appStates: any,
	products: any,
	liftUpAppStates: any,
	params: any,
    idn: any
}
interface State {
    products:any
    defaultHeaders:any
    costBookNames:any
    priceBookNames:any
    costBooks:any
    priceBooks:any
    taxes:any
    bookToModify:any
    rename:any
    confirmAction:boolean
    manualUpdates:any
    message:any
    renameValue:string
    keys:any[],
    bubble:any
}
class PriceForm extends React.Component<Props,State> {
    version:string = '20200716'
    defaultHeaders:any = {
        cost: {default: true},
        price: {default: true}
    }
    constructor(props: Props) {
        super(props)
        this.state = {
            products:null,
            defaultHeaders: [],
            costBookNames: null, priceBookNames: null, costBooks:[], priceBooks:[], taxes:[], 
            bookToModify:null,
            confirmAction: false,
            manualUpdates: {inserts: [], updates: []},
            message:null,
            rename:null,
            renameValue: '',
            keys: [],
            bubble: null
        }
        this.setPriceList = this.setPriceList.bind(this)
        this.searchList = this.searchList.bind(this)
    }
    componentDidMount() {
        // Cookies
        const defaultHeadersCK:any = getCookie("price-list")
        if(!defaultHeadersCK) {
            this.setState({defaultHeaders: this.defaultHeaders})
            setCookie('price-list', JSON.stringify({version: this.version, data: {defaultHeaders: this.defaultHeaders}}), 30)
            console.log(JSON.stringify({version: this.version, data: {defaultHeaders: this.defaultHeaders}}))
        } else {
            const { version, data } = JSON.parse(defaultHeadersCK)
            if(version !== this.version) {
                this.setState({defaultHeaders: this.defaultHeaders})
                setCookie('price-list', JSON.stringify({version: this.version, data: {defaultHeaders: this.defaultHeaders}}), 30)
            } else{
                this.setState({defaultHeaders: data.defaultHeaders})
            }
        }
    }
    componentDidUpdate() {
        if(!this.state.costBookNames && this.props.appStates.bookNames) {
            this.orderBooks()
        }
        if(!this.state.products && this.props.products) {
            this.setState({products: this.props.products})
        }    
    }
    orderBooks() {
        const costBookNames:any = {},
                priceBookNames:any = {}

        let reordered:any[] = Object.entries(this.props.appStates.bookNames.cost_hash ?? {}).sort((a:any, b:any) => a[1].index - b[1].index)
        reordered.forEach((c:any) => {
            costBookNames[c[0]] = true
        })
        reordered = Object.entries(this.props.appStates.bookNames.price_hash ?? {}).sort((a:any, b:any) => a[1].index - b[1].index)
        reordered.forEach((p:any) => {
            priceBookNames[p[0]] = true
        })
        this.setState({costBookNames, priceBookNames})
    }
    searchList(event: any) {
        // Declare variables 
        const needle:string = event.target.value.toUpperCase()
        let products:any = []

        this.props.products.forEach((p:any) => {
            if (!needle) {
                products = this.props.products
            } else if ([p.name, p.description, p.sku, p.group].join().toUpperCase().indexOf(needle) > -1) {
                //tr.style.display = ""
                products.push(p)
            }
        })
        this.setState({products})
    }
    newBook(name:string) {
        const bookNameInput:any = document.getElementById(name)
        if(!bookNameInput.value) {
            alert('name cannot be empty')
            return
        }
        this.setState({message: 'adding book name'})
        let type = name === 'costBookName' ? 'Cost' : 'Price',
            data = {
                [name]: bookNameInput.value.replace(/\W+/g,'_'),
                alias: bookNameInput.value
            }
        
        BzrAxios.records({url: `/Books/${type}`, method: 'POST', data})
        .then((response: any) => {
            const bookName:any = response.data.bookName,
                bookNames:any = this.props.appStates.bookNames

            type = type.toLowerCase()
                bookNames[`${type}`][bookName[`${type}BookName`]] = bookName.id
                bookNames[`${type}_hash`][bookName.id] = bookName
            
            const books:any = this.props.appStates.books
            books[bookName.id] = {}

            this.props.liftUpAppStates({bookNames, books})
            this.orderBooks()
            this.setState({message: null, bubble: {variant: 'success', info: 'name successfully changed'}})
            bookNameInput.value = '' // Clear input field
        })
        .catch((error: any) => {
            if(error.response && error.response.status === 409) {
                console.log(error.response)
                alert(`${error.response.statusText ?? 'error'}: ${t(error.response.data.message)}`)
                this.setState({message: null})
			}
            console.error(error)
            bookNameInput.value = '' // Clear input field
        })
    }
    async setPriceList(preset:any) {
        console.log(preset)
        const books:any = this.props.appStates.books,
            refBook:any = this.props.appStates.books[preset.marginRefId]

        if(!preset.marginRefId) alert(t('choose a reference book'))

        const inserts:any[] = [], updates:any[] = []
        let count = 0
        this.state.products.forEach((product:any) => {
            if(!product.cc) return 
            const entry:any = {}
            entry['objectId'] = this.state.bookToModify.type === 'cost' ? '10' : '8'
            entry['entryValue'] = (refBook[product.id].entryValue * (1 + preset.marginRate/100)).toFixed(2)
            entry['currency'] = refBook[product.id].currency
            entry['recordName'] = `Bzr${this.state.bookToModify.type === 'cost' ? 'Cost' : 'Price'}BookName_${this.props.appStates.activeBU.id}`
            if(books[this.state.bookToModify.id][product.id]) {
                entry.id = books[this.state.bookToModify.id][product.id].id
                entry['updaterId'] = this.props.appStates.user.id
                entry['updatedAt'] = new Date().toISOString()
                updates.push(entry)
            } else {
                entry['bzrProductId'] = product.id
                entry['bookId'] = this.state.bookToModify.id
                entry['isActive'] = true
                entry['creatorId'] = entry['updaterId'] = this.props.appStates.user.id
                inserts.push(entry)
            }
            count++
        })
        if(!count) {
            alert('select items')
            this.setState({bookToModify: null})
            return
        }

        console.log(inserts, updates)
        const data = {inserts, updates}
        try {
            const response:any = await BzrAxios.records({url: `/Books/SetList`, method: 'POST', data})
            let updated:any[] = []
            if(response.data.insertedEntries) updated = updated.concat(response.data.insertedEntries)
            if(response.data.updatedEntries) updated =  updated.concat(response.data.updatedEntries)
            //console.log(updated)
            const newBooks = this.props.appStates.books
            updated.forEach((entry:any) => {
                newBooks[entry.bookId][entry.bzrProductId] = entry
            })
            this.props.liftUpAppStates({books: newBooks})
        } catch (err) {
            console.log(err)
        }
        this.setState({ bookToModify: null, confirmAction: false, bubble: {variant: 'success', info: 'adjustment done'} })
    }
    async manualUpdate(override = true) {
        const manualUpdates:any = this.state.manualUpdates
        this.setState({message: 'updating entries'})

        manualUpdates.updates.forEach((entry:any) => {
            entry['orgId'] = this.props.appStates.org.id
            entry['businessUnitId'] = this.props.appStates.activeBU.id
            entry['updaterId'] = this.props.appStates.user.id
        })
        manualUpdates.inserts.forEach((entry:any) => {
            entry['orgId'] = this.props.appStates.org.id
            entry['businessUnitId'] = this.props.appStates.activeBU.id
            entry['recordName'] = `Bzr${entry.objectId === '10' ? 'Cost' : 'Price'}BookName_${this.props.appStates.activeBU.id}`
            entry['currency'] = '840' ///  FIXXXXXXX
            entry['isActive'] = true
            entry['creatorId'] = entry['updaterId'] = this.props.appStates.user.id
        })
        
        const data = {inserts: manualUpdates.inserts, updates: manualUpdates.updates}

        try {
            const response:any = await BzrAxios.records({url: `/Books/SetList`, method: 'POST', data})
            let updated:any[] = []
            if(response.data.insertedEntries) updated = updated.concat(response.data.insertedEntries)
            if(response.data.updatedEntries) updated =  updated.concat(response.data.updatedEntries)
            
            updated.forEach((entry:any) => {
                const bookId = entry.bookId ?? entry.bookId
                this.props.appStates.books[bookId][entry.bzrProductId] = entry
            })
        } catch (err) {
            console.log(err)
        } 
        this.setState({
            bookToModify: null, 
            message: null,
            manualUpdates: {inserts: [], updates: []},
            bubble: {variant: 'success', info: 'adjustment done'}
        })
    }
    presetMarginForm() {
        return(
            <Formik className="price-presets-modal"
            onSubmit={this.setPriceList}
            initialValues={{
                marginRefType: 'cost',
                marginRefId: this.props.appStates.bookNames ? Object.entries(this.props.appStates.bookNames['cost_hash'])[0][0] : '0',
                marginRate: 0
            }}
            enableReinitialize={true}
            >
            {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                values
                }) => (
                <Form  noValidate onSubmit={handleSubmit} id='price-preset-form'>
                    <h4>{t('run margin function')}</h4>
                    <Form.Row>
                        <Col className="py-2 px-5 w-75">
                            <div style={{height: "120px"}}>
                                <span className="badge badge-primary m-3 text-uppercase">{t('step')} 1</span>
                                <h6>{t('select type of reference')}</h6>
                            </div>
                            <Form.Group controlId="marginRefType">
                                <Form.Control as="select" custom value={values.marginRefType} onChange={(event:any) => {
                                    handleChange(event)
                                    setFieldValue('marginRefId', Object.entries(this.props.appStates.bookNames[`${event.target.value}_hash`])[0][0])
                                    }}>
                                    <option value='cost'>{t('costs')}</option>
                                    <option value='price'>{t('prices')}</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col className="py-2 px-5 w-75 border-left">
                            <div style={{height: "120px"}}>
                                <span className="badge badge-primary m-3 text-uppercase">{t('step')} 2</span>
                                <h6>{t('select book of reference')}</h6>
                            </div>
                            <Form.Group controlId="marginRefId">
                                {/* {this.state.preset.marginRefId ? this.props.appStates.bookNames[this.state.preset.marginRefType + '_hash'][this.state.preset.marginRefId].name : ''} */}
                                <Form.Control as="select" custom value={values.marginRefId} onChange={handleChange}>
                                    {this.props.appStates.bookNames ? 
                                        Object.entries(this.props.appStates.bookNames[`${values.marginRefType}_hash`])
                                            .map((n:any, k:number) => (<option key={k} value={n[0]}> {n[1].alias} </option>)) : null
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col className="py-2 px-5 w-75 border-left">
                            <div style={{height: "120px"}}>
                                <span className="badge badge-primary m-3 text-uppercase">{t('step')} 3</span>
                                <h6>{t('set margin to apply')} (%)</h6>
                            </div>
                            <Form.Group controlId="marginRate">
                                <Form.Control type="text" value={values.marginRate} onChange={handleChange} />
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <div className="w-100 text-center mt-4">
                        {
                        isSubmitting ? <div><h5>{t('submitting')}...<FontAwesomeIcon icon={faSpinner} spin /></h5></div> :
                            <>
                                <Button className="mx-3 px-5" variant="danger" onClick={() => { this.setState({ bookToModify: null }) }}>{t('close')}</Button>
                                <Button className="mx-3 px-5" type='submit' id='price-preset-submit'>{t('apply')}</Button>
                            </>
                        }
                    </div>
                </Form>
                )}
            </Formik>
        )
    }
    renderList() {
        let trs:any[] = []
        if(this.state.products && this.state.products.length > 0 && this.state.costBookNames) {  
            const costBooks:string[] = Object.keys(this.state.costBookNames).filter((c)=> (this.state.costBookNames[c] === true)),
                priceBooks:string[] = Object.keys(this.state.priceBookNames).filter((p)=> (this.state.priceBookNames[p] === true)),  
                books:any = this.props.appStates.books

            const products:any = [...this.state.products]
            products.forEach((product:any, key:number) => {
                //product.cc = product.cc ?? false
                trs.push(
                    <tr key={key} data-record-id={product.id} >
                        <td key={`cc-${key}`} className="text-center">
                            <input name={product.id} className="price-list-cc" type="checkbox"
                                checked={product.cc}       
                                onChange={(event:any) => {
                                    product.cc = event.target.checked
                                    
                                    }}/>
                        </td>
                        <td className="text-center">{key + 1}</td>

                        <td>{product.name}<p><b>SKU: </b>{product.sku}</p></td>
                        <td>{product.description}</td>
                        <td>{product.group}</td>

                        
                        { (this.props.appStates.user.userType === 'administrator' || this.props.appStates.user.userType === 'root') && costBooks.map((c,i) => {
                            
                            return(
                            <td className="number-format" key={'c' + i} >
                                <EditableCell 
                                    
                                    value={books[c][product.id]?.entryValue} 
                                    message={this.state.message}
                                    liftUpValue={(val:any) => {
                                        val = parseFloat(val)
                                        const manualUpdates:any = this.state.manualUpdates
                                        const entry:any = books[c][product.id] ?? {}
                                        entry['objectId'] = '10'
                                        entry['entryValue'] = val
                                        entry['bzrProductId'] = product.id
                                        entry['bookId'] = c
                                        if(entry.id){
                                            const ent:any = manualUpdates.updates.find((el:any) => (el.bzrProductId === product.id && el.bookId === c))
                                            if(!ent) manualUpdates.updates.push(entry)
                                            else ent.entryValue = val
                                        } else {
                                            const ent:any = manualUpdates.inserts.find((el:any) => (el.bzrProductId === product.id && el.bookId === c))
                                            if(!ent) manualUpdates.inserts.push(entry)
                                            else ent.entryValue = val
                                        }
                                        this.setState({manualUpdates})
                                    }} 
                                />
                            </td> )}
                        )}
                        {priceBooks.map((p,i) => (
                            <td className="number-format" key={'p' + i} onClick={(event:any) => event.preventDefault()}>
                                <EditableCell 
                                    disabled={!(this.props.appStates.user.userType === 'administrator' || this.props.appStates.user.userType === 'root')}
                                    value={books[p][product.id]?.entryValue} 
                                    liftUpValue={(val:any) => {
                                        val = parseFloat(val)
                                        const manualUpdates:any = this.state.manualUpdates
                                        const entry:any = books[p][product.id] ?? {}
                                        entry['objectId'] = '8'
                                        entry['entryValue'] = val
                                        entry['bzrProductId'] = product.id
                                        entry['bookId'] = p
                                        if(entry.id){
                                            const ent:any = manualUpdates.updates.find((el:any) => (el.bzrProductId === product.id && el.bookId === p))
                                            if(!ent) manualUpdates.updates.push(entry)
                                            else ent.entryValue = val
                                        } else {
                                            const ent:any = manualUpdates.inserts.find((el:any) => (el.bzrProductId === product.id && el.bookId === p))
                                            if(!ent) manualUpdates.inserts.push(entry)
                                            else ent.entryValue = val
                                        }
                                        this.setState({manualUpdates})
                                    }} 
                                />
                            </td>)
                        )}
                        <td style={{fontWeight: 600}}>
                            <a href="#edit" style={{color: 'red'}} onClick={(event:any)=> {
                                event.preventDefault()
                                }}>action 1
                            </a>
                        </td>
                    </tr>
                )
            })

        } else if(this.props.products && this.props.products.length === 0) {
            trs.push(<tr key="1"><td colSpan={9} className="pt-5 text-center"><h5>No products added yet!</h5></td></tr>)
        }
        return trs
    }
    ddMenuActions() {
        const bookNames:any = this.props.appStates.bookNames
        let bookToModify:any = {}, checked:any = {}
        if(!bookNames || !this.state.costBookNames) return null
        if(this.state.costBookNames && Object.keys(this.state.costBookNames).length < 1) return null
        return(
            <div>
                <Dropdown.Item onClick={() => {
                    console.log(this.state.products)
                    this.setState({bookToModify})}
                    }><h5>{t('run margin on costs')}</h5></Dropdown.Item>
                <Container className="small" style={{minWidth: "250px"}}>
                    <Row>
                        <Col md={5}>
                            <h6>{t('costs')}</h6>
                            {Object.entries(bookNames.cost_hash).sort((a:any, b:any) => a[1].index - b[1].index).map((c:any[],i) => (
                                <Form.Group controlId={"costCheck" + i} key={`c${i}`}>
                                    <Form.Check type="checkbox" checked={checked[`c${i}`]} className="cbs" name={c[0]} label={c[1].alias} onChange={(event:any) => {
                                        const cbs:any = document.getElementsByClassName('cbs')
                                        Array.from(cbs).forEach((el:any) => el.children[0].checked = false )
                                        event.target.checked = true
                                        bookToModify = { type: 'cost', id: event.target.name, cc: true}
                                    }}/>
                                </Form.Group>
                                )
                            )}
                        </Col>
                    </Row>
                </Container>
                <Dropdown.Divider />
                <Container className="small" style={{minWidth: "250px"}}>
                    <Row>
                        <Col md={5}>
                            <h6>{t('prices')}</h6>
                            {Object.entries(bookNames.price_hash).sort((a:any, b:any) => a[1].index - b[1].index).map((p:any[],i) => (
                                <Form.Group controlId={"priceCheck" + i} key={`p${i}`}>
                                    <Form.Check type="checkbox" checked={checked[`p${i}`]} className="cbs" name={p[0]} label={p[1].alias} onChange={(event:any) => {
                                        const cbs:any = document.getElementsByClassName('cbs')
                                        Array.from(cbs).forEach((el:any) => {
                                            el.children[0].checked = false})
                                        event.target.checked = true
                                        bookToModify = { type: 'price', id: event.target.name, cc: true}
                                    }}/>
                                </Form.Group>)
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
    dropdownMenu() {
        const bookNames:any = this.props.appStates.bookNames
        if(!bookNames || !this.state.costBookNames) return null
        if(this.state.costBookNames && Object.keys(this.state.costBookNames).length < 1) return null
        return(
            <Container className="small" style={{minWidth: "250px"}}>
                <Row>
                    <Col md={6}>
                        <h6>{t('costs')}</h6>
                        {Object.entries(bookNames.cost_hash).sort((a:any, b:any) => a[1].index - b[1].index).map((c:any[],i) => (
                            <Form.Group controlId={"costCheck" + i} key={'c' + i}>
                                <Form.Check type="checkbox" name={c[0]} label={c[1].alias} checked={this.state.costBookNames[c[0]]} onChange={(event:any) => {
                                    const { costBookNames } = this.state
                                    costBookNames[event.target.name] = event.target.checked
                                    this.setState({costBookNames})
                                }}/>
                            </Form.Group>
                            )
                        )}
                    </Col>
                    <Col md={6}>
                        <h6>{t('prices')}</h6>
                        {Object.entries(bookNames.price_hash).sort((a:any, b:any) => a[1].index - b[1].index).map((p:any[],i) => (
                            <Form.Group controlId={"priceCheck" + i} key={'p' + i}>
                                <Form.Check type="checkbox" name={p[0]} label={p[1].alias} checked={this.state.priceBookNames[p[0]]} onChange={(event:any) => {
                                    const { priceBookNames } = this.state
                                    priceBookNames[event.target.name] = event.target.checked
                                    this.setState({priceBookNames})
                                }}/>
                            </Form.Group>)
                        )}
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        const bookNames:any = this.props.appStates.bookNames,
            costBookNames:any = this.state.costBookNames ?? [],
            priceBookNames:any = this.state.priceBookNames ?? [],
            cbArray:any = Object.keys(costBookNames).filter((c)=> (costBookNames[c] === true)),
            pbArray:any = Object.keys(priceBookNames).filter((p)=> (priceBookNames[p] === true))
        
        return(
            <Container>
                <Row className="mx-sm-1 mx-md-5">
                    { 
                    (this.props.appStates.user.userType === 'administrator' || this.props.appStates.user.userType === 'root') && 
                    <Col><h5>{t('cost books list')}</h5>
                        <ListGroup className="small">
                            {this.state.costBookNames && bookNames ? Object.keys(this.state.costBookNames).map((c:any, k:number) => 
                                <ListGroup.Item key={k}>
                                    {bookNames.cost_hash[c].alias}
                                    <div className="float-right" >
                                        <a href="#update-cost" onClick={() => this.setState({bookToModify: {type: 'cost',id: c}})}>
                                            {t('run margin')}
                                            {k === 0 ? <span> {HelperMsg('run margin over shown element - overrides individual selection')}</span> : ''}
                                        </a> | 
                                        <a href="#rename" style={{color: 'darkblue'}} onClick={() => {
                                            this.setState({rename: {oid: '11', id: c}})
                                        }}> {t('rename')} </a> | 
                                        <a href="#delist" style={{color: 'orangered'}}> {t('delist')}</a>
                                    </div>
                                </ListGroup.Item>
                                ) : null
                            }      
                        </ListGroup>
                        <InputGroup className="my-3">
                            <FormControl id="costBookName" aria-describedby="basic-addon1" placeholder={t('new cost book')}/>
                            <InputGroup.Prepend>
                                <Button variant="outline-secondary" onClick={() => this.newBook('costBookName')}>{t('add')}</Button>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Col>
                    }
                    
                    <Col md={7}><h5>{t('price books list')}</h5>
                        <ListGroup className="small">
                            {this.state.priceBookNames && bookNames ? Object.keys(this.state.priceBookNames).map((p:any, k:number) => 
                                <ListGroup.Item key={k}>
                                    {bookNames.price_hash[p].alias}
                                    <div className="float-right">
                                        <a href="#run-margin" onClick={() => this.setState({bookToModify: {type: 'price',id: p}})}>{t('run margin')} </a> | 
                                        <a href="#rename" style={{color: 'darkblue'}}  onClick={() => {
                                            this.setState({rename: {oid: '9', id: p}})
                                        }}> {t('rename')} </a> | 
                                        <a href="#delist" style={{color: 'orangered'}}> {t('delist')}</a>
                                    </div>
                                </ListGroup.Item>
                                ) : null
                            }      
                        </ListGroup>
                        <InputGroup className="my-3">
                            <FormControl id="priceBookName" aria-describedby="basic-addon1" placeholder={t('new price book')} />
                            <InputGroup.Prepend>
                                <Button variant="outline-secondary" onClick={() => this.newBook('priceBookName')}>{t('add')}</Button>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mx-sm-1 mx-md-4">
                    <Col md={12}>
                        <div className="d-flex mt-2 mb-1">
                            <div className="p-2"><h4>{t('products')}</h4></div>
                            <div className="p-2">
                                <input type="text" id="search-box" onKeyUp={this.searchList} placeholder={`${t('search')}...`} />
                            </div>
                            <div className="ml-auto p-2">
                                <ButtonToolbar className="mb-4 bzr-table-toolbar">
                                    <ButtonGroup className="mx-2">
                                        <Button variant="outline-secondary">
                                            <span className="text-center" onClick={() => this.manualUpdate() }>
                                                <i className="fa fa-edit" title={t('update manual entries')}></i>
                                            </span>
                                        </Button>
                                        <DropdownButton
                                            as={ButtonGroup}
                                            id="dropdown-actions"
                                            variant="outline-secondary"
                                            title={<i className="fas fa-file-alt" title={t('run margin')}></i>}
                                        >
                                            {/* {this.ddMenuActions()} */}
                                        </DropdownButton>
                                    </ButtonGroup>
                                    <ButtonGroup className="mx-2">
                                        <DropdownButton
                                            as={ButtonGroup}
                                            id="dropdown-configs"
                                            variant="outline-secondary"
                                            title={
                                                <span className="text-center">
                                                    <i className="fas fa-cog" title={t('options')}></i>
                                                </span>
                                                }
                                        >
                                            {this.dropdownMenu()}
                                        </DropdownButton>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </div>
                        </div>
                        <Table id="product-list" className="prices" size="sm" bordered responsive>
                            <thead>
                                <tr className="bg-secondary">
                                    <th colSpan={5}></th>
                                    {(this.props.appStates.user.userType === 'administrator' || this.props.appStates.user.userType === 'root') && cbArray.length !== 0 ? <th colSpan={cbArray.length}>{t('costs')}</th>
                                    : null }
                                    {pbArray.length !== 0 ? 
                                    <th colSpan={pbArray.length}>{t('prices')}</th>
                                    : null }
                                    <th></th>
                                </tr>
                                <tr>
                                    <th key="h-all"  className="text-center">
                                        <input id="select-all-items" type="checkbox" 
                                            onClick={(event:any) => {
                                                const products:any = this.state.products
                                                products.forEach((p:any, i:number) => {
                                                    p.cc = event.target.checked
                                                })
                                                this.setState({products})
                                            }}/>
                                    </th>
                                    <th>#</th>
                                    <th style={{width: "150px"}}>{t('name')}</th>
                                    <th style={{width: "300px"}}>{t('description')}</th>
                                    <th style={{width: "100px"}}>{t('group')}</th>
                                    {(this.props.appStates.user.userType === 'administrator' || this.props.appStates.user.userType === 'root') &&  cbArray.map((c:any, i:number) => (<th key={'c' + i} className="text-right">{bookNames.cost_hash[c].alias ?? bookNames.cost_hash[c].costBookName}</th>))}
                                    {pbArray.map((p:any, i:number) => (<th key={'p' + i} className="text-right">{bookNames.price_hash[p].alias  ?? bookNames.price_hash[p].priceBookName}</th>))}
                                    <th className="text-center" style={{width: "100px"}}>{t('actions')}</th>
                                </tr> 
                            </thead>
                            <tbody id="product-list-tbody">
                                { this.state.products ? this.renderList() : <tr><td colSpan={9} className="pt-5 text-center"><h5>{t('listing')}... <FontAwesomeIcon icon={faSpinner} spin /></h5></td></tr> }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <ParentModal 
					show={!!this.state.bookToModify}  
                    children={this.presetMarginForm()}
					onHide={() => { this.setState({ bookToModify: null }) }}
				/>
                <ParentModal 
                    show={!!this.state.rename}  
                    title="rename book"
					children={
                        <InputGroup className="mb-3">
                            <FormControl id="rename" aria-describedby="rename-book" placeholder='new book alias' value={this.state.renameValue} onChange={(event:any) => this.setState({renameValue: event.target.value})}/>
                            <InputGroup.Prepend>
                                <Button variant="outline-secondary" onClick={() => {
                                    this.setState({message: 'changing book name'})
                                    const data:any = {}
                                    data['id'] = this.state.rename.id
                                    data['orgId'] = this.props.appStates.org.id
                                    data['businessUnitId'] = this.props.appStates.activeBU.id
                                    data['objectId'] = this.state.rename.oid
                                    data['recordName'] = `Bzr${this.state.rename.id === '9' ? 'price' : 'cost'}BookName_${this.props.appStates.activeBU.id}`
                                    data['alias'] = this.state.renameValue
                                    data['creatorId'] = data['updaterId'] = this.props.appStates.user.id
                                    
                                    
                                    BzrAxios.records({url: `/Books/ChangeName`, method: 'POST', data})
                                    .then((response:any) => {
                                        alert(response.data)
                                        this.setState({rename: null, message: null})
                                    })
                                    .catch(err => {
                                        this.setState({rename: null, message: null})
                                        console.log(err)
                                    })
                                }}>Rename</Button>
                            </InputGroup.Prepend>
                        </InputGroup>
                    } 
					onHide={() => {
						this.setState({
							rename: null
						})
					}}
				/>
                <ConfirmModal 
                    show={this.state.confirmAction} 
                    title="confirm" 
                    onClick={this.setPriceList}
                    confirmtitle="apply"
                    onHide={() => { this.setState({confirmAction: false}) }} 
                    />
                <MessageModal show={!!this.state.message} message={this.state.message} />
                <MsgBubble msg={this.state.bubble} onClose={() => this.setState({bubble: null})}/>
            </Container>
        )
    }
}

export default withRouter(PriceForm)