import React from 'react'
import PayPalButton from './PayPalButton'
import { Container, Row, Col, Card } from 'react-bootstrap'

class Pricing extends React.Component {

    render() {
        return(
            <Container>
                <Row>
                    <Col md={4}>
                        <Card className="pricing-card">
                            <Card.Header>Bizor Lite</Card.Header>
                            <Card.Body>
                                <Card.Title>Special title treatment</Card.Title>
                                <Card.Text>
                                With supporting text below as a natural lead-in to additional content.
                                </Card.Text>
                                <PayPalButton />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Pricing