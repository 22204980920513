import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { header } from './Functions'

class Printer {
    static print(html:any, options:any) {
        const doc = new jsPDF('p', 'mm', 'letter'),
            offset = header(doc, {title: options.title}, options)
            
        this.renderTable(doc, Object.assign(html, {margin: {top: offset, bottom: 30}, headStyles: {fontSize: 6}, bodyStyles: {fontSize: 6}}), options.colHeaders)
        
        //doc.text(`test test test`, 200, 250, {align: 'right'})

        return doc.output('blob')
    }    

    static renderTable(doc:any, table:any, colHeaders:any) {
        let tb = document.getElementById(table.html.replace('#',''))
        var decodedTable:any = doc.autoTableHtmlToJson(tb, true)
        //atTB.columns.splice(0,1)
        console.log(decodedTable)

        let columns:any[] = []
        decodedTable.columns.forEach((c:any, i:number) => {
            c.header.content = c.header.content.toUpperCase()
            if(i !== 0) columns.push(c)
        })
/*         const hooks:any = {
            didParseCell: (data:any) => {
                if(data.section === 'head') {
                    data.cell.text[0] = colHeaders[data.column.index]
                }
            }
        } */
        const body = decodedTable.rows.map((r:any) => {
            let res:any = []
            r.forEach((d:any, i:number) => {
                if(i !== 0) {
                    if(d._element.className.match('text-right')) d.styles = {halign: 'right'}
                    res.push(d)
                }
            })
            return res
        })
        console.log(columns, body)

        doc.autoTable(
            {
                columns, 
                body, 
                margin: {top: 15, bottom: 25},
                startY: 40,
                headStyles: {fontSize: 8, fontStyle: 'bold'}, 
                bodyStyles: {fontSize: 8}
            }
        )
    }
}

export default Printer