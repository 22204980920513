import React, { useState } from 'react'
import { Table, Container, Row, Col, Form, ButtonToolbar, ButtonGroup, Button, DropdownButton, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import FilterSearch from './FilterSearch'
import XLSX from 'xlsx'
import { printProductsList } from '../Printer/PdfPrinter'
import t, { fn } from '../../utils/I18n/I18n'
import ImageBox from '../Common/ImageBox'
import ImageInput from '../Common/ImageInput'
import { ParentModal } from '../../layouts/Modals/Modals'
import BzrAxios from '../../utils/BzrAxios'
import { Storage } from 'aws-amplify'


function AddImage(props:any) {
    const [image, setImage]:[any, any] = useState({imageURL: null})
    const [submitting, setSubmitting] = useState(false)
    const updateImage = (props:any) => {
        if(!image.imageURL) {
            alert(t('select an image'))
            return
        }
        setSubmitting(true)
        const imageFile = image.imageFile ?? null
        if(imageFile) {
            const imageKey:string = `${props.orgId}/${props.activeBUId}/bzrProductImage/${props.productId}.${imageFile.type.replace('image/','')}`

            BzrAxios.records({url:`/BzrProduct/${props.productId}`, method: 'PUT', data: {bzrProductImage: imageKey} })
            .then((response:any) => {
                Storage.put(imageKey, imageFile, {
                    contentType: imageFile.type
                })
                .then (result => {
                    props.liftUpImage({imageURL: image.imageURL, imageKey})
                })
                .catch(err => {
                    alert(t('error uploading image - try later on edit'))
                    console.log(err)
                })
            })
            .catch( err => console.log(err))
        }
    }
    return(
        <Card>
            <Card.Body>
                <div className="d-flex justify-content-around">
                    <div className="p-2">
                    <ImageInput
                        image={image}
                        liftUpState={(image:any) => {
                            console.log(image)
                            setImage(image)
                        }} 
                        boxW={170}
                        boxH={170} 
                        scale={0.95}
                        className="rounded-sm mx-auto dashed-border" />
                    </div>
                    <div className="d-flex align-items-start flex-column">
                        <div className="mb-auto  ml-auto p-2">
                            <i className="fas fa-times" onClick={() => props.cancel()} />
                        </div>
                        <div className="p-2">
                            { submitting ? 
                                <h5>{t('updating')} <FontAwesomeIcon icon={faSpinner} spin /></h5>
                            :
                                <Button variant="primary" onClick={() => updateImage(props)}>{t('update')}</Button>
                            }
                            
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

interface Props {
    id:string
    items:any[]
    headers:any[]
    extras?:any
    title?:string
    defaultHeaders?:any
    liftUpHeaders?:any
    updateClick?:any
    fields?:any
    actions?:any
    reportsMenu?:any
    refreshing?:boolean
    doubleClick?:any
    onRowClick?:any
    liftUpImage?:any
    updated?:any
}
interface State {
    items:any
    filteredItems:any[]
    headers:any[]
    trs:any[]
    keys:any
    filters:any
    printing:boolean
    maths:any
    defaultHeaders:any
    imageProductId:any
    updated:boolean
}
class BzrTable extends React.Component<Props,State> {
    irTimer:any = null // image refresh timer
    gotFields:boolean = false
    isSearching:boolean = false
    constructor(props:Props) {
        super(props)
        this.state = {
            items: null,
            filteredItems: [],
            headers: [],
            trs: [],
            keys: null,
            filters: {},
            printing: false,
            maths: {},
            defaultHeaders: [],
            imageProductId:null,
            updated:false
        }
        this.handleFiltering = this.handleFiltering.bind(this)
        this.handleClearFilter = this.handleClearFilter.bind(this)
        this.searchList = this.searchList.bind(this)
    }
    componentDidUpdate() {
        if(this.props.headers.length > 0 && this.state.headers.length === 0) this.setState({headers: this.props.headers})
        if(this.props.fields && this.props.fields.length > 0 && !this.gotFields) {
            this.gotFields = true
            const headers:any[] = this.props.headers.map((h:any) => {
                const fn:any = this.props.fields.find((fi:any) => fi.fieldName === h.fieldName)
                return Object.assign({},h,fn)
            })
            this.setState({headers})
        }
        if(this.props.items && this.props.items.length > 0 && JSON.stringify(this.props.items) !== JSON.stringify(this.state.items)) {
            this.setState({items: this.props.items, filteredItems: this.props.items}, () => this.renderBody(this.props.items))
        }
        if(this.props.updated && !this.state.updated) {
            this.setState({updated: true})
            this.renderBody(this.props.items)
        }
    }
    componentWillUnmount() {
        if(this.irTimer) clearTimeout(this.irTimer)
    }
    handleFiltering(filter:any) {
        const filters:any = Object.assign(this.state.filters, filter)
        var filteredItems:any[] = this.props.items
        
        for(let f in filters) {
            if(filters[f] && filters[f].values) {
                
                const items:any[] = filteredItems
                filteredItems = []
                const filterValues:any = filters[f].values,
                dataType:string = filters[f].dataType
                // eslint-disable-next-line no-loop-func
                items.forEach((i:any) => {
                    let key:any = i[f]
                    if(dataType === 'date') {
                        key = new Date(i[f]).toLocaleDateString(this.props.extras.activeBU.locale ?? 'en-US', { day: '2-digit', month: '2-digit', year: 'numeric'})
                    }
                    if(filterValues && filterValues[key].selected) filteredItems.push(i)
                })
            } 
        }
        
        if(filteredItems.length === 0) filteredItems = this.props.items
        this.setState({filteredItems})
        this.renderBody(filteredItems)
    }
    handleClearFilter() {
        const items:any = this.state.filteredItems, cball:any = document.getElementById('select-all-items')
        cball.checked = false
        items.forEach((item:any, i:number) => {
            item.cc = false
        })
        this.setState({filteredItems: items})
        this.renderBody(items)
    }
    exportFile() {
        const headers = this.state.headers.map((h:any) => h.fieldLabel ?? h.fieldName )
        const items = this.state.filteredItems.map((i:any, k:number) => {
            let tds:any[] = []
            this.state.headers.forEach((h:any, l) => {
/*                 if(this.props.fields) {
                    const f:any = this.props.fields.find((fi:any) => fi.fieldName === h.fieldName) */
                    if(h.fieldType === 'numeric') {
                        tds.push(fn(i[h.fieldName]))
                        //this.maths(h.fieldName, i[h.fieldName])
                    } else tds.push(i[h.fieldName])
/*                 } else {
                    tds.push(i[h.fieldName])
                } */
            })
            return tds
        })
        /* convert state to workbook */
        let data:any[] = []
        data.push([this.props.extras.activeBU.legalName])
        data.push([this.props.extras.activeBUId.identificationNumber])
        data.push([])
        data.push(headers)
        data = data.concat(items)

        console.log(data)

        const ws = XLSX.utils.aoa_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, this.props.extras.activeBU.legalName.replace(/\W+/g,'_') + '-transacs.xlsx')
    }
    dropdownMenu() {
        return(
            <Container className="small" style={{minWidth: "250px"}}>
                <Row>
                    <Col md={11}>
                        <h6>{t('fields')}</h6>
                        {this.props.headers.map((h:any,i:number) => {
                            return(
                            <Form.Group controlId={"fieldCheck" + i} key={i}>
                                <Form.Check type="checkbox" name={h.fieldName} label={h.fieldLabel ? t(h.fieldLabel) : h.fieldName} checked={h.show} onChange={(event:any) => {
                                    const defaultHeaders:any = this.props.headers, headers:any = this.state.headers
                                    defaultHeaders[i].show = event.target.checked
                                    headers[i].show = event.target.checked
                                    h.show =  event.target.checked 
                                    this.props.liftUpHeaders(defaultHeaders)
                                    this.setState({headers})
                                    this.renderBody(this.props.items)
                                }}/>
                            </Form.Group>
                            )}
                        )}
                    </Col>
                </Row>
            </Container>
        )
    }
    renderHeader() {
        let tr:any = [
            <th key="h-all"  className="text-center">
                <input id="select-all-items" type="checkbox" 
                    onClick={(event:any) => {
                        const items:any = this.state.filteredItems
                        items.forEach((item:any, i:number) => {
                            item.cc = event.target.checked
                        })
                        this.setState({filteredItems: items})
                        this.renderBody(items)
                    }}/>
            </th>, <th key="h-index" className="text-center">#</th>
        ]
        this.state.headers.forEach((h:any, k:number) => {
            if(h.show) {
                tr.push(
                <th key={k} style={h.style ?? {}}>
                    {h.filter ? 
                    <FilterSearch 
                    id={k}
                    pkey={h.fieldName}
                    label={h.fieldLabel ? t(h.fieldLabel): t(h.fieldName)}
                    liftUpOption={this.handleFiltering}
                    handleClear={this.handleClearFilter}
                    items={this.state.keys ? this.state.keys[h.fieldName] : []}
                    placeholder="Search for client..."
                    whenEmpty="No parties..."
                    /> :  <div>{h.fieldLabel ? t(h.fieldLabel): t(h.fieldName)}</div>}
                </th>
                )
            }
        })
        if(this.props.actions) tr.push(<th key="ha">actions</th>)
        return (<tr>{tr}</tr>)
    }
    renderBody(items:any[]) {
        let trs:any = [], keys:any = {}, maths:any = {}, filteredItems:any = []
        const el:any = document.getElementById('search-box')
        if(!el) return
        const needle:string = el.value.toUpperCase()
        items.forEach((i:any,k:number) => {
            if ([i.name, i.description, i.sku, i.group].join().toUpperCase().indexOf(needle) > -1) {
                let tds:any = []
                i.cc = i.cc ?? false
                tds.push(
                    <td key={`cc-${k}`} className="text-center">
                        <input id={i.id} className={`${this.props.id}-cb`} type="checkbox"
                            checked={i.cc}       
                            onChange={(event:any) => {
                                i.cc = event.target.checked
                                this.setState({filteredItems: items})
                                this.renderBody(items)
                                }}/>
                    </td>
                )
                tds.push(<td key={`ci-${k}`} className="text-center">{k + 1}</td>)
                tds = tds.concat(this.props.headers.map((h:any, l:number) => {
                    if(h.show) {
                        const fieldType:string = h.fieldType, alarm = h.alarm
                        const fdn = h.fieldName  
                        let td:any, cn:string = ''
                        if(fieldType === 'numeric') {
                            if(h.totalizeIf && i[h.totalizeIf.ref].indexOf(h.totalizeIf.stringLike) > -1) {
                                maths[fdn] = maths[fdn] ?? {sum: 0, avg: 0}
                                maths[fdn].sum += i[fdn] ? parseFloat(i[fdn]) : 0
                                maths[fdn].avg = maths[fdn].sum/(k + 1)
                                cn += 'font-weight-bold '
                            } else {
                                cn += 'text-muted '
                            }
                            td = fn(i[fdn])
                            cn += 'text-right pr-3 '
                        } else if( fieldType === 'date') {
                            td = new Date(i[fdn]).toLocaleDateString(this.props.extras.activeBU.locale ?? 'en-US', { day: '2-digit', month: '2-digit', year: 'numeric'})
                            if(alarm && alarm.daysBefore) {
                                const now:any = new Date()
                                const e:any = new Date(i[fdn])
                                const diff = (e - now)/(24*60*60*1000)
                                if(diff < alarm.daysBefore && diff >= 0) cn = 'show-alarm '
                                if(diff < 0) cn = 'show-alarm-2 '
                            }
                        } else if( fieldType === 'image') {
                            td = i.imageURL ? 
                                <div style={{zIndex: 10000}} onDoubleClick={(event:any) => {
                                    event.stopPropagation()
                                    this.setState({imageProductId: i.id})
                                }}><ImageBox url={i.imageURL} id={i.id} size={75} /></div> : 
                                <button className="bzr-table-btn" onClick={() => this.setState({imageProductId: i.id}) }>{t('add picture')}</button>
                            cn = 'bzr-image-cell '
                        } else {
                            td = i[fdn]
                            cn += 'text-left '
                        }
                        if(!keys[fdn]) {
                            keys[fdn] = {values: [], dataType: fieldType}
                            keys[fdn].values.push(td)
                        } else if(!keys[fdn].values.includes(td)) {
                            keys[fdn].values.push(td)
                        }
                        return(<td key={l} className={cn} >{td}</td>)
                    } else return null
                }))
                if(this.props.actions) tds.push(<td key="ba">{this.props.actions(i, k, this.props.extras.options)}</td>)
    
                trs.push(<tr key={k} 
                            onDoubleClick={(event:any) => { event.preventDefault(); if(this.props.doubleClick) this.props.doubleClick(event.target, i); event.target.blur() }}
                            onClick={() => {if(this.props.onRowClick) this.props.onRowClick(i) }}
                            >{tds}</tr>)
                this.setState({maths})
                filteredItems.push(i)
            }
        })
        //this.setState({trs, keys: !this.state.keys ? keys : this.state.keys})
        this.setState({trs, keys, filteredItems})
    }
    renderFooter() {
        let td:any[] = [<td key="f1" colSpan={2}></td>]
        this.props.headers.forEach((h:any, k:number) => {
            if(h.show) {
                td.push(
                    <td key={k}>
                        {h.fieldType === 'numeric' ? 
                        <div>
                            <div>sum:<span>{this.state.maths[h.fieldName] ? fn(this.state.maths[h.fieldName].sum ): null}</span></div><div>avg:<span>{this.state.maths[h.fieldName] ? fn(this.state.maths[h.fieldName].avg) : null}</span></div>
                        </div>
                        : null }
                    </td>
                )
            }
        })
        if(this.props.actions) td.push(<td key="f2"></td>)
        return (<tr>{td}</tr>)
    }
    searchList(event: any) {
        this.renderBody(this.props.items)
    }
    render() {
        return (
            <Container>
                { this.props.refreshing ? <div className="modal-frame"><div>{t('updating')} <FontAwesomeIcon icon={faSpinner} spin /></div></div> : null }
                <Row>
                    <Col md={8}>
                        <div className="d-flex mb-4">
                            <div className="p-2">{ this.props.title ? <h4>{t(this.props.title)}</h4> : null}</div>
                            <div className="p-2">
                                <input type="text" id="search-box" onKeyUp={this.searchList} placeholder={`${t('quick search')}...`} />
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className="text-right">
                        <ButtonToolbar className="mb-4 bzr-table-toolbar">
                            <ButtonGroup className="mx-2">
                                {this.props.updateClick ? 
                                <Button variant="outline-secondary">
                                    <span className="text-center" >
                                        <i className="fas fa-sync-alt" title={t('refresh')} onClick={() => this.props.updateClick() }/>
                                    </span> 
                                </Button> 
                                : null}
                            </ButtonGroup>
                            <ButtonGroup className="mx-2">
                                <Button variant="outline-secondary">
                                    <span className="text-center" onClick={() => {
                                        this.setState({printing:true})
                                        setTimeout(() => {

                                            const options = {title: t(this.props.id.replace('-', ' ')), priceBookId: this.props.extras.priceBookId}
                                            const pdfURL = URL.createObjectURL(printProductsList(`${this.props.id}`, this.state.filteredItems, options))
                                            const w:any = window.open()
                                            if(!w) {
                                                this.setState({printing:false})
                                                return
                                            }
                                            w.location.href = pdfURL
                                            setTimeout(() => {
                                                w.print()
                                                this.setState({printing:false})
                                            }, 500)
                                        }, 100)     
                                    }}>
                                        {this.state.printing ? <FontAwesomeIcon icon={faSpinner} spin /> : <i className="fas fa-file-pdf" title={t('print pdf')} />}
                                    </span>
                                </Button>
                                <Button variant="outline-secondary">
                                    <span className="text-center" onClick={() => {
                                        this.exportFile()
                                    }}>
                                        <i className="fas fa-file-excel" title={t('export to xslx')}></i>
                                    </span>
                                </Button>
                                <DropdownButton
                                    as={ButtonGroup}
                                    id="dropdown-reports"
                                    variant="outline-secondary"
                                    title={
                                        <span className="text-center">
                                            <i className="fas fa-file-alt" title={t('quick reports')}></i>
                                        </span>
                                        }
                                >
                                    {this.props.reportsMenu ? this.props.reportsMenu() : null}
                                </DropdownButton>
                            </ButtonGroup>
                            <ButtonGroup className="mx-2">
                                <DropdownButton
                                    as={ButtonGroup}
                                    id="dropdown-configs"
                                    variant="outline-secondary"
                                    title={
                                        <span className="text-center">
                                            <i className="fas fa-cog" title={t('options')}></i>
                                        </span>
                                        }
                                >
                                    {this.dropdownMenu()}
                                </DropdownButton>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Table id={this.props.id} className="bzr-table" responsive={true} bordered>
                            <thead className={`${this.props.refreshing ? 'd-none' : ''}`}>
                                {this.renderHeader()}
                            </thead>
                            <tbody>
                                {this.state.trs}
                            </tbody>
                            {/* <tfoot className="bg-dark">
                                {this.renderFooter()}
                            </tfoot> */}
                        </Table>
                    </Col>
                </Row>
                <ParentModal 
                    show={!!this.state.imageProductId}
                    title={t('update image')}
                    children={<AddImage 
                            orgId={this.props.extras.orgId} 
                            activeBUId={this.props.extras.activeBU.id} 
                            productId={this.state.imageProductId}
                            liftUpImage={(image:any) => {
                                const filteredItems:any = this.state.filteredItems,
                                    idx:number = filteredItems.findIndex((i:any) => i.id === this.state.imageProductId)

                                filteredItems[idx]['bzrProductImage'] = image.imageKey
                                filteredItems[idx]['imageURL'] = image.imageURL
                                this.renderBody(filteredItems)
                                this.props.liftUpImage(image)
                                this.setState({filteredItems, imageProductId: null})
                            }}
                            cancel={() => this.setState({imageProductId: null})}
                            />} 
					onHide={() => {
						this.setState({
							imageProductId: null
						})
					}}
				/>
            </Container>
        )
    }
}

export default BzrTable