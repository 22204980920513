import React from 'react'

//  Org, ActiveBU and User
export const AppStateContext = React.createContext( { bzrUser: {} } )

//  Policies
export const PolicyContext = React.createContext(null)

//  Languages
export const langs = {
    es: 'es',
    en: 'en'
}
export const LangContext = React.createContext({
    lang: langs.en,
    setLang: (l:string) => {}
})