import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap'
import { History, LocationState } from "history"
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import FormInputs from '../../layouts/Forms/FormInputs'
import BzrAxios from '../../utils/BzrAxios'
import t, { translate } from '../../utils/I18n/I18n'
import { MsgBubble } from '../../layouts/Modals/Modals'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>
	appStates: any
	liftUpAppStates:any
}

interface States {
	alertMsg: any
	switch: boolean
	bubble: any
}

class BUPreferences extends React.Component<Props,States> {
	
	constructor(props: Props) {
		super(props);

        this.state = {
			alertMsg: null,
			switch: false,
			bubble: null
		}
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	componentDidMount() {
		const view:any = document.getElementById('api-preferences-form')
		translate(view)
	}

	async handleSubmit(data:any, formikBag:any) {
		try {
			await BzrAxios.orgs({url: `/BusinessUnits/ChangeRate`, method: 'PUT', data})

		} catch(err) {
			this.setState({alertMsg: {variant: 'danger', message: err.message, heading: t('error on apply')}})
			console.log(err)
		} finally {
			formikBag.resetForm()
			this.setState({bubble: {variant: 'success', info: 'changes successfully applied'}})
			document.getElementById('mfa-switch-id')?.click()
		}
	}

	correlativesForm () {
		return (
			<Formik
				validationSchema={
					Yup.object().shape({
						changeRate: Yup.mixed().required()
					})
				}
				onSubmit={this.handleSubmit}
				initialValues={{ changeRate: '' }}
				validateOnChange={false}
				enableReinitialize
			>
				{({
					handleSubmit, handleChange, handleBlur, values, touched, errors, isSubmitting
				}) =>
				(
				<Form noValidate onSubmit={handleSubmit}>
					<fieldset disabled={isSubmitting || !this.state.switch}>
					<FormInputs
						ncols={['12']}
						className={['px-2']}
						properties={[
							{
								label: "change rate",
								horizontalLabel: "7",
								feedback: " ",
								fieldType: "text",
								name: "changeRate",
								value: values.changeRate ?? '',
								onChange: handleChange,
								onBlur: handleBlur,
								isValid: touched.changeRate && !errors.changeRate,
								isInvalid: !!errors.changeRate,
								error: errors.changeRate
							}
						]}
					/>
					<br />
					<Form.Group as={Row}>
						{ 	isSubmitting ? 
							(<div className="w-100 text-center">Submitting <FontAwesomeIcon icon={faSpinner} spin /></div>) : 
							<Button variant="primary" type="submit" className="mx-auto text-capitalize"> {t('apply changes')} </Button>
						}
					</Form.Group>
					</fieldset>
				</Form>
				)}
			</Formik>
		)
	}

	render() {
		return (
			<Container id="api-preferences-form" className="mx-auto mb-5 px-5 pb-5">
				<Row>
					<Col md={12}>
					{this.state.alertMsg ? 
						(<Alert variant={this.state.alertMsg.variant} onClose={() => this.setState({alertMsg: null})} dismissible>
							<Alert.Heading>{this.state.alertMsg.heading}</Alert.Heading>
							{this.state.alertMsg.message}
						</Alert>) : null
					} 
					</Col>
					<Col md={8} className="mx-auto">
						<br />
						<div className="mx-4 p-2 border rounded">
							<h5>Price Ids</h5>
							{ this.props.appStates.bookNames && Object.entries(this.props.appStates.bookNames.price).map((ent, key) => (
								<p key={key}>{`${ent[0]}: ${ent[1]}`}</p>
							)) }	
							<h5>Inventory Ids</h5>
							{ this.props.appStates.bookNames && Object.entries(this.props.appStates.bookNames.inventory).map((ent, key) => (
								<p key={key}>{`${ent[0]}: ${ent[1]}`}</p>
							)) }
							<h5>api preferences</h5>
							<FormInputs
								ncols={['3']}
								className={['ml-auto']}
								properties={[
									{
										label: 'enable',
										horizontalLabel: "5",
										fieldType: 'switch',
										name: 'mfa',
										value: this.state.switch,
										onChange: () => this.setState({switch: !this.state.switch})
									}
								]}
							/>
							<br />
							{this.correlativesForm()}
						</div>
					</Col>
				</Row>
				<MsgBubble msg={this.state.bubble} onClose={() => this.setState({bubble: null})}/>
			</Container>
		)
	}
}

export default withRouter(BUPreferences);