import React from 'react'

class Footer extends React.Component {
    date = new Date()
    render(){
        return(
            <footer className="bg-dark text-white" style={{minHeight: "100px"}}>
                <div className="copyright">
                    <div className="container">
                    <div className="row">
                            <div className="col-lg-12" style={{height: "80px"}}>
                                
                            </div> 
                        </div> 
                        <div className="row">
                            <div className="col-lg-12">
                            <p className="p-small text-center">
                                Copyright 2021© <b>Bizor</b> Iris - 
                                <span style={{fontSize: "0.75rem"}}>
                                V1R2.0005.3
                                </span></p>
                            </div> 
                        </div> 
                    </div> 
                </div> 
            </footer>
        )
    }
}
export default Footer