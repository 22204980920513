import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history"
import { Form, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'

import BzrAxios from '../../utils/BzrAxios'

//import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	liftUpAppStates: any,
	disableFields?: boolean,
	disableFieldsHandler?: any,
	params: any,
	idn: any
}

interface State {
	formError: any
}

class ProfileForm extends React.Component<Props,State> {

	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null
		}		
	}
	componentDidMount() {
		setTimeout(() => {
			const firstInput:any = document.getElementsByName('firstName')
			firstInput[0]?.focus()
		}, 1500)
	}

	handleSubmit(data: any) {
		this.props.liftUpAppStates({submitting: true})
		this.props.disableFieldsHandler(true)
		if(this.props.appStates.user.userStatus < 1100) data['userStatus'] = 1100 // Enrollment case
		
		const url = `/${this.props.appStates.user.id}`, method = 'PUT'
		BzrAxios.users({ url, method, data })
		.then((response: any) => {
			// 1. UPDATE 'bzrUser' lifting the state up
			this.props.liftUpAppStates({user: response.data.user, submitting: false})
			// 2. REDIRECT window.location.replace('/dashboard/address')
			this.props.disableFieldsHandler(false)
		}).catch((error: any) => {
			// User already exists in Bizor Realm
			// Notify -> Post Request /BzrDoctor 
			console.error(error)
			// Retry Update user state
			//window.location.replace('/dashboard/profile')
			this.props.liftUpAppStates({submitting: false})
		})
	}

	render() {
		let u = this.props.appStates.user
		return (
			<Formik
				validationSchema={Yup.object({
					firstName: Yup.string().required(),
					middleName: Yup.string(),
					lastName: Yup.string().required(),
					secondLastName: Yup.string(),
					birthDate: Yup.string().required()
				})}
				onSubmit={(values) => { this.handleSubmit(values)} }
				initialValues={{firstName: u.firstName ?? '', 
								middleName: u.middleName ?? '', 
								lastName: u.lastName ?? '', 
								secondLastName: u.secondLastName ?? '', 
								birthDate: u.birthDate ?? ''
							}}
				enableReinitialize={true}
			>
				{({
				handleSubmit,
				handleChange,
				setFieldValue,
				values,
				touched,
				isValid,
				errors,
				}) => (
				<Form noValidate onSubmit={handleSubmit} id={"profile-form-" + this.props.idn}>
					<fieldset disabled={this.props.disableFields}>
					<FormInputs
						ncols={["6", "6"]}
						className={["", ""]}
						properties={[
							{
								label: "First Name",
								feedback: "",
								fieldType: "text",
								name: "firstName",
								value: values.firstName,
								onChange: handleChange,
								isValid: touched.firstName && !errors.firstName,
								isInvalid: !!errors.firstName,
								error: errors.firstName
							},
							{
								label: "Middle Name",
								feedback: "",
								fieldType: "text",
								name: "middleName",
								value: values.middleName,
								onChange: handleChange,
								isValid: touched.middleName && !errors.middleName
							}
						]}
                    />
					<FormInputs
						ncols={["6", "6"]}
						className={["", ""]}
						properties={[
							{
								label: "Last Name",
								feedback: "",
								fieldType: "text",
								name: "lastName",
								value: values.lastName,
								onChange: handleChange,
								isValid: touched.lastName && !errors.lastName
							},
							{
								label: "Second Last Name",
								feedback: "",
								fieldType: "text",
								name: "secondLastName",
								value: values.secondLastName,
								onChange: handleChange,
								isValid: touched.secondLastName && !errors.secondLastName
							}
						]}
                    />
					<FormInputs
						ncols={["6", "6"]}
						className={["", ""]}
						properties={[
							{
								label: "Birthdate",
								fieldType: "date",
								name: "birthDate",
								value:	values.birthDate,
								onChange: setFieldValue,
								isValid: touched.birthDate && !errors.birthDate
							},
							{
								label: "Select ",
								feedback: "",
								fieldType: "select",
								name: "gender",
								options: [["gender","Gender"], ["female", "Female"], ["male", "Male"], ["nd", "ND"]],
								onChange: handleChange
							}
						]}
                    />
					<Row>
						<Col md="5" className="mx-auto mt-4 text-center">
							<Button 
								type="submit" 
								id={this.props.params.id + "-form-submit-" + this.props.idn}
								className="single-submit"
								style={ this.props.singleSubmit || this.props.disableFields ? {display: "none"}: {display: "inline"} } >
									Submit
							</Button>							
							{
								this.props.appStates.submitting ? <div><h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
							}
						</Col>
					</Row>
					</fieldset>
				</Form>
				)}
			</Formik>
		)
	}
}

export default withRouter(ProfileForm);