import React from 'react'
import ReactDOM from 'react-dom'
import scriptLoader from './ScriptLoader'

let PayPalButton:any = null

interface Props {
    isScriptLoaded:boolean
    isScriptLoadSucceed:boolean
}
interface State {
    showButtons:boolean
    loading:boolean
    paid:boolean
}

class PaypalButton extends React.Component<Props,State> {

    window:any  

    constructor(props:Props) {
        super(props)

        this.state = {
        showButtons: false,
        loading: true,
        paid: false
        }
        this.window = window
        this.window.React = React
        this.window.ReactDOM = ReactDOM
    }

    componentDidMount() {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props

        if (isScriptLoaded && isScriptLoadSucceed) {
        PayPalButton = this.window.paypal.Buttons.driver("react", { React, ReactDOM });
        this.setState({ loading: false, showButtons: true });
        }
    }

    componentWillReceiveProps(nextProps:any) {
        const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

        const scriptJustLoaded =
        !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

        if (scriptJustLoaded) {
        if (isScriptLoadSucceed) {
            PayPalButton = this.window.paypal.Buttons.driver("react", {
            React,
            ReactDOM
            })
            this.setState({ loading: false, showButtons: true });
        }
        }
    }

    createSubscription =  (data:any, actions:any) => {
        console.log(data)
        console.log(actions)
        return actions.subscription.create({
            plan_id: 'P-63E42367N4344663SL5AV7LY'
        })
    }
    onApprove = (data:any, actions:any) => {
        console.log(data)
        actions.order.capture().then((details:any) => {
            console.log(details)
            
            this.setState({ showButtons: false, paid: true })
        })
    }

    render() {
        const { showButtons } = this.state;
        return (
            <div>
                {showButtons && 
                <PayPalButton 
                    createSubscription={(data:any, actions:any) => this.createSubscription(data, actions)}
                    onApprove={(data:any, actions:any) => this.onApprove(data, actions)}
                />}
            </div>
        )
    }
}


 export default scriptLoader('https://www.paypal.com/sdk/js?client-id=AUKfhubhyWtxIxrMl0cagoQipZE2i2hZ3bz433GsBG6bearTLDEjL2k7e6xFrjIsnZr2ZQmUNtlRiXbZ&vault=true')(PaypalButton);