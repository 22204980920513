import React from 'react'
import BzrAxios from '../../utils/BzrAxios'
import { Container, Row, Col } from 'react-bootstrap'
import ImageBox from '../Common/ImageBox'
import { PreviewModal } from '../../layouts/Modals/Modals'
import Printer from '../Printer/ProductToPDF'
import t from '../../utils/I18n/I18n'

interface Props {
    id?:string,
    item:any,
    appStates:any
}
interface State {
    fm:any
    showPreviewModal:boolean
    pdfURL:any
    fields:any
}

class PreviewProduct extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            fm:null,
            showPreviewModal:false,
            pdfURL:null,
            fields:null
        }
    }
    componentDidMount() {
        BzrAxios.records({url: '/fields/2+6+7'}).then((response:any) => this.setState({fields: response.data}))
    }

    renderFeatures(item:any) {
        if(!item || !this.state.fields) return null
        let trs:any[] = [], trSchema:any[] = [], schema:any = null

        this.state.fields['2'].forEach((f:any, k:number) => {
            if( !f.fieldName.match(/name|description|sku|bzrProductImage|fixedSchemaId|isActive/) && item[f.fieldName]) {
                trs.push(<tr key={k} ><td>{t(f.fieldLabel) ?? f.fieldName}:</td><td>{item[f.fieldName]}</td></tr>)
            } else if(f.fieldType === 'schema' && item[f.fieldName]) schema = item[f.fieldName]
        })

        if(schema && schema !== '0') {
            this.state.fields[schema].forEach((f:any,k:number) => {
                if(item[f.fieldName]) {
                    trSchema.push(<tr key={k} ><td>{t(f.fieldLabel) ?? f.fieldName}:</td><td>{item[f.fieldName]}</td></tr>)
                }
            })
        }
        return(
            <Row>
                <Col md='auto'>
                    <h5>{t('general info')}</h5>
                    <table className="table table-borderless small preview-table">
                        <tbody>
                            {trs}
                        </tbody>
                    </table>
                </Col>
                {schema === '0' ? null : 
                    <Col md={6}>
                    <h5>{t('features of')} {schema === '6' ? t('product') : t('service')}</h5>
                    <table className="table table-borderless small preview-table">
                        <tbody>
                            {trSchema}
                        </tbody>
                    </table>
                </Col>
                }
            </Row>
        )
    }

    trimDescription(desc:string) {
        if(desc.length > 70) return desc.slice(0,70) + '...'
        return desc
    }

    boolStr(b: string) {
        return b === 'true' ? 'yes' : 'no';
    }

    render() {
        let preview:any = this.props.item
        return(
            <Container className="bg-white pt-1" style={{minHeight: '225px'}}>
                { preview ? 
                <Row>
                    <Col>
                        <h5><b>{t(`preview item`)}</b></h5>
                        <table className="table table-borderless small preview-table">
                            <tbody>
                                <tr><td><b>{t('name')}:</b></td></tr><tr><td>{preview.name}</td></tr>
                                <tr><td><b>{t('sku')}/{t('code')}:</b></td></tr><tr><td>{preview.sku}</td></tr>
                                <tr><td><b>{t('description')}:</b></td></tr><tr><td>{preview.description}</td></tr>
                            </tbody>
                        </table> 
                    </Col>
                    <Col md="auto" style={{minWidth: '160px'}}>
                        <ImageBox id={'image-preview-' + this.props.id ?? '1'} url={preview.imageURL} size={150} /> 
                        <div className="text-right font-icon-detail">
                            <span className="pe-7s-cloud-download text-center" onClick={() => {
                                console.log(preview)
                                const titles = {title: 'product card'}
                                const pdfBLOB = Printer.productToPDF(this.props.appStates, preview, titles)
                                this.setState({showPreviewModal: true, pdfURL: URL.createObjectURL(pdfBLOB)})
                                }} />
                            <span className="pe-7s-print text-center" onClick={()=> {
                                const titles = {title: 'product card'}
                                const pdfBLOB = Printer.productToPDF(this.props.appStates, preview, titles)
                                const w:any = window.open()
                                w.location.href = URL.createObjectURL(pdfBLOB)
                                setTimeout(() => w.print(), 1000)                                   
                            }} />
                            <span className="pe-7s-more text-center"></span>
                        </div> 
                    </Col> 
                    <Col md={12}>
                        {this.renderFeatures(preview)}
                    </Col>
                </Row> : <div className="py-5 text-center mx-auto border"><h3 style={{color: 'dimgray'}}>{t('Select an item!')}</h3></div> }
                {this.props.appStates.activeBU ? <PreviewModal 
                                    show={this.state.showPreviewModal} 
                                    url={this.state.pdfURL} 
                                    title="product card"
                                    onHide={() => {
                                        this.setState({showPreviewModal: false, pdfURL: null})
                                    }
                                } /> : null}
            </Container>
        )
    }
}

export default PreviewProduct