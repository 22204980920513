import jsPDF from 'jspdf'
import { fn, header, subHeader } from './Functions'
import t, { locales } from '../../../utils/I18n/I18n'


class SaleOrder {
    static print(order:any, transacs:any, client:any, options?:any) {
        const doc:any = new jsPDF('p', 'mm', 'letter')
        let transacsCount = 0, pageCount = 1, offset:any = null

        if(options && options.letterhead) { 
            offset = header(doc, order, options)
        } 

        offset = subHeader(doc, client, order, pageCount, offset, options)
        
        offset = this.listTransacs(doc, transacs, transacsCount, offset, options)
        transacsCount += offset.count
        pageCount++
    
        this.totalize(doc, order, offset.height, options)
        return doc.output('blob')
    }

    static listTransacs(doc:any, transacs:any, count:number, offset:number, options:any):any {
        const billedIn:any = options.billedIn
        const headers = {
            n: '#', 
            code: t('code'), 
            description: t('description'), 
            quantity: t('quantity'), 
            price: t('price'), 
            vat: locales[billedIn.code].taxName,
            alicuote: t('alicuote'),
            amount: t('amount'), 
            unit: t('unit')
        }

        let transacsOffset = offset, fontSize = 9, lineHeightFactor = 1.15, maxtransacsHeight = 190
        doc.setDrawColor(100)
        doc.setLineWidth(0.2)
        doc.rect(9, transacsOffset, 197, 8)
        transacsOffset += 5
        // List transacs Header
        doc.setTextColor(50)
        doc.setFontStyle("bold")
        doc.setFontSize(fontSize - 1)
        doc.text(headers.code.toUpperCase(), 12, transacsOffset)                                   // CODE/SKU
        doc.text(headers.description.toUpperCase(), 38, transacsOffset)                            // DESCRIPTION
        doc.text(headers.unit.toUpperCase(), 95, transacsOffset, {align: 'center'})                // UNIT OF MEASURE
        doc.text(headers.vat.toUpperCase(), 108, transacsOffset, {align: 'center'})                // VAT
        doc.text(headers.quantity.toUpperCase(), 123, transacsOffset, {align: 'center'})           // QTY
        doc.text(`${headers.price.toUpperCase()} (${billedIn.currencySymbol})`, 157, transacsOffset, {align: 'right'})      // PRICE
        doc.text(`${headers.alicuote.toUpperCase()} (%)`, 170, transacsOffset, {align: 'center'})      // ALICUOTE
        doc.text(`${headers.amount.toUpperCase()} (${billedIn.currencySymbol})`, 205, transacsOffset, {align: 'right'})      // AMOUNT
        transacsOffset += 10
        // transac X
        doc.setFontStyle("normal")
        for(let i = count; i < transacs.length; i++) {

            const sku:string[] = doc.splitTextToSize(transacs[i].sku !== '' ? transacs[i].tsku.toUpperCase() : 'N/A', 25),
            desc:string[] = doc.splitTextToSize(transacs[i].tdescription, 60),
            lineCount:number = desc.length > sku.length ? desc.length : sku.length,
            textHeight:number = lineCount * fontSize * lineHeightFactor * 34/100

            if(transacsOffset + textHeight > maxtransacsHeight) {
                // Horizontal Ruler
                doc.setDrawColor(100)
                doc.setLineWidth(0.2)
                doc.line(10, transacsOffset, 205, transacsOffset)
                return {count: i, height: transacsOffset}
            }
            doc.setFontSize(fontSize - 1)
            doc.text(sku, 10, transacsOffset)
            doc.text(desc, 38, transacsOffset)                                                                     // DESCRIPTION
            doc.text(transacs[i].retailed === 'true' ? 'U' : `${transacs[i].unitOfMeasure.toUpperCase()}`, 95, transacsOffset, {align: 'center'})             // UNIT OF MEASURE
            // TAX/EXEMPT 
            doc.setFontSize(fontSize)           // VAT
            doc.text(transacs[i].isTaxExempt === 'true' ? '(E)' : fn(transacs[i].taxRate1 * 100, billedIn.code) + '%', 108, transacsOffset, {align: 'center'})
            ///////
            doc.text(`${fn(transacs[i].quantity, billedIn.code)}`, 123, transacsOffset, {align: 'center'})             // QTY
            ////
            let price = parseFloat(transacs[i].entryValue) * parseFloat(transacs[i].changeRate)
            if(transacs[i].retailed === 'true') {
                price = price/parseFloat(transacs[i].unitsPerPkg)
            }

            doc.text(fn(price, billedIn.code), 155, transacsOffset, {align: 'right'})        // PRICE
            doc.text(fn(transacs[i].alicuote * 100, billedIn.code) + '%', 170, transacsOffset, {align: 'center'})        // ALICUOTE
            doc.text(fn(transacs[i].amount, billedIn.code), 203, transacsOffset, {align: 'right'})   // AMOUNT
            transacsOffset += textHeight + 2
        }
        // Horizontal Ruler
        doc.setDrawColor(100)
        doc.setLineWidth(0.2)
        doc.line(10, transacsOffset, 205, transacsOffset)
        return {count: transacs.length, height: transacsOffset}
    }

    static totalize(doc:any, order:any, offset:number, options:any) {
        const billedIn:any = options.billedIn
        const totalFields = {
            subTotal: t('subtotal'),
            discounts: t('discounts'),
            taxable: t('tax'),
            exempt: t('exempt'),
            credits: t('credits'),
            total: t('total'),
            remarks: t('remarks'),
            receivedBy: t('received by'),
            signature: t('signature'),
            vat: locales[billedIn.code].taxName,
            base: t('base')
        }

        offset = 200 // Fixed Offset
        // Horizontal Ruler
        doc.setDrawColor(100)
        doc.setLineWidth(0.2)
        doc.line(10, offset, 205, offset)
        // SUBTOTALS
        offset += 7
        const upperOffset = offset
        doc.setTextColor(50)
        doc.setFontSize(10)
        doc.setFontStyle("bold")
        doc.text(`${totalFields.subTotal.toUpperCase()} ${billedIn.currencySymbol}`, 90, offset)
        doc.text(fn(order.subTotal, billedIn.code), 200, offset, {align: 'right'})

        // DISCOUNT
        if(order.discounts && parseFloat(order.discounts) !== 0) {
            offset += 10
            doc.setFontSize(9)
            doc.setFontStyle("normal")
            doc.text(totalFields.discounts.toUpperCase(), 90, offset)
            doc.text(fn(order.discounts, billedIn.code), 200, offset, {align: 'right'})
        }

        // TAX
        offset += 10
        doc.setFontSize(9)
        doc.setFontStyle("normal")
        doc.text(`${totalFields.taxable.toUpperCase()} ${billedIn.currencySymbol} (${totalFields.base.toUpperCase()} ${fn(order.taxable, billedIn.code)}) ${totalFields.vat.toUpperCase()} ${fn(order.taxRate1 * 100, billedIn.code) + '%'}`, 90, offset)
        doc.text(fn(order.tax1, billedIn.code), 200, offset, {align: 'right'})
        
        // EXEMPT
        if(order.exempt) {
            offset += 5
            doc.text(`${totalFields.exempt.toUpperCase()} ${billedIn.currencySymbol} (${totalFields.base.toUpperCase()} ${fn(order.exempt, billedIn.code)}) 0%`, 90, offset)
            doc.text(fn(0, billedIn.code), 200, offset, {align: 'right'})
        }

        // CREDITS
        if(order.credits && parseFloat(order.credits) !== 0) {
            offset += 10
            doc.setFontSize(9)
            doc.setFontStyle("bold")
            doc.text(totalFields.credits.toUpperCase(), 90, offset)
            doc.text('(' + fn(order.credits, billedIn.code) + ')', 200, offset, {align: 'right'})
        }

        // Horizontal Ruler
        offset += 3
        doc.setDrawColor(100)
        doc.setLineWidth(0.3)
        doc.line(90, offset, 205, offset)

        // TOTAL
        offset += 5
        doc.setFontSize(11)
        doc.setFontStyle("bold")
        doc.setDrawColor(0)
        doc.text(`${totalFields.total.toUpperCase()} ${billedIn.currencySymbol}`, 90, offset)
        doc.text(fn(order.total, billedIn.code), 200, offset, {align: 'right'})

        // REMARKS
        doc.setFontSize(9)
        doc.setFontStyle("bold")
        offset += 10
        if(order.remarks) {
            doc.setLineWidth(0.2)
            doc.setDrawColor(100)
            doc.rect(20, offset - 3, 135, 20)
            doc.setDrawColor(0)
            doc.text(totalFields.remarks.toUpperCase(), 22, offset )
            doc.setFontSize(9)
            doc.setFontStyle("normal")
            const remarks:string[] = doc.splitTextToSize(order.remarks, 130)
            doc.text(remarks, 22, offset + 4) 
        }
        doc.setFontSize(11)
        doc.setFontStyle("bold")
        // RECEIVED BY
        doc.text(totalFields.receivedBy.toUpperCase(), 20, upperOffset)
        // SIGNATURE
        doc.text(totalFields.signature.toUpperCase(), 20, upperOffset + 20)
    }
}

export default SaleOrder