import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/index.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorker from './utils/serviceWorker'
import 'bootstrap/dist/css/bootstrap.min.css'
import "./assets/css/light-bootstrap-dashboard-react.css"
import "./assets/css/demo.css"
import "./assets/css/pe-icon-7-stroke.css"
import "./assets/css/themify-icons.css"
import "./assets/css/main.css"

ReactDOM.render(
    <Router>
        <App name="bizor" />
    </Router>
    , document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
