import React, { Component } from "react"
import { Navbar } from "react-bootstrap"

import AdminNavbarLinks from "./AdminNavbarLinks"
import t from "../../utils/I18n/I18n"

interface Props {
  routeName: string,
  appStates: any
}

interface States {
  sidebarExists: boolean
}

class Header extends Component<Props, States> {
    constructor(props: any) {
        super(props)
        this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this)
        this.state = {
            sidebarExists: false
        };
    }
    mobileSidebarToggle(e:any) {
        if (this.state.sidebarExists === false) {
            this.setState({
                sidebarExists: true
            })
        } 
        e.preventDefault()
        document.getElementById('sidebar')?.classList.remove("hide")
        document.getElementById('main-panel')?.classList.remove("hide")


        document.documentElement.classList.toggle("nav-open")
        var node = document.createElement("div")
        node.id = "bodyClick"
        node.onclick = function() {
            var child = document.getElementById("bodyClick")
            child?.remove()
            document.documentElement.classList.toggle("nav-open")
        }
        document.body.appendChild(node);
    }
    render() {
        return (
        <Navbar className="navbar-default" collapseOnSelect expand="lg">
            <Navbar.Brand className="text-capitalize">
                {t(this.props.routeName)}
            </Navbar.Brand>
            <Navbar.Toggle onClick={this.mobileSidebarToggle} aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <AdminNavbarLinks appStates={this.props.appStates} />
            </Navbar.Collapse>
        </Navbar>
        );
    }
}

export default Header;
