import React, { useState } from 'react'
import { Row, Col, Badge, Toast } from 'react-bootstrap'

const Example = () => {
    const [showA, setShowA] = useState(true);
  
    const toggleShowA = () => setShowA(!showA);
  
    return (
        <Row>
            <Col xs={12}>
            <Toast show={showA} onClose={toggleShowA}>
                <Toast.Header>
                <Badge variant="danger">1</Badge>
                <strong className="mr-auto ml-1">First Step</strong>
                <small>1 min ago</small>
                </Toast.Header>
                <Toast.Body>Register your company info</Toast.Body>
            </Toast>
            </Col>
        </Row>
    )
}
  
export default Example