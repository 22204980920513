export const isDefined = (val:any) => val != null
export const isFunction = (val:any) => typeof val === 'function'
export const noop:any = () => { }

export const newScript = (src:any) => (cb:any) => {
  const scriptElem:any = document.createElement('script')
  if (typeof src === 'object') {
    // copy every property to the element
    for (var key in src) {
      if (Object.prototype.hasOwnProperty.call(src, key)) {
        scriptElem[key] = src[key];
      }
    }
    src = src.src;
  } else {
    scriptElem.src = src
  }
  scriptElem.addEventListener('load', () => cb(null, src))
  scriptElem.addEventListener('error', () => cb(true, src))
  document.body.appendChild(scriptElem)
  return scriptElem
}

const keyIterator = (cols:any) => {
  const keys = Object.keys(cols)
  let i = -1
  return {
    next () {
      i++ // inc
      if (i >= keys.length) return null
      else return keys[i]
    }
  }
}

// tasks should be a collection of thunk
export const parallel = (...tasks:any) => (each:any) => (cb:any) => {
  let hasError = false
  let successed = 0
  const ret:any[] = []
  tasks = tasks.filter(isFunction)

  if (tasks.length <= 0) cb(null)
  else {
    tasks.forEach((task:any, i:number) => {
      const thunk = task
      thunk((err:any, ...args:any) => {
        if (err) hasError = true
        else {
          // collect result
          if (args.length <= 1) args = args[0]

          ret[i] = args
          successed ++
        }

        if (isFunction(each)) each.call(null, err, args, i)

        if (hasError) cb(true)
        else if (tasks.length === successed) {
          cb(null, ret)
        }
      })
    })
  }
}

// tasks should be a collection of thunk
export const series = (...tasks:any) => (each:any) => (cb:any) => {
  tasks = tasks.filter((val:any) => val != null)
  const nextKey = keyIterator(tasks)
  const nextThunk = () => {
    const key:any = nextKey.next()
    let thunk = tasks[key]
    if (Array.isArray(thunk)) thunk = parallel.apply(null, thunk).call(null, each)
    return [ +key, thunk ] // convert `key` to number
  }
  let key:any, thunk:any
  let next = nextThunk()
  key = next[0]
  thunk = next[1]
  if (thunk == null) return cb(null)

  const ret:any[] = []
  const iterator = () => {
    thunk((err:any, ...args:any) => {
      if (args.length <= 1) args = args[0]
      if (isFunction(each)) each.call(null, err, args, key)

      if (err) cb(err)
      else {
        // collect result
        ret.push(args)

        next = nextThunk()
        key = next[0]
        thunk = next[1]
        if (thunk == null) return cb(null, ret) // finished
        else iterator()
      }
    })
  }
  iterator()
}