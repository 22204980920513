import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'
import AdminNavbarLinks from '../Navbars/AdminNavbarLinks'
import logo from '../../assets/img/logo-white-d.png'
import t from '../../utils/I18n/I18n'
import { PolicyContext } from '../../utils/Contexts'

interface Props {
  location: any,
  routes: any,
  image: string,
  color: string,
  hasImage: boolean,
  appStates: any
}

interface States {
  width: number,
  hide: boolean
}

class Sidebar extends Component<Props,States> {
  constructor(props:any) {
    super(props)
    this.state = {
      width: window.innerWidth,
      hide: false
    }
  }
  activeRoute(routeName: string) {
    return this.props.location.pathname === routeName ? "active" : "";
  }
  updateDimensions() {
    const btn:any = document.getElementById('hide-button')
    if (window.innerWidth <= 991) btn.style.display = 'none'
    else btn.style.display = ''

    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this))
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  mobileSidebarToggle(e:any) {
      e.preventDefault()
      document.documentElement.classList.toggle("nav-open")
      var node = document.createElement("div")
      node.id = "bodyClick"
      node.onclick = function() {
          var child = document.getElementById("bodyClick")
          child?.remove()
          document.documentElement.classList.toggle("nav-open")
      }
      document.body.appendChild(node);
  }

  parsePath(path:string) {
    if(path.indexOf(':') > -1)
      return path.slice(0, path.indexOf(':'))
    else 
      return path
  }

  render() {
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")"
    };
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={this.props.color}
        data-image={this.props.image}
      >
        <div 
          id="hide-button" 
          className="text-center" 
            onClick={() => {
                //this.setState({hide: !this.state.hide})
                const hideBtn = document.getElementById('angle-double')
                document.getElementById('sidebar')?.classList.toggle("hide")
                document.getElementById('main-panel')?.classList.toggle("hide")
                const els:any = document.getElementsByClassName('bzr-lateral-box')
                Array.from(els).forEach((el:any) => el.classList.toggle("hide"))
                if(hideBtn?.classList.contains('fa-angle-double-left')) hideBtn?.classList.replace('fa-angle-double-left', 'fa-angle-double-right')
                else hideBtn?.classList.replace('fa-angle-double-right', 'fa-angle-double-left')
                }}>
            <span id='angle-double' className="fas fa-angle-double-left text-center"></span>
        </div>
          {this.props.hasImage ? (
            <div className="sidebar-background" style={sidebarBackground} />
          ) : (
            null
          )}
        <div className="logo">
          <a href="/home" className="simple-text logo-mini" >
            <div className="logo-img">
              <img src={logo} alt="logo_image" />
            </div>
          </a>
          <a
            href="/home"
            className="simple-text logo-normal"
          >
            Bizor<span>{t('by')} CastellSys</span>
          </a>
        </div>
        <PolicyContext.Consumer>
          {(userPolicies:any) => (
              <div className="sidebar-wrapper">
              <ul className="nav">
                {this.state.width <= 991 ? <AdminNavbarLinks appStates={this.props.appStates} /> : null}          
                {this.props.routes.map((prop:any, key:number) => {
                  if(!userPolicies) return null
                    const allowedRoutes:any = userPolicies[this.props.appStates.activeBU.id].routes
                    
                    if (prop.noSidebar) {
                      return null
                    }
                    if (prop.dropdown) {
                        prop = prop.dropdown
                        let navLinks:any = []
                        prop.routes.forEach((route:any, key: number) => {
                          if(allowedRoutes.find((r:any) => r === route.path) || allowedRoutes[0] === '*')
                            navLinks.push(
                              <NavLink key={key} to={this.parsePath(route.path)} exact={route.exact ?? true} className="nav-link dropdown-item">
                                <p style={{marginLeft: "5px"}}> {t(route.name)}</p>
                              </NavLink>
                            )}
                        )
                        if(navLinks.length > 0)
                          return (
                              <NavDropdown key={key} title={<div><i className={prop.icon} /><p>{t(prop.name)}</p></div>} id={`sidebar-dropdown-${key}`}>
                                  { navLinks }
                              </NavDropdown>
                          ) 
                        else return null
                    } else if(!prop.redirect) {
                        if(prop.path === '/admin/signout') return (
                          <li className={ this.activeRoute(prop.path) } key={key}>
                            <div><br /><hr /></div>
                            <NavLink to={prop.path} className="nav-link">
                            <i className={prop.icon} />
                            <p>{t(prop.name)}</p>
                            </NavLink>
                            {prop.extra}
                          </li>
                        )
                        else if(allowedRoutes[0] === '*') return(
                          <li className={ this.activeRoute(prop.path) } key={key}>
                              <NavLink to={prop.path} className="nav-link">
                              <i className={prop.icon} />
                              <p>{t(prop.name)}</p>
                              </NavLink>
                              {prop.extra}
                          </li>
                        )
                        else if(!allowedRoutes.find((r:any) => r === prop.path)) return null
                        else return (
                            <li className={ this.activeRoute(prop.path) } key={key}>
                                <NavLink to={prop.path} className="nav-link">
                                <i className={prop.icon} />
                                <p>{t(prop.name)}</p>
                                </NavLink>
                                {prop.extra}
                            </li>
                        )
                    }
                  return null
                })}
              </ul>
            </div>
          )}
        </PolicyContext.Consumer>
      </div>
    );
  }
}

export default Sidebar;
