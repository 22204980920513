import React from 'react'
import { InputGroup, FormControl, Dropdown} from 'react-bootstrap'
import { isEmptyArray } from 'formik'
import t from '../../utils/I18n/I18n'

interface Props {
    id: any,
    pkey: string,
    value: string,
    items: any[],
    selected: any,
    liftUpOption: any,
    liftUpValue?: any,
    label?:string,
    filters?: string[],
    whenEmpty?: string,
    placeholder?: string,
    nextFocus?: string,
    disabled?: boolean,
    autoComplete?: string,
    style?: any,
    className?: string
}

interface States {
    dropItems: any[],
    show: boolean
    prevKeyEvent: any
}

class CBList extends React.Component<Props, States> {
    constructor(props: Props){
        super(props)
        this.state = {
            dropItems: [],
            show: false,
            prevKeyEvent: null
        }
        this.handleClick = this.handleClick.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.searchItem = this.searchItem.bind(this)
        this.handleOnKeyUp = this.handleOnKeyUp.bind(this)
    }

    renderFilters(item:any) {
        let filters:any = this.props.filters?.map((filter) => item[filter]).join(' / ')
        if(filters) return ' / ' + filters
        return ''
    }

    handleClick() {
        if(this.props.items && !this.state.show) {
            this.setState({
                dropItems: this.props.items.map((item:any, i:number) => {
                    const line:string = item[this.props.pkey] + this.renderFilters(item)
                    return(
                        <Dropdown.Item 
                            onSelect={this.handleSelect} 
                            key={i} 
                            eventKey={item.id}
                            className={'cblist-dd-' + this.props.id}
                            >
                            {line.length > 50 ? `${line.slice(0,50)} ...` : line}
                        </Dropdown.Item>
                    )
                })
            })
        } else if(!this.props.items && !this.state.show) {
            this.setState({
                dropItems: [
                <Dropdown.Item key="1">{ this.props.whenEmpty }</Dropdown.Item>
                ]
            })
        }
        this.setState({show: !this.state.show})
    }

    handleSelect(eventKey:any, event:any) {
        this.setState({show: false})
        const selected:any = this.props.items.find((item:any) => item.id.toString() === eventKey)
        selected['cblistEmitter'] = this.props.id
        this.props.liftUpValue(selected[this.props.pkey])
        this.props.liftUpOption(selected)
        const input:any = document.getElementById(this.props.id)
        input.focus()
    }

    handleOnKeyUp(event:any) {
        const k = event.keyCode
        if(k === 40 && this.props.value.trim() === '' && !this.state.show) {
            this.handleClick()
        } else if(k === 40) {
            const items:any = document.getElementsByClassName('cblist-dd-' + this.props.id)
            if(items.length > 0) items[0].focus()
        } else if(k === 38 && this.state.show) {
            this.setState({show: false})
        } else if(k === 13 && this.state.prevKeyEvent === k && this.props.nextFocus) {
            if(!this.props.nextFocus) return
            const item:any = document.getElementById(this.props.nextFocus)
            item.select()
            this.setState({prevKeyEvent: null})
            return
        } else if(k === 39 && this.props.selected && this.props.nextFocus) {
            const item:any = document.getElementById(this.props.nextFocus)
            item.select()
        }
        this.setState({prevKeyEvent: k})
    }

    searchItem(event: any) {
        if(!this.props.items) return
        // Declare variables 
        const ops:any[] = []
        this.props.liftUpValue(event.target.value)
        const match = event.target.value.toUpperCase()
        match === '' ? this.setState({show: false}) : this.setState({show: true})

        this.props.items.forEach((item:any, key:any) => {

            let line:any = item[this.props.pkey] + this.renderFilters(item)
            if (line.toUpperCase().indexOf(match) > -1) {
                ops.push(
                    <Dropdown.Item 
                        onSelect={this.handleSelect} key={key} eventKey={item.id} className={'cblist-dd-' + this.props.id}>
                        {line.length > 50 ? `${line.slice(0,50)} ...` : line}
                    </Dropdown.Item>
                )
            } 
        })

        if(isEmptyArray(ops)) {
            this.setState({show: false})
            this.props.liftUpOption(null)
        } else {
            const selected:any = this.props.items.find((item:any) => item[this.props.pkey] === event.target.value)
            if(selected) this.setState({show: false})
            this.props.liftUpOption(selected)
        }
        this.setState({dropItems: ops})
    }

    render() {
        return (
            <div className="bzr-cblist">
                { this.props.label ? <div className="bzr-label">{this.props.label}</div> : null}
                <InputGroup>
                    <FormControl
                        id={this.props.id}
                        placeholder={t(this.props.placeholder)}
                        aria-label={t(this.props.placeholder)}
                        aria-describedby="cblist-addon"
                        onKeyUp={this.handleOnKeyUp}
                        onChange={this.searchItem}
                        value={this.props.value ?? ''}
                        style={Object.assign({width: "250px !important"}, this.props.style)}
                        className={this.props.className}
                        disabled={this.props.disabled ?? false}
                        autoComplete={this.props.autoComplete ?? 'off'}
                        />
                    <InputGroup.Append>
                    <Dropdown id={'dropdown-menu' + this.props.id} drop='down' show={this.state.show} >
                        <Dropdown.Toggle 
                            id={'dropdown-cblist-' + this.props.id} 
                            onClick={this.handleClick}
                            variant="success" 
                            disabled={this.props.disabled ?? false} 
                            >
                            <i className="fas fa-search" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu 
                            className="cblist-menu" 
                            flip={false} 
                            >
                            {this.state.dropItems}
                        </Dropdown.Menu>
                    </Dropdown>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        )
    }
}

export default CBList