import React from 'react'
import Table from 'react-bootstrap/Table'
import t, { fn, dn } from '../../../utils/I18n/I18n'

interface Props {
    transacs:any
    sales_hash:any[]
    range:any
    locale:any
    products_hash:any
    clients_hash:any
    urlParams:any
}
interface State {
    clients:any,
    facilities:any
}
class ClientTotals extends React.Component<Props,State> {
    constructor(props:Props) {
        super(props)
        this.state = {
            clients: [],
            facilities: []
        }
    }
    async componentDidMount() {
        let clients:any = {}
        this.props.transacs.forEach((t:any) => {
            const sale:any = this.props.sales_hash[t.bzrOrderId]
            const client:any = this.props.clients_hash[sale.clientId]
            const quantity:any = t.retailed === 'true' ? parseFloat(t.quantity) / parseFloat(t.unitsPerPkg) : parseFloat(t.quantity)
            const match:any = t.billStatus.match(/credit|debit|void/)
            if(!!match) t.billStatus = match[0]
            if(!clients[sale.clientId]) {
                clients[sale.clientId] = { client, tpp: {} }
                switch(t.billStatus) {
                    case 'printed_legal':
                    case 'printed_receipt':
                        if(!clients[sale.clientId].tpp[t.bzrProductId]) {
                            clients[sale.clientId].tpp[t.bzrProductId] = {quantity, amount: quantity * parseFloat(t.entryValue) }
                        } else {
                            clients[sale.clientId].tpp[t.bzrProductId].quantity += quantity 
                            clients[sale.clientId].tpp[t.bzrProductId].amount += quantity * parseFloat(t.entryValue)
                        }
                        break
                    case 'debit':
                        if(!clients[sale.clientId].tpp[t.bzrProductId]) {
                            clients[sale.clientId].tpp[t.bzrProductId] = {amount: quantity * t.entryValue * t.alicuote }
                        } else {
                            clients[sale.clientId].tpp[t.bzrProductId].amount += quantity * t.entryValue * t.alicuote
                        }
                        break
                    case 'credit':
                        if(!clients[sale.clientId].tpp[t.bzrProductId]) {
                            clients[sale.clientId].tpp[t.bzrProductId] = {amount: -(quantity * t.entryValue * t.alicuote) }
                        } else {
                            clients[sale.clientId].tpp[t.bzrProductId].amount -= quantity * t.entryValue * t.alicuote
                        }
                        break
                    default:
                        break
                }
            } else {
                switch(t.billStatus) {
                    case 'printed_legal':
                    case 'printed_receipt':
                        if(!clients[sale.clientId].tpp[t.bzrProductId]) {
                            clients[sale.clientId].tpp[t.bzrProductId] = {quantity, amount: quantity * parseFloat(t.entryValue) }
                        } else {
                            clients[sale.clientId].tpp[t.bzrProductId].quantity += quantity 
                            clients[sale.clientId].tpp[t.bzrProductId].amount += quantity * parseFloat(t.entryValue)
                        }
                        break
                    case 'debit':
                        if(!clients[sale.clientId].tpp[t.bzrProductId]) {
                            clients[sale.clientId].tpp[t.bzrProductId] = {amount: quantity * t.entryValue * t.alicuote }
                        } else {
                            clients[sale.clientId].tpp[t.bzrProductId].amount += quantity * t.entryValue * t.alicuote
                        }
                        break
                    case 'credit':
                        if(!clients[sale.clientId].tpp[t.bzrProductId]) {
                            clients[sale.clientId].tpp[t.bzrProductId] = {amount: -(quantity * t.entryValue * t.alicuote) }
                        } else {
                            clients[sale.clientId].tpp[t.bzrProductId].amount -= quantity * t.entryValue * t.alicuote
                        }
                        break
                    default:
                        break
                }
            }
        })
        this.setState({clients})
    }

    render() {
        // Totals Per Products
        const renderTpc:any = Object.values(this.state.clients).map((c:any, l:any) => {
            let tq = 0, ta = 0
            return(
                <div className="ml-3" key={l}>
                    <h5>{c.client.legalName} ({dn(this.props.range.from, this.props.locale)} - {dn(this.props.range.to, this.props.locale)})</h5>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>{t('product')}</th><th>{t('quantity')}</th><th>{t('amount')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(c.tpp).map((p:any, k:number) => {
                                tq += c.tpp[p].quantity
                                ta += c.tpp[p].amount
                                return(
                                    <tr key={k}>
                                    <td>{this.props.products_hash[p].name}</td>
                                    <td className="text-right pr-3">{fn(c.tpp[p].quantity)}</td>
                                    <td className="text-right pr-3">{fn(c.tpp[p].amount)}</td>
                                </tr>
                                )}
                            )}
                        </tbody>
                        <tfoot className="bg-light small">
                                <tr>
                                    <td className="pl-3 text-uppercase">{t('totals')}</td>
                                    <td className="text-right pr-3">{fn(tq)}</td>
                                    <td className="text-right pr-3">{fn(ta)}</td>
                                </tr>
                        </tfoot>
                    </Table>
                    <br />
                </div>
            )
        })

        return(
            <div className="my-5 mx-3 bzr-lateral-scrollable-75vh">
                <h2>{t('totals per client')}</h2>
                <br />
                <div className="">
                    {renderTpc}
                </div>
            </div>
        )
    }
}

export default ClientTotals