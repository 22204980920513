import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history"
import { Auth, Storage } from 'aws-amplify'
import { Form, Container,  Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'
import ImageInput from '../Common/ImageInput'
import { PolicyContext } from '../../utils/Contexts'
import BzrAxios from '../../utils/BzrAxios'

import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	liftUpAppStates: any,
	disableFields: boolean,
	disableFieldsHandler: any,
	params: any,
	idn: any
}

interface State {
	formError: any,
	options: any,
	createBU: boolean,
	bu: any,
	buValue: string,
	verifiying: boolean,
	submitting: boolean,
	image: any
}

class BusinessUnitForm extends React.Component<Props,State> {

	focused = false

	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null,
			options: <option value="0">Loading...</option>,
			createBU: props.appStates.activeBU ? false : true,
			bu: null,
			buValue: '0',
			verifiying: false,
			submitting: false,
			image: { imageStorage: null, imageURL: null }
		}
		this.handleSelect = this.handleSelect.bind(this) 
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleImageSelect = this.handleImageSelect.bind(this)
	}

	componentDidMount() {
		const activeBU:any = this.props.appStates.activeBU
		this.props.liftUpAppStates({bu: activeBU ?? null})
		if(this.props.appStates.org && this.props.appStates.user.userStatus > 2000 && !this.props.appStates.bus) {
			const buids = Object.keys(this.context)
			console.log(buids)
			BzrAxios.orgs({url: `/BusinessUnits?ids=${buids.join('+')}`})
			.then((response:any) => {
				this.props.liftUpAppStates({bus: response.data.companies})
				this.setOptions(response.data.companies, activeBU)
			})
			.catch((err:any) => console.error(err))
		} else if(this.props.appStates.org && this.props.appStates.bus) {
			this.setOptions(this.props.appStates.bus, activeBU)
		} else {
			this.setOptions([], activeBU)
		}
	}

	setOptions(values:any, activeBU:any) {
		let options:any[] = [<option key="0" value="0">Create New Business Unit</option>]
		options = options.concat(values.map((option:any, i:any) =>
			<option key={i + 1} value={option.id}>{i + 1} - {option.legalName}</option>
		))
		
		this.setState({
			options,
			bu: activeBU ?? null,
			buValue: activeBU ? activeBU.id : '0',
			image: { imageStorage: activeBU && activeBU.logoImage ? activeBU.logoImage : null }
		})	
	}

	handleImageSelect(image:any) { 
		this.setState({image})
	}

	async validateLegalName(value:string) {
		this.setState({verifiying: true})
		try {
			const response:any = await BzrAxios.orgs({url: `/BusinessUnits?verifyLegalName=${value.trim()}`})
			if(response.data.companies.length > 0 && response.data.companies[0].id !== this.props.appStates.bu.id) {
				this.setState({verifiying: false})
				return false
			}
		} catch(error) {
			this.setState({verifiying: false})
			console.log(error)
			return false
		}
		this.setState({verifiying: false})
		return true
	}

	handleSelect(event: any) {
		if(event.target.value === '0') {
			this.setState({ createBU: true, buValue: '0', bu: null, image: null})
			this.props.liftUpAppStates({bu: null})
		} else { 
			const bu = this.props.appStates.bus.find((c:any) => c.id === event.target.value)
			this.setState({ 
				createBU: false, 
				buValue: event.target.value, 
				bu, 
				image: bu && bu.logoImage ? { imageStorage:  bu.logoImage } : null 
			})
			this.props.liftUpAppStates({bu})
		}
	}

	async handleSubmit(data:any) {
		const userStatus = this.props.appStates.user.userStatus
		if(data.locale === '0') {
			alert(t('select country of operation'))
			if(this.props.disableFieldsHandler) this.props.disableFieldsHandler(false)
			//  Add Field Error Alert
			return
		}

		let method = 'POST', url = `/BusinessUnits`
		if(!this.state.createBU) {
			method = 'PUT'
			const id = this.props.appStates.bu ? this.props.appStates.bu.id : this.props.appStates.activeBU.id
			url = `/BusinessUnits/${id}`
		} else {
			data['userId'] = this.props.appStates.user.id
		}
		if(userStatus === 1700) {
			data['userStatus'] = 4000
		}
		
		///   FIX   ///
		if(this.state.image.imageFile) {
			const imageFile = this.state.image.imageFile
			const imageName = data['legalName'].replace(/\W+/g,'_') + '.' + imageFile.type.replace('image/','')
			const imageKey = [this.props.appStates.org.id,'bzrBULogo',imageName].join('/')
			
			try {
				await Storage.put(imageKey, imageFile, { contentType: imageFile.type })
				data['logoImage'] = imageKey
			} catch(err) {
				alert('Image NOT Saved')
				console.log(err)
				return
			}
			if(this.state.bu && this.state.bu.id === this.props.appStates.activeBU.id)
					this.props.liftUpAppStates({activeBULogo: this.state.image.imageURL})
		}
		////////////////////
		BzrAxios.orgs({ url, method, data })
		.then((response: any) => {
			if(this.props.disableFieldsHandler) this.props.disableFieldsHandler(true)
			this.props.liftUpAppStates({bu: response.data.bu})
			if(this.state.createBU) {
				const bus:any = this.props.appStates.bus ?? []
				bus.push(response.data.bu)
				this.setOptions(bus, this.props.appStates.activeBU)
			}
			if(userStatus === 1700) {
				Auth.currentAuthenticatedUser()
				.then((user:any) => {	
					Auth.updateUserAttributes(user, {'custom:status': '4000'})
					.then((v:string) => {
						this.props.liftUpAppStates({enrollMsg: null})
						window.location.replace('/admin/dashboard')
					})
					.catch((err:any) => console.error(err)) })
				.catch((err) => console.error(err))
			}
		}).catch((error: any) => {
			console.error(error)
		})
	}

	render() {
		const bu = this.state.bu ?? {}
		if(this.props.appStates.org && !this.focused) {
			this.focused = true
			setTimeout(() => {
				const firstInput:any = document.getElementsByName('legalName')
				firstInput[0].focus()
			}, 2000)
		}
		return (
			<Container>
				<Row>
					<Col md={12} className="ml-auto">
						<ImageInput 
							image={this.state.image}
							liftUpState={this.handleImageSelect} 
							boxW={300}
							boxH={120} 
							className="rounded-sm border ml-auto"
							scale={this.props.appStates.bu ? 1 : 0.8} 
							disabled={this.props.disableFields || this.props.appStates.org === undefined } />
					</Col>
				</Row>
				<div>
					<Form style={ this.state.options.length === 1 ? {display: "none"}: {display: "inline"} } >
						<Form.Group controlId="buSelect">
							<Form.Label className="bzr-label">Select an existing business unit</Form.Label>
							<Form.Control 
								as="select" 
								onChange={this.handleSelect}
								value={this.state.buValue} 
								disabled={this.props.disableFields || this.props.appStates.org === undefined }
								>
							{ 
								this.state.options
							}
							</Form.Control>
						</Form.Group>
						<hr />
					</Form>
				</div>
				<Formik
					validationSchema={
						Yup.object({
							legalName: Yup.string().required()//.test(t('is good'), t('name repited'), async (v:string) => await this.validateLegalName(v))
						})
					}
					onSubmit={this.handleSubmit}
					initialValues={{
						legalName: bu.legalName ?? '', 
						businessUnitType: bu.businessUnitType ?? 'retail',
						locale: bu.locale ?? 'es-VE'
					}}
					validateOnChange={false}
					enableReinitialize={true}
				>
					{({
					handleSubmit,
					handleChange,
					handleBlur,
					values,
					isSubmitting,
					touched,
					isValid,
					errors,
					}) => (
					<Form noValidate onSubmit={handleSubmit} id={"business-unit-form-"  + this.props.idn}>
						<fieldset disabled={this.props.disableFields || this.props.appStates.org === undefined || isSubmitting || this.state.verifiying}>
						{ this.state.verifiying ? <div className="float-right verifier"><h6>verifiying name... <FontAwesomeIcon icon={faSpinner} spin /></h6></div> : null }
						<FormInputs
							ncols={['10']}
							className={['px-2']}
							properties={[
								{
									label: t('legal name'),
									feedback: ' ',
									fieldType: 'text',
									name: 'legalName',
									value: values.legalName,
									onChange: handleChange,
									onBlur: handleBlur,
									isValid: touched.legalName && !errors.legalName,
									isInvalid: !!errors.legalName,
									error: errors.legalName,
									autoFocus: true
								}
							]}
						/>
						<FormInputs
							ncols={['8']}
							className={['']}
							properties={[
								{
									label: t('business type'),
									fieldType: 'select',
									name: 'businessUnitType',
									value: values.businessUnitType,
									options: [
										['retail','Retail'], 
										['service','Service'],
										['inventory', 'Inventory'], 
										['manufacture', 'Manufacture']
									],
									onChange: handleChange
								}
							]}
						/>
						<FormInputs
							ncols={["8"]}
							className={["px-2"]}
							properties={[
								{
									label: t('country of operation'),
									fieldType: 'select',
									name: 'locale',
									value: values.locale,
									options: [
										['es-VE','Venezuela'], 
										['es-CO','Colombia'],
										['es-MX', 'México'], 
										['es-ES', 'España'],
										['pt-BR', 'Brasil'],
										['en-US', 'United States of America']
									],
									onChange: handleChange
								}
							]}
						/>
						<Row>
							<Col md="5" className="mx-auto mt-4 text-centert">
								{
									isSubmitting 
									? 
									<div><h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> 
									: 
									<Button 
										type="submit" 
										id={this.props.params.id + "-form-submit-" + this.props.idn}
										className="single-submit text-capitalize" >
											{t('submit')}
									</Button>
								}
							</Col>
						</Row>
						</fieldset>
					</Form>
					)}
				</Formik>
			</Container>
		)
	}
}
BusinessUnitForm.contextType = PolicyContext

export default withRouter(BusinessUnitForm);