import jsPDF from 'jspdf'
import { fn } from './Functions'
import t, { locales } from '../../../utils/I18n/I18n'

const docTypes:any = {
    printed_legal: {es: 'NOTA DE ENTREGA'},
    printed_receipt: {es: 'NOTA DE ENTREGA'},
    issued_legal: {es: 'NOTA DE ENTREGA'},
    issued_receipt: {es: 'NOTA DE ENTREGA'},
    delivery_note: {es: 'NOTA DE ENTREGA'}
}


class DeliveryNote {
    static print(order:any, items:any, client:any, options?:any) {
        const doc:any = new jsPDF('p', 'mm', 'letter')
        let pageCount = 1, offset:any=13

        const localeObj = locales[options.locale] // locale object
        offset = this.subHeader(doc, client, order, pageCount, localeObj, offset)
        offset = this.listItems(doc, items, offset + 5, localeObj, options)

        offset = this.subHeader(doc, client, order, pageCount, localeObj, 100)
        offset = this.listItems(doc, items, offset + 5, localeObj, options)

        const ot = this.subHeader(doc, client, order, pageCount, localeObj, 190, {showDeliveryAddress: true})
        this.listItems(doc, items, ot + 5, localeObj, options)
    
        return doc.output('blob')
    }

    static subHeader(doc:any, client:any, order:any, pageCount:number, locale:any, offset:number, options:any = {}) {
        console.log(order)
        const headerFields = {
            docType: `${docTypes[order.status][locale.language]} Nº`, 
            totalToPay: t('total to pay'),
            issueDate: t('issue date'),
            payBefore: t('pay before'),
            paymentCondition: t('payment condition'),
            page: t('page'),
            seller: t('seller')
        }
        const clientHeaders = {
            clientInfo: t('client info'),
            clientAdds:t('client address'),
            clientNumber: t('client number'),
            purchaseOrderNumber: t('purchase order number'),
            deliveryAdds: t('delivery address'),
        }

        // Horizontal Ruler
        let leftOffset = offset
        doc.setDrawColor(100)
        doc.line(10, leftOffset - 5, 205, leftOffset - 5)
    
        // ORDER NUMBER
        let rightOffset = leftOffset
        doc.setFontStyle("normal")
        doc.setTextColor(50)
        doc.setFontSize(10)
        doc.text(`${headerFields.docType.toUpperCase()}:`, 180, rightOffset, {align: 'right'})
        doc.setFontStyle("bold")
        doc.setTextColor(50)
        doc.setFontSize(10)
        doc.text(order.status === 'printed_legal' ? `F-${order.billNumber}` : `R-${order.orderNumber}`, 182, rightOffset)

        rightOffset += 5
        // DOC DATA (e.g. 'Date') 
        doc.setFontStyle("normal")
        doc.setTextColor(50)
        doc.setFontSize(8)
        doc.text([`${headerFields.issueDate.toUpperCase()}:`], 180, rightOffset, {align: 'right'})

        doc.setFontStyle("bold")
        doc.setTextColor(50)
        const lo:any = { day: '2-digit', month: '2-digit', year: 'numeric'}
        // new Date(Date.now() - 30*24*60*60*1000)

        doc.text([new Date(order.date).toLocaleDateString(locale.code, lo)], 182, rightOffset)

        ///////////////////////////////////////////////////////  left side
        // Client Info
        doc.setTextColor(50)
        doc.setFontSize(8)
        doc.setFontStyle("bold")
        doc.text(clientHeaders.clientInfo.toUpperCase(), 12, leftOffset)
    
        leftOffset += 4
        doc.setFontStyle("normal")
        doc.text([
            client.legalName ? client.legalName.toUpperCase() : '', 
            client.identificationNumber ? 'RIF: ' + client.identificationNumber.toUpperCase() : '',
            client.clientNumber.toString()
        ], 12, leftOffset)

        if(options.showDeliveryAddress && !!client && !!client.facility) {
            leftOffset += 3
            doc.setFontSize(7)
            doc.setFontStyle("bold")
            const address = `${t('place of delivery')}: ${client.facility.facilityName ? client.facility.facilityName + ':' : ''} ${client.facility.addressLine1} ${client.facility.addressLine2 ? ', ' + client.facility.addressLine2 : ''} ${client.facility.cityName ? ', ' + client.facility.cityName : ''} ${client.facility.postalCodeText ? ', ' + client.facility.postalCodeText : ''} ${client.facility.stateProvinceName ? ', ' + client.facility.stateProvinceName : ''} ${client.facility.countryName ? ', ' + client.facility.countryName : ''}`
            const addressTextSplit = doc.splitTextToSize(address.toUpperCase(), 120)
            doc.text(addressTextSplit, 40, leftOffset, {maxWidth: '110'})
        }

        return (leftOffset < rightOffset ? rightOffset : leftOffset) + 4
    }
    static listItems(doc:any, items:any, offset:number, locale:any, options:any):any {
        const headers = {
            n: '#', 
            code: t('code'), 
            description: t('description'), 
            quantity: t('quantity'), 
            price: t('price'), 
            vat: locales[locale.code].taxName,
            amount: t('amount'), 
            unit: t('unit')
        }

        let itemsOffset = offset, fontSize = 9, lineHeightFactor = 1.15

        doc.setDrawColor(100)
        doc.setLineWidth(0.2)
        doc.rect(9, itemsOffset, 197, 8)
        itemsOffset += 5
        // List Items Header
        doc.setTextColor(50)
        doc.setFontStyle("bold")
        doc.setFontSize(fontSize - 1)
        doc.text(headers.code.toUpperCase(), 15, itemsOffset)                          // CODE/SKU
        doc.text(headers.description.toUpperCase(), 45, itemsOffset)                         // DESCRIPTION
        doc.text(headers.unit.toUpperCase(), 130, itemsOffset, {align: 'center'})      // UNIT OF MEASURE
        doc.text(headers.quantity.toUpperCase(), 155, itemsOffset, {align: 'center'})      // QTY
        //doc.text(headers.vat.toUpperCase(), 140, itemsOffset, {align: 'center'})      // VAT
        itemsOffset += 10
        // ITEM X
        doc.setFontStyle("normal")
        for(let i = 0; i < items.length; i++) {

            const sku:string[] = doc.splitTextToSize(items[i].sku !== '' ? items[i].sku.toUpperCase() : 'N/A', 25),
            desc:string[] = doc.splitTextToSize(items[i].description, 70),
            lineCount:number = desc.length > sku.length ? desc.length : sku.length,
            textHeight:number = lineCount * fontSize * lineHeightFactor * 34/100


            doc.setFontSize(fontSize - 1)
            doc.text(sku, 15, itemsOffset)
            doc.text(desc, 45, itemsOffset)                                                                     // DESCRIPTION
            doc.text(items[i].retailed === 'true' ? 'UND' : `${items[i].unitOfMeasure.toUpperCase()}`, 130, itemsOffset, {align: 'center'})             // UNIT OF MEASURE
            ///////
            doc.text(`${fn(items[i].quantity, locale.code)}`, 155, itemsOffset, {align: 'center'})             // QTY

            itemsOffset += textHeight + 2
        }
        // Horizontal Ruler
        doc.setDrawColor(100)
        doc.setLineWidth(0.2)
        doc.line(10, itemsOffset, 205, itemsOffset)
        return {count: items.length, height: itemsOffset}
    }
}

export default DeliveryNote