import React from 'react'
import { Route } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
//import { PolicyContext } from '../utils/Contexts'
import OrgForm from '../components/Org/OrgForm'
import BusinessUnitForm from '../components/Org/BUForm'
import BUAddressForm from '../components/Org/BUAddressForm'
import BUIdForm from '../components/Org/BUIdForm'
import BUPreferences from '../components/Org/BUPreferences'
import BUApi from '../components/Org/BUApi'
import Dashboard from '../components/Dashboard/Dashboard'
import NewProduct from '../components/Product/NewProduct'
import ProductList from '../components/Product/ProductList'
import EditProduct from '../components/Product/EditProduct'
import InactiveList from '../components/Product/InactiveList'
import NewSale from '../components/Sale/NewSale'
import NewBillConsignment from '../components/Sale/BillConsignment'
import ReportAlfa from '../components/Sale/SaleOrders'
import PriceForm from '../components/Product/PriceMaster'
import ProfileForm from '../components/User/ProfileForm'
import AddressForm from '../components/User/AddressForm'
import ClientForm from '../components/Client/ClientForm'
import FacilityForm from '../components/Client/FacilityForm'
import ClientList from '../components/Client/ClientList'
import ClientBulkLoad from '../components/Client/ClientBulkLoad'
import PlainBox from '../layouts/Forms/PlainBox'
import SingleBox from '../layouts/Forms/SingleBox'
import AccordionBox from '../layouts/Forms/AccordionBox'
import PreferenceForm from '../components/User/PreferenceForm'
import NewUser from '../components/Org/Individual/NewUser'
import Signout from '../components/User/Signout'
import ProductInventory from '../components/Inventory/ProductInventory'
import LoadInventory from '../components/Inventory/LoadInventory'
import NewIndividual from '../components/Org/Individual/NewIndividual'

const dashboard = {
    component: Dashboard,
    params: {title: 'dashboard', id: 'dashboard'}
}
const orgBasicInfo = {
    component: SingleBox, 
    params: {title: 'org basic info', id: 'org-box'},
    children: [
        {
            component: OrgForm, 
            params: { id: 'org-info', endpoint: 'POST /orgs'}
        }   
    ]
}
const businessUnit = {
    component: SingleBox, 
    params: {title: 'general info', id: 'bu-box', className: 'mt-4'},
    children: [
        {
            component: BusinessUnitForm, 
            params: { id: 'bu-profile', endpoint: 'POST /orgs/:id/companies'}
        }   
    ]
}
const buAddress = {
    component: AccordionBox,
    params: {title: 'address & phones', id: 'bu-address-box'},
    children: [
        {
        component: BUAddressForm, 
        params: { id: 'bu-address', endpoint: 'POST /orgs/:id/companies/:id/addresses'}
        }
    ]
}
const buIdAccordion = {
    component: AccordionBox,
    params: {title: 'legal identification', id: 'bu-id-box'},
    children: [
        {
        component: BUIdForm, 
        params: { id: 'bu-id', endpoint: 'POST /orgs/:id/companies/:id/ids'}
        }
    ]
}
const userPersonalInformation = {
    component: SingleBox,
    params: {title: 'personal information', id: 'user-box', className: "px-1 bzr-form"},
    children: [
        {
            component: ProfileForm,
            params: { id: 'user-profile', endpoint: 'PUT /users/:id' }
        }
    ]
}
const userAddress = {
    component: SingleBox,
    params: {title: 'address', id: 'address-box'},
    children: [
        {
        component: AddressForm, 
        params: { id: 'user-address', endpoint: 'PUT /users/:id/addresses/:id'}
        }
    ]
}
const userPreferences = {
    component: SingleBox,
    params: {title: 'preferences', id: 'user-prefs-box'},
    children: [
        {
        component: PreferenceForm, 
        params: { id: 'user-prefs', endpoint: 'PUT /users/:id/preferences/:userId'}
        }
    ]
}
const newUser = {
    component: PlainBox,
    params: {title: 'new user', id: 'new-user-box'},
    children: [
        {
        component: NewUser, 
        params: { id: 'new-user', endpoint: 'POST /users/:id/bzrUsers'}
        }
    ]
}
const newIndividual = {
    component: PlainBox,
    params: {title: 'new individual', id: 'new-individual-box'},
    children: [
        {
        component: NewIndividual, 
        params: { id: 'new-individual', endpoint: 'POST /orgs/:id/companies/:id/individuals'}
        }
    ]
}
const newProduct = {
    component: PlainBox, 
    params: {title: 'basic info', id: 'product-box'},
    children: [
        {
            component: NewProduct, 
            params: { id: 'product-new', endpoint: 'POST /orgs/:id/companies/:id/products'}
        }   
    ]
}
const editProduct = {
    component: PlainBox, 
    params: {title: 'edit product info', id: 'edit-product-box'},
    children: [
        {
            component: EditProduct, 
            params: { id: 'product-edit', endpoint: 'PUT /orgs/:id/companies/:id/products/:id'}
        }   
    ]
}
const productList = {
    component: PlainBox, 
    params: {id: 'product-box'},
    children: [
        {
            component: ProductList, 
            params: { id: 'product-list', endpoint: 'GET /objects/:id/orgs/:id/companies/:id/records'}
        }   
    ]
}
const inactiveList = {
    component: PlainBox, 
    params: {id: 'product-box'},
    children: [
        {
            component: InactiveList, 
            params: { id: 'inactive-list', endpoint: 'GET /orgs/:id/companies/:id/products'}
        }   
    ]
}
const newSale = {
    component: PlainBox, 
    params: {id: 'pos-box', className: "bzr-pos"},
    children: [
        {
            component: NewSale, 
            params: { id: 'sale-form', endpoint: 'POST /objects/:id/orgs/:id/companies/:id/sales'}
        }   
    ]
}
const newBillConsignment = {
    component: PlainBox, 
    params: {id: 'pos-box', className: "bzr-pos"},
    children: [
        {
            component: NewBillConsignment, 
            params: { id: 'sale-form', endpoint: 'POST /objects/:id/orgs/:id/companies/:id/sales'}
        }   
    ]
}
const billPreference = {
    component: PlainBox,
    params: {id: 'bill-preferences-box'},
    children: [
        {
        component: BUPreferences, 
        params: { id: 'bill-preferences', endpoint: ''}
        }
    ]
}
const apiPreferences = {
    component: PlainBox,
    params: {id: 'api-box'},
    children: [
        {
        component: BUApi, 
        params: { id: 'api-preferences', endpoint: ''}
        }
    ]
}
const reportAlfa = {
    component: PlainBox, 
    params: {id: 'report-box'},
    children: [
        {
            component: ReportAlfa, 
            params: { id: 'report-alfa', endpoint: 'GET /orgs/:id/companies/:id/records/:id'}
        }   
    ]
}
const newClient = {
    component: PlainBox, 
    params: {id: 'client-box'},
    children: [
        {
            component: ClientForm, 
            params: { id: 'new-client', endpoint: 'POST /orgs/:id/companies/:id/clients'}
        }   
    ]
}
const facility = {
    component: PlainBox, 
    params: {id: 'client-box'},
    children: [
        {
            component: FacilityForm, 
            params: { id: 'new-client', endpoint: 'POST /orgs/:id/companies/:id/clients'}
        }   
    ]
}
const listClients = {
    component: PlainBox, 
    params: {id: 'client-list-box'},
    children: [
        {
            component: ClientList, 
            params: { id: 'client-list', endpoint: 'GET /orgs/:id/companies/:id/clients'}
        }   
    ]
}
const priceForm = {
    component: PlainBox, 
    params: {id: 'client-list-box'},
    children: [
        {
            component: PriceForm, 
            params: { id: 'price-form', endpoint: 'POST /orgs/:id/companies/:id/prices'}
        }   
    ]
}
const inventoryForm = {
    component: PlainBox, 
    params: {id: 'inventory-box'},
    children: [
        {
            component: ProductInventory, 
            params: { id: 'inventory-form', endpoint: 'POST /orgs/:id/companies/:id/inventories'}
        }   
    ]
}
const inventoryLoad = {
    component: PlainBox, 
    params: {id: 'inventory-load-box'},
    children: [
        {
            component: LoadInventory, 
            params: { id: 'inventory-load-form', endpoint: 'POST /orgs/:id/companies/:id/inventories'}
        }   
    ]
}
const clientsLoad = {
    component: PlainBox, 
    params: {id: 'clients-load-box'},
    children: [
        {
            component: ClientBulkLoad, 
            params: { id: 'clients-load-form'}
        }   
    ]
}


export const routes = [
    {
        path: "/admin/dashboard",
        name: "dashboard",
        icon: "pe-7s-display1",
        main: [1],
        rows: [
            [dashboard]
        ],
        type: "grid"
    },
    {
        path: "/admin/sales/new",
        name: "POS",
        icon: "pe-7s-calculator",
        main: [newSale],
        extra: <hr />
    },
    {
        exact: true,
        noSidebar: true,
        path: "/admin/sales/modify/:id",
        name: "Modify Order",
        icon: "pe-7s-display1",
        main: [newSale]
    },
    {
        exact: true,
        noSidebar: true,
        path: "/admin/consignments/bill/:id",
        name: "Modify Order",
        icon: "pe-7s-display1",
        main: [newBillConsignment]
    },
    {
        noSidebar: true,
        path: "/admin/clients/new",
        name: "New Client",
        icon: "pe-7s-display1",
        main: [1],
        rows: [
            [newClient]
        ],
        cols: [
            [12]
        ],
        type: "grid",
    },
    {
        noSidebar: true,
        path: "/admin/clients/modify/:id?",
        name: "Modify Client",
        icon: "pe-7s-display1",
        main: [1],
        rows: [
            [newClient]
        ],
        cols: [
            [12]
        ],
        type: "grid",
    },
    {
        noSidebar: true,
        path: "/admin/clients/facilities",
        name: "Facility",
        icon: "pe-7s-display1",
        main: [1],
        rows: [
            [facility]
        ],
        cols: [
            [12]
        ],
        type: "grid",
    },
    {
        noSidebar: true,
        path: "/admin/user/profile",
        name: "profile",
        icon: "pe-7s-user",
        main: [userPersonalInformation, userAddress]
    },
    {
        noSidebar: true,
        path: "/admin/user/preference",
        name: "preferences",
        main: [userPreferences]
    },
    {
        noSidebar: true,
        path: "/admin/products/inventories/:id?",
        name: "add inventory",
        main: [1],
        rows: [
            [inventoryForm]
        ],
        type: "grid",
    },
    {
        noSidebar: true,
        path: "/admin/api/preferences",
        name: "api preferences",
        main: [1],
        rows: [
            [apiPreferences]
        ],
        type: "grid",
    },
    {
        noSidebar: true,
        path: "/admin/clients/loads",
        name: "bulk client load",
        main: [1],
        rows: [
            [clientsLoad]
        ],
        type: "grid",
    },
    // Sales Dropdown
    {
        dropdown: {
            name: "sales",
            icon: "pe-7s-cash",
            routes: [
                {
                    path: "/admin/sales/new",
                    name: "POS",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [newSale]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid",
                },
                {
                    path: "/admin/sales/transacs",
                    name: "Transacs",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [reportAlfa]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid",
                },
                {
                    path: "/admin/clients/list",
                    name: "Client List",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [listClients]
                    ],
                    cols: [[12]],
                    type: "grid",
                },
                {
                    path: "/admin/sales/preferences",
                    name: "bill preferences",
                    main: [1],
                    rows: [
                        [billPreference]
                    ],
                    cols: [[12]],
                    type: "grid",
                }
            ]
        }
    },    
    {
        dropdown: {
            name: "products",
            icon: "pe-7s-box2",
            routes: [
                {
                    path: "/admin/products/new",
                    name: "new product",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [newProduct]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid",
                },
                {
                    path: "/admin/products/list",
                    name: "list",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [productList]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid",
                },
                {
                    path: "/admin/products/edit/:id?",
                    name: "edit product",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [editProduct]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid",
                },
                {
                    path: "/admin/products/inactives",
                    name: "inactive products",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [inactiveList]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid",
                }
            ]
        }
    },
    {
        dropdown: {
            name: "inventories",
            icon: "pe-7s-box1",
            routes: [
                {
                    path: "/admin/inventories/loads",
                    name: "load inventories",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [inventoryLoad]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid",
                },
                {
                    path: "/admin/inventories",
                    exact: true,
                    name: "manage inventories",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [inventoryForm]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid",
                }
            ]
        }
    },
    {
        dropdown: {
            name: "prices",
            icon: "pe-7s-note2",
            routes: [
                {
                    path: "/admin/products/prices",
                    name: "price books",
                    icon: "pe-7s-note2",
                    main: [1],
                    rows: [
                        [priceForm]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid",
                }
            ]
        }
    },
    {
        dropdown: {
            name: "organization",
            icon: "pe-7s-culture",
            routes: [
                {
                    path: "/admin/org/profile",
                    name: "org profile",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [orgBasicInfo, businessUnit]
                    ],
                    cols: [
                        [6,6]
                    ],
                    type: "grid",
                },
                {
                    path: "/admin/org/company",
                    name: "business units",
                    icon: "pe-7s-display1",
                    main: [1],
                    rows: [
                        [businessUnit],
                        [buIdAccordion, buAddress]
                    ],
                    cols: [5, 7],
                    type: "vertical"
                },
                {
                    path: "/admin/company/individuals",
                    name: "individuals",
                    icon: "pe-7s-add-user",
                    main: [1],
                    rows: [
                        [newIndividual]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid"
                }
            ]
        }
    },
    {
        dropdown: {
            name: "users",
            icon: "pe-7s-users",
            routes: [
                {
                    path: "/admin/users/new",
                    name: "new user",
                    icon: "pe-7s-add-user",
                    main: [1],
                    rows: [
                        [newUser]
                    ],
                    cols: [
                        [12]
                    ],
                    type: "grid",
                    extra: <hr />
                },
/*                 {
                    path: "/admin/users",
                    name: "manage",
                    icon: "pe-7s-graph",
                    main: [SingleBox, SingleBox],
                    children: [
                        [ProfileForm, {id: 'user-profile', title: 'Personal Information', endpoint: 'PUT /users/:userId'}], 
                        [AddressForm, {id: 'user-address', title: 'Contact Information', endpoint: 'PUT /users/:userId/addresses/:id'}]
                    ],
                    type: "nested-form"
                } */
            ]
        }
    },
    {
        path: "/admin/signout",
        name: "sign out",
        icon: "pe-7s-power",
        breadcrumbs: () => <div>Signout!</div>,
        main: [Signout],
        type: "default"
    }
]

const mountComponent = (idn:number, compObj:any, extras:any, props:any) => {
    if(compObj.children) {
        return( <compObj.component key={idn} idn={idn} params={compObj.params} {...extras} {...props} comps={compObj.children} /> )
    } else 
        return( <compObj.component key={idn} idn={idn} params={compObj.params} {...extras} {...props} /> )
}

export const getRoutes = (routes:any, extras?:any) => {
    return(
        routes.map((route:any, index:any) => {
            if(route.dropdown) return getRoutes(route.dropdown.routes, extras) 
            else {
                //  POLICY APPLY FROM HERE
                //console.log(extras.policies, extras.appStates.activeBU)
                const allowedRoutes:any = extras.policies[extras.appStates.activeBU.id].routes
                if(allowedRoutes.find((r:any) => r === route.path) || allowedRoutes[0] === '*' || route.type === 'default' ) return(
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        children={ 
                            (props:any) => {
                                var row:any = []
                                route.main.forEach((item:any, key:any) => { 
                                    const ob = { main: item }
                                    if(route.type === undefined) {
                                        row.push(
                                            mountComponent(key*20, ob.main, extras, props)
                                        )
                                    } else if(route.type.match(/nested-form/gi)) {
                                        row.push(
                                            <ob.main 
                                                key={key} 
                                                appStates={extras.appStates} 
                                                liftUpAppStates={extras.liftUpAppStates} 
                                                singleSubmit={route.type.match(/single-submit/gi)}
                                                formType={route.children[key][0]} 
                                                params={route.children[key][1]}
                                                {...props} />
                                        )
                                    } else if(route.type.match(/grid/)) {
                                        route.rows.forEach((cols:any, k:any) => { 
                                            let col:any = []
                                            for(let i = 0; i < cols.length; i++) {
                                                col.push(
                                                <Col key={i} md={route.cols ? route.cols[k][i] : true} className={route.className ?? ""}>
                                                    {cols[i] ? mountComponent(k*10 + i,cols[i], extras, props) : null}
                                                </Col>)
                                            }
                                            row.push(<Row key={k}>{col}</Row>)
                                        })                          
                                    } else if(route.type.match(/vertical/)) {
                                        route.rows.forEach((cols:any, k:number) => { 
                                            let col:any = []
                                            for(let i = 0; i < cols.length; i++) {
                                                col.push(
                                                <Row key={i}>
                                                    <Col md={12}>
                                                        {mountComponent(k*10 + i, cols[i], extras, props)}
                                                    </Col> 
                                                </Row>)
                                            }
                                            row.push(<Col key={k} md={route.cols[k]}>{col}</Col>)
                                        })   
                                    row = <Row>{ row }</Row>                       
                                    } else {
                                        row.push(<ob.main 
                                            key={key} 
                                            appStates={extras.appStates} 
                                            liftUpAppStates={extras.liftUpAppStates} 
                                            products={extras.products}
                                            singleSubmit={route.type.match(/single-submit/gi)}
                                            {...props} />)
                                    }
                                })
                                return <Container fluid>{ row }</Container>
                            }
                        }
                    />
                )
                else return null
            }
        })
    )
}
