import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

class Menu extends React.Component {
    render() {
        return(
            <Container>
                <Row>
                    <Col>Slot 1</Col>
                    <Col>Slot 2</Col>
                </Row>
                <Row>
                    <Col>Slot 3</Col>
                    <Col>Slot 4</Col>
                </Row>
            </Container>
        )
    }
}

export default Menu