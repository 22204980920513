import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap'
import { History, LocationState } from "history"
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import FormInputs from '../../layouts/Forms/FormInputs'
import BzrAxios from '../../utils/BzrAxios'
import t, { translate } from '../../utils/I18n/I18n'
import { MsgBubble } from '../../layouts/Modals/Modals'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>
	appStates: any
	liftUpAppStates:any
}

interface States {
	seqs: any
	alertMsg: any
	switch: boolean
	bubble: any
}

class BUPreferences extends React.Component<Props,States> {
	
	constructor(props: Props) {
		super(props);

        this.state = {
			seqs: {},
			alertMsg: null,
			switch: false,
			bubble: null
		}
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	componentDidMount() {
		const view:any = document.getElementById('bill-correlatives-form')
		translate(view)
	}
	componentDidUpdate() {
		if(this.props.appStates.activeBU && !this.state.seqs.orderSeq) {
			const seqs:any = {}
			seqs.billSeq = this.incOrderNumber(this.props.appStates.activeBU.billSeq)
			seqs.controlSeq = this.incOrderNumber(this.props.appStates.activeBU.controlSeq)
			seqs.creditSeq = this.incOrderNumber(this.props.appStates.activeBU.creditSeq)
			seqs.debitSeq = this.incOrderNumber(this.props.appStates.activeBU.debitSeq)
			seqs.noteSeq = this.incOrderNumber(this.props.appStates.activeBU.noteSeq)
			seqs.orderSeq = this.incOrderNumber(this.props.appStates.activeBU.orderSeq)
			this.setState({seqs})
		}
	}

	incOrderNumber(on:any, sep:string = '-') {
        on = parseInt(on.replace(/\W+/g,''))
        on++
        if(on.length > 6) {
            on = on.toString().padStart(8, '0')
            on = on.slice(0,2) + sep + on.slice(2)
        } else on = on.toString().padStart(6, '0')
        return on
    }

	async handleSubmit(data:any, formikBag:any) {
		let activeBU:any = this.props.appStates.activeBU

		Object.entries(data).forEach((entry:any) => {
			if(entry[0].match(/seq/ig)) data[entry[0]] = parseInt(entry[1]) - 1
		})

		try {
			const response:any = await BzrAxios.orgs({url: `/BusinessUnits/${activeBU.id}`, method: 'PUT', data})
			activeBU.billSeq = response.data.bu.billSeq
			activeBU.controlSeq = response.data.bu.controlSeq
			activeBU.creditSeq = response.data.bu.creditSeq
			activeBU.debitSeq = response.data.bu.debitSeq
			activeBU.noteSeq = response.data.bu.noteSeq
			activeBU.orderSeq = response.data.bu.orderSeq
			this.props.liftUpAppStates({activeBU})
		} catch(err) {
			this.setState({alertMsg: {variant: 'danger', message: err.message, heading: t('error on apply')}})
			console.log(err)
		} finally {
			formikBag.resetForm()
			const seqs:any = {}
			seqs.billSeq = this.incOrderNumber(this.props.appStates.activeBU.billSeq)
			seqs.controlSeq = this.incOrderNumber(this.props.appStates.activeBU.controlSeq)
			seqs.creditSeq = this.incOrderNumber(this.props.appStates.activeBU.creditSeq)
			seqs.debitSeq = this.incOrderNumber(this.props.appStates.activeBU.debitSeq)
			seqs.noteSeq = this.incOrderNumber(this.props.appStates.activeBU.noteSeq)
			seqs.orderSeq = this.incOrderNumber(this.props.appStates.activeBU.orderSeq)
			this.setState({seqs, bubble: {variant: 'success', info: 'changes successfully applied'}})
			document.getElementById('mfa-switch-id')?.click()
		}
	}

	correlativesForm () {
		return (
			<Formik
				validationSchema={
					Yup.object().shape({
						billSeq: Yup.mixed().required().test(t('ok'), t('wrong'), async (v:string) => parseInt(v) >= this.state.seqs.billSeq),
						controlSeq: Yup.mixed().required().test(t('ok'), t('wrong'), async (v:string) => parseInt(v) >= this.state.seqs.controlSeq),
						creditSeq: Yup.mixed().required().test(t('ok'), t('wrong'), async (v:string) => parseInt(v) >= this.state.seqs.creditSeq),
						debitSeq: Yup.mixed().required().test(t('ok'), t('wrong'), async (v:string) => parseInt(v) >= this.state.seqs.debitSeq),
						noteSeq: Yup.mixed().required().test(t('ok'), t('wrong'), async (v:string) => parseInt(v) >= this.state.seqs.noteSeq),
						orderSeq: Yup.mixed().required().test(t('ok'), t('wrong'), async (v:string) => parseInt(v) >= this.state.seqs.orderSeq)
					})
				}
				onSubmit={this.handleSubmit}
				initialValues={{ 
					billSeq: this.state.seqs.billSeq ?? '',
					controlSeq: this.state.seqs.controlSeq ?? '',
					creditSeq: this.state.seqs.creditSeq ?? '',
					debitSeq: this.state.seqs.debitSeq ?? '',
					noteSeq: this.state.seqs.noteSeq ?? '',
					orderSeq: this.state.seqs.orderSeq ?? '' }}
				validateOnChange={false}
				enableReinitialize
			>
				{({
					handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, isSubmitting
				}) =>
				(
				<Form noValidate onSubmit={handleSubmit}>
					<fieldset disabled={isSubmitting || !this.state.switch}>
					<FormInputs
						ncols={['12', '12','12','12','12','12']}
						className={['px-2', 'px-2', 'px-2', 'px-2', 'px-2', 'px-2']}
						properties={[
							{
								label: "bill number",
								horizontalLabel: "7",
								feedback: " ",
								fieldType: "text",
								name: "billSeq",
								value: values.billSeq ?? '',
								onChange: handleChange,
								onBlur: handleBlur,
								isValid: touched.billSeq && !errors.billSeq,
								isInvalid: !!errors.billSeq,
								error: errors.billSeq
							},
							{
								label: "control number",
								horizontalLabel: "7",
								feedback: " ",
								fieldType: "text",
								name: "controlSeq",
								value: values.controlSeq ?? '',
								onChange: handleChange,
								onBlur: handleBlur,
								isValid: touched.controlSeq && !errors.controlSeq,
								isInvalid: !!errors.controlSeq,
								error: errors.controlSeq
							},
							{
								label: "credit number",
								horizontalLabel: "7",
								feedback: " ",
								fieldType: "text",
								name: "creditSeq",
								value: values.creditSeq ?? '',
								onChange: handleChange,
								onBlur: handleBlur,
								isValid: touched.creditSeq && !errors.creditSeq,
								isInvalid: !!errors.creditSeq,
								error: errors.creditSeq
							},
							{
								label: "debit number",
								horizontalLabel: "7",
								feedback: " ",
								fieldType: "text",
								name: "debitSeq",
								value: values.debitSeq ?? '',
								onChange: handleChange,
								onBlur: handleBlur,
								isValid: touched.debitSeq && !errors.debitSeq,
								isInvalid: !!errors.debitSeq,
								error: errors.debitSeq
							},
							{
								label: "note number",
								horizontalLabel: "7",
								feedback: " ",
								fieldType: "text",
								name: "noteSeq",
								value: values.noteSeq ?? '',
								onChange: handleChange,
								onBlur: handleBlur,
								isValid: touched.noteSeq && !errors.noteSeq,
								isInvalid: !!errors.noteSeq,
								error: errors.noteSeq
							},
							{
								label: "order number",
								horizontalLabel: "7",
								feedback: " ",
								fieldType: "text",
								name: "orderSeq",
								value: values.orderSeq ?? '',
								onChange: handleChange,
								onBlur: handleBlur,
								isValid: touched.orderSeq && !errors.orderSeq,
								isInvalid: !!errors.orderSeq,
								error: errors.orderSeq
							}
						]}
					/>
					<br />
					<Form.Group as={Row}>
						{ 	isSubmitting ? 
							(<div className="w-100 text-center">Submitting <FontAwesomeIcon icon={faSpinner} spin /></div>) : 
							<Button variant="primary" type="submit" className="mx-auto text-capitalize"> {t('apply changes')} </Button>
						}
					</Form.Group>
					</fieldset>
				</Form>
				)}
			</Formik>
		)
	}

	render() {
		return (
			<Container id="bill-correlatives-form" className="mx-auto mb-5 px-5 pb-5">
				<Row>
					<Col md={12}>
					{this.state.alertMsg ? 
						(<Alert variant={this.state.alertMsg.variant} onClose={() => this.setState({alertMsg: null})} dismissible>
							<Alert.Heading>{this.state.alertMsg.heading}</Alert.Heading>
							{this.state.alertMsg.message}
						</Alert>) : null
					} 
					</Col>
					<Col md={6} className="mx-auto">
						<br />
						<div className="mx-4 p-2 border rounded">
							<h4>bill correlatives</h4>
							<FormInputs
								ncols={['3']}
								className={['ml-auto']}
								properties={[
									{
										label: 'enable',
										horizontalLabel: "5",
										fieldType: 'switch',
										name: 'mfa',
										value: this.state.switch,
										onChange: () => this.setState({switch: !this.state.switch})
									}
								]}
							/>
							<br />
							{this.correlativesForm()}
						</div>
					</Col>
				</Row>
				<MsgBubble msg={this.state.bubble} onClose={() => this.setState({bubble: null})}/>
			</Container>
		)
	}
}

export default withRouter(BUPreferences);