// Render Prop
import React from 'react'
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history"
import { Auth } from 'aws-amplify'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Formik } from 'formik'
import * as Yup  from 'yup'
import { MessageModal } from '../../layouts/Modals/Modals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle, faAmazon } from '@fortawesome/free-brands-svg-icons'

import BzrAxios from '../../utils/BzrAxios'
import Axios from 'axios'

import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
    history: History<LocationState>
}

interface State {
    cognitoUser: CognitoUser | any,
    mfaEnabled: boolean,
    loggedIn: boolean,
    code: string,
    password: string,
    validated: boolean, 
    toggleMessageModal: boolean
    submitted: boolean,
    cognitoErr: any,
    tabindex: number
}

class Signin extends React.Component<Props, State> {
    myRefs:any = []
    constructor(props: Props) {
        super(props)
        this.state = {
            mfaEnabled: false, 
            loggedIn: false,
            cognitoUser: null,
            code: '',
            password: '',
            validated: false,
            toggleMessageModal: false,
            submitted: false,
            cognitoErr: null,
            tabindex: 1
        }
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.getRef = this.getRef.bind(this)
    }

    getRef(el:any) { if(el) this.myRefs[el.getAttribute('tabindex')] = el }
    handleKeyDown(event:any) { 
        const index:any = parseInt(event.target.getAttribute('tabindex')) + 1
        if(event.keyCode === 13 && this.myRefs[index]) {
            event.preventDefault()
            event.stopPropagation()
            this.myRefs[index].focus()
        }
    }

    handleSignIn( values: any ) {
        Auth.signIn(values.username, values.password).then(
            (user: CognitoUser | any ) => {
                if (user.challengeName === 'SMS_MFA' ||
                    user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    console.log('PRE-AUTHENTICATED USER');    
                    console.log(user);
                    //  1. PRE-SET USER
                    this.setState({cognitoUser: user, mfaEnabled: true})

                } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    console.log('NEW_PASSWORD_REQUIRED')
                } else if (user.challengeName === 'MFA_SETUP') {
                    console.log('MFA_SETUP')
                } else {
                    this.setState({cognitoUser: user, loggedIn: true, submitted: false})
                    Axios.defaults.headers.common['Authorization'] = user.signInUserSession.idToken.jwtToken
                    if(Number.parseInt(user.attributes['custom:status']) > 700 && Number.parseInt(user.attributes['custom:status']) < 1000) {
                        // AUTHENTICATED/NOT ENROLL - GO TO ENROLLMENT
                        this.setState({toggleMessageModal: false})
                        window.location.replace('/enrollment')
                        return 
                    } else if(Number.parseInt(user.attributes['custom:status']) > 2900 && Number.parseInt(user.attributes['custom:status']) < 5000 ) {
                        this.setState({toggleMessageModal: false})
                        window.location.replace('/admin/dashboard')
                        //this.props.history.push('/admin/dashboard');
                        return
                    } else if(Number.parseInt(user.attributes['custom:status']) === 5000) { 
                        Auth.updateUserAttributes(user, {'custom:status': '5100', 'custom:registered': 'true'})
                        .then(() => {
                            this.setState({toggleMessageModal: false})
                            // 1. Create User in Bizor Realm
                            BzrAxios.users({url: `/users/${user.attributes.sub}`, method: 'PUT' ,data: {userStatus: 5100}})
                            .then((response: any) => {
                                console.log('put', response)
                                // GOTO SOMEWHERE
                                window.location.replace('/admin/dashboard')         
                            }).catch((error: any) => {
                                console.log(error)
                                alert('error while signing in')
                                window.location.replace('/signout')
                            })
                        })
                        .catch(err => {
                            alert('error while signing in')
                            window.location.replace('/signout')
                        })
                    } else if(Number.parseInt(user.attributes['custom:status']) > 5000 ) {
                        this.setState({toggleMessageModal: false})
                        window.location.replace('/admin/dashboard')
                        //this.props.history.push('/admin/dashboard');
                        return
                    }
                    // 1. Create User in Bizor Realm
                    BzrAxios.users({
                        url:"/",
                        method: 'POST',
                        data:{
                            username: user.attributes['custom:username'],
                            email: user.attributes['email'],
                            id: user.attributes['sub'],
                            userType: 'root',
                            userStatus: 900,
                            emailVerifiedAt: (new Date()).toISOString()  ////// Check
                        }
                    }).then((response: any) => {
                            console.log(response)
                            Auth.updateUserAttributes(user, {'custom:registered': 'true', 'custom:status': '900'})
                            .then(() => {
                                this.setState({toggleMessageModal: false})
                                window.location.replace('/enrollment')
                            })                            
                    }).catch((error: any) => {
                        if (error.response.status === 409) {
                            // User already exists in Bizor Realm
                            // Notify -> Post Request /BzrDoctor 
                            console.error(error.response.data)
                            // Retry Update user state
                            Auth.updateUserAttributes(user, {'custom:registered': 'true'})
                            this.setState({toggleMessageModal: false})
                            window.location.replace('/enrollment')
                        } 
                    })
                }
            }
        ).catch(
            err => {
                this.setState({cognitoErr: err, submitted: false, toggleMessageModal: false})
                console.log('BAD SIGNIN REQUEST: ' + err.code);
            }
        )
    }    

    handleMfa( values: any ) {
        Auth.confirmSignIn(
            this.state.cognitoUser,   // Return object from Auth.signIn()
            values.code,   // Confirmation code  
            this.state.cognitoUser.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        ).then(
            user => {
                this.setState({cognitoUser: user});
                this.setState({loggedIn: true});
                console.log('USER IS AUTHENTICATED');    
                console.log(user);
            }
        ).catch(
            err => console.log('BAD MFA REQUEST: ' + err.code)
        );
    }   
    
    SignInForm() {  
        return (
            <Formik
                validationSchema={
                    Yup.object().shape({
                        username: Yup.string().required(),
                        password: Yup.string().required()
                    })
                }
                onSubmit={ 
                    (values) => { 
                        this.handleSignIn(values); 
                        this.setState({submitted: true, toggleMessageModal: true}); 
                    }
                }
                initialValues={{ username: '', password: '' }}
            >
                {({
                    handleSubmit, handleChange, handleBlur, values, touched, isValid, errors
                }) =>
                (
                <Form noValidate onSubmit={handleSubmit}>
                    <br />
                    <fieldset disabled={this.state.submitted}>
                    <Form.Group as={Row} controlId="validationCustomEmail">
                        <Form.Label>{t('email')}</Form.Label>
                        <Form.Control   name="username" 
                                        type="text" 
                                        placeholder="ur@e.mail" 
                                        value={values.username}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.username && !!errors.username}
                                        onKeyDown={this.handleKeyDown}
                                        tabIndex={1}
                                        ref={this.getRef}
                                        />
                        <Form.Control.Feedback type="invalid">{t(errors.username)}</Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Form.Group as={Row} controlId="validationCustomPassword">
                        <Form.Label>{t('password')}</Form.Label>
                        <Form.Control   name="password" 
                                        type="password" 
                                        value={values.password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.password && !!errors.password}
                                        tabIndex={2}
                                        ref={this.getRef}
                                        />
                        <Form.Control.Feedback type="invalid">{t(errors.password)}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Row}>
                            <Form.Check className="ml-auto" label={t('remember me')} />
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col lg="12" className="text-right">
                            <Button type="submit" className="text-capitalize">{t('signin')}</Button>
                            <br />
                            <a className="small" href="https://bizor.auth.us-east-2.amazoncognito.com/forgotPassword?response_type=code&client_id=7v153mrhsqurockp8ll2c9sg4s&redirect_uri=https://sphere.bizor.io"> {t('forgot password?')} </a>
                        </Col>
                    </Form.Group>
                    </fieldset>
                </Form>
            )} 
            </Formik>
        );
    }

    MfaForm() {
        return (
            <Formik
                validationSchema={
                    Yup.object().shape({
                        code: Yup.string().required()
                    })
                }
                onSubmit={ 
                    (values) => { 
                        this.handleMfa(values); 
                        this.setState({submitted: true}); 
                    }
                }
                initialValues={{ code: '' }}
            >
                {({
                    handleSubmit, handleChange, handleBlur, values, touched, isValid, errors
                }) =>
                (
                <Form noValidate onSubmit={handleSubmit}>
                    <br />
                    <fieldset disabled={this.state.submitted}>
                    <Form.Group as={Row} controlId="validationCustomCode">
                        <Form.Label >{t('MFA Code')}</Form.Label>
                        <Form.Control   name="code" 
                                        type="text" 
                                        value={values.code}
                                        onBlur={handleBlur}
                                        onChange={handleChange} 
                                        isInvalid={touched.code && !!errors.code} />
                        <Form.Control.Feedback type="invalid">{t(errors.code)}</Form.Control.Feedback>                        
                    </Form.Group>
                    <br />
                    <Form.Group as={Row}>
                        <Col sm="6" className="offset-sm-4">
                            <Button type="submit">{t('Submit')}</Button>
                        </Col>
                    </Form.Group>
                    </fieldset>
                </Form>
                )}
            </Formik>
        );
    }

    render() {
        return (
            <Container className="d-flex justify-content-center">
                {/*this.state.loggedIn ? <Redirect to="/dashboard" /> :  ''*/}
                        {this.state.cognitoErr !== null ? 
							(<Alert variant={'danger'} onClose={() => this.setState({cognitoErr: null})} dismissible>
								<Alert.Heading>Oh snap! You got an error!</Alert.Heading>
								{this.state.cognitoErr.code}: {this.state.cognitoErr.message}
							</Alert>) : null
						} 
                        <Card className="px-1 auth-form">
                            <Card.Body>
                                <h4 className="text-capitalize">{ this.state.mfaEnabled ? t('confirm sign in') : t('sign in') }</h4>
								<br />
                                <div className="mx-4">
                                    { this.state.mfaEnabled ? this.MfaForm() : this.SignInForm() }
                                </div>
                                <hr />
                                <FederatedButtons />
                            </Card.Body>
                            <div className="text-md-right card-out">
                        {t('don\'t have an account yet?')} <Link to="/signup"><b>{t('sign up')}</b></Link>
                        </div>
                        </Card>
                <MessageModal
                    show={this.state.toggleMessageModal}
                    onHide={() => this.setState({toggleMessageModal: false})}
                    message="signing in..."
                />
            </Container>
        );
    }
}

const FederatedButtons = (props: any) => (
    <div className="text-center">
        <a className="mx-3" href="#your-link">
            <FontAwesomeIcon size={"2x"} color="gray" icon={faAmazon} />
        </a> 
        <a className="mx-3" href="#your-link">
            <FontAwesomeIcon size={"2x"} color="red" icon={faGoogle} />
        </a> 
        <a className="mx-3" href="#your-link">
            <FontAwesomeIcon size={"2x"} icon={faFacebook} />
        </a>         
    </div>
)

export default withRouter(Signin);