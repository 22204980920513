import Axios from 'axios'

//const appStates:any = React.useContext(AppStateContext)

class BzrHttp {

    static users(req:any) {
        req.baseURL = process.env.REACT_APP_API_USERS
        return Axios(req)
    }
    static orgs(req:any) {
        req.baseURL = process.env.REACT_APP_API_ORGS
        return Axios(req)
    }
    static records(req:any) {
        req.baseURL = req.baseURL ?? process.env.REACT_APP_API_RECORDS
        return Axios(req)
    }
    static objects(req:any) {
        req.baseURL = process.env.REACT_APP_API_OBJECTS
        return Axios(req)
    }
}

export default BzrHttp