import React, { useState, useEffect } from 'react'
import { I18n } from 'aws-amplify'
import { dict } from './es'
import Badge from 'react-bootstrap/Badge'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'


I18n.putVocabularies(dict);

export function fn(n:any) {
    const r = Number(Number(n).toFixed(2))
    if(isNaN(r)) return '-'
    return r.toLocaleString('es-VE',{minimumFractionDigits: 2}) 
}

export function dn(date:any, locale = 'en-US') {
    return date.toLocaleDateString(locale, { day: '2-digit', month: '2-digit', year: 'numeric'})   
}

export function p(dict:any) { return I18n.putVocabularies(dict)}

export function setI18nLanguage(lang:string) { I18n.setLanguage(lang)}

export function translate(el:any) {
    if(!el) return
    const nodes:any = el.childNodes
    if(!nodes) return
    for(let i:number = 0; i < nodes.length; i++) {
        if(nodes[i].nodeName === '#text'){
            nodes[i].textContent = t(nodes[i].textContent)
        }
        translate(nodes[i])
    }
}

export const locales:any = {
    'es-VE': {code: 'es-VE', countryName: 'venezuela', currency: 'VES', currencySymbol: 'Bs.S', currencyCode: '928', language: 'es', decimalSeparator: ',', thousandSeparator: '.', identificationName: 'RIF', taxName: 'IVA', dateFormat: 'dd-MM-yyyy' },
    'en-US': {code: 'en-US', countryName: 'united states', currency: 'USD', currencySymbol: '$', currencyCode: '840', language: 'en', decimalSeparator: '.', thousandSeparator: ',', identificationName: '', taxName: 'TAX', dateFormat: 'MM/dd/yyyy'  }
}

export const currencyHash:any = {
    '928': {code: 'es-VE', countryName: 'venezuela', currency: 'VES', currencySymbol: 'Bs.S', currencyCode: '928'},
    '840': {code: 'en-US', countryName: 'united states', currency: 'USD', currencySymbol: '$', currencyCode: '840'}
}

export default function t(key?:any) { 
    if(!key) return ''
    key = key.toLowerCase()
    return I18n.get(key) 
}

export const EditableCell = (props:any) => {
    const [highlight, setHighlight] = useState('')
    const [touched, setTouched] = useState(false)
    const [value, setValue] = useState(props.value)
    
    useEffect(() => {
        setValue(props.value)
    },[props.value])

    useEffect(() => {
        if(props.message && !touched) {
            setTouched(true)
            setHighlight('')
        } else if(!props.message && touched) {
            setTouched(false)
        }
        //if(value !== props.value) 
    }, [props.message, highlight, touched])
    
    
    return(
        props.disabled ? 
            <div>{ props.isString ? value : fn(value)} </div>
            :
            <div suppressContentEditableWarning={true}
                className={props.className + " editable-cell " + highlight}
                onClick={(event:any) => {
                    const ele:any = event.target
                    ele.contentEditable="true" 
                    let rng:any = document.createRange()
                    let sel:any = window.getSelection()
                    if(props.isString) {
                        ele.focus()
                    } else if(ele.innerHTML.match(/-/g)) {
                        ele.innerHTML = '0.00'
                        rng.setStart(ele.childNodes[0], 0)
                        rng.setEnd(ele.childNodes[0], 1)                
                        //rng.collapse(true)
                        sel.removeAllRanges()
                        sel.addRange(rng)
                        ele.focus()
                    } else if(ele.innerHTML.match('.') && ele.innerHTML.match(',')) {
                        ele.innerHTML = ele.innerHTML.replace(/\./g, '')
                        ele.innerHTML = ele.innerHTML.replace(/,/g, '.')
                        let pos:any = ele.innerHTML.search(/\./g)
                        rng.setStart(ele.childNodes[0], 0)
                        rng.setEnd(ele.childNodes[0], pos < -1 ? 0 : pos)
                        sel.removeAllRanges()
                        sel.addRange(rng)
                        ele.focus()
                    } 
                }}
                onKeyDown={(event:any) => {
                    event.stopPropagation()
                    const k:number = event.keyCode
                    const pos:any = window.getSelection()?.getRangeAt(0).startOffset
                    if(k === 13) { /* enter */ 
                        event.preventDefault()
                        event.target.blur()
                        console.log('enter')
                    }
                    if(k === 190 && !props.isString) {
                        event.preventDefault()
                        const mtchs:any = event.target.innerHTML.match(/\./g)
                        if(!mtchs) {
                            event.target.innerHTML = `${event.target.innerHTML.slice(0, pos)}.${event.target.innerHTML.slice(pos)}`
                            const rng:any = document.createRange(), sel:any = window.getSelection()
                            rng.setStart(event.target.childNodes[0], pos + 1)
                            rng.collapse(true)
                            sel.removeAllRanges()
                            sel.addRange(rng)
                            event.target.focus()
                        }
                    }
                    if(k === 188 && !props.isString) { /* comma */
                        event.preventDefault()
                    }
                    setHighlight('font-weight-bolder mark')
                }}
                onBlur={(event:any) => {
                    event.stopPropagation()
                    console.log(event.target.innerHTML, parseFloat(event.target.innerHTML), props.isString)
                    if(props.isString) {
                        setValue(event.target.innerHTML)
                        props.liftUpValue(event.target.innerHTML)
                    } else if(!isNaN(parseFloat(event.target.innerHTML))) {
                        setValue(parseFloat(event.target.innerHTML))
                        props.liftUpValue(parseFloat(event.target.innerHTML))
                        event.target.innerHTML = fn(event.target.innerHTML)
                    } else {
                        event.target.innerHTML = '-'
                    }
                    event.target.contentEditable="false"
                }}
                >
                { props.isString ? value : fn(value)}
            </div>  
    )
}

export const FocusDigits = (ele:any) => {
    let rng:any = document.createRange()
    let sel:any = window.getSelection()
    if(ele.innerHTML.match(/-/g)) {
        ele.innerHTML = '0.00'
        rng.setStart(ele.childNodes[0], 0)
        rng.setEnd(ele.childNodes[0], 1)                
        //rng.collapse(true)
        sel.removeAllRanges()
        sel.addRange(rng)
        ele.focus()
    } else if(ele.innerHTML.match('.') && ele.innerHTML.match(',')) {
        ele.innerHTML = ele.innerHTML.replace(/\./g, '')
        ele.innerHTML = ele.innerHTML.replace(/,/g, '.')
        let pos:any = ele.innerHTML.search(/\./g)
        rng.setStart(ele.childNodes[0], 0)
        rng.setEnd(ele.childNodes[0], pos < -1 ? 0 : pos)
        sel.removeAllRanges()
        sel.addRange(rng)
        ele.focus()
    } else {
        ele.focus()
    }
}

export const HelperMsg = (msg:any) => {

    const renderTooltip = (props:any) => (<Tooltip id="button-tooltip" {...props}>{t(msg)}</Tooltip>)
    return(
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 150, hide: 250 }}
            overlay={renderTooltip}
        >
            <Badge className="helper-msg" variant="secondary">?</Badge>
        </OverlayTrigger>
    )
}

export const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"]