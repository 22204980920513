import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { History, LocationState } from "history"
import { Form, Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { Formik } from 'formik'
import * as Yup from 'yup'
import FormInputs from '../../layouts/Forms/FormInputs'

import BzrAxios from '../../utils/BzrAxios'

//import t from '../../utils/I18n/I18n'

interface Props extends RouteComponentProps<LocationState>{
	history: History<LocationState>,
	singleSubmit?: any,
	appStates: any,
	liftUpAppStates: any,
	disableFields: boolean,
	disableFieldsHandler: any,
	params: any,
	idn: any
}

interface State {
	formError: any,
	displayButton: boolean,
	submitting: boolean
}

class OrgForm extends React.Component<Props,State> {

	constructor(props: Props) {
		super(props)
        this.state = {
			formError: null,
			displayButton: props.appStates.org && props.appStates.user.userStatus < 1900 ? false : true,
			submitting: false
		}
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidMount() {
		setTimeout(() => {
			const firstInput:any = document.getElementsByName('orgName')
			firstInput[0]?.focus()
		}, 2000)
	}
	handleSubmit(data: any) {
		this.setState({submitting: true})
		if(this.props.disableFieldsHandler) this.props.disableFieldsHandler(true)
		this.props.liftUpAppStates({submitting: true})

		// -> design a function for App/User/Org Status handling
		let method = 'POST', url = '/'
		if(this.props.appStates.user.userStatus === 1500 && method?.match(/post/i)) {
			data['creatorId'] = this.props.appStates.user.id
			data['userStatus'] = 1700
		} else {
			method = 'PUT'
			url = `/${this.props.appStates.org.id}`
		}
        data['updaterId'] = this.props.appStates.user.id
		BzrAxios.orgs({ url, method, data })
		.then((response: any) => {
			this.setState({displayButton: false, submitting: false})
			this.props.liftUpAppStates({org: response.data.org, user: response.data.user, submitting: false})
		}).catch((error: any) => {
			this.setState({submitting: false})
			this.props.liftUpAppStates({submitting: false})
			console.error(error)
		})
	}

	render() {
		const org = this.props.appStates.org ?? {}
		return (
			<Formik
				validationSchema={
                    Yup.object({
					orgName: Yup.string().required(),
					orgShortDesc: Yup.string()
				})}
				onSubmit={this.handleSubmit}
				initialValues={{
                    orgName: org.orgName ?? '', 
					orgShortDesc: org.orgShortDesc ?? ''
				}}
				enableReinitialize={true}
			>
				{({
				handleSubmit,
				handleChange,
				handleBlur,
				values,
				touched,
				isValid,
				errors,
				}) => (
				<Form noValidate onSubmit={handleSubmit} id={"org-form-" + this.props.idn} >
					<fieldset disabled={this.props.disableFields || this.props.appStates.submitting || !this.state.displayButton}>
					<FormInputs
						ncols={["12"]}
						className={["mx-auto px-1 form-control-resize"]}
						properties={[
							{
								label: "Organization Name",
								feedback: "",
								fieldType: "text",
								name: "orgName",
								value: values.orgName,
								onChange: handleChange,
								isValid: touched.orgName && !errors.orgName,
								isInvalid: !!errors.orgName,
								error: errors.orgName,
								autoFocus: true
							}
						]}
                    />
					<FormInputs
						ncols={["12"]}
						className={["mx-auto px-1 form-control-resize"]}
						properties={[
							{
								label: "Short Description",
								feedback: "",
								fieldType: "text",
								name: "orgShortDesc",
								value: values.orgShortDesc,
								onChange: handleChange,
								isValid: touched.orgShortDesc && !errors.orgShortDesc
							}
						]}
                    />
					<Row>
						<Col md="5" className="mx-auto mt-4 text-center">
							<Button 
								type="submit" 
								id={this.props.params.id + "-form-submit-" + this.props.idn}
								className="single-submit"
								style={ this.props.singleSubmit || 
										this.props.appStates.submitting || 
										this.props.disableFields ||
										!this.state.displayButton
										? {display: "none"}: {display: "inline"} } >
									Submit
							</Button>							
							{
								this.props.appStates.submitting && this.state.submitting ? <div><h5>submitting... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
							}
						</Col>
					</Row>

					</fieldset>
				</Form>
				)}
			</Formik>
		)
	}
}

export default withRouter(OrgForm)