import React from 'react'
import { Switch, withRouter, RouteComponentProps } from 'react-router-dom'
import { guestRoutes, getRoutes } from '../routes'
import { History, LocationState } from "history"

import TopNavbar from './Navbars/TopNavbar'
import Footer from './Footer/Footer'

interface Props extends RouteComponentProps<LocationState>{
    history: History<LocationState>,
    user: any
}

class Guest extends React.Component<Props> {
    render(){
        return(
            <div>
                <TopNavbar user={this.props.user}/>
                <div className="main-container my-5">
                <Switch>{getRoutes(guestRoutes, {user: this.props.user, liftUpAppStates: null})}</Switch>
                </div>
                <Footer />
            </div>
        )
    }
}
export default withRouter(Guest)