import React from 'react'
import BzrAxios from '../../../utils/BzrAxios'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import FormInputs from '../../../layouts/Forms/FormInputs'
//import FileInput from  '../../Common/FileInput'
import t, { fn } from '../../../utils/I18n/I18n'

interface Props {
    fullOrder:any
    liftUpFullOrder:any
    appStates:any
    onSubmitted?:any
    onClose?:any
}
interface State {
    currency:string
    attachment:any
}
// order.subTotalUSD = r.billedCurrency !== '840' ? (parseFloat(r.subTotal)/parseFloat(r.changeRate)) : parseFloat(r.subTotal)
class RegisterPayment extends React.Component<Props,State> {
    constructor(props:Props) {
        super(props)
        this.state = {
            currency: this.props.fullOrder.saleOrder.billedCurrency,
            attachment: null
        }
        this.handlePaymentRegistry = this.handlePaymentRegistry.bind(this)
        console.log(this.props.fullOrder)
    }

    handlePaymentRegistry(data:any, formikBag:any) {
        const fullOrder:any = this.props.fullOrder
        data.order = fullOrder.saleOrder
        data.paymentDate = data.paymentDate.toISOString()
        console.log('POST BODY', data)
        BzrAxios.records({url: `/SaleOrders/${fullOrder.saleOrder.id}/Payments`, method: 'POST', data})
        .then((response:any) => {
            fullOrder.saleOrder.paymentStatus  = response.data.saleOrder.paymentStatus
            fullOrder.saleOrder.paidAmount  = response.data.saleOrder.paidAmount
            this.props.liftUpFullOrder(fullOrder)
            console.log('RESPONSE', response.data)
            formikBag.resetForm()
        })
        .catch(err => console.log(err))
    }
    checkBillStatus(order:any) {
        const prefixes:any = this.props.appStates.activeBU.preferences.prefixes[this.props.appStates.activeBU.locale],
            match:any = order.status.match(/credit|debit/)

        if(!!match) order.status = match[0]
        switch(order.status) {
            case 'printed_legal':
                return `${prefixes.pl}-${order.billNumber}`
            case 'printed_receipt':
                return `${prefixes.pr}-${order.orderNumber}` 
            default:
                return ''
        }
    }
    renderBalance() {
        const order:any = this.props.fullOrder.saleOrder
        let paidAmount = order.paidAmount ? parseFloat(order.paidAmount) : 0
        let total = this.state.currency !== order.billedCurrency ? (parseFloat(order.total)/parseFloat(order.changeRate)) : parseFloat(order.total)
        if(this.state.currency === '840') {
            paidAmount = order.billedCurrency === this.state.currency ? paidAmount : paidAmount / order.changeRate
        } else {
            paidAmount = order.billedCurrency === this.state.currency ? paidAmount : paidAmount * order.changeRate 
        }
        return(
            <>
                <h4>balance</h4>
                <table className="bzr-mini-table w-100">
                    <tbody>
                        <tr><td><b>{t('total')} + {t('tax')}</b></td><td className="text-right">{fn(total)}</td></tr>
                        <tr className="border-bottom border-secondary"><td><b>{t('paid')}</b></td><td className="text-right">{fn(paidAmount)}</td></tr>
                        <tr><td><b>{t('debt')}</b></td><td className="text-right">{fn(total - paidAmount)}</td></tr>
                    </tbody>
                </table>
            </>
        )
    }
    render() {
        return (
            <div className="my-5 mx-3">
                <div className="d-flex">
                    <div className="p-2 flex-grow-1"><h2>{t(`payments`)}</h2></div>
                    <div className="p-2">
                        <span className="h5">{t('invoice to pay')}: {this.checkBillStatus(this.props.fullOrder.saleOrder)} </span><br />
                        <span className="h6">{t('client')}: {this.props.fullOrder.client.legalName} </span> <br />
                        <span className="h6">{t('invoice date')}: {new Date(this.props.fullOrder.saleOrder.date).toLocaleString(this.props.appStates.activeBU.locale)} </span>     
                    </div>
                </div>
                <hr />
                <div className="d-flex bd-highlight">
                    <div className="p-2 bd-highlight w-50">
                        <Formik
                            validationSchema={
                                Yup.object({
                                    amount: Yup.string().required(),
                                })
                            }
                            onSubmit={this.handlePaymentRegistry}
                            initialValues={{
                                isPartial: false,
                                paymentDate: new Date(),
                                amount: 0,
                                paymentType: 'cash',
                                paymentSource: '',
                                paymentRef: '',
                                attachment: '',
                                isTaxDeductible: false,
                                taxAmount: 0,
                                remarks: '',
                                currency: this.state.currency
                            }}
                            >
                            {
                                ({
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                isSubmitting,
                                values
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit} id={'inventory-entry-form'}>
                                        <FormInputs
                                            ncols={['4','4','4']}
                                            className={['px-2','px-2','px-2']}
                                            properties={[
                                                {
                                                    label: 'amount',
                                                    feedback: ' ',
                                                    fieldType: 'text',
                                                    name: 'amount',
                                                    className: `text-right`,
                                                    value: values.amount,
                                                    onChange: handleChange
                                                },
                                                {
                                                    label: 'paymentRef',
                                                    feedback: ' ',
                                                    fieldType: 'text',
                                                    name: 'paymentRef',
                                                    value: values.paymentRef,
                                                    onChange: handleChange
                                                },
                                                {
                                                    label: 'date',
                                                    feedback: ' ',
                                                    fieldType: 'date',
                                                    name: 'paymentDate',
                                                    className: 'text-right',
                                                    value: values.paymentDate,
                                                    onChange: setFieldValue
                                                }
                                            ]}
                                        />
                                        <br />
                                       {/*  <FormInputs
                                            ncols={['6', '6']}
                                            className={['px-2', 'px-2']}
                                            properties={[
                                                {
                                                    label: 'taxAmount',
                                                    horizontalLabel: '6',
                                                    feedback: ' ',
                                                    fieldType: 'text',
                                                    name: 'taxAmount',
                                                    className: `text-right`,
                                                    value: values.taxAmount,
                                                    onChange: handleChange
                                                },
                                                {
                                                    label: 'tax deductible',
                                                    horizontalLabel: '6',
                                                    fieldType: 'checkbox',
                                                    name: 'isTaxDeductible',
                                                    value: values.isTaxDeductible,
                                                    defaultChecked: false,
                                                    onChange: (event:any) => {values.isTaxDeductible = event.target.checked}
                                                }
                                            ]}
                                        /> */}
                                        <br />
                                        <FormInputs
                                            ncols={['4', '4', '4']}
                                            className={['px-2', 'px-2', 'px-2']}
                                            properties={[
                                                {
                                                    label: 'type',
                                                    fieldType: 'select',
                                                    name: 'paymentType',
                                                    value: values.paymentType,
                                                    options: [
                                                        ['cash', 'cash'],
                                                        ['check', 'check'],
                                                        ['transfer', 'transfer'],
                                                        ['other', 'other']
                                                    ],
                                                    onChange: handleChange
                                                },
                                                {
                                                    label: 'source',
                                                    fieldType: 'select',
                                                    name: 'paymentSource',
                                                    value: values.paymentSource,
                                                    options: [
                                                        ['bank', 'bank'],
                                                        ['personal', 'personal']
                                                    ],
                                                    onChange: handleChange
                                                },
                                                {
                                                    label: 'currency',
                                                    fieldType: 'select',
                                                    name: 'currency',
                                                    value: values.currency,
                                                    options: [
                                                        ['840', 'USD'], 
                                                        ['928', 'VES']
                                                    ],
                                                    onChange: (event:any) => {
                                                        handleChange(event)
                                                        setTimeout(() => this.setState({currency: event.target.value}),500)
                                                    }
                                                }
                                            ]}
                                        />
                                        <br />
                                        <FormInputs
                                            ncols={['8']}
                                            className={['px-2']}
                                            properties={[
                                                {
                                                    label: 'remarks',
                                                    feedback: ' ',
                                                    fieldType: 'textarea',
                                                    name: 'remarks',
                                                    value: values.remarks,
                                                    onChange: handleChange
                                                }
                                            ]}
                                        />
                                        {/* <div className="px-3 w-50">
                                            <FileInput id="attachment-file-input" label="attachment" className="px-2" handleFile={(attachment:any) => {
                                                this.setState({attachment})
                                                console.log(attachment)
                                                }} />
                                        </div> */}
                                        <br />
                                        <div className="p-3 text-center">
                                            <Button 
                                                type='submit' 
                                                id='inventory-entry-submit' 
                                                style={ isSubmitting ? {display: 'none'}: {display: 'inline'} } >
                                                    Submit
                                            </Button>
                                        </div>
                                        {
                                            isSubmitting ? <div><h5>{t('submitting')}... <FontAwesomeIcon icon={faSpinner} spin /></h5></div> : null
                                        }
                                    </Form> 
                                )
                            }
                        </Formik>
                    </div>
                    <div className="p-2 w-50 bd-highlight">
                        <h4>registered payments</h4>
                        ...
                        <hr />
                        { this.renderBalance() }    
                    </div>
                </div>
            </div>
        )
    }
}

export default RegisterPayment