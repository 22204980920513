import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { History, LocationState } from "history"
import BzrAxios from '../../utils/BzrAxios'
import { Container, Row, Col, Table, Form, ButtonGroup, Button, DropdownButton } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import CBList from '../Common/CBList'
import CBList2 from '../Common/CBList2'
import { printSaleOrder, printDeliveryNote, printDispatchNote} from '../Printer/PdfPrinter'
import { PreviewModal, MessageModal, ParentModal } from '../../layouts/Modals/Modals'
import ClientForm from '../Client/ClientForm'
import FormInputs from '../../layouts/Forms/FormInputs'
import PreviewCard from '../Common/PreviewCard'
import t, {fn, translate, locales, EditableCell, HelperMsg } from '../../utils/I18n/I18n'
import { getCookie, setCookie } from '../../utils/Cookies'


interface Props extends RouteComponentProps {
    history: History<LocationState>,
    appStates: any,
    products: any,
	liftUpAppStates: any,
}

interface States {
    order:any
    postOrder:any
    duplicatedOrder:boolean
    preview:any
    options:any
    pdfFile:any
    productValue:string
    quantity:number
    product:any
    priceEntry:any
    items:any
    itemsChange:any
    clients:any[]
    client:any
    autoGenerate:boolean
    autoIncrement:boolean
    generateDoc:any
    previewURL:string
    deliveryNoteURL:string
    submitting:any
    verifiying:boolean
    displayTotals:boolean
    changeRate:any
    billedIn:any
    facilityValue:any
    facility:any
    facilities:any[]
    addNewClient:boolean
    animate:string
    seqRefs: any
    retailed: boolean
    date: any
    showRemarks: boolean
    doptions: any
    bzrInventoryBookId:any
    cblistFilters:any
    bcvRef:any
    loaded:boolean
    updateOrder:boolean
}

class NewSale extends React.Component<Props,States> {
    
    message:any = {preview: 'loading preview', bill: 'billing', quote: 'quoting'}
    timer:any = null
    initTransacs:any[] = []
    fromStatus:string = ''
    printOptions:any = null
    
    constructor(props: Props) {
        super(props)
        this.state = {
            order: { subTotal: 0, tax1: 0, taxable: 0, exempt: 0, credits: 0, discounts: 0, total: 0, purchaseOrderNumber: '', orderNumber: '', billNumber: '', controlNumber: '', paymentCondition: '0', remarks: '' },
            postOrder: null,
            duplicatedOrder: false,
            preview: null,
            options: [],
            pdfFile: null,
            productValue: '',
            quantity: 0,
            product: null,
            priceEntry: {entryValue: 0, bookId: ''},
            items: [],
            itemsChange: [],
            clients: [],
            client: null,
            autoGenerate: false,
            autoIncrement: true,
            generateDoc: null,
            previewURL: '',
            deliveryNoteURL: '',
            submitting: null,
            verifiying: false,
            displayTotals: false,
            changeRate: 1,
            billedIn: {code: 'en-US'},
            facilityValue: '',
            facility: null,
            facilities: [],
            addNewClient: false,
            animate: 'none',
            seqRefs: null,
            retailed: false,
            date: null,
            showRemarks: false,
            doptions: {letterhead: true, printUsd: true, printLocal: false, currencyCode: 840, locale: props.appStates.activeBU.locale},
            bzrInventoryBookId: '',
            cblistFilters: ['description', 'sku'],
            bcvRef: null,
            loaded: true,
            updateOrder:false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSOChange = this.handleSOChange.bind(this)
        this.handleOnKeyUp = this.handleOnKeyUp.bind(this)
        this.handlePartyInput = this.handlePartyInput.bind(this)
        this.getPreview = this.getPreview.bind(this)
        this.getCBValue = this.getCBValue.bind(this)
        this.addItem = this.addItem.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePreSubmit = this.handlePreSubmit.bind(this)
        this.displayTotalsBag = this.displayTotalsBag.bind(this)
        this.changeCurrency = this.changeCurrency.bind(this)
        this.handleRateChange = this.handleRateChange.bind(this)
        this.handleNewClient = this.handleNewClient.bind(this)
    }

    async componentDidMount() {
        const match:any = this.props.match
        if(match.params && match.params.id) this.setState({updateOrder: true, loaded: false})
        const el:any = document.getElementById('product-new-sale')
        translate(el)
        // GET orderNumber Seq
        const order:any = this.state.order
        try {
            const response:any = await BzrAxios.orgs({url: `/ActiveBU/SeqRefs`})
            const { billSeq, controlSeq, orderSeq } = response.data.seqRefs
            order.orderNumber = this.incOrderNumber(orderSeq)
            order.billNumber = this.incOrderNumber(billSeq)
            order.controlNumber = this.incOrderNumber(controlSeq)
            this.setState({seqRefs: response.data.seqRefs})
        } catch(err) {
            console.error(err)
            alert('series not updated, press F5 to update')
            return
        }
        //  GET INDIVIDUALS
        if(!this.props.appStates.individuals) {
            BzrAxios.records({url: `/BzrIndividual`})
            .then((response:any) => this.props.liftUpAppStates({individuals: response.data.records}))
            .catch(err => console.log(err))
        }
        // GET Clients
        let clients:any = null
        if(this.props.appStates.clients) {
            clients = this.props.appStates.clients
            this.setState({clients})
        } else {
            try {
                const response:any = await BzrAxios.records({url: `/BzrClient?qorderby=legalName`})
                clients = response.data.records
                this.props.liftUpAppStates({clients, clientFields: response.data.fields})
                this.setState({clients})
            } catch(err) {
                console.error(err)
                return
            }
        }
        // SET Date - ChangeRate - Bill Currency
        const changeRate:any = getCookie("bzr-change-rate")
        this.setState({
            billedIn: locales[this.props.appStates.activeBU.locale],
            changeRate: changeRate !== '' ? Number(changeRate) : 0,
            date: new Date()
        })

        //  IF RELOAD
        if(match.params && match.params.id) {
            try {
                const response:any = await BzrAxios.records({url: `/SaleOrders/${match.params.id}`}), 
                { saleOrder, transacs } = response.data

                if(!!saleOrder.status.match(/printed_/)) {
                    this.props.history.push('/admin/sales/transacs')
                    return
                }
                // SET Client
                const client = clients.find((c:any) => c.id === saleOrder.clientId)
                this.setState({client})
                let facilities:any = []
                try {
                    // Get facilities
                    const { data } = await BzrAxios.records({url: `/BzrClientFacility?clientId=${client.id}`})
                    facilities = data.records
                    const facility:any = facilities.find((f:any) => f.id === saleOrder.facilityId)
                    if(facility) this.setState({facility, facilityValue: facility.facilityName})
                } catch(err) {
                    console.log(err)
                }

                /////////////
                while(!this.props.products) {}
                transacs.forEach((t:any) => {
                    const product:any = Object.assign({}, this.props.products.find((p:any) => p.id === t.bzrProductId))
                    delete product.id
                    this.initTransacs.push(Object.assign({}, t, product))
                    t = Object.assign(t, product)
                })  
                this.setState({
                    items: transacs,
                    changeRate: saleOrder.changeRate,
                    retailed: saleOrder.retailed === 'true'
                })
                setTimeout(() => {
                    this.totalize(transacs, saleOrder.changeRate, saleOrder.billedCurrency)
                    this.setState({retailed: saleOrder.retailed === 'true', loaded: true})
                }, 100)
        
               // GET orderNumber Seq
               /* order.orderNumber = saleOrder.orderNumber
               order.purchaseOrderNumber = saleOrder.purchaseOrderNumber
               order.paymentCondition = saleOrder.paymentCondition
               order.remarks = saleOrder.remarks
               order.status =  */
               this.fromStatus = saleOrder.status
                
                window.addEventListener('scroll', this.displayTotalsBag)
                this.setState({
                    order: saleOrder,
                    facilities,
                    bzrInventoryBookId: saleOrder.bzrInventoryBookId,
                    billedIn: locales[this.props.appStates.activeBU.locale],
                    date: new Date()
                })
            } catch(err) {
                console.error(err)
                alert('wrong request - wrong sale order')
                return
            }
        }

        window.addEventListener('scroll', this.displayTotalsBag)
        fetch('https://s3.amazonaws.com/dolartoday/data.json',{method: 'GET'})
        .then((res:any) => res.json()).then((res:any) => this.setState({bcvRef: res['USD']['sicad1'] > res['USD']['sicad2'] ? res['USD']['sicad1'] : res['USD']['sicad2'] }))
        this.printOptions = this.props.appStates.activeBU.preferences.printer ? this.props.appStates.activeBU.preferences.printer : {subHeaderOffset: 50}
    }
    componentDidUpdate() {
        if(this.props.appStates.bookNames && this.props.appStates.bookNames.inventory && !this.state.bzrInventoryBookId) {
            this.setState({bzrInventoryBookId: this.props.appStates.bookNames.inventory.default})
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.displayTotalsBag)
    }
    handleChange(event: any) {
        if(event.target.name === 'orderNumber') {
            const order = this.state.order
            if(this.state.duplicatedOrder) this.setState({duplicatedOrder: false})
            order['orderNumber'] = event.target.value
            this.setState({order})
        } else if(event.target.name === 'controlNumber') {
            const order = this.state.order
            if(this.state.duplicatedOrder) this.setState({duplicatedOrder: false})
            order['controlNumber'] = event.target.value
            this.setState({order})
        } else if(event.target.name === 'paymentCondition') {
            const order = this.state.order
            order['paymentCondition'] = event.target.value
            this.setState({order})
        } else if(event.target.name === 'purchaseOrderNumber') {
            const order = this.state.order
            order['purchaseOrderNumber'] = event.target.value
            this.setState({order})
        } else if(event.target.name === 'remarks') {
            const value:any = event.target.value
            if(value.length > 300 || value.split(/\r\n|\r|\n/).length > 4 || (value.split(/\r\n|\r|\n/)[3] && value.split(/\r\n|\r|\n/)[3].length > 74)) {
                alert(t('content to large'))
                event.target.blur()
                return
            }
            const order = this.state.order
            order['remarks'] = value
            this.setState({order})
        } else if(event.target.name === 'entryValue') {
            const priceEntry = {entryValue: event.target.value, bookId: '0'} 
            this.setState({priceEntry})
        } else {
            const newState = { [event.target.name]: event.target.value } as Pick<States, keyof States>
            this.setState(newState)
        }
    }
    handleSOChange(event: any) {
        const billSeq:string = event.target.value
        const order = this.state.order
        order['billNumber'] = billSeq
        this.setState({order})
    }
    handleOnKeyUp(event: any) {
        if(event.keyCode === 13) {
            const id = event.currentTarget.id
            let item:any
            switch(id) {
                case 'quantity-input':
                    const elq:any = document.getElementById('qty-verifier')
                    if(event.target.value > 0) {
                        elq.style.display = "none"
                        item = document.getElementById('price-input')
                        item.focus()
                    } else {
                        elq.style.display = "block"
                    }
                    break
                case 'price-input':
                    const elp:any = document.getElementById('qty-verifier')
                    if(event.target.value > 0) {
                    elp.style.display = "none"
                    item = document.getElementById('add-item-btn')
                    item.focus()
                    } else {
                        elp.style.display = "block"
                    }
                    break
            }
        } 
    }
    async handlePreSubmit(docType:string) {
        if(this.state.client && !this.state.client.id) {
            alert(t('select a client from the list or create a new one'))
            this.setState({client: {legalName: null}})
            setTimeout( () => {
                const cbc:any = document.getElementById('cblist-client')
                cbc.value = ''
                cbc.focus()
            }, 750)
            return
        }
        if(this.state.items.length === 0) {
            // Display MESSAGE -> ORDER CANNOT BE EMPTY
            this.setState({submitting: null, generateDoc: null})
            alert(t('add one item at least'))
            return
        }
        if(!this.state.client) {
            // Display MESSAGE -> ORDER CANNOT BE EMPTY
            this.setState({submitting: null, generateDoc: null})
            alert(t('add client'))
            return
        }
        this.setState({submitting: this.message[docType], generateDoc: docType})

        const recordHeader:any = {}
        recordHeader['orgId'] = this.props.appStates.org.id
		recordHeader['businessUnitId'] = this.props.appStates.activeBU.id
		recordHeader['recordName'] = 'BzrSaleOrder_' + this.state.order.orderNumber
        recordHeader['creatorId'] = recordHeader['updaterId'] = this.props.appStates.user.id
        
        const order:any = this.state.order
        order['date'] = this.state.date.toISOString()

        ////////    KEEP IN MIND THIS IMPLEMENTATION
        if(!this.state.updateOrder) order.status = docType
        order['taxRate1'] = this.props.appStates.activeBU.legals.vat.rate
        /////////////////////////////////////////////////////////////////

        const items = this.state.items

        const client:any = this.state.client
        const facility = this.state.facility ?? {}
        if(facility.id) client.facility = facility
        else if(this.state.facilityValue) {
            facility.facilityName = this.state.facilityValue
            client.facility = facility
        }
        
        const blob = printSaleOrder(order, items, client, {letterhead: false, mediaType: 'paper-letter', locale: this.props.appStates.activeBU.locale, billedIn: this.state.billedIn, subHeaderOffset: this.printOptions.subHeaderOffset})
        this.setState({previewURL: URL.createObjectURL(blob), submitting: null})
    }
    async handleSubmit(status:string, from:string) {
        if(this.state.items.length === 0) {
            // Display MESSAGE -> ORDER CANNOT BE EMPTY
            return
        }

        this.setState({submitting: 'processing', generateDoc: `after-bill-${from}`})

        const recordHeader:any = {}
        recordHeader['orgId'] = this.props.appStates.org.id
		recordHeader['businessUnitId'] = this.props.appStates.activeBU.id
        recordHeader['creatorId'] = recordHeader['updaterId'] = this.props.appStates.user.id
        
        const order:any = this.state.order
        order['date'] = this.state.date.toISOString()
        order['billedCurrency'] = this.state.billedIn.currencyCode
        order['changeRate'] = this.state.changeRate
        order['bookId'] = this.state.client.priceBookId
        order['retailed'] = this.state.retailed
        order['locale'] = this.state.billedIn.code
        order['bzrInventoryBookId'] = this.state.bzrInventoryBookId
        order['sellerId'] = this.state.client.sellerId ? this.state.client.sellerId : null
        order['sellerPaymentStatus'] = this.state.client.sellerId ? `not_paid` : null
        order['sellerCommission'] = this.state.client.sellerId ? this.state.client.sellerCommission : null
        if(!order.paymentCondition) order.paymentCondition = '0'
        ////////    CHECK THIS IMPLEMENTATION
        order['status'] = status
        /////////////////////////////////////////////////////////////////
        
        ////////     PAYMENT VARIABLES
        order['paymentStatus'] = 'not_paid'
        order['paidAmount'] = '0'
        /////////////////////////////////////////////////////////////////
        const prefixes:any = this.props.appStates.activeBU.preferences.prefixes[this.props.appStates.activeBU.locale]
        if(status === 'issued_legal') {
            order['billPrefix'] = prefixes.pl
            order['controlPrefix'] = prefixes.pt
        }
        order['orderPrefix'] = prefixes[this.mapPrefixes(status)]
        order['notePrefix'] = prefixes.dn

        /////////
        if(status === 'printed_receipt' || status === 'printed_consignment') {
            order.taxable = 0
            order.tax1 = 0
            order.taxRate1 = 0
            order.total = order.subTotal
        }
        
        const data:any = {
            recordHeader,
            order,
            items: this.state.items.map((item:any) => {
                const i:any = Object.assign({}, item)

                i.unitsPerPkg = i.unitsPerPkg ? i.unitsPerPkg : 'na'  ///   Necesary to match insert backend command

                return i
            }),
            client: this.state.client,
            initTransacs: this.initTransacs,
            fromStatus: this.fromStatus
        }
        const facility = this.state.facility ?? {}
        if(facility.id) data.facility = facility
        else if(this.state.facilityValue) {
            facility.facilityName = this.state.facilityValue
            data.facility = facility
        }

        // concatenate transac + product objects

        const items:any = this.state.items,
            url = status === 'printed_consignment' ? '/SaleConsignments' : `/SaleOrders${this.state.updateOrder ? '/' + this.state.order.id : ''}`,
            method = this.state.updateOrder ? 'PUT' : 'POST'

        BzrAxios.records({ url, method, data })
		.then((response: any) => {
            const { saleOrder, transacs, inventoryEntries } = response.data
            console.log(saleOrder, transacs, inventoryEntries)
            const blob = printSaleOrder(saleOrder, items, this.state.client, {letterhead: false, mediaType: 'paper-letter', locale: this.props.appStates.activeBU.locale, billedIn: this.state.billedIn, subHeaderOffset: this.printOptions.subHeaderOffset})

            this.setState({
                postOrder: {
                    client: this.state.client,
                    saleOrder, 
                    transacs: items,
                    options: {}
                }
            })

			const activeBU = this.props.appStates.activeBU
            activeBU.billSeq = response.data.seqRefs.billSeq
            activeBU.controlSeq = response.data.seqRefs.controlSeq
            activeBU.orderSeq = response.data.seqRefs.orderSeq
            activeBU.creditSeq = response.data.seqRefs.creditSeq
            activeBU.debitSeq = response.data.seqRefs.debitSeq
            activeBU.quoteSeq = response.data.seqRefs.quoteSeq
            
            ///////  UPDATE INVENTORY BOOK LOCALLY
            if(!!saleOrder.status.match(/printed/) || !!saleOrder.status.match(/issued/)) {
                const books:any = this.props.appStates.books
                transacs.forEach((t:any) => {
                    books[saleOrder.bzrInventoryBookId][t.bzrProductId] = inventoryEntries.find((entry:any) => entry.bzrProductId === t.bzrProductId)
                })
            }

            this.setState({
                seqRefs: response.data.seqRefs,
                previewURL: URL.createObjectURL(blob), 
                submitting: null
            })

		}).catch((error: any) => {
			console.error(error)
		})
    }
    handlePrintLegal() {
        this.setState({submitting: 'printing'})

        const data:any = { status: this.state.postOrder.saleOrder.status.replace('issued','printed') }
        // PRINCIPAL ACTION: CHANGE ORDER STATUS TO PRINTED_LEGAL

        BzrAxios.records({url: `/SaleOrders/Status/${this.state.postOrder.saleOrder.id}`, method: 'PUT', data})
        .then((response:any) => {
            const postOrder:any = Object.assign(this.state.postOrder, response.data)
            postOrder.saleOrder = response.data.saleOrder

            postOrder.transacs = postOrder.transacs.map((t:any) => Object.assign({}, this.props.products.find((p:any) => p.id === t.bzrProductId), t))
            console.log(postOrder.transacs)
            const blob = printSaleOrder(response.data.saleOrder, postOrder.transacs, postOrder.client, {letterhead: false, mediaType: 'paper-letter', locale: this.props.appStates.activeBU.locale, billedIn: this.state.billedIn, subHeaderOffset: this.printOptions.subHeaderOffset}),
                previewURL = URL.createObjectURL(blob)

            if(postOrder.inventoryEntries) {
                const books:any = this.props.appStates.books
                postOrder.inventoryEntries.forEach((entry:any) => {
                    books[this.state.bzrInventoryBookId][entry.bzrProductId] = entry
                })
            }
            
            this.setState({ previewURL, postOrder, submitting: null })
            // Open New Tab
            const w:any = window.open()
            w.location.href = previewURL
            setTimeout(() => w.print(), 1000)
        })
        .catch((err) => {
            console.log(err)
            this.setState({submitting: null})
        })
    }
    async handleShowLegal() {
        const { saleOrder, client, transacs} = this.state.postOrder
        console.log(transacs)
        const blob = printSaleOrder(saleOrder, transacs, client, {letterhead: false, mediaType: 'paper-letter', locale: this.props.appStates.activeBU.locale, billedIn: this.state.billedIn, subHeaderOffset: this.printOptions.subHeaderOffset})
        this.setState({ previewURL: URL.createObjectURL(blob) })
    }
    async handleShowDigital() {
        const { saleOrder, client, transacs} = this.state.postOrder
        console.log(transacs)
        const blob = printSaleOrder(saleOrder, transacs, client, {letterhead: true, mediaType: 'digital-letter', locale: this.props.appStates.activeBU.locale, billedIn: this.state.billedIn, subHeaderOffset: this.printOptions.subHeaderOffset})
        this.setState({ previewURL: URL.createObjectURL(blob) })
    }
    handleNewClient(state:any) {     
        this.setState({addNewClient: false, clients: state.clients})
        this.props.liftUpAppStates({clients: state.clients})
    }
    handleRateChange(event:any) {
        const changeRate:any = String(event.target.value).replace(/,/g,'.')
        this.setState({changeRate})
        
        if(this.timer) window.clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            this.totalize(this.state.items, parseFloat(changeRate))
            setCookie('bzr-change-rate', parseFloat(changeRate), 30)
            this.timer = null
        }, 750)
    }
    handlePartyInput(party:any) {
        this.setState({client: Object.assign({}, party ?? {}), animate: 'flash-text'})
        if(party && party.id) {
            const books:any = this.props.appStates.books
            
            if(this.state.preview)
                this.setState({
                    priceEntry: books[party.priceBookId][this.state.preview.id] ?? 0
                })

            setTimeout(() => {
                const items:any = this.state.items
                items.forEach((item:any) => {
                    const newPrice = books[party.priceBookId][item.id] ?? 0
                    item.entryValue = newPrice.entryValue
                    item.amount = parseFloat(item.entryValue) * parseFloat(item.quantity)
                })
                this.totalize(items, this.state.changeRate)
            }, 100) 
            setTimeout(() => this.setState({animate: 'none'}), 1500)    
            
            // Get facilities
            BzrAxios.records({url: `/BzrClientFacility?clientId=${party.id}`})
            .then((response: any) => this.setState({facilities: response.data.records}))
            .catch((error: any) => console.error(error))
        }
    }
    incOrderNumber(on:any, sep:string = '-') {
        on = parseInt(on.replace(/\W+/g,''))
        on++
        if(on.length > 6) {
            on = on.toString().padStart(8, '0')
            on = on.slice(0,2) + sep + on.slice(2)
        } else on = on.toString().padStart(6, '0')
        return on
    }
    changeCurrency(event:any) {
        const changeRateCK:any = getCookie("bzr-change-rate") !== '' ? Number(getCookie("bzr-change-rate")) : 1,
            billedIn:any = locales[event.target.value],
            changeRate = event.target.value === 'en-US' ? 1 : changeRateCK

        this.setState({billedIn, changeRate})
        this.totalize(this.state.items, changeRate, billedIn.currencyCode)
    }
    displayTotalsBag() {
        if(this.timer) window.clearTimeout(this.timer)
        this.timer = setTimeout(() => {
            const rect:any = document.getElementById('totals-box')?.getBoundingClientRect()
            if(!rect) return
            if(rect.top > window.innerHeight - 125) this.setState({displayTotals: true})
            else this.setState({displayTotals: false})
        }, 500)
    }
    updateOrder = (state:any) => {
        const keys:string[] = Object.keys(state),
            values:any = Object.values(state),
            order = this.state.order
		keys.forEach((key, i) => {
            order[key] = values[i]
            this.setState({order: order})			
		})
    }
    getPreview(preview:any) {
        if(preview) {
            const books:any = this.props.appStates.books
            let priceEntry:any = books[this.props.appStates.bookNames.price.default][preview.id]
            preview.onHand = books[this.props.appStates.bookNames.inventory.default][preview.id].onHand
            if(this.state.client && this.state.client.priceBookId) priceEntry = books[this.state.client.priceBookId][preview.id] ?? 0
            this.setState({ preview, priceEntry })
        }
    }
    getCBValue(value: string) {
        this.setState({productValue: value})
    }
    taxFunction() {
        return this.props.appStates.activeBU.legals.vat.rate
    }
    addItem() {
        if(this.state.quantity === 0) {
            const next:any = document.getElementById('products-cblist')
            this.setState({productValue: '', preview: null, quantity: 0, priceEntry: {entryValue: 0}})
            next?.focus()
            return
        }
        if(!this.state.preview) {
            this.setState({productValue: '', preview: null, quantity: 0, priceEntry: {entryValue: 0}})
            const next:any = document.getElementById('products-cblist')
            next?.focus()
            return
        }
        let items:any[] = this.state.items, itemsChange:any[] = this.state.itemsChange
        const item = Object.assign({}, this.state.preview)
            item.bzrProductId = item.id
            item["quantity"] = this.state.quantity
            item["amount"] = this.state.quantity * this.state.priceEntry.entryValue
            item["entryValue"] = this.state.priceEntry.entryValue
            item["bookId"] = this.state.priceEntry.bookId
            if(this.props.appStates.books[this.state.bzrInventoryBookId] && this.props.appStates.books[this.state.bzrInventoryBookId][item.id]) {
                item["bzrInventoryEntryId"] = this.props.appStates.books[this.state.bzrInventoryBookId][item.id].id
                item["bzrInventoryBookId"] = this.state.bzrInventoryBookId
            }
            delete item.id  ///////////////////////////////

        items.push(item)
        itemsChange.push(item)
        this.totalize(items, this.state.changeRate)
        this.displayTotalsBag()
        this.setState({productValue: '', preview: null, priceEntry: {entryValue: 0}})
        const next:any = document.getElementById('products-cblist')
        next?.focus()
    }
    totalize(items:any[], changeRate:number = 1, currencyCode:any = null) {
        let subTotal:number = 0, 
            tax1:number = 0,
            taxable:number = 0,
            exempt:number = 0

        currencyCode = currencyCode ?? this.state.billedIn.currencyCode
        const itemsChange:any[] = items.map((item:any, n:number) => {
            const i:any = Object.assign({}, item)
            if(i.currency === currencyCode) changeRate = 1
            if(this.state.retailed && item.unitsPerPkg) {
                i["entryValue"] = i.entryValue * changeRate / item.unitsPerPkg
                items[n]['retailed'] = i['retailed'] = 'true'
            }
            else {
                i["entryValue"] = i.entryValue * changeRate
                items[n]['retailed'] = i['retailed'] = 'false'
            }
            items[n]["amount"] = i["amount"] = i.quantity * i.entryValue
            subTotal += i.amount
            if (i.isTaxExempt === 'true') { 
                items[n]['taxRate1'] = 0
                exempt += i.amount 
            } else { 
                items[n]['taxRate1'] = i.taxRate1 = this.taxFunction()
                tax1 += i.amount * i.taxRate1
                taxable += i.amount
            }
            items[n]['n'] = n + 1
            items[n]['billedCurrency'] = this.state.billedIn.currencyCode
            items[n]['changeRate'] = changeRate
            return i
        })
        this.setState({
            itemsChange,
            items, 
            quantity: 0,
            animate: 'flash-text'
        })
        this.updateOrder({
            subTotal,
            tax1,
            taxable,
            total: subTotal + tax1 - this.state.order.credits,
            exempt
        })
        setTimeout(() => this.setState({animate: 'none'}), 1500)
    }
    renderItems() {
        const items:any[] = this.state.itemsChange
        let itemsList:any = <tr className="center-text"><td colSpan={7} className="text-center text-capitalize"><h4 style={{color: 'dimgray', marginTop: '10px'}}>empty!</h4></td></tr>
       
        if (items.length > 0)
            itemsList  = items.map((product:any, i:number) =>{
            product['n'] = i + 1
            return(
            <tr data-record-id={product.id} key={i}>
                <td>{product.n}</td>
                <td>{product.name}<p><b className="text-uppercase">sku/{t('code')}</b><b>:</b> {product.sku}</p></td>
                <td>{product.description}</td>
                <td className="number-format">
                        <EditableCell value={product.quantity} liftUpValue={(val:any) => {
                            const itms = this.state.items
                            itms[i].quantity = parseFloat(val)
                            this.totalize(itms, this.state.changeRate)
                        }} /> ({(product.unitOfMeasure && product.unitOfMeasure === '') || (this.state.retailed && product.unitsPerPkg) ? 'U' : product.unitOfMeasure.toUpperCase()})</td>
                <td className="number-format" style={{animationName: this.state.animate}}>
                    {fn(product.entryValue)} {product.isTaxExempt === 'true' ? '(e)' : ''}
                    {this.state.billedIn.code !== 'en-US' ? <p><b className="text-uppercase">($ {(this.state.retailed && product.unitsPerPkg) ? this.state.items[i].entryValue/this.state.items[i].unitsPerPkg : this.state.items[i].entryValue})</b></p> : null}
                </td>
                <td className="number-format" style={{animationName: this.state.animate}}>
                    {fn(product.amount)}
                </td>
                <td><Button data-product-index={i} className="btn-trash" onClick={
                    (event:any) => {
                        const index = parseInt(event.currentTarget.dataset.productIndex)
                        let items = this.state.items
                        items.splice(index, 1)
                        this.totalize(items, this.state.changeRate)
                    }
                } variant="danger"><span className="pe-7s-trash text-center" /></Button>
                </td>
            </tr>
            )})

        return(
            <Table id="item-list" className="items flash-text-animation" size="sm" bordered responsive>
                <thead>
                    <tr>
                        <th style={{minWidth: "30px"}}>#</th>
                        <th style={{minWidth: "225px"}}>name</th>
                        <th style={{minWidth: "300px"}}>description</th>
                        <th style={{minWidth: "80px"}} className="text-right">quantity</th>
                        <th style={{minWidth: "150px"}} className="text-right">{t('price')}({this.state.billedIn.currencySymbol})</th>
                        <th style={{minWidth: "150px"}} className="text-right">{t('amount')}({this.state.billedIn.currencySymbol})</th>
                        <th style={{minWidth: "10px"}}></th>
                    </tr>
                </thead>
                <tbody>
                    { itemsList }
                </tbody>
            </Table>
        )
    }
    async generateSaleOrder() {
        try {
            const response:any = await BzrAxios.orgs({url: `/ActiveBU/SeqRefs`})
            const { billSeq } = response.data.seqRefs
            return this.incOrderNumber(billSeq)
        } catch(err) {
            console.log(err)
        }
    }
    async verifyBillSeq(billSeq:string) {
        try {
            this.setState({verifiying: true})
            const response:any = await BzrAxios.orgs({url: `/ActiveBU/SeqRefs`})
            this.setState({seqRefs: response.data.seqRefs})

            if(parseInt(billSeq) > parseInt(response.data.seqRefs.billSeq)) {
                this.setState({verifiying: false})
                return true
            }
            this.setState({verifiying: false})
            return false
        } catch (err) {
            this.setState({verifiying: false})
            console.error(err)
            return false
        }
    }
    async clearForm() {
        if(!this.state.updateOrder || !!this.state.postOrder.saleOrder.status.match(/printed_/i)) {
            this.setState({
                generateDoc: null, 
                items: [], 
                itemsChange: [],
                client: {legalName: null},
                facilityValue: '',
                facility: null,
                facilities: [],
                date: new Date(),
                retailed: false,
                preview: null,
                productValue: '',
                priceEntry: {entryValue: 0, bookId: ''}
            })
            if(window.location.pathname.match(/\/admin\/sales\/[new|modify]/)) {
                const cbc:any = document.getElementById('cblist-client')
                cbc.value = ''
                cbc.focus()
            }
            // GET orderNumber Seq
            const order:any = {}
            try {
                const response:any = await BzrAxios.orgs({url: `/ActiveBU/SeqRefs`})
                const { billSeq, controlSeq, orderSeq } = response.data.seqRefs
                order.orderNumber = this.incOrderNumber(orderSeq)
                order.billNumber = this.incOrderNumber(billSeq)
                order.controlNumber = this.incOrderNumber(controlSeq)
                this.setState({seqRefs: response.data.seqRefs})
            } catch(err) {
                console.error(err)
                alert('series not updated, press F5 to update')
                return
            }
            this.updateOrder({
                subTotal: 0, 
                tax1: 0, 
                total: 0, 
                exempt: 0,
                credits: 0,
                taxable: 0,
                orderNumber: order.orderNumber,
                billNumber: order.billNumber,
                controlNumber: order.controlNumber,
                purchaseOrderNumber: '',
                paymentCondition: '0',
                remarks: ''
            })
        }
    }
    setRetailed() {
        let pkg:boolean = false
        this.state.items.forEach((item:any) => {
            if(item.unitOfMeasure === 'pkg' || item.unitOfMeasure === 'bulto') pkg = true
        })
        return(
            pkg ? <div>
             <Form.Group controlId="set-retailed">
                    <Form.Check 
                        defaultChecked={this.state.retailed}
                        onChange={async () => {
                            this.setState({retailed: !this.state.retailed})
                            setTimeout(() => this.totalize(this.state.items, this.state.changeRate), 100) 
                        }}
                        type="checkbox" 
                        label={<div>{t('bill retail')} {HelperMsg('For packages use a content ref. e.g.: 1Pkg = 10Unds, Fill de Units/Pkg fi')}</div>} 
                        style={{textTransform: 'uppercase', fontSize: '.9rem'}}
                        />
                </Form.Group>
            </div> : null
        )
    }
    renderTotals(showChangeRate:boolean = true) {
        const taxRate = this.props.appStates.activeBU.legals.vat.rate * 100,
            taxName = this.props.appStates.activeBU.legals.vat.name
        return(
            <>
                <div className={`p-0 m-0 ${this.state.billedIn.code === 'en-US' ? 'd-none' : ''}`}>
                    <button className="bzr-button-link" onClick={() => this.handleRateChange({target: {value: this.state.bcvRef ?? ''}})}>tasa promedio {this.state.bcvRef}</button>
                    <br />
                </div>
                <Container>      
                    {showChangeRate ? 
                    <Row className="py-2 px-0 text-right">
                        <Col>
                            <Form.Group as={Row} controlId="currencyLocale" className="mb-0">
                                <Form.Label column md={6} className="text-white text-uppercase">bill in</Form.Label>
                                <Col md={6}>
                                    <Form.Control className="text-uppercase form-control custom-select"  as="select" value={this.state.billedIn.code}
                                        onChange={this.changeCurrency}>
                                        <option value='es-VE'>{locales[this.props.appStates.activeBU.locale].currencySymbol}</option>
                                        <option value='en-US'>USD</option>
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group as={Row} controlId="change" className={`mb-0 ${this.state.billedIn.code === 'en-US' ? 'd-none' : ''}`}>
                                <Form.Label column className="text-white text-uppercase">
                                    <span>rate</span> ({locales[this.props.appStates.activeBU.locale].currencySymbol}/USD)
                                </Form.Label>
                                <Col>
                                    <Form.Control name="change" value={this.state.changeRate} className="number-format"
                                        onChange={this.handleRateChange}/>
                                </Col>
                            </Form.Group>
                        </Col>
                        <br />
                    </Row>
                    : null}
                    <Table className="totals-table" size="sm" responsive>
                        <tbody>
                            <tr className="sub-total">
                                <th>sub-total</th><th className="number-format">{this.state.billedIn.currencySymbol} {fn(this.state.order.subTotal)}</th>
                            </tr>
                            <tr className="">
                                <td>taxable base<b> {fn(this.state.order.taxable)}</b> {taxName} {fn(taxRate)}%</td>
                                <td className="number-format">{this.state.billedIn.currencySymbol} {fn(this.state.order.tax1)}</td>
                            </tr>
                            <tr className="mb-3">
                                <td>exempt<b> {fn(this.state.order.exempt)}</b> {fn(0)}%</td>
                                <td className="number-format">{this.state.billedIn.currencySymbol} {fn(0)}</td>
                            </tr>
                            <tr><td><br /></td></tr>
                            {this.state.order.credits !== 0 ? <tr className="sub-total">
                                <th>credits</th><th className="number-format">{this.state.billedIn.currencySymbol} {fn(this.state.order.credits)}</th>
                            </tr> : null}
                            {this.state.order.credits !== 0 ? <tr><td><br /></td></tr> : null }
                            <tr>
                                <th>total</th><th className="number-format">{this.state.billedIn.currencySymbol} {fn(this.state.order.total)}</th>
                            </tr>
                        
                        </tbody>
                    </Table>
                </Container>
        
            </>
        )
    }
    mapPrefixes(status:string) {
        const statusMap:any = {
            'printed_legal': "pl",
            'printed_receipt': "pr",
            'issued_legal': "il",
            'issued_receipt': "ir",
            'delivery_note': "dn", 
            'as_quotation': "aq", 
            'as_proforma': "ap", 
            'void_legal': "vl",
            'void_receipt': "vr"
        }
        return statusMap[status]
    }
    renderSaleOrderForm() {
        return(
            <Form className="bzr-pos-grid-2">
                <h5><b>invoice</b></h5>
                <br />
                <div className="d-flex mb-3">
                    <div className=''><Form.Label className="text-capitalize mb-2" style={{fontWeight: 600, fontSize: '0.9rem'}}>issue date</Form.Label></div>
                    <div className="ml-auto">
                        <DatePicker 
                            dateFormat={locales[this.props.appStates.activeBU.locale].dateFormat}
                            className="form-control text-right" 
                            selected={this.state.date ?? new Date()}
                            showYearDropdown
                            dropdownMode="select"
                            onChange={ date => this.setState({date}) }
                            />
                    </div>
                </div>
                <fieldset disabled={this.state.autoGenerate || this.state.autoIncrement}>
                { this.state.verifiying ? <div className="float-right verifier"><h6>{t('verifiying')}... <i className="fas fa-spinner fa-spin" /></h6></div> : null }
                <Row>
                    <Col md="5">
                        <Form.Label className="text-capitalize" 
                            style={{fontWeight: 600, fontSize: '0.9rem'}}>
                                <span>bill number</span> {" "} 
                                {HelperMsg("manual entry will update the sequence base.")}
                        </Form.Label>
                    </Col>
                    <Col md="7">
                        <Form.Group as={Row}>
                            <Form.Label column md="5" className="text-uppercase text-right px-0" 
                                style={{fontWeight: 600, fontSize: '1.1rem'}}>
                                    <span>{this.props.appStates.activeBU.preferences.prefixes[this.props.appStates.activeBU.locale].il} - </span>
                            </Form.Label>
                            <Col md="7" className="pl-1">
                                <Form.Control 
                                    type="text" 
                                    name="billNumber" 
                                    onChange={this.handleSOChange} 
                                    onBlur={async (event:any) => {
                                        const billSeq:string = event.target.value
                                        if(!await this.verifyBillSeq(billSeq)) {
                                            this.setState({duplicatedOrder: true, generateDoc: null})
                                            return
                                        } else {
                                            this.setState({duplicatedOrder: false})
                                            if(this.state.generateDoc) this.handlePreSubmit(this.state.generateDoc)
                                        }
                                    }}
                                    value={this.state.order.billNumber ?? ''} 
                                    isInvalid={this.state.duplicatedOrder}
                                    className="text-right"
                                    style={{fontSize: "1rem"}}
                                    />
                                <Form.Control.Feedback type="invalid" className="text-uppercase">
                                    {t('wrong sequence number. must be greater than:')} {this.state.seqRefs ? this.state.seqRefs.billSeq : null}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <div className="d-flex my-3">
                    <div className="input-sale-number px-3">
                        <label>control number</label>
                        <input id="control-number-input" type="text" name="controlNumber" value={this.state.order.controlNumber ?? ''} onChange={this.handleChange} />
                    </div>
                    <div className="input-sale-number px-3">
                        <label>order number</label>
                        <input id="order-number-input" type="text" name="orderNumber" value={this.state.order.orderNumber ?? ''} onChange={this.handleChange} />
                    </div>
                </div>
                </fieldset>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check 
                        defaultChecked={this.state.autoIncrement}
                        onChange={async () => {
                            this.setState({autoIncrement: !this.state.autoIncrement, duplicatedOrder: false})
                            this.updateOrder({billNumber: !this.state.autoIncrement ? await this.generateSaleOrder() : this.state.order.billNumber})
                        }}
                        type="checkbox" 
                        label="auto-increment" />
                </Form.Group>
             </Form>
            )
    }
    modalOptions(opt:string) {
        if(this.state.updateOrder) {
            
            switch(opt){
                case 'bill':
                    if(this.state.order.status === 'issued_legal'){
                        return(
                            <div className="bzr-pos-bill">
                                <ButtonGroup vertical size="sm">
                                    <Button className="text-capitalize font-weight-bolder py-2" variant='outline-info' onClick={() => this.handleSubmit('issued_legal', 'bill')}>{t('confirm')}</Button>
                                </ButtonGroup>
                            </div>            
                        )
                    } else {
                        return(
                            <div className="bzr-pos-bill">
                                <ButtonGroup vertical size="sm">
                                    {/* <Button className="text-capitalize font-weight-bolder py-2" variant='outline-success' onClick={() => this.handleSubmit('issued_legal', 'bill')}>{t('issue')} {t('invoice')}</Button> */}
                                    <Button className="text-capitalize font-weight-bolder py-2" variant='outline-success' onClick={() => this.handleSubmit('printed_legal', 'bill')}>{t('print')} {t('invoice')}</Button>
                                    <Button className="text-capitalize font-weight-bolder py-2" variant='outline-info' onClick={() => this.handleSubmit('printed_receipt', 'bill')}>{t('print')} {t('receipt')}</Button>
                                </ButtonGroup>
                            </div>            
                        )
                    }
                case 'save':
                    return(
                        <div >
                        <ButtonGroup vertical size="sm">
                            <Button className="text-capitalize font-weight-bolder py-2" variant='outline-success' onClick={() => this.handleSubmit(this.state.order.status, 'save')}>{t('save')}</Button>
                        </ButtonGroup>
                        </div>            
                    )
                case 'after-bill-bill':
                    return(
                        <div >
                            <ButtonGroup vertical size="sm">
                                <Button className="text-capitalize font-weight-bolder py-2" variant='outline-secondary' 
                                    onClick={() => {
                                        if(this.state.postOrder.saleOrder.status.match(/issued/ig)) {
                                            this.handlePrintLegal()
                                        } else this.handleShowLegal()
                                    }}>
                                    { this.state.postOrder && this.state.postOrder.saleOrder.status.match(/issued/ig) ? t('print on physical') : t('show physical') }
                                </Button>
                                <Button className="text-capitalize font-weight-bolder py-2" variant='outline-secondary' 
                                    onClick={() => this.handleShowDigital()}>
                                    {t('show in digital format')}
                                </Button>
                            </ButtonGroup>
                            <br /><br />
                            <ButtonGroup vertical size="sm">
                                <Button className="text-capitalize font-weight-bolder py-2" variant='outline-secondary' onClick={() => {
                                    const w:any = window.open()
                                    const { client, saleOrder, transacs } = this.state.postOrder
                                    const blobNote = printDeliveryNote(saleOrder, transacs, client, this.state.doptions)
                                    w.location.href = URL.createObjectURL(blobNote)
                                    setTimeout(() => {w.print()}, 500)
                                }}>
                                    {t('print delivery note')}
                                </Button>
                                <Form className="small text-left pl-3 mt-2">
                                    <Form.Check 
                                        custom
                                        checked={this.state.doptions.letterhead}
                                        type='checkbox'
                                        id={`cb-amount-letterhead`}
                                        label={t('print letterhead')}
                                        onChange={(event:any) => this.setState({doptions: Object.assign(this.state.doptions, {letterhead: event.target.checked})})}
                                    />
                                    <Form.Check 
                                        custom
                                        checked={this.state.doptions.printLocal}
                                        type='checkbox'
                                        id={`cb-amount-usd`}
                                        label={t('print in local currency')}
                                        onChange={(event:any) => {
                                            if(event.target.checked) {
                                                const doptions = this.state.doptions 
                                                doptions.printLocal = true
                                                doptions.printUsd = false
                                                doptions.currencyCode = 928
                                                this.setState({doptions})
                                            } else this.setState({doptions: Object.assign(this.state.doptions, {printLocal: false, currencyCode: null})})
                                        }}
                                    />
                                    <Form.Check 
                                        custom
                                        checked={this.state.doptions.printUsd}
                                        type='checkbox'
                                        id={`cb-amount-local`}
                                        label={t('print in USD')}
                                        onChange={(event:any) => {
                                            if(event.target.checked) {
                                                const doptions = this.state.doptions
                                                doptions.printUsd = true
                                                doptions.printLocal = false
                                                doptions.currencyCode = 840
                                                this.setState({doptions})
                                            } else this.setState({doptions: Object.assign(this.state.doptions, {printUsd: false, currencyCode: null})})
                                        }}
                                    />
                                </Form>
                                <Button className="text-capitalize font-weight-bolder py-2" variant='outline-secondary' onClick={() => {
                                    const w:any = window.open()
                                    const { client, saleOrder, transacs } = this.state.postOrder
                                    const blobNote = printDispatchNote(saleOrder, transacs, client, this.state.doptions)
                                    w.location.href = URL.createObjectURL(blobNote)
                                    setTimeout(() => {w.print()}, 500)
                                }}>
                                    {t('print dispatch note')}
                                </Button>
                            </ButtonGroup>
                        </div>            
                    )
                default:
                    return null
            }
        } else {
            switch(opt){
                case 'bill':
                    return(
                        <div className="bzr-pos-bill">
                        <ButtonGroup vertical size="sm">
                            {/* <Button className="text-capitalize font-weight-bolder py-2" variant='outline-success' onClick={() => this.handleSubmit('issued_legal', 'bill')}>{t('issue')} {t('invoice')}</Button> */}
                            <Button className="text-capitalize font-weight-bolder py-2" variant='outline-success' onClick={() => this.handleSubmit('printed_legal', 'bill')}>{t('print')} {t('invoice')}</Button>
                            <Button className="text-capitalize font-weight-bolder py-2" variant='outline-info' onClick={() => this.handleSubmit('printed_receipt', 'bill')}>{t('print')} {t('receipt')}</Button>
                        </ButtonGroup>
                        <br /><br />
                        <ButtonGroup vertical size="sm">
                            <Button className="text-capitalize font-weight-bolder py-2" variant='outline-danger' onClick={() => this.handleSubmit('printed_consignment', 'bill')}>{t('consignment')}</Button>
                        </ButtonGroup>
                        <br /><br />
                        <ButtonGroup vertical size="sm">
                            <Button className="text-capitalize font-weight-bolder py-2" variant='outline-secondary' onClick={() => this.handleSubmit('as_proforma', 'bill')}>{t('save as')} {t('proforma')}</Button>
                        </ButtonGroup>
                        </div>            
                    )
                case 'quote':
                    return(
                        <div >
                        <ButtonGroup vertical size="sm">
                            <Button className="text-capitalize font-weight-bolder py-2" variant='outline-info' onClick={() => this.handleSubmit('as_proforma', 'quote')}>{t('proforma invoice')}</Button>
                            <Button className="text-capitalize font-weight-bolder py-2" variant='outline-success' onClick={() => this.handleSubmit('as_quotation', 'quote')}>{t('quotation')}</Button>
                            <Button className="text-capitalize font-weight-bolder py-2" variant='outline-info' onClick={() => this.handleSubmit('as_sale_order', 'quote')}>{t('sale order')}</Button>
                        </ButtonGroup>
                        </div>            
                    )
                case 'save':
                    return(
                        <div >
                        <ButtonGroup vertical size="sm">
                            <Button className="text-capitalize font-weight-bolder py-2" variant='outline-success' onClick={() => this.handleSubmit('issued_legal', 'bill')}>{t('update')}</Button>
                        </ButtonGroup>
                        </div>            
                    )
                case 'after-bill-bill':
                    return(
                        <div >
                            <ButtonGroup vertical size="sm">
                                <Button className="text-capitalize font-weight-bolder py-2" variant='outline-secondary' 
                                    onClick={() => {
                                        if(this.state.postOrder.saleOrder.status.match(/issued/ig)) {
                                            this.handlePrintLegal()
                                        } else this.handleShowLegal()
                                    }}>
                                    { this.state.postOrder && this.state.postOrder.saleOrder.status.match(/issued/ig) ? t('print on physical') : t('show physical') }
                                </Button>
                                <Button className="text-capitalize font-weight-bolder py-2" variant='outline-secondary' 
                                    onClick={() => this.handleShowDigital()}>
                                    {t('show in digital format')}
                                </Button>
                            </ButtonGroup>
                            <br /><br /><br />
                            <ButtonGroup vertical size="sm">
                                <Button className="text-capitalize font-weight-bolder py-2" variant='outline-secondary' onClick={() => {
                                    const w:any = window.open()
                                    const { client, saleOrder, transacs } = this.state.postOrder
                                    const blobNote = printDeliveryNote(saleOrder, transacs, client, this.state.doptions)
                                    w.location.href = URL.createObjectURL(blobNote)
                                    setTimeout(() => {w.print()}, 500)
                                }}>
                                    {t('print delivery note')}
                                </Button>
                                <Form className="small text-left pl-3 mt-2">
                                    <Form.Check 
                                        custom
                                        checked={this.state.doptions.letterhead}
                                        type='checkbox'
                                        id={`cb-amount-letterhead`}
                                        label={t('print letterhead')}
                                        onChange={(event:any) => this.setState({doptions: Object.assign(this.state.doptions, {letterhead: event.target.checked})})}
                                    />
                                    <Form.Check 
                                        custom
                                        checked={this.state.doptions.printLocal}
                                        type='checkbox'
                                        id={`cb-amount-usd`}
                                        label={t('print in local currency')}
                                        onChange={(event:any) => {
                                            if(event.target.checked) {
                                                const doptions = this.state.doptions 
                                                doptions.printLocal = true
                                                doptions.printUsd = false
                                                doptions.currencyCode = 928
                                                this.setState({doptions})
                                            } else this.setState({doptions: Object.assign(this.state.doptions, {printLocal: false, currencyCode: null})})
                                        }}
                                    />
                                    <Form.Check 
                                        custom
                                        checked={this.state.doptions.printUsd}
                                        type='checkbox'
                                        id={`cb-amount-local`}
                                        label={t('print in USD')}
                                        onChange={(event:any) => {
                                            if(event.target.checked) {
                                                const doptions = this.state.doptions
                                                doptions.printUsd = true
                                                doptions.printLocal = false
                                                doptions.currencyCode = 840
                                                this.setState({doptions})
                                            } else this.setState({doptions: Object.assign(this.state.doptions, {printUsd: false, currencyCode: null})})
                                        }}
                                    />
                                </Form>
                                <br />
                                <Button className="text-capitalize font-weight-bolder py-2" variant='outline-secondary' onClick={() => {
                                    const w:any = window.open()
                                    const { client, saleOrder, transacs } = this.state.postOrder
                                    const blobNote = printDispatchNote(saleOrder, transacs, client, this.state.doptions)
                                    w.location.href = URL.createObjectURL(blobNote)
                                    setTimeout(() => {w.print()}, 500)
                                }}>
                                    {t('print dispatch note')}
                                </Button>
                            </ButtonGroup>
                        </div>            
                    )
                case 'after-bill-quote':
                        return(
                            <div >
                                <ButtonGroup vertical size="sm">
                                    <Button className="text-capitalize font-weight-bolder py-2" variant='outline-secondary' 
                                        onClick={() => this.handleShowDigital()}>
                                        {t('show in digital format')}
                                    </Button>
                                </ButtonGroup>
                            </div>            
                        )
                default:
                    return null
            }
        }
    }
    render() {
        return (
            <Container id="product-new-sale" className="mx-auto p-3 bg-white">
                <Row className="">
                    <Col md={5}>
                        <div className="bzr-pos-grid-1 border-right">
                            <div className="d-flex flex-column mb-0">
                                <div className="text-capitalize"><h5><b>client</b></h5></div>
                                <div className="">
                                    { !this.props.appStates.clients ? <div className="float-right verifier"><h6>{t('fetching clients')} <i className="fas fa-spinner fa-spin" /></h6></div> : null }
                                    <CBList2 
                                        id="cblist-client"
                                        pkey="legalName"
                                        filters={['identificationNumber']}
                                        items={this.state.clients}
                                        value={this.state.client ? this.state.client.legalName : ''}
                                        liftUpOption={this.handlePartyInput}
                                        nextFocus="products-cblist"
                                        />
                                    <span className="float-left" style={{fontSize: '0.8rem', fontWeight: 600, color: 'gray', textTransform: 'uppercase'}}>
                                        {this.state.client && this.state.client.priceBookId && this.props.appStates.bookNames ? t('price list') + ': ' + this.props.appStates.bookNames.price_hash[this.state.client.priceBookId].alias : null}
                                    </span>
                                    <button className="bzr-button-link" onClick={() => this.setState({addNewClient: true})}>add new client</button>
                                    <br />
                                </div>
                            </div>
                            {/* <hr style={{marginTop: "0.3rem"}}/> */}
                            <div className="d-flex flex-column mb-3">
                                <div className="text-capitalize">
                                    <h5 style={{display: 'inline-block'}}><b>product</b></h5>
                                    <DropdownButton
                                        id="dropdown-cblist-options"
                                        className="cblist-options"
                                        variant="outline-secondary"
                                        title={
                                            <span className="text-center">
                                                <i className="fas fa-cog" title={t('options')}></i>
                                            </span>
                                            }
                                    >
                                        {['description','sku','model','oems'].map((dd:any, k:number) => (
                                            <Form.Group controlId={dd} key={k}>
                                                <Form.Check type="checkbox" 
                                                    name={dd} 
                                                    label={dd}
                                                    checked={this.state.cblistFilters.find((f:string) => f === dd)}
                                                    onChange={(event:any) => {
                                                        let cblistFilters:any = this.state.cblistFilters
                                                        if(event.target.checked) {
                                                            cblistFilters.push(event.target.name)
                                                            this.setState({cblistFilters})
                                                        } else {
                                                            let index = cblistFilters.findIndex((f:string) => f === event.target.name)
                                                            cblistFilters.splice(index,1)
                                                            this.setState({cblistFilters})
                                                        }
                                                    console.log(cblistFilters)
                                                }}/>
                                            </Form.Group>
                                        ))}
                                    </DropdownButton>
                                    </div>
                                <div className="">
                                    { !this.props.products ? <div className="float-right verifier"><h6>{t('fetching products')} <i className="fas fa-spinner fa-spin" /></h6></div> : null }
                                    <CBList 
                                        id="products-cblist"
                                        pkey="name"
                                        value={this.state.productValue}
                                        selected={this.state.preview}
                                        liftUpOption={this.getPreview} 
                                        liftUpValue={this.getCBValue}
                                        items={this.props.products} 
                                        filters={this.state.cblistFilters}
                                        placeholder="name/id..."
                                        whenEmpty="no items..."
                                        nextFocus="quantity-input"
                                        disabled={!this.props.products ? true : false}
                                        />
                                    <FormInputs
                                        ncols={['7']}
                                        className={['p-0 my-0 ml-auto bzr-inventory-input']}
                                        properties={[
                                            {
                                                label: 'inventory',
                                                horizontalLabel: '5',
                                                fieldType: 'select',
                                                name: 'bzrInventoryBookId',
                                                value: this.state.bzrInventoryBookId,
                                                options: this.props.appStates.bookNames && this.props.appStates.bookNames.inventory ? Object.entries(this.props.appStates.bookNames.inventory).map((b:any) => [b[1], b[0]]) : [['','']],
                                                onChange: (event:any) => this.setState({bzrInventoryBookId: event.target.value})
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                            <Form.Row>
                                <Col md={12}><div id='qty-verifier' className="text-center verifier" style={{display: 'none'}}><h6>quantity must be greater than 0!</h6></div></Col>                               
                                <Col md={3}>
                                    <div className="input-sale-number">
                                        <label>quantity</label>
                                        <input id="quantity-input" type="text" name="quantity" value={this.state.quantity} onClick={(event:any) => event.target.select()} onChange={this.handleChange} onKeyUp={this.handleOnKeyUp} />
                                    </div>
                                </Col>
                                <Col md={1} className="mx-auto text-center pt-3"><span style={{fontSize: "1.2rem", fontWeight: 600}}>*</span></Col>
                                <Col md={3}>
                                    <div className="input-sale-number">
                                        <label>price</label>
                                        <input id="price-input" type="text" name="entryValue" value={this.state.priceEntry.entryValue} onClick={(event:any) => event.target.select()} onChange={this.handleChange} onKeyUp={this.handleOnKeyUp} />
                                    </div>
                                </Col>
                                <Col md={1} className="mx-auto text-center pt-3"><span style={{fontSize: "1.2rem", fontWeight: 600}}>=</span></Col>
                                <Col md={4}>
                                    <div className="input-sale-number">
                                        <label>amount</label>
                                        <input type="text" name="total" value={(this.state.quantity * this.state.priceEntry.entryValue).toFixed(2)} readOnly />
                                    </div>
                                </Col>
                            </Form.Row>
                            <br />
                            <Row>
                                <Col className="text-center new-item-btn">
                                <Button id="add-item-btn" type="button" variant="primary" onClick={this.addItem}>add item</Button>
                                </Col>
                            </Row>
                        </div>            
                    </Col>  
                    <Col md={4} className="">
                        { this.renderSaleOrderForm() }
                    </Col>
                    <Col md={3}>
                        <PreviewCard item={this.state.preview} appStates={this.props.appStates}/>
                    </Col>
                </Row>
                <br />
                <Row className="my-3 pb-5">
                    <Col md={{span: 12, order: 0}} className="mb-4">
                        <div>
                            {this.setRetailed()}
                        </div>
                        { this.renderItems() }
                        <hr />
                    </Col>
                    <Col md={{span: 6, order: 3}} id="totals-box">
                        { this.renderTotals()}
                    </Col>
                    <Col md={{span: 2, order: 2}} className="mx-auto">

                    </Col>
                    <Col md={{span: 4, order: 1}} className="bg-light">
                        <Form.Label className="text-capitalize mb-2" style={{fontWeight: 600, fontSize: '0.9rem'}}>optional fields</Form.Label>
                        <Form.Row className="mb-2">
                            <Form.Label column className="text-capitalize" style={{fontWeight: 600}}>purchase order #</Form.Label>
                            <Col md={7}>
                                <Form.Control 
                                    type="text" 
                                    name="purchaseOrderNumber" 
                                    onChange={this.handleChange} 
                                    value={this.state.order.purchaseOrderNumber} 
                                    className="text-right"
                                    style={{fontSize: "1.1rem"}}
                                    />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mb-2">
                            <Form.Label column className="text-capitalize">payment condition (days)</Form.Label>
                            <Col md={3}>
                                <Form.Control 
                                    type="number" 
                                    name="paymentCondition" 
                                    onChange={this.handleChange} 
                                    value={this.state.order.paymentCondition} 
                                    min={0}
                                    style={{fontSize: "1.1rem"}}
                                    />
                            </Col>
                        </Form.Row>
                        <Form.Row className="mb-2">
                            <Form.Label column className="text-capitalize">delivery to</Form.Label>
                            <Col md={8}>
                                <CBList 
                                    id="facility-cblist"
                                    style={{fontSize: '0.75rem'}}
                                    pkey="facilityName"
                                    value={this.state.facilityValue}
                                    selected={this.state.facility}
                                    liftUpOption={(facility:any) => this.setState({facility})} 
                                    liftUpValue={(facilityValue:any) => this.setState({facilityValue})}
                                    items={this.state.facilities}
                                    placeholder="name..."
                                    whenEmpty="..."
                                    disabled={!this.state.facilities ? true : false}
                                    />
                            </Col>
                        </Form.Row>
                        <Form.Group as={Col} md={12} className="px-0 mt-1">
                            <Form.Label className="text-capitalize" style={{fontWeight: 400}}>address</Form.Label>
                            <textarea id="deliveryAddress" className="bzr-textarea"
                                name="deliveryAddress" 
                                value={this.state.facility ? this.state.facility.addressLine1 : ''}
                                rows={3} 
                                onChange={(e:any) => {
                                    const facility:any = this.state.facility ?? {}
                                    facility['facilityName'] = this.state.facilityValue
                                    facility['addressLine1'] = e.target.value
                                    this.setState({facility})
                                }} 
                                />
                        </Form.Group>
                    </Col>
                    <Col md={{span: 5, order: 4}} className="new-sale-btns mb-2" >
                        <Form.Group as={Col} md={12} className="px-0 mt-1">
                            <Form.Check type="switch" id='remarks-switch' checked={this.state.showRemarks}
                                onChange={()=> {this.setState({showRemarks: !this.state.showRemarks})}}
                                label={t('add remarks')} />
                            <textarea id="remarks" className="bzr-textarea"
                                style={{resize: 'none', display: this.state.showRemarks ? 'block' : 'none'}}
                                name="remarks" 
                                value={this.state.order.remarks} 
                                rows={4}
                                cols={75}
                                wrap='hard'
                                maxLength={300}
                                onChange={this.handleChange} 
                                />
                        </Form.Group>
                     </Col>
                    <Col md={{span: 5, order: 6}} className="text-center text-capitalize new-sale-btns mb-2 mt-3" >
                        {this.state.updateOrder ? 
                        <>
                            <Button type="button" className="m-2" variant="secondary" onClick={() => this.handleSubmit(this.state.order.status, 'quote')} >save</Button>
                            <Button type="button" className="m-2" variant="primary" onClick={() => { 
                                if(!this.state.verifiying) this.handlePreSubmit('bill') 
                                else this.setState({generateDoc: this.state.order.status})
                            }} >bill</Button>
                        </> : 
                        <>
                            <Button type="button" className="m-2" variant="secondary" onClick={() => { 
                                    if(!this.state.verifiying) this.handlePreSubmit('quote')
                                    else this.setState({generateDoc: 'quote'})
                                }} >quote</Button>
                            <Button type="button" className="m-2" variant="primary" onClick={() => { 
                                if(!this.state.verifiying) this.handlePreSubmit('bill') 
                                else this.setState({generateDoc: 'bill'})
                                }} >bill</Button>
                        </>}
                    </Col>
                    <Col md={{span: 2, order: 5}} className="text-center new-sale-btns mb-2 mt-3" >
                        <Button id="clear" type="button" className="m-2" variant="danger"  onClick={async () => await this.clearForm()} >clear</Button>
                    </Col>
                </Row>
                
                {this.state.displayTotals ? <div className="totals-bag">{this.renderTotals(false)}</div> : null}
                <PreviewModal 
                    show={!this.state.submitting && !!this.state.generateDoc && !this.state.verifiying} 
                    url={this.state.previewURL} 
                    title={t(this.state.generateDoc)}
                    children={this.modalOptions(this.state.generateDoc)}
                    onHide={async () => {
                        //if(this.state.updateOrder) this.props.history.push('/admin/sales/transacs')
                        console.log(this.state.postOrder)
                        if(this.state.generateDoc.match('after-bill') && this.state.updateOrder && this.state.postOrder && !!this.state.postOrder.saleOrder.status.match(/printed_/)) {
                            this.props.history.push('/admin/sales/transacs')
                            return
                        } else if(this.state.generateDoc.match('after-bill') && !this.state.updateOrder) await this.clearForm()
                        this.setState({generateDoc: null})
                        }} />
                <MessageModal show={!!this.state.submitting} message={this.state.submitting ?? ''}/>
                <MessageModal show={!this.state.loaded} message={'recreating order'}/>
                <ParentModal 
                    title="new client"
                    show={this.state.addNewClient}  
					onHide={() => this.setState({addNewClient: false})}
				>
                    <ClientForm id="client" idn="01" {...this.props} liftUpAppStates={this.handleNewClient} parent={{onClose: () => this.setState({addNewClient: false})}} />
                </ParentModal>
            </Container>
        ) 
    }
}

export default withRouter(NewSale)