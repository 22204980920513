import React from 'react'
import BzrAxios from '../../../utils/BzrAxios'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import t from '../../../utils/I18n/I18n'

interface Props {
    order:any
    isSubmitting:any
    isSubmitted:any
    extras:any
}
interface State {
    returnProducts:boolean
}
class VoidInvoice extends React.Component<Props,State> {
    constructor(props:Props){
        super(props)
        this.state = {
            returnProducts: true
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleReturnProduct = this.handleReturnProduct.bind(this)
    }
    handleSubmit() {
        this.props.isSubmitting(true)
        const data = {status: this.props.order.orderStatus, returnProducts: this.state.returnProducts}
        BzrAxios.records({ url: `/SaleOrders/Void/${this.props.order.id}`, method: 'PUT', data })
        .then((response:any) => {
            console.log(response.data)
            const order = this.props.order
            order.status = response.data.saleOrder.status
            this.props.isSubmitting(false)
            this.props.isSubmitted()
        }).catch(error => {
            this.props.isSubmitting(false)
            console.log(error)
        })
    }
    handleReturnProduct(event:any) {
        this.setState({returnProducts: event.target.checked})
    }
    render() {
        if(!this.props.order) return null
        return(
            <div className="my-5 mx-3">
                <h2>{t(`cancel invoice`)} <span className="float-right h4">{this.props.order.status.toUpperCase()} / {this.props.order.billNumber}</span></h2>
                <hr />
                <div className="d-flex mb-5 pr-5">
                    <div className="p-2">
                        <Form.Check 
                            type="checkbox"
                            label={<h5>{t('return products')}</h5>}
                            name="returnProducts"
                            inline
                            defaultChecked
                            onChange={this.handleReturnProduct}
                        />
                    </div>
                </div>
                <div className="d-flex mt-3">
                    <div className="p-2 flex-fill text-center">
                        <Button onClick={this.handleSubmit}>{t('void')}</Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default VoidInvoice