import React from 'react'
import BzrAxios from '../../utils/BzrAxios'
import { Container, Row, Col } from 'react-bootstrap'
import ImageBox from '../Common/ImageBox'
import { PreviewModal } from '../../layouts/Modals/Modals'
import Printer from '../Printer/ProductToPDF'
import t from '../../utils/I18n/I18n'

interface Props {
    id?:string,
    item:any,
    appStates:any
}
interface State {
    fm:any,
    showPreviewModal:boolean,
    pdfURL:any
}

class PreviewCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            fm: null,
            showPreviewModal: false,
            pdfURL: null
        }
    }
    componentDidMount() {
        this.getObjectFields()
    }

    getObjectFields() { 
        BzrAxios.records({url: '/fields/2'})
            .then((r:any) => {
                const fm = new Map()
                r.data['2'].forEach((f:any) => {
                    fm.set(f.fieldName, f.fieldLabel)
                })
                this.setState({fm})
            })
    }

    addTableRows(item:any) {
        if(!item) return null
        let tr:any[] = [], key:any, value:any
        const fm = (key:string) => this.state.fm ? this.state.fm.get(key) : key
        
        for ([key, value] of Object.entries(item)) {
            if(key.match(/isTaxExempt/ig)) 
                tr.push(<tr key={key} ><td>{fm(key)}:</td><td>{value}</td></tr>)
        }
        return tr
    }

    trimDescription(desc:string) {
        if(desc.length > 70) return desc.slice(0,70) + '...'
        return desc
    }

    boolStr(b: string) {
        return b === 'true' ? 'yes' : 'no';
    }

    render() {
        let preview:any = this.props.item
        return(
            <Container className="bg-white pt-1" style={{minHeight: '225px'}}>
                <Row>
                    <Col>
                        <h5><b>Preview Item</b></h5>
                        { preview ?
                        <table className="table table-borderless small preview-table">
                            <tbody>
                                <tr>
                                    <td>name:</td>
                                    <td>{preview.name}<p><b>model/type:</b> {preview.type}</p></td>
                                </tr>
                                <tr>
                                    <td>available:</td>
                                    <td style={{fontSize: '1rem', color: (preview.onHand && preview.onHand < 1) ? 'red' : 'black', backgroundColor: (preview.onHand && preview.onHand < 1) ? 'yellow' : 'white' }}>{preview.onHand}</td>
                                </tr>
                                <tr>
                                    <td>description:</td>
                                    <td>{this.trimDescription(preview.description)} <p><b>sku: </b>{preview.sku}</p><p><b>barcode: </b>{preview.barcode}</p> </td>
                                </tr>
                                {this.addTableRows(preview)}
                            </tbody>
                        </table> :
                        <div className="py-5 text-center mx-auto border"><h4 style={{color: 'dimgray'}}>{t('Select an item!')}</h4></div>
                        }
                    </Col>
                    { preview ? 
                    <Col md="auto" style={{minWidth: '160px'}}>
                        <ImageBox id={'image-preview-' + this.props.id ?? '1'} url={preview.imageURL} size={150} /> 
                        <div className="text-right font-icon-detail">
                            <span className="pe-7s-cloud-download text-center" onClick={() => {
                                console.log(preview)
                                const titles = {title: 'product card'}
                                const pdfBLOB = Printer.productToPDF(this.props.appStates, preview, titles)
                                this.setState({showPreviewModal: true, pdfURL: URL.createObjectURL(pdfBLOB)})
                                }} />
                            <span className="pe-7s-print text-center" onClick={()=> {
                                const titles = {title: 'product card'}
                                const pdfBLOB = Printer.productToPDF(this.props.appStates, preview, titles)
                                const w:any = window.open()
                                w.location.href = URL.createObjectURL(pdfBLOB)
                                setTimeout(() => w.print(), 1000)                                   
                            }} />
                            <span className="pe-7s-more text-center"></span>
                        </div> 
                    </Col> : null }
                    { preview ? 
                    <Col md={12}>
                        <Container className="text-center">
                                                  
                        </Container> 
                    </Col> : null }
                </Row>
                {this.props.appStates.activeBU ? <PreviewModal 
                                    show={this.state.showPreviewModal} 
                                    url={this.state.pdfURL} 
                                    title="product card"
                                    onHide={() => {
                                        this.setState({showPreviewModal: false, pdfURL: null})
                                    }
                                } /> : null}
            </Container>
        )
    }
}

export default PreviewCard