import React from 'react'
import { Route } from 'react-router-dom'
import Home from './components/Guest/Home'
import Pricing from './components/Suscription/Pricing'
import Dashboard from './components/Dashboard/Dashboard'
import Profile from './components/User/Profile'
import ProfileForm from './components/User/ProfileForm'
import AddressForm from './components/User/AddressForm'
import SingleBox from './layouts/Forms/SingleBox'
import PreferenceForm from './components/User/PreferenceForm'
import Signin from './components/User/Signin'
import Signout from './components/User/Signout'
import TestBoard from './components/Guest/TestBoard'
import Signup from './components/User/Register'

export const guestRoutes = [
    {
        path: "/",
        exact: true,
        breadcrumbs: () => <div>Home!</div>,
        main: [[Home]],
        type: "plain",
        layout: ""        
    },
    {
        path: "/home",
        exact: true,
        breadcrumbs: () => <div>Home!</div>,
        main: [[Home]],
        type: "plain",
        layout: ""        
    },
    {
        path: "/features",
        exact: true,
        breadcrumbs: () => <div>Home!</div>,
        main: [[Home]],
        type: "plain",
        layout: ""        
    },
    {
        path: "/pricing",
        exact: true,
        breadcrumbs: () => <div>Pricing!</div>,
        main: [[Pricing]],
        type: "plain",
        layout: ""        
    },
    {
        path: "/signin",
        exact: true,
        breadcrumbs: () => <div>Sign In!</div>,
        main: [[Signin]],
        type: "form",
        layout: ""
    },
    {
        path: "/signup",
        exact: true,
        breadcrumbs: () => <div>Sign Up!</div>,
        main: [[Signup]],
        type: "form",
        layout: ""
    },
    {
        path: "/signout",
        exact: true,
        breadcrumbs: () => <div>Sign Out!</div>,
        main: [[Signout]],
        type: "form",
        layout: ""
    },
    {
        path: "/test-board",
        exact: true,
        main: [[TestBoard]],
        type: "form",
        layout: ""
    }
]

export const enrollmentRoutes = [
    {
        path: "/users/personal-information",
        name: "Personal Information",
        icon: "pe-7s-graph",
        breadcrumbs: () => <div>Personal Information</div>,
        comps: [{
            main: SingleBox,
            type: "single-submit",
            component: ProfileForm, 
            params: { id: 'user-profile', title: 'Personal Information', endpoint: 'PUT /users/:id'}
        }],
        layout: "/enrollment"
    },
    {
        path: "/users/primary-address",
        name: "Primary Address",
        icon: "pe-7s-graph",
        breadcrumbs: () => <div>Primary Address</div>,
        main: [
            [SingleBox, AddressForm, {id: 'user-address', title: 'Primary Address', endpoint: 'POST /users/:id/addresses'}]
        ],
        type: "nested-form-single-submit",
        layout: "/enrollment"
    },
    {
        path: "/users/preferences",
        name: "Preferences",
        icon: "pe-7s-graph",
        breadcrumbs: () => <div>Preferences</div>,
        main: [
            [SingleBox, PreferenceForm, {id: 'user-preference', title: 'Preferences', endpoint: 'POST /users/:id/preferences'}]
        ],
        type: "nested-form-single-submit",
        layout: "/enrollment"
    }
]

export const adminRoutes = [
    {
        dropdown: {
            name: "User",
            icon: "pe-7s-user",
            routes: [
                {
                path: "/profile",
                name: "Profile",
                icon: "fas fa-caret-righ",
                exact: true,
                breadcrumbs: () => <div>Profile!</div>,
                main: [[Profile]],
                type: "form",
                layout: "/admin"
                },
                {
                    path: "/user/preference",
                    name: "Prefs",
                    icon: "fas fa-caret-righ",
                    main: [
                        [SingleBox, PreferenceForm, {id: 'user-preference', title: 'Preferences', endpoint: 'POST /users/:id/preferences'}]
                    ],
                    type: "nested-form",
                    layout: "/admin"
                },
                {
                    path: "/user/profile",
                    name: "Dropdown Nested Form",
                    icon: "pe-7s-graph",
                    main: [
                        [SingleBox, ProfileForm, {id: 'user-profile', title: 'Personal Information', endpoint: 'PUT /users/:id'}], 
                        [SingleBox, AddressForm, {id: 'user-address', title: 'Contact Information', endpoint: 'PUT /users/:id/addresses/:id'}]
                    ],
                    type: "nested-form",
                    layout: "/admin"
                }
            ]
        }
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "pe-7s-display1",
        breadcrumbs: () => <div>Dashboard!</div>,
        main: [[Dashboard]],
        type: "view",
        layout: "/admin"
    },
    {
        path: "/profile/test2",
        name: "Nested Form",
        icon: "pe-7s-graph",
        breadcrumbs: () => <div>Test 2!</div>,
        main: [
            [SingleBox, ProfileForm, {id: 'user-profile', title: 'Personal Information', endpoint: 'PUT /users/:id'}], 
            [SingleBox, AddressForm, {id: 'user-address', title: 'Contact Information', endpoint: 'POST /users/:id/address'}]
        ],
        type: "nested-form-single-submit",
        layout: "/admin",
        extra: <hr />
    },
    {
        path: "/signout",
        name: "Sign Out",
        icon: "pe-7s-power",
        breadcrumbs: () => <div>Signout!</div>,
        main: [[Signout]],
        type: "",
        layout: "/admin"
    }
]

let idx = 100
const gidx = () => {
    return idx++
}

export const getRoutes = (routes:any, extras?:any) => {
    return routes.map((route:any, index:any) => {
        if(route.dropdown) return getRoutes(route.dropdown.routes, extras) 
        else 
            return <Route
                key={index}
                path={route.layout + route.path}
                exact={route.exact}
                children={ (props:any) => {
                    var row:any = []
                    route.main.forEach((item:any, key:any) => { 
                        var ob = { main: item }
                        ob.main= item[0]
                        if(route.type.match(/nested-form/gi)) {/* 
                            let singleSubmit = false
                            if(route.type.match(/single-submit/gi)) singleSubmit = true */
                            row.push(
                                <ob.main 
                                    key={key} 
                                    idn={gidx()}
                                    appStates={extras.appStates} 
                                    liftUpAppStates={extras.liftUpAppStates} 
                                    singleSubmit={extras.singleSubmit}
                                    formType={item[1]} 
                                    params={item[2]}
                                    comps={route.comps ?? null}
                                    {...props} />
                            )
                        } else {
                            row.push(<ob.main 
                                key={key} 
                                appStates={extras.appStates} 
                                liftUpAppStates={extras.liftUpAppStates} 
                                {...props} />)
                        }
                    })
                    return <div>{ row }</div>
                }}
            />
    })
}