import React from 'react'
import imageMock from '../../assets/img/upload-image.png'
import AWS from 'aws-sdk'

interface Props {
	liftUpState: any,
	boxW: number,
	boxH: number,
	image?: any, 
	className?: any,
	scale?: number,
	disabled?: boolean
}

class ImageUpload extends React.Component<Props> {
	imgLoaded:any = null
	componentDidMount() {
		if(this.props.image && this.props.image.imageURL) {
			this.imgLoaded = this.props.image.imageURL
			this.loadInitImage()
		} else if(this.props.image && this.props.image.imageStorage) {
			this.imgLoaded = this.props.image.imageStorage
			this.loadInitImage()
		} else {
			this.imgLoaded = imageMock
			this.loadInitImage()
		}
	}

	componentDidUpdate() {
		if( this.props.image && this.props.image.imageURL && this.imgLoaded !== this.props.image.imageURL) {
			this.imgLoaded = this.props.image.imageURL
			console.log('imgURL', this.imgLoaded)
			setTimeout(() => this.loadInitImage(), 500)
		} else if(this.props.image && this.props.image.imageStorage && this.imgLoaded !== this.props.image.imageStorage) {
			this.imgLoaded = this.props.image.imageStorage
			console.log('imgStorage', this.imgLoaded)
			setTimeout(() => this.loadInitImage(), 500)
		} else if(!this.props.image) {
			this.imgLoaded = imageMock
			console.log('imgMock', this.imgLoaded)
			setTimeout(() => this.loadInitImage(), 500)
		}
	}

	loadInitImage() {
		const canvas:any = document.getElementById("preview"),
		ctx = canvas.getContext("2d"),
		boxW = this.props.boxW,
		boxH = this.props.boxH,
		image:any = document.createElement('img')
		if(this.props.image && this.props.image.imageURL) {
			/////////////////////////////////////////////////////////////////
				image.src = this.props.image.imageURL
				image.onload = () => {
					const	{ w, h } = this.fitImage(image.width, image.height, this.props.scale ?? 1)
					ctx.clearRect(0, 0, boxW, boxH)	
					this.scaleCanvas(canvas, ctx, w, h)
					ctx.drawImage(image, 0, 0, w, h)
				}
			/////////////////////////////////////////////////////////////////
		} else if(this.props.image && this.props.image.imageStorage) {
			/////////////////////////////////////////////////////////////////
			const s3 = new AWS.S3({
                apiVersion: '2006-03-01',
                params: {
                    Bucket: 'bizor-s3-imgs', //REQUIRED -  Amazon S3 bucket
                }
            })
			s3.getObject({
				Bucket: 'bizor-s3-imgs', 
				Key: `public/${this.props.image.imageStorage}`,
				ResponseCacheControl: 'no-cache, max-age=0'
			}, (err:any, result:any) => {
				if(err) {
					console.log(err)
					return
				}
				const blob = new Blob([result.Body], {type: result.ContentType})
				image.src  = URL.createObjectURL(blob)
				image.onload = () => {
					const	{ w, h } = this.fitImage(image.width, image.height, this.props.scale ?? 1)
					ctx.clearRect(0, 0, boxW, boxH)	
					this.scaleCanvas(canvas, ctx, w, h)
					ctx.drawImage(image, 0, 0, w, h)
				}
			})
			/////////////////////////////////////////////////////////////////
		} else {
			image.src = imageMock
			console.log(imageMock)
			image.onload = () => {
				const { w, h } = this.fitImage(image.width, image.height, .8)
				this.scaleCanvas(canvas, ctx, w, h)
				ctx.clearRect(0, 0, boxW, boxH)
				ctx.drawImage(image, 0, 0, w, h)
			}
		} 
	}

    handleImageSelect(event: any) {
		const file = event.target.files[0],
			imageURL = URL.createObjectURL(file),
			image:any = document.createElement('img'),
			boxW = this.props.boxW,
			boxH = this.props.boxH
			
		image.src = imageURL
		image.onload = () => {
			const canvas:any = document.getElementById('preview'),
				ctx:any = canvas.getContext("2d")

			const { w, h } = this.fitImage(image.width, image.height)
			ctx.clearRect(0, 0, boxW, boxH)	
			this.scaleCanvas(canvas, ctx, w, h)
			ctx.drawImage(image, 0, 0, w, h)
			if (file.size > boxW * 1024) {
				canvas.toBlob((blob: any) => { 
					this.props.liftUpState({imageFile: blob, imageURL }) 
				})
			} else {
				this.props.liftUpState({imageFile: file, imageURL })
			}		
		}
	}

	fitImage(width:number, height:number, scale:number = 1) {
		const boxW = this.props.boxW,
			boxH = this.props.boxH
		let w = 0, h = 0, oy = 0, imgRel = width/height, boxRel = boxW/boxH

		if (imgRel > 0 && imgRel > boxRel) {
			w = boxW
			h = boxW/imgRel
			oy = (boxH - h)/2 
		} else if(imgRel > 0 && boxRel >= imgRel) {
			h = boxH
			w = boxH * imgRel
		}
		const cc  = document.getElementById('canvas-container')
		if(cc) {
			cc.style.width = (w + 3) + 'px'
			if(scale < 1) {
				cc.style.paddingLeft = h * (1 - scale)/2  + 'px'
				cc.style.paddingTop = h * (1 - scale)/2 + 'px'
			} else {
				cc.style.paddingLeft = '1px'
				cc.style.paddingTop = oy + 'px'
			}
		}
		w *= scale
		h *= scale
		return { w, h }
	}

	scaleCanvas(canvas:any, context:any, width:any, height:any) {
		// assume the device pixel ratio is 1 if the browser doesn't specify it
		const devicePixelRatio = window.devicePixelRatio || 1;
		// determine the 'backing store ratio' of the canvas context
		const backingStoreRatio = (
		context.webkitBackingStorePixelRatio ||
		context.mozBackingStorePixelRatio ||
		context.msBackingStorePixelRatio ||
		context.oBackingStorePixelRatio ||
		context.backingStorePixelRatio || 1
		);
		// determine the actual ratio we want to draw at
		const ratio = devicePixelRatio / backingStoreRatio;
		if (devicePixelRatio !== backingStoreRatio) {
		// set the 'real' canvas size to the higher width/height
		canvas.width = width * ratio;
		canvas.height = height * ratio;
		// ...then scale it back down with CSS
		canvas.style.width = width + 'px';
		canvas.style.height = height + 'px';
		}
		else {
		// this is a normal 1:1 device; just scale it simply
		canvas.width = width;
		canvas.height = height;
		canvas.style.width = '';
		canvas.style.height = '';
		}
		// scale the drawing context so everything will work at the higher ratio
		context.scale(ratio, ratio);
	}


    render() {
        return (
            <div>
                <input 
                    type="file"
                    id="product-image" 
                    hidden 
                    style={{opacity: 0}} 
                    accept='image/png, image/jpeg' 
                    onChange={(e:any) => this.handleImageSelect(e)} 
					disabled={this.props.disabled ?? false} 
					/>
					<div id='canvas-container' style={{width: `${this.props.boxW}px`, height: `${this.props.boxH}px`}} 
						onClick={() => document.getElementById('product-image')?.click() } 
						className={this.props.className ?? ''} >
						<canvas id="preview" width={this.props.boxW} height={this.props.boxH} />
					</div>
            </div>
        )
    }
}

export default ImageUpload