import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col, Button, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import t from '../../utils/I18n/I18n'

export function MessageModal(props: any) {
    return (
      <Modal
        {...props}
        centered
        dialogClassName="modal-100vw"
        >
            <Modal.Body>
            <h4 style={{textAlign:"center", color: "white", margin: "20px 0 20px"}}>{t(props.message)} <FontAwesomeIcon icon={faSpinner} spin /></h4>
            </Modal.Body>
        </Modal>
    )
}

export function ConfirmModal(props: any) {
    const [submitting, setSubmitting]:[boolean,any] = useState(false)
    return (
      <Modal
        {...props}
        centered
        dialogClassName="modal-confirm"
        onExit={() => setSubmitting(false)}
        onHide={props.onHide}
        >
            <Modal.Body className="bg-transparent">
            <Container>
                <Row className="show-grid">
                    <Col xs={12} md={{span: "auto", order: 1}} 
                        style={{height: "fit-content", minWidth: "180px"}}
                        className="border rounded-sm bg-light">
                        <h4 className="text-center mt-3 text-capitalize"><b>{t(props.title)}</b></h4>
                        <br />
                        <div className="text-center">
                           { 
                            !submitting ? 
                            <div>
                                { props.children }
                                <Button className="text-capitalize font-weight-bolder py-2" 
                                    variant='outline-secondary' 
                                    onClick={() => setSubmitting(true) }
                                    >
                                    {t(props.confirmtitle)}
                                </Button>
                            </div> : <h4>{t('processing')}<FontAwesomeIcon icon={faSpinner} spin /></h4>
                            }
                        </div>
                        <br />
                        <div className="my-2 text-center btns">
                            <Button variant="danger" onClick={(event:any) => {
                                event.stopPropagation()
                                props.onHide()
                                }}>{t('cancel')}</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            </Modal.Body>
        </Modal>
    )
}

export function PreviewModal(props: any) {
    return (
      <Modal
        {...props}
        centered
        dialogClassName="modal-preview"
        >
            <Modal.Body className="bg-transparent">
            <Container>
                <Row className="show-grid">
                    <Col xs={12} md={{span: true, order: 2}} className="mx-auto text-center">
                        {props.url ? 
                        <object id="preview-pdf" title="preview" data={props.url} className="" /> : null}
                    </Col>
                    <Col xs={12} md={{span: "auto", order: 1}} 
                        style={{height: "fit-content", minWidth: "180px"}}
                        className="border rounded-sm bg-light">
                        <h4 className="text-center mt-3 text-capitalize"><b>{t(props.title)}</b></h4>
                        <br />
                        <div className="text-center">
                            {props.children}
                        </div>
                        <br />
                        <div className="mb-2 text-center btns">
                            <a className="download" role="button" href={props.url}
                            download={"order.pdf"} >
                            {t('download')} </a>{" | "}
                            <a href="#print" onClick={(event:any)=> {
                                event.preventDefault()
                                const w:any = window.open()
                                w.location.href = props.url
                                setTimeout(() => w.print(), 1500)
                                }}> 
                                {t('print')} </a>
                        </div>
                        <div className="my-2 text-center btns">
                            <Button variant="danger" onClick={props.onHide}>{t('close')}</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            </Modal.Body>
        </Modal>
    )
}

export function ParentModal(props: any) {
    return (
        <Modal
            {...props}
            centered
            dialogClassName="modal-parent"
            >
            <Modal.Body className="bg-transparent">
                {props.title ? 
                    <h4 className="text-center mt-3 mb-5 text-capitalize"><b>{t(props.title)}</b></h4>
                : null}
                {props.children}
            </Modal.Body>
        </Modal>
      )  
}

export function MsgBubble(props:any) {
    const [show, setShow] = useState(false)
    useEffect(() => {
        if(!show && props.msg) {
            setShow(true)
            setTimeout(() => {
                props.onClose()
                setShow(false)
            }, 3000)
        }
    },[props, show])
    return(
        <div className={`bzr-msg-bubble ${props.msg ? 'show' : ''}`}>
            {props.msg ?
            <Alert variant={props.msg.variant} onClose={() => props.onClose()} dismissible>
                <Alert.Heading>{t(props.msg.info)}</Alert.Heading>
            </Alert> : 
            null
            }
        </div>
    )
}