import React, {useState} from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import TopNavbar from './TopNavbar'
import { Card, Modal, Button, Form, Col, Table } from 'react-bootstrap'
import ImageBox from '../../Common/ImageBox'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

interface Props {
    liftUpCapture?:any
}
interface S {
    imgList: any[]
    showAreaList:boolean
    areas:any[]
}
class WasmerApp extends React.Component<Props,S> {
    constructor(props:Props) {
        super(props)
        this.state = {
            imgList: [],
            showAreaList: true,
            areas: []
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleAddArea = this.handleAddArea.bind(this)
    }
    handleAddArea() {
        this.setState({showAreaList: false})
    }
    handleSubmit(values:any, formikBag:any) {
        values.imgs = this.state.imgList
        const areas:any = this.state.areas
        areas.push(values)
        this.setState({areas, imgList: [], showAreaList: true})
        formikBag.resetForm()
    }
    printPDF() {
        const doc:any = new jsPDF('p', 'mm', 'letter')
        doc.autoTable({html: "#area-list"})
        doc.save('table.pdf')
    }

    render() {
        return  (
            <div>
                <TopNavbar />
                <div className="container-md" style={{maxWidth: "992px"}}>
                    <h2>Demo App</h2>
                    <br />
                    {
                    this.state.showAreaList ? 
                    <div className="d-flex flex-column">
                        <div className="px-auto mx-auto mb-3">
                            <Button variant='outline-primary'
                                onClick={this.handleAddArea}
                                style={{width: "350px", padding: "10px auto", fontSize: '1.5rem' , fontWeight: 600, textTransform: 'capitalize'}} 
                                >
                                    Get Area
                            </Button>
                        </div>
                        <div className="px-auto mx-auto">
                            <h4>Area List</h4>
                            <Card style={{width: "350px"}}>
                                <Card.Body className="px-0">
                                    <Button variant="outline-secondary" className="float-right mr-3 mb-2"  onClick={() => this.printPDF()} ><span><i className="fas fa-file-pdf" title='print pdf' /></span></Button>
                                    
                                    <Table size="sm" responsive bordered id="area-list" >
                                            <thead>
                                                <tr>
                                                    <th className="text-center">#</th>
                                                    <th className="text-center">name</th>
                                                    <th className="text-center">dimensions</th>
                                                    <th className="text-center">ceiling</th>
                                                    <th className="text-center">fixtures</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.areas.length !== 0 ? this.state.areas.map((area:any, i:number) => {
                                                    console.log(area)
                                                    const dims:string = `${area.width} x ${area.long} = ${area.width * area.long}`
                                                    return(
                                                    <tr key={i} >
                                                        <td className="text-center">{i + 1}</td>
                                                        <td className="text-center">{area.areaName}</td>
                                                        <td className="text-center">{dims}</td>
                                                        <td className="text-center">{area.height}</td>
                                                        <td className="text-center">{area.fixtureCount}</td>
                                                    </tr>
                                                )}) : <tr key="11"><td colSpan={5} className="text-center">no areas captured!!!</td></tr>}
                                            </tbody>
                                        </Table>
                                </Card.Body>
                            </Card>
                        </div>
                    </div> 
                    :
                    <div className="d-flex flex-column">
                        <Formik 
                            validationSchema={
                                Yup.object({
                                    areaName: Yup.string().max(20, 'string too large').required(),
                                    desc: Yup.string().max(500, 'string too large').required(),
                                    width: Yup.number().nullable(),
                                    long: Yup.number().nullable(),
                                    height: Yup.number().nullable(),
                                    temp: Yup.number().nullable(),
                                    fixtureCount: Yup.number().nullable()
                                })
                            }
                            onSubmit={this.handleSubmit}
                            initialValues={
                                {
                                    areaName: '',
                                    desc: '',
                                    width: '',
                                    long: '',
                                    height: '',
                                    temp: '',
                                    fixtureCount: ''
                                }
                            }
                            enableReinitialize={true}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                setFieldValue,
                                isSubmitting,
                                values,
                                touched,
                                errors
                            }) => (
                                <Form noValidate onSubmit={handleSubmit} className="mx-auto">
                                    <div className="px-auto mx-auto">
                                        <Card style={{width: "350px"}}>
                                            <Card.Body>
                                                <h4>area name</h4>
                                                <Form.Row>
                                                    <Col>
                                                        <Form.Control placeholder="area name" name='areaName' value={values.areaName} onChange={handleChange} />
                                                        <br />
                                                        <Form.Control placeholder="area description" name='desc' value={values.desc} onChange={handleChange} />
                                                    </Col>
                                                </Form.Row>
                                                <h4>dimensions</h4>
                                                <Form.Row className="mt-3">
                                                    <Col sm={3}>
                                                        <Form.Label style={{fontSize: ".75rem", color: "gray", fontWeight: 600, textTransform: 'uppercase'}}>witdh</Form.Label>
                                                        <Form.Control placeholder="w" name='width' value={values.width} onChange={handleChange} />
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Form.Label style={{fontSize: ".75rem", color: "gray", fontWeight: 600, textTransform: 'uppercase'}}>length</Form.Label>
                                                        <Form.Control placeholder="l" name='long' value={values.long} onChange={handleChange} />
                                                    </Col>
                                                    <Col sm={1}></Col>
                                                    <Col sm={5}>
                                                        <Form.Label style={{fontSize: ".75rem", color: "gray", fontWeight: 600, textTransform: 'uppercase'}}>ceiling height</Form.Label>
                                                        <Form.Control placeholder="h" name='height' value={values.height} onChange={handleChange} />
                                                    </Col>
                                                </Form.Row>
                                                <hr />
                                                <Form.Row>
                                                    <Col>
                                                        <Form.Label style={{fontSize: ".75rem", color: "gray", fontWeight: 600, textTransform: 'uppercase'}}>ceiling temp</Form.Label>
                                                        <Form.Control placeholder="Fº" name='temp' value={values.temp} onChange={handleChange} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label style={{fontSize: ".75rem", color: "gray", fontWeight: 600, textTransform: 'uppercase'}}>fixture count</Form.Label>
                                                        <Form.Control placeholder="" name='fixtureCount' value={values.fixtureCount} onChange={handleChange} />
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="px-auto mx-auto">
                                        <GetCapture liftUpCapture={(capture:any) => {
                                            let imgList:any = this.state.imgList ?? []
                                            imgList.push(capture)
                                            this.setState({imgList})
                                        }}/>
                                    </div>
                                    <div className="px-auto mx-auto">
                                        {
                                        this.state.imgList.length !== 0 ? <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th style={{width: '40px'}} className="text-center">#</th>
                                                    <th style={{width: '210px'}}>remark</th>
                                                    <th style={{width: '100px'}} className="text-center">img</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.imgList.map((cap:any, i:number) => (
                                                    <tr key={i}>
                                                        <td className="text-center">{i + 1}</td>
                                                        <td>{cap.remarks}</td>
                                                        <td className="text-center">
                                                            <ImageBox url={cap.url} id={`img-${i}`} size={75} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table> : null
                                        }
                                    </div>
                    
                                    <div className="px-auto mx-auto mb-2">
                                        <Button variant='outline-primary' type='submit'
                                            style={{width: "350px", padding: "10px auto", fontSize: '1.5rem' , fontWeight: 600, textTransform: 'capitalize'}} 
                                            >
                                                Submit
                                        </Button>
                                    </div>
                                    <div className="px-auto mx-auto mb-5 mr-5">
                                        <i className="fa fa-times float-right" onClick={() => this.setState({showAreaList: true})} style={{fontSize: "32px", marginRight: "20px"}}/>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

export const GetCapture = (props:any) => {
    const [show, setShow] = useState(false)
    const [capture, setCapture] = useState(null)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)


    return (
        <>
            <Card style={{width: "350px"}} onClick={handleShow}>
                <Card.Body>
                <div className="d-flex bd-highlight">
                    <div className="p-2 flex-grow-1 bd-highlight">
                        <h3 style={{marginTop: "10px"}}>capture space</h3>
                    </div>
                    <div className="p-2 bd-highlight">
                        <i className="ti-camera" style={{fontSize: "3rem"}}/>  
                    </div>
                </div>
                </Card.Body>
            </Card>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Capture it!!!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
               
                                <CaptureBox liftUpCapture={setCapture} />
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" className="mr-auto" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => {
                        props.liftUpCapture(capture)
                        handleClose()
                        }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default WasmerApp

interface State {
    showCapture:boolean
    deviceOptions:any
    capture:any
    mediaStreamId:any
}
class CaptureBox extends React.Component<Props,State> {
    video_reference = React.createRef<HTMLVideoElement>()
    constructor(props:Props) {
        super(props)
        this.state = {
            showCapture:false,
            deviceOptions: [],
            capture: {remarks: ''},
            mediaStreamId: ''
        }
    }
    async componentDidMount() {
        const gotDevices = (mediaDevices:any) =>
            new Promise((resolve, reject) => {
                let availableVideoInputs:any = []
                mediaDevices.forEach((mediaDevice:any) => {
                    if (mediaDevice.kind === 'videoinput') {
                        availableVideoInputs.push({
                        deviceId: mediaDevice.deviceId,
                        label: mediaDevice.label
                        })
                    }
                })
                console.log(mediaDevices)
                if (availableVideoInputs.length > 0) {
                resolve(availableVideoInputs)
                } else {
                reject(new Error('ERR::NO_MEDIA_TO_STREAM'))
            }
        })

        if (this.video_reference.current) {
            // facingMode: "user" -> frontal camera
            const video_stream: MediaStream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" }, audio: false })
            this.setState({mediaStreamId: video_stream.id})
            console.log(video_stream.id)
            this.video_reference.current.srcObject = video_stream
        }
        ////////

        

        navigator.mediaDevices.enumerateDevices().then(gotDevices)
        .then(async (availableVideoInputs:any) => {
            let deviceOptions:any = availableVideoInputs.map((d:any, k:number) => <option key={k} value={d.deviceId}>{d.label}</option>)
            this.setState({deviceOptions})
            console.log(availableVideoInputs)
        })
        .catch((err) => console.log(err))

        ////////
    }

    async handleOnChangeDevice(event:any) {
        if (this.video_reference.current) {
            this.video_reference.current.pause()
            const constraints:any = {
                video: {
                    video: true,
                    deviceId: {exact: event.target.value}
                },
                audio: false
            }
            const video_stream: MediaStream = await navigator.mediaDevices.getUserMedia(constraints)
            this.video_reference.current.srcObject = video_stream
            this.setState({mediaStreamId: video_stream.id})
            this.video_reference.current.play()
        }
    }

    render() {
        return (
            <div className="d-flex flex-column mb-0">
                <div className="mx-auto" style={{position: 'relative'}}>
                    <video ref={this.video_reference} id="player" autoPlay style={{width: "300px"}} playsInline muted />
                    <canvas id="capture" width="300" style={{
                        position: 'absolute',
                        top: 0,
                        display: this.state.showCapture ? 'block' : 'none'
                    }}/>
                </div>
                <div className="p-2 text-center">
                <Form.Control
                    as="select"
                    className="my-1 mr-sm-2"
                    id="inlineFormCustomSelectPref"
                    custom
                    style={{width: "300px"}}
                    value={this.state.mediaStreamId}
                    onChange={(event:any) => this.handleOnChangeDevice(event)}
                >
                    {this.state.deviceOptions}
                </Form.Control>
                <br /><br />
                <Button variant={this.state.showCapture ? 'danger' : 'primary'} 
                    onClick={() => {
                        if(this.state.showCapture) {
                            this.setState({showCapture: false})
                        } else {
                            this.setState({showCapture: true})
                            const video:any = document.getElementById('player')
                            const canvas:any = document.getElementById('capture')
                            canvas.height = video.offsetHeight
                            canvas.getContext('2d').drawImage(video, 0, 0, 300, video.offsetHeight)
                            
                            canvas.toBlob((blob: any) => {
                                const capture:any = this.state.capture
                                capture.blob = blob
                                capture.url = URL.createObjectURL(blob)
                                this.setState({capture})
                                this.props.liftUpCapture(capture)
                            })
                        }
                    }}
                    style={{width: "300px", padding: "10px auto", fontSize: '1.5rem' , fontWeight: 600, textTransform: 'capitalize'}} 
                    >
                        { this.state.showCapture ? 'take again' : 'take shot'}
                    </Button>
                <br /><br />
                <Form.Control
                    as="textarea"
                    className="my-1 mr-sm-2"
                    id="remarks"
                    custom
                    placeholder="remarks"
                    value={this.state.capture.remarks}
                    onChange={(event:any) => {
                        const capture:any = this.state.capture
                        capture.remarks = event.target.value
                        this.setState({capture})
                        this.props.liftUpCapture(capture)
                    }}
                    style={{width: "300px", height: "100px"}} 
                >
                </Form.Control>
                </div>
            </div>
        )
    }
}